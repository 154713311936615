



/*
  Template Name: SolTech - Premium Marketing Landing Pages Pack
  Theme URL: https://themeforest.net/user/dsathemes
  Description: SolTech - Premium Marketing Landing Pages Pack
  Author: DSAThemes
  Author URL: https://themeforest.net/user/dsathemes
  Version: 1.2.0
  Website: www.dsathemes.com
  Tags: HTML5, DSAThemes, Creative, Agency, Digital Business, IT Solutions, Digital Product, Marketinge, SaaS, Startup
*/




/* ==========================================================================
  01. GENERAL & BASIC STYLES
  =========================================================================== */

:root {
  --theme-color: #c00;
  --theme-btn-hover: #a00;
  --black: #2e3648;
  --black: #22223b;
  --blue: #308af0;
  --coal: #353f4f;
  --disable: #b5b5b5;
  --form-control: #fafafd;
  --gray: #6c757d;
  --green: #0fbc49;
  --header-color: #313435;
  --indigo: #5d74f2;
  --ink: #1d222f;
  --metal: #2c3840;
  --light-gray: #999;
  --ocean: #167efb;
  --pink: #f74780;
  --purple: #6633ff;
  --silver: #ccc;
  --sky-blue: #04aaff;
  --smoke: #e7e7e7;
  --snow: #f5f6f6;
  --white: #fff;
  --white-smoke: #f4f4f9;
  --yellow: #ffbf15; 
  --tra-black: rgba(0, 0, 0, .08); 
  --tra-blue: rgba(48, 138, 240, .13); 
  --tra-coal: rgba(5, 5, 5, .05);
  --tra-gray: rgba(96, 96, 96, .13);
  --tra-green: rgba(15, 188, 73, .13); 
  --tra-indigo: rgba(128, 96, 225, .13); 
  --tra-ink: rgba(1, 1, 1, .1); 
  --tra-pink: rgba(247, 71, 128, .13); 
  --tra-purple: rgba(102, 51, 255, .13);
  --tra-smoke: rgba(230, 230, 230, .2);
  --tra-white: rgba(255, 255, 255, .2); 
  --tra-white-smoke: rgba(244, 244, 249, 0);
  --tra-yellow: rgba(255, 191, 21, .13); 
}
  

html {
  -webkit-font-smoothing: antialiased;
  font-size: 16px;
} 

body {
  font-family: 'Inter', 'Helvetica Neue', Helvetica, Arial, sans-serif;
  color: var(--gray);
  line-height: 1.6666;
  font-weight: 400;
}

body.dark-page {
  background-color: #1e243b;
}

/*------------------------------------------*/
/*  PAGE CONTENT
/*------------------------------------------*/

#page,
.hidd { 
  overflow: hidden; 
}

.rel,
section,
.container { 
  position: relative!important; 
  z-index: 3; 
}

.section-overlay {
  width: 100%;
  height: 100%;
}

/*------------------------------------------*/
/*  BLOCK SHADOW
/*------------------------------------------*/

.block--shadow { 
  -webkit-box-shadow: 0 4px 12px 0 var(--tra-black);
  -moz-box-shadow: 0 4px 12px 0 var(--tra-black);
  box-shadow: 0 4px 12px 0 var(--tra-black);
}

.img-block .block--shadow { 
  -webkit-box-shadow: 0 4px 12px 0 rgba(20, 20, 20, .18); 
  -moz-box-shadow: 0 4px 12px 0 rgba(20, 20, 20, .18); 
  box-shadow: 0 4px 12px 0 rgba(20, 20, 20, .18); 
}

/*------------------------------------------*/
/*  BORDER SETTINGS
/*------------------------------------------*/

.block--border {
  border: 1px solid var(--silver);
}

.block--border.block--shadow { 
  border: 1px solid var(--smoke);
}

.x-border {
  border-top: 1px solid var(--silver);
  border-bottom: 1px solid var(--silver);
}

.t-border {
  border-top: 1px solid var(--silver);
}

.b-border {
  border-bottom: 1px solid var(--silver);
}

/*------------------------------------------*/
/*  BORDER RADIUS SETTING
/*------------------------------------------*/ 

.r-0 { -webkit-border-radius: 0px; -moz-border-radius: 0px; -o-border-radius: 0px; border-radius: 0px; }
.r-02 { -webkit-border-radius: 2px; -moz-border-radius: 2px; -o-border-radius: 2px; border-radius: 2px; }
.r-04 { -webkit-border-radius: 4px; -moz-border-radius: 4px; -o-border-radius: 4px; border-radius: 4px; }
.r-06 { -webkit-border-radius: 6px; -moz-border-radius: 6px; -o-border-radius: 6px; border-radius: 6px; }
.r-08 { -webkit-border-radius: 8px; -moz-border-radius: 8px; -o-border-radius: 8px; border-radius: 8px; }
.r-10 { -webkit-border-radius: 10px; -moz-border-radius: 10px; -o-border-radius: 10px; border-radius: 10px; }
.r-12 { -webkit-border-radius: 12px; -moz-border-radius: 12px; -o-border-radius: 12px; border-radius: 12px; }
.r-14 { -webkit-border-radius: 14px; -moz-border-radius: 14px; -o-border-radius: 14px; border-radius: 14px; }
.r-16 { -webkit-border-radius: 16px; -moz-border-radius: 16px; -o-border-radius: 16px; border-radius: 16px; }
.r-18 { -webkit-border-radius: 18px; -moz-border-radius: 18px; -o-border-radius: 18px; border-radius: 18px; }
.r-20 { -webkit-border-radius: 20px; -moz-border-radius: 20px; -o-border-radius: 20px; border-radius: 20px; }
.r-22 { -webkit-border-radius: 22px; -moz-border-radius: 22px; -o-border-radius: 22px; border-radius: 22px; }
.r-24 { -webkit-border-radius: 24px; -moz-border-radius: 24px; -o-border-radius: 24px; border-radius: 24px; }
.r-26 { -webkit-border-radius: 26px; -moz-border-radius: 26px; -o-border-radius: 26px; border-radius: 26px; }
.r-28 { -webkit-border-radius: 28px; -moz-border-radius: 28px; -o-border-radius: 28px; border-radius: 28px; }
.r-30 { -webkit-border-radius: 30px; -moz-border-radius: 30px; -o-border-radius: 30px; border-radius: 30px; }
.r-32 { -webkit-border-radius: 32px; -moz-border-radius: 32px; -o-border-radius: 32px; border-radius: 32px; }
.r-34 { -webkit-border-radius: 34px; -moz-border-radius: 34px; -o-border-radius: 34px; border-radius: 34px; }
.r-36 { -webkit-border-radius: 36px; -moz-border-radius: 36px; -o-border-radius: 36px; border-radius: 36px; }
.r-100 { -webkit-border-radius: 100px; -moz-border-radius: 100px; -o-border-radius: 100px; border-radius: 100px; }

/*------------------------------------------*/
/*  OPACITY SETTINGS
/*------------------------------------------*/

.o-0 { opacity: 0; }
.o-10 { opacity: .1; }
.o-15 { opacity: .15; }
.o-20 { opacity: .2; }
.o-25 { opacity: .25; }
.o-30 { opacity: .3; }
.o-35 { opacity: .35; }
.o-40 { opacity: .4; }
.o-45 { opacity: .45; }
.o-50 { opacity: .5; }
.o-55 { opacity: .55; }
.o-60 { opacity: .60; }
.o-65 { opacity: .65; }
.o-70 { opacity: .7; }
.o-75 { opacity: .75; }
.o-80 { opacity: .8; }
.o-85 { opacity: .85; }
.o-90 { opacity: .9; }
.o-95 { opacity: .95; }
.o-100 { opacity: 1; }

/*------------------------------------------*/
/*  SPACING & INDENTS
/*------------------------------------------*/

.py-40 { padding-top: 40px; padding-bottom: 40px; }
.py-50 { padding-top: 50px; padding-bottom: 50px; }
.py-60 { padding-top: 60px; padding-bottom: 60px; }
.py-70 { padding-top: 70px; padding-bottom: 70px; }
.py-80 { padding-top: 80px; padding-bottom: 80px; }
.py-90 { padding-top: 90px; padding-bottom: 90px; }
.py-100 { padding-top: 100px; padding-bottom: 100px; }

.pt-30 { padding-top: 30px; }
.pt-40 { padding-top: 40px; }
.pt-50 { padding-top: 50px; }
.pt-60 { padding-top: 60px; }
.pt-70 { padding-top: 70px; }
.pt-80 { padding-top: 80px; }
.pt-90 { padding-top: 90px; }
.pt-100 { padding-top: 100px; }

.pb-30 { padding-bottom: 30px; }
.pb-40 { padding-bottom: 40px; }
.pb-50 { padding-bottom: 50px; }
.pb-60 { padding-bottom: 60px; }
.pb-70 { padding-bottom: 70px; }
.pb-80 { padding-bottom: 80px; }
.pb-90 { padding-bottom: 90px; }
.pb-100 { padding-bottom: 100px; }

.mt-20 { margin-top: 20px; }
.mt-25 { margin-top: 25px; }
.mt-30 { margin-top: 30px; }
.mt-35 { margin-top: 35px; }
.mt-40 { margin-top: 40px; }
.mt-45 { margin-top: 45px; }
.mt-50 { margin-top: 50px; }
.mt-55 { margin-top: 55px; }
.mt-60 { margin-top: 60px; }
.mt-65 { margin-top: 65px; }
.mt-70 { margin-top: 70px; }
.mt-75 { margin-top: 75px; }
.mt-80 { margin-top: 80px; }

.mb-20 { margin-bottom: 20px; }
.mb-25 { margin-bottom: 25px; }
.mb-30 { margin-bottom: 30px; }
.mb-35 { margin-bottom: 35px; }
.mb-40 { margin-bottom: 40px; }
.mb-45 { margin-bottom: 45px; }
.mb-50 { margin-bottom: 50px; }
.mb-55 { margin-bottom: 55px; }
.mb-60 { margin-bottom: 60px; }
.mb-65 { margin-bottom: 65px; }
.mb-70 { margin-bottom: 70px; }
.mb-75 { margin-bottom: 75px; }
.mb-80 { margin-bottom: 80px; }

/*------------------------------------------*/
/*  BACKGROUND SETTINGS
/*------------------------------------------*/

.bg--fixed,
.bg--scroll {
  width: 100%;
  background-repeat: no-repeat;
  background-position: center center;   
  background-size: cover;
}

.bg--fixed {
  background-attachment: scroll!important;
}

.bg--scroll {
  background-attachment: fixed!important;
}

/*------------------------------------------*/
/*  BACKGROUND COLORS
/*------------------------------------------*/

.bg--black { background-color: var(--black); }
.bg--white { background-color: var(--white); }
.bg--blue { background-color: var(--blue); }
.bg--coal { background-color: var(--coal); }
.bg--green { background-color: var(--green); }
.bg--indigo { background-color: var(--indigo); }
.bg--ocean { background-color: var(--ocean); }
.bg--pink { background-color: var(--pink); }
.bg--purple { background-color: var(--purple); }
.bg--skyblue { background-color: var(--sky-blue); }
.bg--snow { background-color: var(--snow); }
.bg--whitesmoke { background-color: var(--white-smoke); }
.bg--yellow { background-color: var(--yellow); }

/*------------------------------------------*/
/*  TRANSPARENT BACKGROUND COLORS
/*------------------------------------------*/

.bg--tra-blue { background-color: var(--tra-blue); }
.bg--tra-green { background-color: var(--tra-green); }
.bg--tra-indigo { background-color: var(--tra-indigo); }
.bg--tra-pink { background-color: var(--tra-pink); }
.bg--tra-purple { background-color: var(--tra-purple); }
.bg--tra-yellow { background-color: var(--tra-yellow); }

/*------------------------------------------*/
/*  GRADIENT COLOR BACKGROUND
/*------------------------------------------*/

.gr--whitesmoke { 
  background-image: linear-gradient(180deg, rgba(244, 244, 249, .8) 50%, rgba(244, 244, 249, .05) 100%); 
}

.gr--snow { 
  background-image: linear-gradient(180deg, rgba(246, 246, 250, .75) 45%, rgba(246, 246, 250, .03) 100%); 
}

/*------------------------------------------*/
/*  BACKGROUND SHAPE
/*------------------------------------------*/

.bg--shape:after {
  position: absolute;
  content: '';
  width: 600px;
  height: 600px;
  top: 22%;
  left: 20%;
  z-index: -1;
  background-color: rgba(119, 67, 142, .12);
  filter: blur(15rem);
}

.bg--shape-top:after {
  position: absolute;
  content: '';
  width: 600px;
  height: 600px;
  top: -5%;
  right: 12%;
  z-index: -1;
  background-color: rgba(119, 67, 142, .17);
  filter: blur(15rem);
}

.shape--01:after,
.shape--02:after {
  position: absolute;
  content: '';
  width: 100%;
  height: 55%;
  left: 0;
  z-index: -1;
}

.shape--01:after {
  top: 0; 
}

.shape--02:after {
  top: 45%;
}

.shape--whitesmoke:after { background-color: var(--white-smoke); }

/*------------------------------------------*/
/*  BACKGROUND IMAGE / PATTERN
/*------------------------------------------*/
 
.bg--01, 
.bg--02, 
.bg--03, 
.bg--04, 
.bg--05 {
  background-repeat: no-repeat;
  background-position: center center;   
  background-size: cover;
}

.bg--01 { background-image: url(~images/home/bg-01.jpg); }
.bg--02 { background-image: url(~images/home/bg-02.jpg); }
/* .bg--03 { background-image: url(~images/home/bg-03.jpg); } */
/* .bg--04 { background-image: url(~images/home/bg-04.jpg); } */
/* .bg--05 { background-image: url(~images/home/bg-05.jpg); } */

.theme--dark .bg--01,
.theme--dark .bg--04 { 
  /* background-image: url(~images/home/bg-dark.jpg);  */
}

.theme--dark .bg--02 { 
  /* background-image: url(~images/home/bg-03.jpg);  */
}

.ba--01, 
.ba--02,
.ba--03 {
  background-repeat: no-repeat;
  background-position: center center;   
  background-size: cover;
}

/* .ba--01 { background-image: url(~images/home/ba-01.jpg); } */
.ba--02 { background-image: url(~images/home/ba-02.jpg); }
/* .ba--03 { background-image: url(~images/home/ba-03.jpg); } */

.theme--dark .ba--01 { 
  /* background-image: url(~images/home/ba-01d.jpg);  */
}

.theme--dark .ba--02, 
.theme--dark .ba--03 { 
  /* background-image: url(~images/home/ba-02d.jpg);  */
}

/*------------------------------------------*/
/*  SECTION DIVIDER
/*------------------------------------------*/

hr.divider {
  width: 100%;
  height: 1px;
  background-color: transparent;
  background-image: linear-gradient(90deg, rgba(206,211,246,0) 0, #bbb 38%, #999 64%, rgba(206,211,246,0) 99%);
  opacity: .4;
  border: none;
  margin: 0;
}

/*------------------------------------------*/
/*  WAVE SHAPE BOTTOM
/*------------------------------------------*/

.wave-shape-bottom {
  position: absolute;
  bottom: 0;
  left: 0;
  width: 100%;
}




/* ==========================================================================
  02. TYPOGRAPHY
  =========================================================================== */

/*------------------------------------------*/
/*  HEADERS
/*------------------------------------------*/

h6, h5, h4, h3, h2, h1 {  
  font-family: 'Plus Jakarta Sans', sans-serif;
  color: var(--header-color);
  font-weight: 700;
  letter-spacing: 0;
  margin-bottom: 0;
}

/*------------------------------------------*/
/*  HEADER SIZE
/*------------------------------------------*/

h6 { font-size: 1rem; }              /* 16px */
h6.h6-md { font-size: 1.125rem; }    /* 18px */
h6.h6-lg { font-size: 1.25rem; }     /* 20px */
h6.h6-xl { font-size: 1.3125rem; }   /* 21px */

h5 { font-size: 1.375rem; }          /* 22px */
h5.h5-md { font-size: 1.5rem; }      /* 24px */
h5.h5-lg { font-size: 1.625rem; }    /* 26px */
h5.h5-xl { font-size: 1.75rem; }     /* 28px */

h4 { font-size: 1.875rem; }          /* 30px */
h4.h4-md { font-size: 2rem; }        /* 32px */
h4.h4-lg { font-size: 2.125rem; }    /* 34px */
h4.h4-xl { font-size: 2.25rem; }     /* 36px */

h3 { font-size: 2.375rem; }          /* 38px */
h3.h3-md { font-size: 2.5rem; }      /* 40px */
h3.h3-lg { font-size: 2.6255rem; }   /* 42px */
h3.h3-xl { font-size: 2.75rem; }     /* 44px */

h2 { font-size: 2.875rem; }          /* 46px */
h2.h2-md { font-size: 3rem; }        /* 48px */
h2.h2-lg { font-size: 3.125rem; }    /* 50px */
h2.h2-xl { font-size: 3.25rem; }     /* 52px */
h2.h2-title { font-size: 3.375rem; } /* 54px */

/*------------------------------------------*/
/*  PARAGRAPHS
/*------------------------------------------*/

p.p-sm { font-size: 0.9375rem; }    /* 15px */
p { font-size: 1rem; }              /* 16px */
p.p-md { font-size: 1.0625rem; }    /* 17px */
p.p-lg { font-size: 1.125rem; }     /* 18px */
p.p-xl { font-size: 1.1875rem; }    /* 19px */

/*------------------------------------------*/
/*  LINK SETTINGS
/*------------------------------------------*/

a {
  color: var(--black);
  text-decoration: none;
  -webkit-transition: all 400ms ease-in-out;
  -moz-transition: all 400ms ease-in-out;
  -o-transition: all 400ms ease-in-out;
  -ms-transition: all 400ms ease-in-out;
  transition: all 400ms ease-in-out; 
}

a:hover {
  color: var(--coal);
  text-decoration: none;
}

a:focus {
  outline: none;
  text-decoration: none;
}

a.tra-link {
  font-size: 1rem;
  line-height: 1;
  font-weight: 600;
}

a.tra-link span {
  -webkit-transition: all 400ms ease-in-out;
  -moz-transition: all 400ms ease-in-out;
  -o-transition: all 400ms ease-in-out;
  -ms-transition: all 400ms ease-in-out;
  transition: all 400ms ease-in-out; 
}

.tra-link.ico-20 [class*="flaticon-"]:before, 
.tra-link.ico-20 [class*="flaticon-"]:after { 
  position: relative;
  font-size: 0.75rem; 
  line-height: 0.7rem!important;
  top: 1.5px;
  left: 1px;
}

a.tra-link:hover span {
  color: var(--coal)!important;
}

/*------------------------------------------*/
/*  LISTS
/*------------------------------------------*/

ul {
  margin: 0;
  padding: 0;
  list-style: none;
} 

/*------------------------------------------*/
/*  TEXT LIST
/*------------------------------------------*/

ul.simple-list {
  list-style: disc;
  margin-left: 15px;
}

ol.digit-list p,
ul.simple-list.long-list p {
  margin-bottom: 8px;
}

/*------------------------------------------*/
/*  DIGIT LIST
/*------------------------------------------*/

ol.digit-list {
  padding: 0;
  margin-left: 15px;
}

/*------------------------------------------*/
/*  BUTTON SETTINGS
/*------------------------------------------*/

.btn {
  font-size: 1rem;
  line-height: 1;
  font-weight: 600;
  background-color: transparent;
  padding: 0.9rem 1.8rem;
  border: 2px solid transparent;
  -webkit-transition: all 400ms ease-in-out;
  -moz-transition: all 400ms ease-in-out;
  -o-transition: all 400ms ease-in-out;
  -ms-transition: all 400ms ease-in-out;
  transition: all 400ms ease-in-out; 
}

.btn.btn-sm {
  font-size: 0.975rem;
  padding: 0.7rem 1.5rem;
}

.btn.btn-md {
  font-size: 1rem;
  padding: 0.9425rem 1.75rem;
}

.btn.btn-lg {
  font-size: 1.05rem;
  padding: 1.1rem 1.8rem;
}

.btn.ico-30.ico-left,
.btn.ico-30.ico-right {
  padding: 0.37rem 0;
}

/*------------------------------------------*/
/*  Buttons Group
/*------------------------------------------*/

.text-center .btns-group {
  display: inline-block;
}

.btns-group .btn:first-child {
  margin-right: 15px;
}

.btns-group .btn.ico-30.ico-left {
  padding: 0.37rem 0.5rem;
}

.btns-group .btn.ico-30.ico-right {
  padding: 0.37rem 0;
}

/*------------------------------------------*/
/*  Button Icon
/*------------------------------------------*/

.btn.ico-20 [class*="flaticon-"]:before, 
.btn.ico-20 [class*="flaticon-"]:after { 
  position: relative;
  font-size: 1rem; 
  line-height: 0.7rem!important;
  top: 4px;
}

.btn.ico-left span {
  margin-right: 5px;
}

.btn.ico-right span {
  margin-left: 5px;
}

.btn.ico-30.ico-left span {
  position: relative;
  top: 0;
  right: 4px;
}

.btn.ico-30.ico-right span {
  position: relative;
  top: 0;
  left: 4px;
}

.btn.ico-30.ico-left span.ico-30-txt,
.btn.ico-30.ico-right span.ico-30-txt {
  position: relative;
  top: -10px;
  right: 0;
}

.btn.ico-30.ico-right span.ico-30-txt {
  left: 0;
}

/*------------------------------------------*/
/*  Button Text
/*------------------------------------------*/

p.btn-txt {
  margin: 13px 0 0 0;
}

p.btn-txt span {
  font-weight: 700;
}

.btn-rating p.btn-txt {
  margin: 0;
}

.star-rating small,
.star-rating span.rating-txt {
  font-size: 0.9375rem;
}

p.btns-group-txt {
  margin: 14px 0 0 0;
}

.btn-rating {
  margin-top: 18px;
}

.text-center p.btns-group-txt {
  margin-top: 20px;
}

p.btn-txt.ico-15,
p.btns-group-txt.ico-15 {
  padding-left: 5px;
}

.text-center p.btn-txt.ico-15,
.text-center p.btns-group-txt.ico-15 {
  padding-left: 0;
}

p.btn-txt span,
p.btns-group-txt span {
  position: relative;
  top: 0.5px;
  right: 2px;
}

p.btns-group-txt span.txt-data {
  display: block;
  top: 0;
  right: 0;
}

p.btns-group-txt span.txt-data a {
  margin-left: 4px;
  text-decoration: underline;
}

p.btns-group-txt span.txt-data a:hover {
  text-decoration: none;
}

.hero-section .color--white p.btns-group-txt span.txt-data a:hover {
  color: var(--white)!important;
}

.btn-txt.ico-15 [class*="flaticon-"]:before, 
.btn-txt.ico-15 [class*="flaticon-"]:after,
.btns-group-txt.ico-15 [class*="flaticon-"]:before, 
.btns-group-txt.ico-15 [class*="flaticon-"]:after { font-size: 0.75rem; }

.star-rating.ico-15 [class*="flaticon-"]:before, 
.star-rating.ico-15 [class*="flaticon-"]:after { position: relative; font-size: 1rem; top: 3px; }

.star-rating.ico-20 [class*="flaticon-"]:before, 
.star-rating.ico-20 [class*="flaticon-"]:after { position: relative; font-size: 0.95rem; top: 1px; }

.p-sm.btn-txt.ico-20 [class*="flaticon-"]:before, 
.p-sm.btn-txt.ico-20 [class*="flaticon-"]:after { position: relative; font-size: 0.875rem; top: 1px; }

/*------------------------------------------*/
/*  TRANPARENT BUTTON
/*------------------------------------------*/

.btn--transparent {
  color: var(--black)!important;
  background-color: transparent!important;
  border-color: transparent!important;
}

.btn--transparent:hover {
  background-color: transparent!important;
  border-color: transparent!important;
} 

/*------------------------------------------*/
/*  WHITE BUTTON
/*------------------------------------------*/

.btn--white,
.hover--white:hover,
.color--white .hover--white:hover {
  color: var(--black)!important;
  background-color: var(--white)!important;
  border-color: var(--white)!important;
}

.btn--tra-white,
.hover--tra-white:hover,
.color--white .hover--tra-white:hover {
  color: var(--white)!important;
  background-color: transparent!important;
  border-color: var(--white)!important;
}

.white-scroll .scroll .hover--tra-white:hover {
  color: var(--black)!important;
  background-color: transparent!important;
  border-color: var(--black)!important;
}

.white-scroll .scroll .hover--white:hover {
  color: var(--whte)!important;
  background-color: var(--black)!important;
  border-color: var(--black)!important;
}

/*------------------------------------------*/
/*  BLACK BUTTON
/*------------------------------------------*/

.btn--black,
.hover--black:hover {
  color: var(--white)!important;
  background-color: var(--black)!important;
  border-color: var(--black)!important;
}

.btn--tra-black,
.hover--tra-black:hover {
  color: var(--black)!important;
  background-color: transparent!important;
  border-color: var(--black)!important;
}

/*------------------------------------------*/
/*  COAL BUTTON
/*------------------------------------------*/

.btn--coal, 
.hover--coal:hover,
.color--white .hover--coal:hover {
  color: var(--white)!important;
  border-color: var(--coal)!important;
  background-color: var(--coal)!important;
}

.btn--tra-coal, 
.hover--tra-coal:hover, 
.color--white .btn--tra-coal {
  color: var(--coal)!important;
  background-color: transparent!important;
  border-color: var(--coal)!important;
}

/*------------------------------------------*/
/*  TRA GRAY BUTTON
/*------------------------------------------*/

.btn--tra-gray, 
.hover--tra-gray:hover {
  color: var(--black)!important;
  background-color: transparent!important;
  border-color: var(--silver)!important;
}

/*------------------------------------------*/
/*  BLUE BUTTON
/*------------------------------------------*/

.btn--blue, 
.hover--blue:hover,
.color--white .hover--blue:hover {
  color: var(--white)!important;
  border-color: var(--blue)!important;
  background-color: var(--blue)!important;
}

.btn--tra-blue, 
.hover--tra-blue:hover, 
.color--white .btn--tra-blue {
  color: var(--blue)!important;
  background-color: transparent!important;
  border-color: var(--blue)!important;
}

/*------------------------------------------*/
/*  GREEN BUTTON
/*------------------------------------------*/

.btn--green, 
.hover--green:hover,
.color--white .btn--green {
  color: var(--white)!important;
  border-color: var(--green)!important;
  background-color: var(--green)!important;
}

.btn--tra-green, 
.hover--tra-green:hover, 
.color--white .btn--tra-green {
  color: var(--green)!important;
  background-color: transparent!important;
  border-color: var(--green)!important;
}

/*------------------------------------------*/
/*  INDIGO BUTTON
/*------------------------------------------*/

.btn--indigo, 
.hover--indigo:hover,
.color--white .btn--indigo {
  color: var(--white)!important;
  border-color: var(--indigo)!important;
  background-color: var(--indigo)!important;
}

.btn--tra-indigo, 
.hover--tra-indigo:hover, 
.color--white .btn--tra-indigo {
  color: var(--indigo)!important;
  background-color: transparent!important;
  border-color: var(--indigo)!important;
}

/*------------------------------------------*/
/*  OCEAN BUTTON
/*------------------------------------------*/

.btn--ocean, 
.hover--ocean:hover,
.color--white .btn--ocean {
  color: var(--white)!important;
  border-color: var(--ocean)!important;
  background-color: var(--ocean)!important;
}

.btn--tra-ocean, 
.hover--tra-ocean:hover, 
.color--white .btn--tra-ocean {
  color: var(--ocean)!important;
  background-color: transparent!important;
  border-color: var(--ocean)!important;
}

/*------------------------------------------*/
/*  PINK BUTTON
/*------------------------------------------*/

.btn--pink, 
.hover--pink:hover,
.color--white .btn--pink {
  color: var(--white)!important;
  border-color: var(--pink)!important;
  background-color: var(--pink)!important;
}

.btn--tra-pink, 
.hover--tra-pink:hover, 
.color--white .btn--tra-pink {
  color: var(--pink)!important;
  background-color: transparent!important;
  border-color: var(--pink)!important;
}

/*------------------------------------------*/
/*  PURPLE BUTTON
/*------------------------------------------*/

.btn--purple, 
.hover--purple:hover,
.color--white .btn--purple {
  color: var(--white)!important;
  border-color: var(--purple)!important;
  background-color: var(--purple)!important;
}

.btn--tra-purple, 
.hover--tra-purple:hover, 
.color--white .btn--tra-purple {
  color: var(--purple)!important;
  background-color: transparent!important;
  border-color: var(--purple)!important;
}

/*------------------------------------------*/
/*  SKYBLUE BUTTON
/*------------------------------------------*/

.btn--skyblue, 
.hover--skyblue:hover,
.color--white .btn--skyblue {
  color: var(--white)!important;
  border-color: var(--sky-blue)!important;
  background-color: var(--sky-blue)!important;
}

.btn--tra-skyblue, 
.hover--tra-skyblue:hover, 
.color--white .btn--tra-skyblue {
  color: var(--sky-blue)!important;
  background-color: transparent!important;
  border-color: var(--sky-blue)!important;
}

/*------------------------------------------*/
/*  YELLOW BUTTON
/*------------------------------------------*/

.btn--yellow, 
.hover--yellow:hover,
.color--white .btn--yellow {
  color: var(--black)!important;
  border-color: var(--yellow)!important;
  background-color: var(--yellow)!important;
}

/*------------------------------------------*/
/*  BUTTON FOCUS
/*------------------------------------------*/

.btn:focus,
.btn.btn--black:focus {
  color: var(--white);
  -webkit-box-shadow: none;
  box-shadow: none; 
}

.btn--transparent:focus {
  background-color: transparent;
  border-color: transparent;
} 

.btn--tra-black:focus, 
.btn--transparent:focus,
.btn.btn--tra-gray:focus {
  color: var(--black); 
  -webkit-box-shadow: none;
  box-shadow: none; 
}

/*------------------------------------------*/
/*  STORE BADGE ICONS
/*------------------------------------------*/

.stores-badge {
  display: inline-block;
}

.store {
  display: inline-block;
  text-align: center;
  margin-right: 10px;
}

.store:last-child {
  margin-right: 0;
} 

.store img {
  width: auto;
  max-width: inherit;
}

.badge-img-xs .store img { max-height: 2.5rem; }
.badge-img-sm .store img { max-height: 2.625rem; }
.badge-img-md .store img { max-height: 2.875rem; }
.badge-img-lg .store img { max-height: 3rem; }

/*------------------------------------------*/
/*  WATCH VIDEO LINK
/*------------------------------------------*/

.watch-video {
  display: flex;
  position: relative;
  flex-flow: row wrap;
  align-items: stretch!important;
  justify-content: flex-start;
  margin-top: 25px;
}

.btns-group .watch-video {
  display: inline-block;
  margin-top: 0!important;
  top: 22px;
}

/*------------------------------------------*/
/*  Link Icon
/*------------------------------------------*/

.watch-video-link {
  position: relative;
  text-align: center;
  float: left;
  width: 2.85rem;
  height: 2.85rem; 
  color: var(--white);
  border: none;
  margin-right: 12px;
  -webkit-border-radius: 100%;
  -moz-border-radius: 100%;
  border-radius: 100%; 
}

.watch-video-link:before {
  content: '';
  position: absolute;
  left: -3px;
  right: -3px;
  top: -3px;
  bottom: -3px;
  background: var(--tra-white);
  opacity: 0;
  -webkit-border-radius: 50%;
  -moz-border-radius: 50%;
  border-radius: 50%;
  -webkit-transition: all 400ms ease-in-out;
  -moz-transition: all 400ms ease-in-out;
  -o-transition: all 400ms ease-in-out;
  -ms-transition: all 400ms ease-in-out;
  transition: all 400ms ease-in-out;
}

.watch-video-link:hover:before {
  opacity: .75;
  left: -8px;
  right: -8px;
  top: -8px;
  bottom: -8px;
}

.watch-video-link span[class^="flaticon-"]:before, 
.watch-video-link span[class^="flaticon-"]:after {
  font-size: 2rem; 
  line-height: 2.85rem!important;   
  margin-left: 5px;
}

/*------------------------------------------*/
/*  Link Text
/*------------------------------------------*/

.watch-video-txt {
  overflow: hidden;
  flex: 1;
  max-width: 100%;
  text-align: left!important;
}

p.video-txt-lg {
  font-size: 1rem;
  line-height: 1;
  font-weight: 400;
  margin-bottom: 5px;
}

p.video-txt-sm {
  font-size: 0.925rem;
  line-height: 1;
  margin-bottom: 0;
}

/*------------------------------------------*/
/*  VIDEO POPUP ICON
/*------------------------------------------*/

.video-preview {
  position: relative; 
  text-align: center;
}

.video-btn {
  position: absolute!important;
  top: 50%;
  left: 50%;
  display: inline-block;
  text-align: center;
  color: var(--white);
  -webkit-border-radius: 100%;
  -moz-border-radius: 100%;
  border-radius: 100%; 
}

.video-btn-xl {
  width: 6.25rem;
  height: 6.25rem;
  margin-top: -3.125rem;
  margin-left: -3.125rem;
}

.video-btn-lg {
  width: 5.625rem;
  height: 5.625rem;
  margin-top: -2.8125rem;
  margin-left: -2.8125rem;
}

.video-btn-md {
  width: 5rem;
  height: 5rem;
  margin-top: -2.5rem;
  margin-left: -2.5rem;
}

.video-btn-sm {
  width: 4.375rem;
  height: 4.375rem;
  margin-top: -2.1875rem;
  margin-left: -2.1875rem;
}

.video-block-wrapper {
  -webkit-transition: all 400ms ease-in-out;
  -moz-transition: all 400ms ease-in-out;
  -o-transition: all 400ms ease-in-out;
  -ms-transition: all 400ms ease-in-out;
  transition: all 400ms ease-in-out;
}

.video-btn.video-btn-xl [class^="flaticon-"]:before, 
.video-btn.video-btn-xl [class^="flaticon-"]:after {
  font-size: 5rem;
  line-height: 6.25rem!important;   
  margin-left: 10px;
}

.video-btn.video-btn-lg [class^="flaticon-"]:before, 
.video-btn.video-btn-lg [class^="flaticon-"]:after {
  font-size: 4.5rem;
  line-height: 5.625rem!important;   
  margin-left: 8px;
}

.video-btn.video-btn-md [class^="flaticon-"]:before, 
.video-btn.video-btn-md [class^="flaticon-"]:after {
  font-size: 4.35rem;
  line-height: 5rem!important;   
  margin-left: 8px;
}

.video-btn.video-btn-sm [class^="flaticon-"]:before, 
.video-btn.video-btn-sm [class^="flaticon-"]:after {
  font-size: 3.5rem;
  line-height: 4.375rem!important;   
  margin-left: 5px;
}

.video-btn:hover .video-block-wrapper {
  -moz-transform: scale(0.95);
  -ms-transform: scale(0.95);
  -webkit-transform: scale(0.95);
  -o-transform: scale(0.95);
  transform: scale(0.95);
}

.video-btn:before {
  content: '';
  position: absolute;
  left: -5px;
  right: -5px;
  top: -5px;
  bottom: -5px;
  background: var(--tra-white);
  opacity: 0;
  -webkit-border-radius: 50%;
  -moz-border-radius: 50%;
  border-radius: 50%;
  -webkit-transition: all 400ms ease-in-out;
  -moz-transition: all 400ms ease-in-out;
  -o-transition: all 400ms ease-in-out;
  -ms-transition: all 400ms ease-in-out;
  transition: all 400ms ease-in-out;
}

.video-btn.video-btn-xl:hover:before {
  opacity: .75;
  left: -1.5rem;
  right: -1.5rem;
  top: -1.5rem;
  bottom: -1.5rem;
}

.video-btn.video-btn-lg:hover:before {
  opacity: .75;
  left: -1.25rem;
  right: -1.25rem;
  top: -1.25rem;
  bottom: -1.25rem;
}

.video-btn.video-btn-md:hover:before {
  opacity: .75;
  left: -1rem;
  right: -1rem;
  top: -1rem;
  bottom: -1rem;
}

.video-btn.video-btn-sm:hover:before {
  opacity: .75;
  left: -0.75rem;
  right: -0.75rem;
  top: -0.75rem;
  bottom: -0.75rem;
}

/*------------------------------------------*/
/*  VECTOR ICONS
/*------------------------------------------*/

.ico-10 [class*="flaticon-"]:before, .ico-10 [class*="flaticon-"]:after { font-size: 0.75rem; }      /* 12px */
.ico-15 [class*="flaticon-"]:before, .ico-15 [class*="flaticon-"]:after { font-size: 0.9375rem; }    /* 15px */
.ico-20 [class*="flaticon-"]:before, .ico-20 [class*="flaticon-"]:after { font-size: 1.25rem; }      /* 20px */
.ico-25 [class*="flaticon-"]:before, .ico-25 [class*="flaticon-"]:after { font-size: 1.5625rem; }    /* 25px */
.ico-30 [class*="flaticon-"]:before, .ico-30 [class*="flaticon-"]:after { font-size: 1.875rem; }     /* 30px */
.ico-35 [class*="flaticon-"]:before, .ico-35 [class*="flaticon-"]:after { font-size: 2.1875rem; }    /* 35px */
.ico-40 [class*="flaticon-"]:before, .ico-40 [class*="flaticon-"]:after { font-size: 2.5rem; }       /* 40px */
.ico-45 [class*="flaticon-"]:before, .ico-45 [class*="flaticon-"]:after { font-size: 2.8125rem; }    /* 45px */
.ico-50 [class*="flaticon-"]:before, .ico-50 [class*="flaticon-"]:after { font-size: 3.125rem; }     /* 50px */
.ico-55 [class*="flaticon-"]:before, .ico-55 [class*="flaticon-"]:after { font-size: 3.4375rem; }    /* 55px */
.ico-60 [class*="flaticon-"]:before, .ico-60 [class*="flaticon-"]:after { font-size: 3.75rem; }      /* 60px */
.ico-65 [class*="flaticon-"]:before, .ico-65 [class*="flaticon-"]:after { font-size: 4.0625rem; }    /* 65px */
.ico-70 [class*="flaticon-"]:before, .ico-70 [class*="flaticon-"]:after { font-size: 4.375rem; }     /* 70px */
.ico-75 [class*="flaticon-"]:before, .ico-75 [class*="flaticon-"]:after { font-size: 4.6875rem; }    /* 75px */
.ico-80 [class*="flaticon-"]:before, .ico-80 [class*="flaticon-"]:after { font-size: 5rem; }         /* 80px */
.ico-85 [class*="flaticon-"]:before, .ico-85 [class*="flaticon-"]:after { font-size: 5.3125rem; }    /* 85px */
.ico-90 [class*="flaticon-"]:before, .ico-90 [class*="flaticon-"]:after { font-size: 5.625rem; }     /* 90px */
.ico-95 [class*="flaticon-"]:before, .ico-95 [class*="flaticon-"]:after { font-size: 5.9375rem; }    /* 95px */
.ico-100 [class*="flaticon-"]:before, .ico-100 [class*="flaticon-"]:after { font-size: 6.25rem; }    /* 100px */
.ico-105 [class*="flaticon-"]:before, .ico-105 [class*="flaticon-"]:after { font-size: 6.5625rem; }  /* 105px */
.ico-110 [class*="flaticon-"]:before, .ico-110 [class*="flaticon-"]:after { font-size: 6.875rem; }   /* 110px */
.ico-115 [class*="flaticon-"]:before, .ico-115 [class*="flaticon-"]:after { font-size: 7.1875rem; }  /* 115px */
.ico-120 [class*="flaticon-"]:before, .ico-120 [class*="flaticon-"]:after { font-size: 7.5rem; }     /* 120px */
.ico-125 [class*="flaticon-"]:before, .ico-125 [class*="flaticon-"]:after { font-size: 7.8125rem; }  /* 125px */

/*------------------------------------------*/
/*  VECTOR ICON BACKGROUND
/*------------------------------------------*/ 

.shape-ico {
  position: relative;
  display: inline-block;
  margin: 6px auto 0;
}

.shape-ico span {
  position: relative;
  z-index:  2;
}

.shape-ico svg {
  position: absolute;
  z-index:  1;
}

.ico-50 .shape-ico svg {
  width: 130px;
  height: 130px;
  top: -35px;
  left: calc(50% - 70px);
}

.ico-55 .shape-ico svg {
  width: 135px;
  height: 135px;
  top: -35px;
  left: calc(50% - 68px);
}

.ico-60 .shape-ico svg {
  width: 140px;
  height: 140px;
  top: -35px;
  left: calc(50% - 70px);
}

.shape-ico.color--theme path,
.shape-ico.color--theme path,
.shape-ico.color--theme path {
  fill: #f4f9fc;
}

/*------------------------------------------*/
/*  VECTOR ROUNDED ICONS
/*------------------------------------------*/ 

.ico-rounded,
.ico-rounded-md,
.ico-rounded-lg {
  display: inline-block;
  text-align: center;
  -webkit-border-radius: 100%;
  -moz-border-radius: 100%;
  -o-border-radius: 100%;
  border-radius: 100%;
}

.ico-rounded { width: 80px; height: 80px; }
.ico-rounded-md { width: 90px; height: 90px; }
.ico-rounded-lg { width: 100px; height: 100px; }

.ico-rounded [class*="flaticon-"]:before, 
.ico-rounded [class*="flaticon-"]:after { font-size: 2.15rem; line-height:80px!important; }

.ico-rounded-md [class*="flaticon-"]:before, 
.ico-rounded-md [class*="flaticon-"]:after { font-size: 2.4rem; line-height: 90px!important; }

.ico-rounded-lg [class*="flaticon-"]:before, 
.ico-rounded-lg [class*="flaticon-"]:after { font-size: 2.65rem; line-height: 100px!important; }

/*------------------------------------------*/
/*   PNG ICONS SETTINGS
/*------------------------------------------*/ 

.img-20 img { width: 20px; height: 20px; }
.img-25 img { width: 25px; height: 25px; }
.img-30 img { width: 30px; height: 30px; }
.img-35 img { width: 35px; height: 35px; }
.img-40 img { width: 40px; height: 40px; }
.img-45 img { width: 45px; height: 45px; }
.img-50 img { width: 50px; height: 50px; }
.img-55 img { width: 55px; height: 55px; }
.img-60 img { width: 60px; height: 60px; }
.img-65 img { width: 65px; height: 65px; }
.img-70 img { width: 70px; height: 70px; }
.img-75 img { width: 75px; height: 75px; }
.img-80 img { width: 80px; height: 80px; }
.img-85 img { width: 85px; height: 85px; }
.img-90 img { width: 90px; height: 90px; }
.img-95 img { width: 95px; height: 95px; }
.img-100 img { width: 100px; height: 100px; }
.img-105 img { width: 105px; height: 105px; }
.img-110 img { width: 110px; height: 110px; }
.img-115 img { width: 115px; height: 115px; }
.img-120 img { width: 120px; height: 120px; }
.img-125 img { width: 125px; height: 125px; }
.img-130 img { width: 130px; height: 130px; }
.img-135 img { width: 135px; height: 135px; }
.img-140 img { width: 140px; height: 140px; }
.img-145 img { width: 145px; height: 145px; }

/*------------------------------------------*/
/*  IMAGE HOVER OVERLAY
/*------------------------------------------*/

.hover-overlay {
  width: 100%;
  height: auto;
  overflow: hidden;
  position: relative; 
}

.hover-overlay img {
  transform: scale(1);
  -ms-transform: scale(1);
  -webkit-transform: scale(1);
  -o-transform: scale(1);
  -moz-transform: scale(1);
  overflow: hidden;
  -webkit-transition: transform 400ms;
  -moz-transition: transform 400ms;
  -o-transition: transform 400ms;
  transition: transform 400ms;
}

/*------------------------------------------*/
/*  Overlay Background 
/*------------------------------------------*/ 

.item-overlay {
  opacity: 0;
  -moz-opacity: 0; 
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  background: rgba(20, 20, 20, .5);
  -webkit-transition: all 400ms ease-in-out;
  -moz-transition: all 400ms ease-in-out;
  -o-transition: all 400ms ease-in-out;
  -ms-transition: all 400ms ease-in-out;
  transition: all 400ms ease-in-out;
}

/*------------------------------------------*/
/*   SECTION ID
/*------------------------------------------*/

.section-id {
  display: block;
  font-size: 0.85rem;
  line-height: 1;
  font-weight: 600;
  letter-spacing: 1.25px;
  text-transform: uppercase;
  margin-bottom: 32px;   
}

/*------------------------------------------*/
/*  SECTION TITLE
/*------------------------------------------*/

.section-title {
  text-align: center;
}

.section-title p {
  margin-top: 22px;
  margin-bottom: 0;
}

.section-title .btns-group {
  margin-top: 35px;
}

/*------------------------------------------*/
/*  ADVANTAGES LIST
/*------------------------------------------*/

.advantages {
  margin-top: 15px;
}

.text-center .advantages {
  margin-top: 20px;
}

.advantages li {
  width: auto!important;
  display: inline-block!important;
  vertical-align: top;
  clear: none!important;
}

.advantages-links-divider {
  position: relative;
  top: 0.5px;
  transform: rotate(-90deg);
}

.advantages li p {
  display: inline-block;
  float: left;
  margin-bottom: 0;
}

.advantages.ico-15 li p span {
  position: relative;
  top: 2px;
  right: -1px;
}

.advantages.ico-15 [class*="flaticon-"]:before, 
.advantages.ico-15 [class*="flaticon-"]:after { font-size: 0.75rem; }

.advantages li p a {
  font-weight: 400;
  text-decoration: underline;
}

.advantages li p a:hover {
  text-decoration: underline;
}

.white--color .advantages li p a:hover {
  color: var(--white);
}

/*------------------------------------------*/
/*  COMPATABILITY
/*------------------------------------------*/

.compatibility {
  display: inline-block;
  padding: 3px 20px;
  margin-top: 15px;
  margin-bottom: 0;
}

.compatibility span {
  position: relative;
  top: -1.5px;
  right: 2px;
}

.compatibility.ico-10 [class*="flaticon-"]:before, 
.compatibility.ico-10 [class*="flaticon-"]:after { font-size: 0.5rem; }

/*------------------------------------------*/
/*  TOOLS LIST / PAYMENT METHODS
/*------------------------------------------*/

.tools-list,
.payment-methods {
  margin-top: 25px;
}

.tools-list p,
.payment-methods p {
  font-weight: 400;
  margin-bottom: 12px;
}

.tools-list.ico-35 [class*="flaticon-"]:before, 
.tools-list.ico-35 [class*="flaticon-"]:after { line-height: 2.1875rem!important; margin-right: 6px; }

/*------------------------------------------*/
/*  Payment Icons
/*------------------------------------------*/

.payment-icons li {
  display: inline-block!important;
  vertical-align: top;
  clear: none!important;
  margin: 0;
  padding-right: 5px;
}

.payment-icons li:last-child {
  padding-right: 0;
}

.payment-icons img {
  width: auto;
  max-width: inherit;
  max-height: 32px;
}

/*------------------------------------------*/
/*  USER AVATARS
/*------------------------------------------*/

.txt-block .users {
  margin-top: 22px;
}

.users img {
  width: auto;
  max-width: inherit;
  max-height: 50px;
}

.users p {
  font-size: 0.935rem;
  position: relative;
  top: 13px;
  left: 10px;
  display: inline-block;
  line-height: 1.4;
  margin-bottom: 0;
}

.users p span {
  display: block;
}

/*------------------------------------------*/
/*  TEXT COLORS
/*------------------------------------------*/

.color--white,
.color--white h1,
.color--white h2, 
.color--white h3, 
.color--white h4, 
.color--white h5, 
.color--white h6, 
.color--white p, 
.color--white a, 
.color--white i, 
.color--white li,
.color--white span { color: var(--white); }

.color--black,
.color--black h1, 
.color--black h2, 
.color--black h3, 
.color--black h4, 
.color--black h5, 
.color--black h6, 
.color--black p, 
.color--black a,
.color--black i,  
.color--black li,
.color--black span { color: var(--black); }

.color--coal,
.color--coal h1, 
.color--coal h2, 
.color--coal h3, 
.color--coal h4, 
.color--coal h5, 
.color--coal h6, 
.color--coal p, 
.color--coal a,
.color--coal i,  
.color--coal li,
.color--coal span { color: var(--coal); }

.color--gray,
.color--gray h1, 
.color--gray h2, 
.color--gray h3, 
.color--gray h4, 
.color--gray h5, 
.color--gray h6, 
.color--gray p, 
.color--gray a,
.color--gray i,  
.color--gray li,
.color--gray span { color: var(--gray); }

.color--lightgray,
.color--lightgray h1, 
.color--lightgray h2, 
.color--lightgray h3, 
.color--lightgray h4, 
.color--lightgray h5, 
.color--lightgray h6, 
.color--lightgray p, 
.color--lightgray a,
.color--lightgray i,  
.color--lightgray li,
.color--lightgray span { color: var(--light-gray); }

.color--silver,
.color--silver h1, 
.color--silver h2, 
.color--silver h3, 
.color--silver h4, 
.color--silver h5, 
.color--silver h6, 
.color--silver p, 
.color--silver a,
.color--silver i,  
.color--silver li,
.color--silver span { color: var(--silver); }

.color--blue, 
.color--blue h1, 
.color--blue h2, 
.color--blue h3, 
.color--blue h4, 
.color--blue h5, 
.color--blue h6, 
.color--blue p, 
.color--blue a, 
.color--blue i,
.color--blue li, 
.color--blue span,
.color--white .color--blue { color: var(--blue); }

.color--green, 
.color--green h1, 
.color--green h2, 
.color--green h3, 
.color--green h4, 
.color--green h5, 
.color--green h6, 
.color--green p, 
.color--green a, 
.color--green li, 
.color--green i, 
.color--green span,
.color--white .color--green { color: var(--green); }

.color--indigo, 
.color--indigo h1, 
.color--indigo h2, 
.color--indigo h3, 
.color--indigo h4, 
.color--indigo h5, 
.color--indigo h6, 
.color--indigo p, 
.color--indigo a, 
.color--indigo i, 
.color--indigo li, 
.color--indigo span,
.color--white .color--indigo { color: var(--indigo); }

.color--ocean, 
.color--ocean h1, 
.color--ocean h2, 
.color--ocean h3, 
.color--ocean h4, 
.color--ocean h5, 
.color--ocean h6, 
.color--ocean p, 
.color--ocean a, 
.color--ocean li, 
.color--ocean i, 
.color--ocean span,
.color--white .color--ocean { color: var(--ocean); }

.color--pink, 
.color--pink h1, 
.color--pink h2, 
.color--pink h3, 
.color--pink h4, 
.color--pink h5, 
.color--pink h6, 
.color--pink p, 
.color--pink a,
.color--pink i, 
.color--pink li, 
.color--pink span,
.color--white .color--pink { color: var(--pink); }

.color--purple, 
.color--purple h1, 
.color--purple h2, 
.color--purple h3, 
.color--purple h4, 
.color--purple h5, 
.color--purple h6, 
.color--purple p, 
.color--purple a,
.color--purple i, 
.color--purple li, 
.color--purple span,
.color--white .color--purple { color: var(--purple); }

.color--skyblue, 
.color--skyblue h1, 
.color--skyblue h2, 
.color--skyblue h3, 
.color--skyblue h4, 
.color--skyblue h5, 
.color--skyblue h6, 
.color--skyblue p, 
.color--skyblue a, 
.color--skyblue li, 
.color--skyblue i, 
.color--skyblue span,
.color--white .color--skyblue { color: var(--sky-blue); }

.color--yellow, 
.color--yellow h1, 
.color--yellow h2, 
.color--yellow h3, 
.color--yellow h4, 
.color--yellow h5, 
.color--yellow h6, 
.color--yellow p, 
.color--yellow a, 
.color--yellow li, 
.color--yellow i, 
.color--yellow span,
.color--white .color--yellow { color: var(--yellow); }

/*------------------------------------------*/
/*  FONT WEIGHT
/*------------------------------------------*/

.txt-upcase { 
  text-transform: uppercase 
}

.w-200 { font-weight: 200; }
.w-300 { font-weight: 300; }
.w-400 { font-weight: 400; }
.w-500 { font-weight: 500; }
.w-600 { font-weight: 600; }
.w-700 { font-weight: 700; }
.w-800 { font-weight: 800; }
.w-900 { font-weight: 900; } 




/* ==========================================================================
  03. PRELOADER SPINNER
  ========================================================================== */

#loading {
  position: fixed;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  z-index: 9999;
  background-color: var(--white);
}

#loading-center {
  display: block;
  position: relative;
  top: 50%;
  transform: translateY(-50%);
}

.loader {
  display: block;
  margin: 0 auto;
  width: 60px;
  height: 60px;
  position: relative;
  border: 7px solid var(--theme-color);
  animation: loader 2.3s infinite ease;
  -o-animation: loader 2.3s infinite ease;
  -ms-animation: loader 2.3s infinite ease;
  -webkit-animation: loader 2.3s infinite ease;
  -moz-animation: loader 2.3s infinite ease;
}

.loader-inner {
  vertical-align: top;
  display: inline-block;
  width: 100%;
  background-color: var(--theme-color);
  animation: loader-inner 2.3s infinite ease-in;
  -o-animation: loader-inner 2.3s infinite ease-in;
  -ms-animation: loader-inner 2.3s infinite ease-in;
  -webkit-animation: loader-inner 2.3s infinite ease-in;
  -moz-animation: loader-inner 2.3s infinite ease-in;
}

@keyframes loader {
  0% {
    transform: rotate(0deg);
  }
  
  25% {
    transform: rotate(180deg);
  }
  
  50% {
    transform: rotate(180deg);
  }
  
  75% {
    transform: rotate(360deg);
  }
  
  100% {
    transform: rotate(360deg);
  }
}

@keyframes loader-inner {
  0% {
    height: 0%;
  }
  
  25% {
    height: 0%;
  }
  
  50% {
    height: 100%;
  }
  
  75% {
    height: 100%;
  }
  
  100% {
    height: 0%;
  }
}




/* ==========================================================================
  04. HEADER & NAVIGATION
  =========================================================================== */

#header {
  width: 100%;
  display: block;
  padding-top: 0px;
}

.header-wrapper {
  position: fixed;
  top: 0;
  right: 0;
  left: 0;
  z-index: 1030;
}

.headerwp {
  max-width: 1140px;
  margin: 0 auto;
  padding: 0;
}

.posrlt {
  position: relative;
}

/*------------------------------------------*/
/*  HEADER LOGO
/*------------------------------------------*/

.desktoplogo img {
  width: auto;
  max-width: inherit;
  max-height: 50px;
}

/*------------------------------------------*/
/*  NAVIGATION MENU
/*------------------------------------------*/

.wsmainfull {
  width: 100%;
  height: auto;
  z-index: 1031;
  -webkit-transition: all 450ms ease-in-out;
  -moz-transition: all 450ms ease-in-out;
  -o-transition: all 450ms ease-in-out;
  -ms-transition: all 450ms ease-in-out;
  transition: all 450ms ease-in-out; 
}

.tra-menu .wsmainfull {
  padding: 20px 0;
  background-color: transparent!important;
  -webkit-box-shadow: none;
  -moz-box-shadow: none;
  box-shadow: none;
}

.white-menu .wsmainfull {
  padding: 6px 0;
  background-color: var(--white)!important;
  -webkit-box-shadow: 0 2px 3px var(--tra-gray);
  -moz-box-shadow: 0 2px 3px var(--tra-gray);
  box-shadow: 0 2px 3px var(--tra-gray);
}

.dark-menu .wsmainfull {
  padding: 6px 0;
  background-color: var(--ink)!important;
  -webkit-box-shadow: 0 2px 3px var(--tra-ink);
  -moz-box-shadow: 0 2px 3px var(--tra-ink);
  box-shadow: 0 2px 3px var(--tra-ink);
}

#header.hiddden-header {
  display: none;
}

.hidden-nav .wsmainfull {
  margin-top: -140px;
  -webkit-box-shadow: 0 2px 3px var(--tra-gray);
  -moz-box-shadow: 0 2px 3px var(--tra-gray);
  box-shadow: 0 2px 3px var(--tra-gray);
  -webkit-transition: all 400ms ease-in-out;
  -moz-transition: all 400ms ease-in-out;
  -o-transition: all 400ms ease-in-out;
  -ms-transition: all 400ms ease-in-out;
  transition: all 400ms ease-in-out;
}

/*------------------------------------------*/
/*  HEADER LINK
/*------------------------------------------*/ 

.wsmenu > .wsmenu-list > li > a {
  font-family: 'Plus Jakarta Sans', sans-serif;
  display: block;
  font-size: 1rem;
  line-height: 50px;
  font-weight: 600;
  letter-spacing: 0;
  text-decoration: none;  
  margin: 0 7px;
  padding: 10px 15px;
}

.navbar-dark .wsmenu > .wsmenu-list > li > a.h-link {
  color: var(--black);
}

.navbar-light .wsmenu > .wsmenu-list > li > a.h-link {
  color: var(--white);
}

.wsmenu > .wsmenu-list > li > a.h-link.last-link {
  padding: 10px 0px;
  margin: 0;
}

.wsmenu > .wsmenu-list > li > a .wsarrow:after {
  border-left: 4px solid rgba(0, 0, 0, 0);
  border-right: 4px solid rgba(0, 0, 0, 0);
  border-top: 4px solid;
  content: "";
  float: right;
  right: 0;
  height: 0;
  margin: 0 0 0 14px;
  position: absolute;
  text-align: right;
  top: 35px;
  width: 0;
}

/*------------------------------------------*/
/*  HEADER LINK INFO
/*------------------------------------------*/

span.sm-info {
  position: relative;
  display: inline-block;
  text-align: center;
  width: 20px;
  height: 20px;
  color: var(--white);
  font-size: 0.65rem;
  line-height: 19px;
  font-weight: 700;
  margin-left: 5px;
  -webkit-border-radius: 100%;
  -moz-border-radius: 100%;
  -o-border-radius: 100%;
}

.nav-blue .sm-info { background-color: var(--blue); }
.nav-green .sm-info { background-color: var(--green); }
.nav-indigo .sm-info { background-color: var(--indigo); }
.nav-ocean .sm-info { background-color: var(--ocean); }
.nav-pink .sm-info { background-color: var(--pink); }
.nav-purple .sm-info { background-color: var(--purple); }

/*------------------------------------------*/
/*  HEADER BUTTONS
/*------------------------------------------*/

.wsmenu > .wsmenu-list > li a.btn {
  font-size: 1rem;
  line-height: 30px;
  font-weight: 600;
  text-transform: none;
  letter-spacing: 0;
  margin-top: 14px;
  padding: 6px 28px 8px;
}

.wsmenu > .wsmenu-list > li a.btn.reg-btn {
  padding: 4px 22px;
}

.wsmenu > .wsmenu-list > li a.btn.fst-btn-link {
  margin-left: 12px;
}

.wsmenu > .wsmenu-list > li a.store.header-store {
  line-height: 1;
  margin-top: 13px;
  margin-left: 10px;
  margin-bottom: 0;
  padding: 0;
}

/*------------------------------------------*/
/*  LANGUAGE SELECT
/*------------------------------------------*/

.wsmenu > .wsmenu-list > li.lang {
  margin-right: 12px;
}

.wsmenu > .wsmenu-list > li.lang > ul.sub-menu {
  width: auto;
  min-width: 115px!important;
  padding: 8px 5px;
}

.wsmenu > .wsmenu-list > li.lang > ul.sub-menu > li > a {
  font-size: 0.875rem;
  padding: 8px 11px;
}

.wsmenu > .wsmenu-list > li.lang > ul.sub-menu > li > a:hover {
  padding: 8px 11px;
}

/*------------------------------------------*/
/*  HEADER STORE BADGES
/*------------------------------------------*/

.store.header-store img {
  max-height: 44px;
}

/*------------------------------------------*/
/*  HEADER SOCIAL LINKS  
/*------------------------------------------*/

.header-socials {
  margin: 0;
  display: inline-block; 
  text-align: center;
}

.header-socials span {
  float: left;
  width: auto!important;
  display: inline-block!important;
  vertical-align: top;
  clear: none!important;
  margin: 0;
}

.header-socials a {
  display: block; 
  margin-left: 15px;
}

.navbar-light .header-socials a {
  color: #ddd;
}

.navbar-light .header-socials a:hover {
  color: var(--silver);
}

.header-socials.ico-20 [class*="flaticon-"]:before, 
.header-socials.ico-20 [class*="flaticon-"]:after { line-height: 70px!important; } 

/*------------------------------------------*/
/*  HEADER SUBMENU
/*------------------------------------------*/

.wsmenu > .wsmenu-list > li > ul.sub-menu {
  width: auto;
  min-width: 200px;
  top: 62px;
  padding: 12px 10px;
  -webkit-border-radius: 6px;
  -moz-border-radius: 6px;
  -o-border-radius: 6px;
  -webkit-box-shadow: 0 2px 3px var(--tra-gray);
  -moz-box-shadow: 0 2px 3px var(--tra-gray);
  box-shadow: 0 2px 3px var(--tra-gray);
}

.wsmenu > .wsmenu-list > li > ul.sub-menu > li > a {
  background-color: var(--tra-white-smoke);
  font-family: 'Plus Jakarta Sans', sans-serif;
  color: var(--black);
  font-size: 1rem;
  font-weight: 600;
  padding: 11px 14px;
  -webkit-border-radius: 6px;
  -moz-border-radius: 6px;
  -o-border-radius: 6px;
  transition: all 0.3s ease-in-out;
}

.wsmenu > .wsmenu-list > li > ul.sub-menu > li > a:hover {
  background-color: var(--white-smoke);
  padding: 11px 14px;
}

/*------------------------------------------*/
/*  HEADER SUBMENU SUBMENU
/*------------------------------------------*/

.wsmenu > .wsmenu-list > li > ul.sub-menu > li > ul.sub-menu {
  min-width: 210px;
  position: absolute;
  left: 102%;
  top: 0;
  margin: 0;
  padding: 10px;
  border: none;
  -webkit-border-radius: 6px;
  -moz-border-radius: 6px;
  border-radius: 6px;
  -webkit-box-shadow: 0 2px 3px var(--tra-gray);
  -moz-box-shadow: 0 2px 3px var(--tra-gray);
  box-shadow: 0 2px 3px var(--tra-gray);
}

.wsmenu > .wsmenu-list > li > ul.sub-menu > li > ul.sub-menu > li {
  border-bottom: none;
}

.wsmenu > .wsmenu-list > li > ul.sub-menu > li > ul.sub-menu > li > a {
  background-color: var(--tra-white-smoke);
  font-family: 'Plus Jakarta Sans', sans-serif;
  color: var(--black);
  font-size: 1rem;
  font-weight: 600;
  padding: 11px 14px;
  -webkit-border-radius: 6px;
  -moz-border-radius: 6px;
  -o-border-radius: 6px;
  transition: all 0.3s ease-in-out;
}

.wsmenu > .wsmenu-list > li > ul.sub-menu > li > ul.sub-menu > li > a:hover {
  background-color: var(--white-smoke);
  padding: 11px 14px;
}

.wsmenu > .wsmenu-list > li > ul.sub-menu > li > a span {
  position: absolute;
  top: 12px;
  right: 12px;
}

.sub-menu.ico-10 [class*="flaticon-"]:before, 
.sub-menu.ico-10 [class*="flaticon-"]:after { font-size: 0.7rem; } 

/*------------------------------------------*/
/*  HALFMENU
/*------------------------------------------*/

.wsmenu > .wsmenu-list > li > .wsmegamenu {
  top: 62px;
}

.wsmenu > .wsmenu-list > li > .wsmegamenu.halfmenu {
  width: 33%;
  padding: 12px 0;
  -webkit-border-radius: 6px;
  -moz-border-radius: 6px;
  -o-border-radius: 6px;
  -webkit-box-shadow: 0 2px 3px var(--tra-gray);
  -moz-box-shadow: 0 2px 3px var(--tra-gray);
  box-shadow: 0 2px 3px var(--tra-gray);
}

.wsmenu > .wsmenu-list > li > .wsmegamenu .link-list li {
  border-bottom: none;
}

.wsmenu > .wsmenu-list > li > .wsmegamenu.w-75 ul.link-list > li > a,
.wsmenu > .wsmenu-list > li > .wsmegamenu.halfmenu ul.link-list > li > a {
  font-family: 'Plus Jakarta Sans', sans-serif;
  background-color: var(--tra-white-smoke);
  color: var(--black);
  font-size: 1rem;
  font-weight: 600;
  padding: 11px 14px;
  -webkit-border-radius: 6px;
  -moz-border-radius: 6px;
  -o-border-radius: 6px;
  transition: all 0.3s ease-in-out;
}

.wsmenu > .wsmenu-list > li > .wsmegamenu.w-75 ul.link-list > li > a:hover,
.wsmenu > .wsmenu-list > li > .wsmegamenu.halfmenu ul.link-list > li > a:hover {
  background-color: var(--white-smoke);
  padding: 11px 14px;
}

/*------------------------------------------*/
/*  MEGAMENU
/*------------------------------------------*/

.wsmenu > .wsmenu-list > li > .wsmegamenu {
  padding: 12px 0;
  -webkit-border-radius: 6px;
  -moz-border-radius: 6px;
  -o-border-radius: 6px;
  -webkit-box-shadow: 0 2px 3px var(--tra-gray);
  -moz-box-shadow: 0 2px 3px var(--tra-gray);
  box-shadow: 0 2px 3px var(--tra-gray);
}

.wsmenu > .wsmenu-list > li > .wsmegamenu.w-75 {
  width: 74%!important;
  left: 25%;
  padding: 20px 10px;
}

.wsmegamenu.w-75 .col-lg-3 {
  padding: 0 6px;
}

/*------------------------------------------*/
/*  SUBMENU TITLE
/*------------------------------------------*/

.wsmenu > .wsmenu-list > li > .wsmegamenu .title,
.wsmenu > .wsmenu-list > li > .wsmegamenu.halfmenu .title {
  color: var(--light-gray);
  font-family: 'Plus Jakarta Sans', sans-serif;
  font-size: 0.875rem;
  line-height: 1;
  font-weight: 600;
  text-transform: uppercase;
  letter-spacing: 0;
  margin: 10px 0 15px 13px;
}

/*------------------------------------------*/
/*  DARK HEADER SUBMENU / MEGAMENU
/*------------------------------------------*/

.dark-menu .wsmenu > .wsmenu-list > li > ul.sub-menu,
.dark-menu .wsmenu > .wsmenu-list > li > .wsmegamenu,
.dark-menu .wsmenu > .wsmenu-list > li > .wsmegamenu.halfmenu,
.black-scroll .scroll .wsmenu > .wsmenu-list > li > ul.sub-menu,
.black-scroll .scroll .wsmenu > .wsmenu-list > li > .wsmegamenu,
.black-scroll .scroll .wsmenu > .wsmenu-list > li > .wsmegamenu.halfmenu {
  background-color: var(--metal)!important; 
  border: solid 1px var(--metal)!important; 
  -webkit-box-shadow: 0 2px 3px var(--tra-ink);
  -moz-box-shadow: 0 2px 3px var(--tra-ink);
  box-shadow: 0 2px 3px var(--tra-ink);
}

.dark-menu .wsmenu > .wsmenu-list > li > ul.sub-menu > li > a,
.dark-menu .wsmenu > .wsmenu-list > li > .wsmegamenu.w-75 ul.link-list > li > a,
.dark-menu .wsmenu > .wsmenu-list > li > .wsmegamenu.halfmenu ul.link-list > li > a,
.black-scroll .scroll .wsmenu > .wsmenu-list > li > ul.sub-menu > li > a,
.black-scroll .scroll .wsmenu > .wsmenu-list > li > .wsmegamenu.w-75 ul.link-list > li > a,
.black-scroll .scroll .wsmenu > .wsmenu-list > li > .wsmegamenu.halfmenu ul.link-list > li > a {
  color: #ddd;
  background-color: rgba(255, 255, 255, 0)!important;
}

.dark-menu .wsmenu > .wsmenu-list > li > ul.sub-menu > li > a:hover,
.dark-menu .wsmenu > .wsmenu-list > li > .wsmegamenu.w-75 ul.link-list > li > a:hover,
.dark-menu .wsmenu > .wsmenu-list > li > .wsmegamenu.halfmenu ul.link-list > li > a:hover,
.black-scroll .scroll .wsmenu > .wsmenu-list > li > ul.sub-menu > li > a:hover,
.black-scroll .scroll .wsmenu > .wsmenu-list > li > .wsmegamenu.w-75 ul.link-list > li > a:hover,
.black-scroll .scroll .wsmenu > .wsmenu-list > li > .wsmegamenu.halfmenu ul.link-list > li > a:hover {
  background-color: rgba(255, 255, 255, .05)!important;
}

.dark-menu .title, .black-scroll .scroll .title,
.dark-menu .icons-menu-txt span, .black-scroll .scroll .icons-menu-txt span {
  color: var(--white)!important;
}

/*------------------------------------------*/
/*  NAVBAR HOVER
/*------------------------------------------*/

.navbar-light .wsmenu-list > li > a.h-link:hover {
  color: var(--smoke);
}

.navbar-dark .wsmenu-list > li > a.h-link:hover {
  color: var(--ink);
}

.wsmenu > .wsmenu-list.nav-blue > li > ul.sub-menu > li > a:hover,
.wsmenu > .wsmenu-list.nav-blue > li > ul.sub-menu > li > ul.sub-menu > li > a:hover,
.wsmenu > .wsmenu-list.nav-blue > li > .wsmegamenu.w-75 ul.link-list > li > a:hover,
.wsmenu > .wsmenu-list.nav-blue > li > .wsmegamenu.halfmenu ul.link-list > li > a:hover {
  color: var(--blue);
}

.wsmenu > .wsmenu-list.nav-green > li > ul.sub-menu > li > a:hover,
.wsmenu > .wsmenu-list.nav-green > li > ul.sub-menu > li > ul.sub-menu > li > a:hover,
.wsmenu > .wsmenu-list.nav-green > li > .wsmegamenu.w-75 ul.link-list > li > a:hover,
.wsmenu > .wsmenu-list.nav-green > li > .wsmegamenu.halfmenu ul.link-list > li > a:hover {
  color: var(--green);
}

.wsmenu > .wsmenu-list.nav-indigo > li > ul.sub-menu > li > a:hover,
.wsmenu > .wsmenu-list.nav-indigo > li > ul.sub-menu > li > ul.sub-menu > li > a:hover,
.wsmenu > .wsmenu-list.nav-indigo > li > .wsmegamenu.w-75 ul.link-list > li > a:hover,
.wsmenu > .wsmenu-list.nav-indigo > li > .wsmegamenu.halfmenu ul.link-list > li > a:hover {
  color: var(--indigo);
}

.wsmenu > .wsmenu-list.nav-ocean > li > ul.sub-menu > li > a:hover,
.wsmenu > .wsmenu-list.nav-ocean > li > ul.sub-menu > li > ul.sub-menu > li > a:hover,
.wsmenu > .wsmenu-list.nav-ocean > li > .wsmegamenu.w-75 ul.link-list > li > a:hover,
.wsmenu > .wsmenu-list.nav-ocean > li > .wsmegamenu.halfmenu ul.link-list > li > a:hover {
  color: var(--ocean);
}

.wsmenu > .wsmenu-list.nav-pink > li > ul.sub-menu > li > a:hover,
.wsmenu > .wsmenu-list.nav-pink > li > ul.sub-menu > li > ul.sub-menu > li > a:hover,
.wsmenu > .wsmenu-list.nav-pink > li > .wsmegamenu.w-75 ul.link-list > li > a:hover,
.wsmenu > .wsmenu-list.nav-pink > li > .wsmegamenu.halfmenu ul.link-list > li > a:hover {
  color: var(--pink);
}

.wsmenu > .wsmenu-list.nav-purple > li > ul.sub-menu > li > a:hover,
.wsmenu > .wsmenu-list.nav-purple > li > ul.sub-menu > li > ul.sub-menu > li > a:hover,
.wsmenu > .wsmenu-list.nav-purple > li > .wsmegamenu.w-75 ul.link-list > li > a:hover,
.wsmenu > .wsmenu-list.nav-purplen > li > .wsmegamenu.halfmenu ul.link-list > li > a:hover {
  color: var(--purple);
}

.wsmenu > .wsmenu-list.nav-skyblue > li > ul.sub-menu > li > a:hover,
.wsmenu > .wsmenu-list.nav-skyblue > li > ul.sub-menu > li > ul.sub-menu > li > a:hover,
.wsmenu > .wsmenu-list.nav-skyblue > li > .wsmegamenu.w-75 ul.link-list > li > a:hover,
.wsmenu > .wsmenu-list.nav-skyblue > li > .wsmegamenu.halfmenu ul.link-list > li > a:hover {
  color: var(--sky-blue);
}

/*------------------------------------------*/
/*  HIDDEN NAVBAR SCROLL
/*------------------------------------------*/

.hidden-nav .wsmainfull.scroll {
  margin-top: 0;
}

/*------------------------------------------*/
/*  NAVBAR WHITE SCROLL
/*------------------------------------------*/

.tra-menu.white-scroll .wsmainfull.scroll,
.white-menu.white-scroll .wsmainfull.scroll,
.tra-menu.border-header.white-scroll .wsmainfull.scroll {
  padding: 0;
  border: none;
  background-color: rgba(255, 255, 255, .7)!important;
  backdrop-filter: blur(20px);
  -webkit-backdrop-filter: blur(20px);
  -webkit-box-shadow: 0 2px 3px var(--tra-gray);
  -moz-box-shadow: 0 2px 3px var(--tra-gray);
  box-shadow: 0 2px 3px var(--tra-gray);
}

.tra-menu.navbar-dark.white-scroll .scroll .wsmenu > .wsmenu-list > li > a.h-link, 
.tra-menu.navbar-light.white-scroll .scroll .wsmenu > .wsmenu-list > li > a.h-link {
  color: var(--coal);
}

.navbar-light.white-scroll .scroll .wsmenu-list > li > a.h-link:hover {
  color: var(--ink)!important;
}

/*------------------------------------------*/
/*  NAVBAR BLACK SCROLL
/*------------------------------------------*/

.tra-menu.black-scroll .wsmainfull.scroll,
.dark-menu.black-scroll .wsmainfull.scroll {
  padding: 0;
  background-color: var(--ink)!important;
  -webkit-box-shadow: 0 2px 3px var(--tra-ink);
  -moz-box-shadow: 0 2px 3px var(--tra-ink);
  box-shadow: 0 2px 3px var(--tra-ink);
}

.tra-menu.navbar-dark.black-scroll .scroll .wsmenu > .wsmenu-list > li > a.h-link {
  color: var(--white);
}

.tra-menu.navbar-dark.black-scroll .scroll .wsmenu > .wsmenu-list > li > a.h-link:hover {
  color: var(--silver)!important;
}

/*------------------------------------------*/
/*  WHITE SCROLL BUTTONS
/*------------------------------------------*/

.white-scroll .scroll .btn--tra-white {
  color: var(--coal)!important;
  border-color: var(--coal)!important;
}

/*------------------------------------------*/
/*  BLACK SCROLL BUTTONS
/*------------------------------------------*/

.black-scroll .scroll .btn--tra-black {
  color: var(--white)!important;
  border-color: var(--white)!important;
}

/*------------------------------------------*/
/*  LOGO IMAGE
/*------------------------------------------*/

.logo-white, 
.logo-black {
  display: block;
}

.navbar-dark .logo-white,
.navbar-light .logo-black,
.tra-menu.navbar-light .scroll .logo-white,
.tra-menu.navbar-dark.black-scroll .scroll .logo-black {
  display: none;
}

.tra-menu.navbar-light .scroll .logo-black,
.tra-menu.navbar-dark.black-scroll .scroll .logo-white {
  display: block;
}




/* ==========================================================================
  05. HERO
  ========================================================================== */

.hero-section .container {
  position: relative;
  z-index: 3;
}

.hero-section.blur--purple:after {
  position: absolute;
  content: '';
  z-index: -1;
  width: 100%;
  height: 90%;
  top: -45%;
  left: 10%;
  /* background-image: url(~images/home/blur-purple.png); */
  background-repeat: no-repeat;
  background-position: center center;   
  background-size: cover;
  background-attachment: scroll;
  opacity: 0.1;
}

/*------------------------------------------*/
/*  IMAGE LINK
/*------------------------------------------*/

.img-link {
  text-align: center;
  position: absolute;
  bottom: 30px;
  right: 30px;
  width: 55px;
  height: 55px;
  background-color: var(--yellow);
  border: 3px solid transparent;
  -webkit-border-radius: 100%;
  -moz-border-radius: 100%;
  border-radius: 100%; 
  -webkit-transition: all 450ms ease-in-out;
  -moz-transition: all 450ms ease-in-out;
  -o-transition: all 450ms ease-in-out;
  -ms-transition: all 450ms ease-in-out;
  transition: all 450ms ease-in-out;
}

.img-link a span {
  position: relative;
  top: 7px;
  -webkit-transition: all 450ms ease-in-out;
  -moz-transition: all 450ms ease-in-out;
  -o-transition: all 450ms ease-in-out;
  -ms-transition: all 450ms ease-in-out;
  transition: all 450ms ease-in-out;
}

.img-link:hover {
  background-color: transparent;
  border-color: var(--white);
}

.img-link:hover span {
  color: var(--white);
}

/*------------------------------------------*/
/*  HERO SLIDESHOW
/*------------------------------------------*/

.slideshow {
  overflow: hidden;
  position: relative;
  width: 100%;
  height: 650px;
  z-index: 1;
}

#hero-20 .slideshow {
  height: 600px;
}

.slideshow .slideshow-inner {
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
}

.slideshow .slides {
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 650px;
  z-index: 1;
}

#hero-20 .slideshow .slides {
  height: 600px;
}

.slideshow .slide {
  display: none;
  overflow: hidden;
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  z-index: 1;
  opacity: 0;
  transition: opacity 0.3s ease;
}

.slideshow .slide.is-active {
  display: block;
}

.slideshow .slide.is-loaded {
  opacity: 1;
}

.slideshow .slide .image-container {
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  z-index: 1;
  background-repeat: no-repeat;
  background-position: center center;
  background-size: cover;
}

.slideshow .slide .image {
  width: 100%;
  object-fit: cover;
  height: 100%;
}

.slideshow .slide-content {
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  z-index: 2;
  text-align: center;
  display: flex;
  justify-content: center;
  align-items: center;
}

/*------------------------------------------*/
/*  HERO BACKGROUND SHAPE
/*------------------------------------------*/

.hero-bg-shape {
  position: absolute;
  content: '';
  z-index: -1;
  width: 116%;
  height: 100%;
  top: 0;
  left: -8%;
  right: -8%;
  opacity: .25;
}

.hero-section h2,
.hero-section h3 {
  margin-bottom: 0;
}

/*------------------------------------------*/
/*  HERO DIGITS
/*------------------------------------------*/

.hero-digits {
  padding-right: 20px;
}

#hd-1-1, #hd-1-2 {
  display: inline-block;
  float: left;
  padding-right: 20px;
} 

#hd-1-1 { width: 48%; }
#hd-1-2 { width: 48%; }

.hero-digits h2.statistic-number {
  font-size: 3.35rem;
  line-height: 1;
  letter-spacing: -1.5px;
  margin-bottom: 10px;
} 

.hero-digits h2.statistic-number small {
  font-size: 2.25rem;
  margin-left: 5px;
}

.hero-digits .block-txt p.p-sm {
  margin-bottom: 0;
}

/*------------------------------------------*/
/*  HERO-1
/*------------------------------------------*/

#hero-1 {
  /* background-image: url(~images/home/hero-1.jpg); */
  padding-top: 130px;
  padding-bottom: 100px;
}

.theme--dark #hero-1 { 
  /* background-image: url(~images/home/bg-dark.jpg);  */
}

.hero-1-txt h2 {
  font-size: 3.45rem;
}

.hero-1-txt p.p-lg,
.hero-1-txt p.p-xl {
  margin-top: 25px;
  margin-bottom: 30px;
}

.hero-1-img {
  text-align: center;
  margin: 0 -5px;
}

/*------------------------------------------*/
/*  HERO-2
/*------------------------------------------*/

#hero-2 {
  overflow: hidden; 
  background-image: url(~images/home/hero-2.jpg);
  padding-top: 170px;
}

.theme--dark #hero-2 {
  /* background-image: url(~images/home/bg-dark.jpg); */
}

#hero-2:after {
  position: absolute;
  content: '';
  z-index: -1;
  width: 100%;
  height: 10%;
  bottom: 0;
  left: 0;
  background-color: var(--white);
}

.theme--dark #hero-2:after {
  background-color: var(--dark-theme-bg);
}

.hero-2-txt h2 {
  font-size: 3.75rem;
}

.hero-2-txt p.p-lg {
  padding: 0 5%;
  margin-top: 20px;
  margin-bottom: 30px;
}

.hero-2-img {
  margin-top: 60px;
}

/*------------------------------------------*/
/*  HERO-3
/*------------------------------------------*/

#hero-3 {
  /* background-image: url(~images/home/hero-3.jpg); */
  padding-top: 160px;
  padding-bottom: 100px;
}

.theme--dark #hero-3 {
  /* background-image: url(~images/home/hero-3d.jpg); */
}

.hero-3-txt h2 {
  font-size: 3.5rem;
}

.hero-3-txt p.p-md {
  padding-right: 3%;
  margin-top: 20px;
  margin-bottom: 0;
}

.hero-3-txt .users {
  margin-top: 20px;
}

.hero-3-img {
  text-align: center;
  padding-right: 30px;
}

/*------------------------------------------*/
/*  HERO-4
/*------------------------------------------*/

#hero-4 {
  position: relative;
  /* background-image: url(~images/home/hero-4.jpg); */
  background-position: bottom center;
  padding-top: 170px;
  padding-bottom: 50px;
}

.theme--dark #hero-4 {
  /* background-image: url(~images/home/bg-dark.jpg); */
}

.hero-4-txt p.p-lg {
  margin-top: 25px;
  margin-bottom: 30px;
}

.hero-4-img {
  position: relative;
  text-align: center;
  margin: 0 -115% 0 20px;
}

/*------------------------------------------*/
/*  HERO-5
/*------------------------------------------*/

#hero-5 {
  padding-top: 130px;
  padding-bottom: 70px;
}

#hero-5:after {
  position: absolute;
  content: '';
  z-index: -1;
  width: 42%;
  height: 100%;
  top: 0;
  background-image: url(~images/home/bg-02.jpg);
}

.theme--dark #hero-5:after {
  /* background-image: url(~images/home/bg-03.jpg); */
}

.hero-5-txt {
  margin-left: 15px;
}

.hero-5-txt h2 {
  font-size: 3.4rem;
}

.hero-5-txt p.p-md {
  padding-right: 6%;
  margin-top: 25px;
  margin-bottom: 30px;
}

.hero-5-img {
  text-align: center;
  margin-right: 30px;
}

/*------------------------------------------*/
/*  HERO-6
/*------------------------------------------*/

#hero-6 {
  background-image: url(~images/home/hero-6.jpg);
  padding-top: 160px;
  padding-bottom: 100px;
}

.theme--dark #hero-6 {
  /* background-image: url(~images/home/bg-dark.jpg); */
}

.hero-6-txt h2 {
  font-size: 3.4rem;
}

.hero-6-txt p.p-lg {
  margin-top: 25px;
  margin-bottom: 0;
}

#hero-6 .quick-form {
  margin-top: 30px;
  margin-right: 5%;
}

.hero-6-img {
  padding-left: 20px;
}

/*------------------------------------------*/
/*  HERO-7
/*------------------------------------------*/

#hero-7 {
  /* background-image: url(~images/home/hero-7.jpg); */
  padding-top: 130px;
}

.theme--dark #hero-7 {
  /* background-image: url(~images/home/bg-dark.jpg); */
} 

.hero-7-txt h2 {
  font-size: 3.65rem;
}

.hero-7-txt p.p-lg,
.hero-7-txt p.p-xl {
  margin-top: 20px;
  margin-bottom: 25px;
}

#hero-7 .users {
  margin-top: 20px;
}

#hero-7 .users img {
  max-height: 48px;
}

#hero-7 .users p {
  font-size: 0.875rem;
  top: 12px;
  left: 8px;
}

.hero-7-img {
  text-align: center;
  margin-left: -10px;
}

/*------------------------------------------*/
/*  HERO-8
/*------------------------------------------*/

#hero-8 {
  padding-top: 170px;
}

.hero-8-txt h2 {
  font-size: 3.5rem;
}

.hero-8-txt p.p-lg {
  padding: 0 5%;
  margin-top: 20px;
  margin-bottom: 30px;
}

.hero-8-txt p.btn-txt {
  margin-top: 40px;
}

#hero-8 .quick-form {
  margin: 35px 7% 0;
}

.hero-8-img {
  margin-top: 60px;
}

/*------------------------------------------*/
/*  HERO-9
/*------------------------------------------*/

#hero-9 {
  /* background-image: url(~images/home/hero-9.jpg); */
  padding-top: 160px;
  padding-bottom: 85px;
}

#hero-9 .wave-shape-bottom path {
  fill: var(--white);
}

.theme--dark #hero-9 .wave-shape-bottom path {
  fill: var(--dark-theme-bg);
}

.hero-9-txt {
  margin-top: -50px;
}

.hero-9-txt h2 {
  font-size: 3.4rem;
}

.hero-9-txt p.p-lg {
  margin-top: 25px;
  margin-bottom: 0;
}

#hero-9 .quick-form {
  margin-top: 30px;
  margin-right: 5%;
}

.hero-9-img {
  padding-right: 20px;
}

/*------------------------------------------*/
/*  HERO-10
/*------------------------------------------*/

#hero-10 {
  padding-top: 170px;
}

.hero-10-txt {
  margin-bottom: 70px;
}

.hero-10-txt h2 {
  font-size: 3.5rem;
}

.hero-10-txt p.p-lg {
  padding: 0 5%;
  margin-top: 20px;
  margin-bottom: 30px;
}

.hero-10-wrapper {
  overflow: hidden;
}

#hero-10 .hero-overlay {
  /* background-image: url(~images/home/hero-10.jpg); */
  padding: 90px 70px 0;
}

.theme--dark #hero-10 .hero-overlay {
  /* background-image: url(~images/home/bg-03.jpg); */
}

.hero-10-img {
  position: relative;
  margin-bottom: -20%;
}

.hero-10-img .video-btn {
  top: calc(50% - 60px);
}

/*------------------------------------------*/
/*  HERO-11
/*------------------------------------------*/

#hero-11 {
  /* background-image: url(~images/home/hero-11.jpg); */
  padding-top: 150px;
  padding-bottom: 100px;
}

.theme--dark #hero-11 {
  /* background-image: url(~images/home/bg-dark.jpg); */
}

.hero-11-txt h2 {
  font-size: 3.45rem;
}

.hero-11-txt p.p-md,
.hero-11-txt p.p-lg {
  padding-right: 6%;
  margin-top: 25px;
  margin-bottom: 30px;
}

.hero-11-img {
  text-align: center;
  margin-left: -15px;
}

/*------------------------------------------*/
/*  HERO-12
/*------------------------------------------*/

#hero-12 {
  padding-top: 130px;
}

.hero-12-wrapper {
  overflow: hidden;
  /* background-image: url(~images/home/hero-12.jpg); */
  padding: 75px 65px;
}

.theme--dark .hero-12-wrapper {
  /* background-image: url(~images/home/bg-dark.jpg); */
}

.hero-12-txt h2 {
  font-size: 3.1rem;
}

.hero-12-txt p.p-lg {
  margin-top: 20px;
  margin-bottom: 25px;
}

.hero-12-img {
  position: relative;
  text-align: center;
  margin-left: -15px;
}

/*------------------------------------------*/
/*  HERO-13
/*------------------------------------------*/

#hero-13 {
  /* background-image: url(~images/home/hero-13.jpg); */
  padding-top: 170px;
  padding-bottom: 80px;
} 

.theme--dark #hero-13 {
  /* background-image: url(~images/home/bg-dark.jpg); */
} 

.hero-13-txt h2 {
  font-size: 3.5rem;
}

.hero-13-txt p.p-xl {
  padding: 0 10%;
  margin-top: 20px;
  margin-bottom: 0;
}

.hero-13-txt p.btn-txt {
  margin-top: 40px;
}

#hero-13 .quick-form {
  margin: 35px 12% 0;
}

#hero-13 .rating-1-wrapper {
  padding: 0 14%;
}

/*------------------------------------------*/
/*  HERO-14
/*------------------------------------------*/

#hero-14 {
  position: relative;
  /* background-image: url(~images/home/hero-14.jpg); */
  padding-top: 160px;
}

#hero-14:after {
  position: absolute;
  content: '';
  z-index: -1;
  width: 100%;
  height: 5%;
  bottom: 0;
  left: 0;
  background-color: var(--white);
}

.theme--dark #hero-14:after {
  background-color: var(--dark-theme-bg);
}

.hero-14-txt {
  margin-top: -70px;
  padding-right: 5%;
}

.hero-14-txt .section-id {
  display: inline-block;
  background-color: var(--tra-white);
  padding: 12px 25px;
  font-size: 0.845rem;
  margin-bottom: 45px;
}

.hero-14-txt h2 {
  font-size: 3.5rem;
}

.hero-14-txt p.p-md {
  margin-top: 30px;
  margin-bottom: 25px;
}

.hero-14-img {
  position: relative;
  text-align: center;
  margin: 0 -90% 0 -20px;
}

/*------------------------------------------*/
/*  HERO-15
/*------------------------------------------*/

#hero-15 .hero-overlay {
  /* background-image: url(~images/home/hero-15.png); */
  padding-top: 170px;
  padding-bottom: 100px;
}

.hero-15-txt h2 { 
  font-size: 3.5rem;
}

.hero-15-txt p.p-lg { 
  padding: 0 3%;
  margin-top: 30px;
  margin-bottom: 30px;
}

/*------------------------------------------*/
/*  HERO-16
/*------------------------------------------*/

#hero-16 {
  padding-top: 160px;
  padding-bottom: 90px;
}

.hero-16-txt h2 {
  font-size: 3.25rem;
}

.hero-16-txt p.p-lg {
  margin-top: 25px;
  margin-bottom: 0;
}

#hero-16 .quick-form {
  margin-top: 30px;
  margin-right: 5%;
}

#hero-16 .users {
  margin-top: 18px;
}

#hero-16 .users img {
  max-height: 44px;
}

.users p {
  font-size: 0.875rem;
  top: 12px;
  left: 8px;
}

.hero-16-img {
  padding-left: 20px;
}

/*------------------------------------------*/
/*  HERO-17
/*------------------------------------------*/

#hero-17 {
  overflow: hidden; 
  /* background-image: url(~images/home/hero-17.jpg); */
  padding-top: 180px;
}

.theme--dark #hero-17 {
  /* background-image: url(~images/home/bg-03.jpg); */
}

#hero-17:after {
  position: absolute;
  content: '';
  z-index: -1;
  width: 100%;
  height: 10%;
  bottom: 0;
  left: 0;
  background-color: var(--white);
}

.theme--dark #hero-17:after {
  background-color: var(--dark-theme-bg);
}

.hero-17-txt h2 {
  font-size: 3.6rem;
  padding: 0 2%;
}

.hero-17-txt p.p-xl { 
  padding: 0 8%; 
  margin-top: 25px;
  margin-bottom: 0;
}

.hero-17-txt .btns-group {
  margin-top: 30px;
}

.hero-17-img {
  margin-top: 70px;
}

/*------------------------------------------*/
/*  HERO-18
/*------------------------------------------*/

#hero-18 {
  position: relative;
  /* background-image: url(~images/home/hero-18.png); */
  padding-top: 190px;
}

.hero-18-txt {
  margin-bottom: 80px;
}

.hero-18-txt h2 {
  font-size: 3.85rem;
}

.hero-18-txt p.p-xl {
  padding: 0 5%;
  margin-top: 25px;
  margin-bottom: 0;
}

.hero-18-txt .btns-group {
  margin-top: 30px;
}

.hero-18-img {
  overflow: hidden;
  position: relative;
}

.h18-1 {
  margin-bottom: 30px;
}

.theme--dark .hero-bg-shape { 
  opacity: .05; 
}

/*------------------------------------------*/
/*  HERO-19
/*------------------------------------------*/

#hero-19 {
  /* background-image: url(~images/home/hero-19.jpg); */
  padding-top: 170px;
} 

#hero-19 .wave-shape-bottom path {
  fill: #fff;
}

.theme--dark #hero-19 .wave-shape-bottom path {
  fill: var(--dark-theme-bg);
}

.hero-19-txt h2 {
  font-size: 3.5rem;
}

.hero-19-txt p.p-xl {
  padding: 0 10%;
  margin-top: 25px;
  margin-bottom: 0;
}

.hero-19-txt p.btn-txt {
  margin-top: 40px;
}

#hero-19 .quick-form {
  margin: 35px 12% 0;
}

.hero-19-img {
  margin-top: 60px;
}

/*------------------------------------------*/
/*  HERO-20
/*------------------------------------------*/

#hero-20 {
  padding-top: 110px;
}

#hero-20 .caption {
  margin-top: 20px;
}

#hero-20 .caption h2 {
  font-size: 3.85rem;
  font-weight: 600;
  padding: 0 8%;
  margin-bottom: 0;
}

#hero-20 .caption p.p-xl {
  padding: 0 8%;
  margin-top: 25px;
  margin-bottom: 0;
}

#hero-20 .caption .btn {
  margin-top: 30px;
}

/*------------------------------------------*/
/*  HERO-21
/*------------------------------------------*/

#hero-21 {
  position: relative;
  padding-top: 180px;
}

#hero-21:after {
  position: absolute;
  content: '';
  z-index: -1;
  width: 100%;
  height: 15%;
  bottom: 0;
  left: 0;
  background-color: var(--white-smoke);
}

.hero-21-shape {
  position: absolute;
  content: '';
  z-index: -1;
  width: 74%;
  height: 100%;
  top: 7%;
  left: 12%;
  right: 0;
  opacity: .6;
}

.hero-21-txt h2 {
  font-size: 3.5rem;
}

.hero-21-txt p.p-xl { 
  padding: 0 8%; 
  margin-top: 25px;
  margin-bottom: 0;
}

.hero-21-txt .btns-group {
  margin-top: 30px;
}

.hero-21-img {
  margin-top: 70px;
}

/*------------------------------------------*/
/*  HERO-22
/*------------------------------------------*/

#hero-22 .caption {
  margin-top: 65px;
}

#hero-22 .caption h2 {
  font-size: 3.85rem;
  font-weight: 600;
  padding: 0 5%;
  margin-bottom: 0;
}

#hero-22 .caption p.p-xl {
  padding: 0 8%;
  margin-top: 25px;
  margin-bottom: 0;
}

#hero-22 .caption .btn {
  margin-top: 30px;
}

/*------------------------------------------*/
/*  HERO-23
/*------------------------------------------*/

#hero-23 {
  /* background-image: url(~images/home/hero-23.png); */
  padding-top: 170px;
}

.theme--dark #hero-23 {
  /* background-image: url(~images/home/hero-23-dark.png); */
}

.hero-23-txt h2 {
  font-size: 3.5rem;
}

.hero-23-txt p.p-lg {
  padding: 0 5%;
  margin-top: 20px;
  margin-bottom: 30px;
}

.hero-23-img {
  margin-top: 70px;
}

/*------------------------------------------*/
/*  HERO-24
/*------------------------------------------*/

#hero-24 {
  position: relative;
  /* background-image: url(~images/home/hero-24.jpg); */
  padding-top: 190px;
  padding-bottom: 25px;
}

#hero-24 .wave-shape-bottom path {
  fill: var(--white);
}

.theme--dark #hero-24 .wave-shape-bottom path {
  fill: var(--dark-theme-bg);
}

.hero-24-txt {
  margin-top: -75px;
  padding-right: 8%;
}

.hero-24-txt h2 {
  font-size: 3.5rem;
  font-weight: 600;
  margin-bottom: 0;
}

.hero-24-txt p.p-lg {
  margin-top: 28px;
  margin-bottom: 0;
}

.hero-24-txt .users {
  margin-top: 22px;
}

#hero-24-form {
  background-color: var(--white);
  padding: 40px 30px;
  margin-left: 20px;
}

#hero-24-form h5 {
  line-height: 1.4;
  margin-bottom: 15px;
}

.theme--dark #hero-24-form h5 { 
  color: var(--black); 
}

#hero-24-form p {
  color: #757575;
  margin-bottom: 30px;
}

#hero-24-form p.p-sm {
  text-align: center;
  padding: 0 5%!important;
  margin-top: 5px;
  margin-bottom: 0;
}

#hero-24-form p a {
  color: #1e90ff;
  font-weight: 500;
  text-decoration: underline;
}

#hero-24-form .btn {
  width: 100%;
  height: 58px;
  padding: 16px;
}

/*------------------------------------------*/
/*  HERO-25
/*------------------------------------------*/

#hero-25 {
  overflow: hidden; 
  /* background-image: url(~images/home/hero-25.jpg); */
  padding-top: 170px;
}

.hero-25-txt h2 {
  font-size: 3.85rem;
}

.hero-25-txt p.p-lg { 
  padding: 0 5%; 
  margin-top: 25px;
  margin-bottom: 30px;
}

.hero-25-txt p.btn-txt {
  margin-top: 20px;
}

.hero-25-img {
  margin-top: 60px;
  margin-bottom: -110px;
}

.hero-25-img .video-btn {
  top: calc(50% - 35px);
}

/*------------------------------------------*/
/*  HERO-26
/*------------------------------------------*/

#hero-26 {
  /* background-image: url(~images/home/hero-26.jpg); */
  background-position: bottom center;
  padding-top: 170px;
}

.theme--dark #hero-26 {
  /* background-image: url(~images/home/bg-dark.jpg); */
}

.hero-26-txt h2 {
  font-size: 3.85rem;
}

.hero-26-txt p.p-lg {
  padding: 0 5%;
  margin-top: 25px;
  margin-bottom: 30px;
}

.hero-26-img {
  margin: 60px -3% 0;
}

/*------------------------------------------*/
/*  HERO-27
/*------------------------------------------*/

#hero-27 {
  padding-top: 60px;
}

.hero-27-txt {
  padding-right: 5%;
}

.hero-27-txt .hero-logo {
  margin-top: 15px;
  margin-bottom: 45px;
}

.hero-27-txt .hero-logo img {
  width: auto;
  max-width: inherit;
  max-height: 35px;
}

.hero-27-txt h2 {
  font-size: 3.5rem;
  margin-bottom: 35px;
}

.hero-27-txt p {
  margin-top: 75px;
  margin-bottom: 0;
}

.hero-27-img {
  position: relative;
}

#hero-27 .img-link {
  bottom: 20px;
  left: 25px;
  right: 0;
  width: 70px;
  height: 70px;
}

#hero-27 .img-link a span {
  position: relative;
  top: 12px;
}

#hero-27 .img-link:hover {
  background-color: transparent;
  border-color: var(--coal);
}

#hero-27 .img-link:hover span {
  color: var(--coal);
}

/*------------------------------------------*/
/*  HERO QUICK FORM
/*------------------------------------------*/

.hero-section .quick-form .input-group {
  border: none;
}

/*------------------------------------------*/
/*  Hero Quick Form Input
/*------------------------------------------*/

.hero-section .quick-form .form-control {
  height: 64px;
  background-color: var(--white);
  border: 2px solid var(--silver);
  color: #666;
  font-size: 1.125rem;
  font-weight: 400;
  padding: 0 20px;
  -webkit-transition: all 450ms ease-in-out;
  -moz-transition: all 450ms ease-in-out;
  -o-transition: all 450ms ease-in-out;
  -ms-transition: all 450ms ease-in-out;
  transition: all 450ms ease-in-out;  
}

.hero-section .quick-form.form--shadow .form-control {
  border-color: transparent;
  -webkit-box-shadow: 0 5px 10px var(--tra-black);
  -moz-box-shadow: 0 5px 10px var(--tra-black);
  box-shadow: 0 5px 10px var(--tra-black);
}

.hero-section .quick-form.form-stroke .form-control {
  border-color: var(--coal);
}

.hero-section .quick-form.form-half .form-control {
  height: 58px;
 font-size: 1.0625rem;
}

/*------------------------------------------*/
/*  Hero Quick Form Placeholder
/*------------------------------------------*/

.hero-section .quick-form .form-control::-moz-placeholder { color: var(--light-gray); }
.hero-section .quick-form .form-control:-ms-input-placeholder { color: var(--light-gray); }
.hero-section .quick-form .form-control::-webkit-input-placeholder { color: var(--light-gray); }

/*------------------------------------------*/
/*  Hero Quick Form Focus
/*------------------------------------------*/

.hero-section .quick-form .form-control:focus {
  background-color: var(--white);
  border-color: var(--theme-color);
  outline: 0;
  box-shadow: none;
}

/*------------------------------------------*/
/*  Hero Quick Form Button
/*------------------------------------------*/

.hero-section .quick-form .btn {
  height: 64px;
  font-size: 1.0625rem;
  padding: 12px 45px;
  margin-left: 15px;
}

.hero-section .quick-form.form-half .btn {
  height: 58px;
  font-size: 1rem;
  padding: 13px 40px;
  margin-left: 14px;
}

/*------------------------------------------*/
/*  Hero Quick Form Message
/*------------------------------------------*/

.hero-section .quick-form-msg {
  width: 100%!important;
  display: block;
  margin: 20px 0 15px 5px;
}

.hero-section .text-center .quick-form-msg {
  margin: 20px 0 -15px 0;
}

.hero-section .quick-form-msg .loading {
  color: #3eb1ff;
  font-size: 1.05rem;
  line-height: 1;
  font-weight: 500;
}

.hero-section .text-center .quick-form-msg .loading {
  font-size: 1.125rem;
}

/*------------------------------------------*/
/*  HERO REQUEST FORM
/*------------------------------------------*/

/*------------------------------------------*/
/*  Hero Request Form Input
/*------------------------------------------*/

.request-form .form-control {
  height: 60px;
  background-color: #f2f3f5;
  border: 1px solid transparent;
  color: #666;
  font-size: 1rem;
  font-weight: 400;
  margin-bottom: 22px;
  -webkit-transition: all 400ms ease-in-out;
  -moz-transition: all 400ms ease-in-out;
  -o-transition: all 400ms ease-in-out;
  -ms-transition: all 400ms ease-in-out;
  transition: all 400ms ease-in-out;
}

/*------------------------------------------*/
/*  Hero Request Form Placeholder
/*------------------------------------------*/

.hero-form-wrapper .form-control::-moz-placeholder { color: var(--light-gray); } 
.hero-form-wrapper .form-control:-ms-input-placeholder { color: var(--light-gray); } 
.hero-form-wrapper .form-control::-webkit-input-placeholder { color: var(--light-gray); } 

.request-form .form-control:focus {
  background-color: var(--white);
  border-color: var(--theme-color);
  outline: 0;
  box-shadow: none;
}

/*------------------------------------------*/
/*  Hero Request Form Button
/*------------------------------------------*/

/*------------------------------------------*/
/*  Hero Request Form Message
/*------------------------------------------*/

.request-form-msg {
  width: 100%!important;
  display: block;
  text-align: center;
  margin-top: 15px;
}

.request-form-msg .loading {
  color: #00b2e4;
  font-size: 1.0625rem;
  line-height: 1;
  font-weight: 500;
}

.request-form .error {
  color: #e74c3c;
  font-size: 0.85rem;
  line-height: 1;
  font-weight: 400;
  margin-bottom: 10px;
  padding-left: 10px;
}




/* ==========================================================================
  06. ABOUT
  ========================================================================== */

.about-1-title {
  text-align: center;
  margin-bottom: 50px;
}

.about-2-title {
  padding-right: 4%;
}

/*------------------------------------------*/
/*  ABOUT TYPOGRAPHY
/*------------------------------------------*/ 

.about-1-title p {
  margin-top: 30px;
  margin-bottom: 0;
}

#about-3 .txt-block h5 {
  margin-bottom: 20px;
}

/*------------------------------------------*/
/*  ABOUT IMAGE
/*------------------------------------------*/ 

.about-1-img {
  margin: 0 -10%;
}




/* ==========================================================================
  07. FEATURES
  ========================================================================== */

.fbox-12-wrapper {
  padding-left: 25px;
}

#features-3.py-100.shape--bg:after,
#features-3.pt-100.shape--bg:after,
#features-4.py-100.shape--bg:after,
#features-4.pt-100.shape--bg:after {
  position: absolute;
  content: '';
  z-index: -1;
}

#features-3.py-100.shape--bg:after { width: 48%; height: 64%; top: 18%; left: 52%; }
#features-3.pt-100.shape--bg:after { width: 48%; height: 72%; top: 21%; left: 52%; }
#features-4.py-100.shape--bg:after { width: 48%; height: 64%; top: 18%; }
#features-4.pt-100.shape--bg:after { width: 48%; height: 72%; top: 21%; }

.rows-2 .fb-1, .rows-2 .fb-2, .rows-2 .fb-3 { margin-bottom: 50px; }

.rows-3 .fb-1, .rows-3 .fb-2, 
.rows-3 .fb-3, .rows-3 .fb-4 { 
  margin-bottom: 40px; 
}

.rows-4 .fb-1, .rows-4 .fb-2, 
.rows-4 .fb-3, .rows-4 .fb-4 { margin-bottom: 35px; }

.rows-4 .fbox-2.fb-1, .rows-4 .fbox-2.fb-2, 
.rows-4 .fbox-2.fb-3, .rows-4 .fbox-2.fb-4, 
.rows-4 .fbox-2.fb-5, .rows-4 .fbox-2.fb-6 {
  margin-bottom: 35px;
}

.fbox-6.fb-1, .fbox-6.fb-2, .fbox-6.fb-3, .fbox-12.fb-1, .fbox-12.fb-2 {
  margin-bottom: 30px;
}

/*------------------------------------------*/
/*  FEATURE BOX
/*------------------------------------------*/

.fbox-5 {
  padding: 40px 30px;
  border: 1px solid var(--silver);
}

.fbox-6 {
  background-color: var(--white);
  padding: 40px 35px;
  border: 1px solid var(--silver);
}

.fbox-9 { 
  position: relative;
  overflow: hidden;
  padding: 40px 30px; 
  border: 1px solid transparent;
}

.ico-title .fbox-9.fb-1,
.ico-title .fbox-9.fb-2,
.ico-title .fbox-9.fb-3 { 
  padding: 30px; 
}  

.fbox-9.fb-4,
.fbox-9.fb-5,
.fbox-9.fb-6 { 
  padding: 40px 30px 0; 
} 

.ico-title .fbox-9.fb-4,
.ico-title .fbox-9.fb-5,
.ico-title .fbox-9.fb-6 { 
  padding: 30px 30px 0; 
}  

.fbox-10 { 
  padding: 60px; 
  border: 1px solid transparent;
} 

.fbox-10.fb-5,
.fbox-10.fb-6 { 
  padding: 70px 65px; 
}

.ico-title .fbox-10.fb-5,
.ico-title .fbox-10.fb-6 { 
  padding: 45px 50px 35px; 
}  

.fbox-10.fb-7,
.fbox-10.fb-8 { 
  padding: 60px 60px 0; 
} 

.ico-title .fbox-10.fb-7,
.ico-title .fbox-10.fb-8 { 
  padding: 45px 50px 0; 
}  

/*------------------------------------------*/
/*  FEATURE BOX IMAGE
/*------------------------------------------*/ 

.fbox-img img {
  width: auto;
  max-width: inherit;
}

.fbox-img.h-175 img { max-height: 175px; }
.fbox-img.h-180 img { max-height: 180px; }

.fbox-7 .fbox-img { 
  margin-bottom: 30px; 
}

/*------------------------------------------*/
/*  FBOX-8 IMAGE
/*------------------------------------------*/ 

.fbox-8 .fbox-img {
  -webkit-border-radius: 12px 12px 0 0;
  -moz-border-radius: 12px 12px 0 0;
  -o-border-radius: 12px 12px 0 0;
  border-radius: 12px 12px 0 0;
} 

.fbox-8 .fbox-img.h-175 { padding-top: 40px; margin: 0 8px 60px; }
.fbox-8 .fbox-img.h-180 { padding-top: 42px; margin: 0 5px 60px; }  

.fbox-8 .fbox-img img { 
  margin-bottom: -25px; 
} 

/*------------------------------------------*/
/*  FBOX-9 IMAGE
/*------------------------------------------*/ 

.fbox-9.fb-1 .fbox-9-img,
.fbox-9.fb-2 .fbox-9-img,
.fbox-9.fb-3 .fbox-9-img {
  margin: 25px 0 0;
}

.fbox-9.fb-4 .fbox-9-img,
.fbox-9.fb-5 .fbox-9-img,
.fbox-9.fb-6 .fbox-9-img {
  margin: 25px 0 -20px;
}

.fbox-9.fb-7 .fbox-9-img,
.fbox-9.fb-8 .fbox-9-img,
.fbox-9.fb-9 .fbox-9-img {
  margin: 0 0 25px;
}

/*------------------------------------------*/
/*  FBOX-10 IMAGE
/*------------------------------------------*/ 

.fbox-10.fb-1 .fbox-10-img,
.fbox-10.fb-7 .fbox-10-img,
.fbox-10.fb-8 .fbox-10-img {
  margin: 40px 5% 0;
}

.fbox-10.fb-5 .fbox-10-img,
.fbox-10.fb-6 .fbox-10-img {
  margin: 45px 0 0;
}

.fbox-10.fb-2 .fbox-10-img,
.fbox-10.fb-3 .fbox-10-img,
.fbox-10.fb-4 .fbox-10-img {
   margin: 0 5% 40px;
}

/*------------------------------------------*/
/*  FEATURE BOX VECTOR ICON
/*------------------------------------------*/ 

.fbox-ico,
.fbox-ico.ico-rounded {
  margin-bottom: 22px;
}

.fbox-5 .fbox-ico {
  margin-bottom: 22px;
}

.fbox-ico[class*="img-"],
.fbox-ico.ico-rounded-md,
.fbox-ico.ico-rounded-lg {
  margin-bottom: 25px;
}

.t-icon.ico-25 [class*="flaticon-"]:before,
.t-icon.ico-25 [class*="flaticon-"]:after { font-size: 1.6875rem; }

.t-icon {
  margin-bottom: 6px;
}

.fbox-11-ico {
  margin-bottom: 20px;
} 

/*------------------------------------------*/
/*  FBOX-2
/*------------------------------------------*/ 

.fbox-2 {
  display: flex;
  position: relative;
  flex-flow: row wrap;
  align-items: stretch!important;
  justify-content: flex-start;
}

.fbox-ico-wrap {
  position: relative;
  margin-right: 30px;
}

.fbox-2 .fbox-ico {
  margin-top: 2px;
}

.fbox-2 .fbox-txt {
  overflow: hidden;
  flex: 1;
  max-width: 100%;
}

/*------------------------------------------*/
/*  FBOX-3, FBOX-4
/*------------------------------------------*/ 

.fbox-3-wrapper { 
  padding-left: 45px;
}

.fbox-4-wrapper { 
  padding-right: 45px;
}

.fbox-3,
.fbox-4 {
  background-color: var(--white);
  border: 1px solid transparent;
  padding: 32px 35px;
} 

#fb-3-1, #fb-3-3,
#fb-4-1, #fb-4-3 {
  margin-bottom: 30px;
}

.fbox-3 h6,
.fbox-4 h6 {
  margin-top: 20px;
  margin-bottom: 12px;
}

.fbox-3 p,
.fbox-4 p {
  margin-bottom: 0;
} 

/*------------------------------------------*/
/*  FBOX TYPOGRAPHY
/*------------------------------------------*/ 

.fbox-2 h5,
.fbox-wrapper .row-cols-md-2 h6,
.fbox-wrapper .row-cols-md-3 h6 {
  margin-bottom: 12px;
}

.fbox-6 h6,
.fbox-6 h5,
.fbox-12 h5 {
  margin-bottom: 14px;
}

.fbox-10 h5 {
  margin-bottom: 16px;
}

.fbox-9 h6 { 
  margin-bottom: 16px; 
}

.fbox-10.fb-5 h5, 
.fbox-10.fb-6 h5 { 
  margin-bottom: 24px; 
}

.ico-title .fbox-10.fb-5 h5,
.ico-title .fbox-10.fb-6 h5 { 
  margin-bottom: 20px;
}  

.fbox-11 h6 {
  line-height: 1.35;
  margin-bottom: 20px;
}

.fbox-6 p {
  color: var(--gray);
  margin-bottom: 0;
}

.fbox-9 p {
  margin-bottom: 0;
}

.fbox-5 p,
.fbox-12 p,
.fbox-wrapper p,
.fbox-10 p.mb-0 {
  margin-bottom: 0;
}

.fbox-10 p {
  margin-bottom: 14px;
}

.fbox-5 .txt-block-tra-link {
  margin-top: 15px;
}




/* ==========================================================================
  08. CONTENT
  ========================================================================== */

.bc-03 .section-title p {
  padding: 0 5%;
}

/*------------------------------------------*/
/*  CONTENT BOX WRAPPER
/*------------------------------------------*/

.bc-01-wrapper,
.bc-02-wrapper {
  border: 1px solid transparent;
}

.ct-06 .section-overlay {
  margin-bottom: 100px;
}

.bc-01 .section-overlay {
  padding: 80px 65px;
}

.bc-02 .section-overlay {
  padding: 65px 60px;
}

.bc-02 .ico-title .section-overlay {
  padding: 45px 50px;
}

.bc-02.pt-30 .section-overlay,
.bc-02.pb-30 .section-overlay { 
  padding: 50px; 
}

.bc-02.pt-30.bc-02-top { 
  margin-top: 70px;
}

.bc-03 .section-overlay {
  padding: 80px 70px 0;
}

/*------------------------------------------*/
/*  TEXT BLOCK
/*------------------------------------------*/ 

.bc-01 .txt-block.left-column {
  padding-right: 20px;
}

.bc-01 .txt-block.right-column {
  padding-left: 20px;
}

.ct-02-top {
  margin-bottom: 50px;
}

.txt-box { 
  margin-bottom: 25px;
}

.two-txt .txt-box { 
  margin-bottom: 20px;
}

.bc-01 .txt-box {
  margin-bottom: 30px;
}

.txt-box:last-child,
.bc-01 .txt-box:last-child { 
  margin-bottom: 0; 
}

.bc-04-box {
  text-align: center;
  padding: 40px 35px;
  border: 1px solid transparent;
}

#bc-4-3 {
  margin-top: 30px;
}

.two-txt .txt-block.left-column {
  padding-right: 8%;
}

.ct-07-txt {
  padding-left: 15px;
}

/*------------------------------------------*/
/*  TEXT BLOCK TYPOGRAPHY
/*------------------------------------------*/ 

.txt-block h3,
.txt-block h2 {
  margin-bottom: 25px;
}

.txt-block h5 {
  margin-bottom: 20px;
}

h5.h5-title {
  margin-top: 20px;
  margin-bottom: 20px;
}

.txt-block-tra-link {
  margin-top: 20px;
}

.txt-block .btn {
  margin-top: 30px;
}

.txt-block .btn.btn--transparent {
  margin-top: 0;
}

.bc-04-box h2 {
  font-size: 3.35rem;
  line-height: 1;
  letter-spacing: -2px;
  margin-bottom: 18px;
}

.bc-04-box h2 small {
  font-size: 2.75rem;
  margin-left: 3px;
}

.bc-04-box h2 span {
  font-size: 2.85rem;
  margin-right: 5px;
}

/*------------------------------------------*/
/*  CONTENT BOX
/*------------------------------------------*/

.cbox-1,
.cbox-2,
.cbox-3 {
  display: flex;
  position: relative;
  flex-flow: row wrap;
  align-items: stretch!important;
  justify-content: flex-start;
}

.cbox-4 {
  display: inline-block;
  width: 400px;
  min-width: 400px;
  padding: 18px 26px;
  -webkit-box-shadow: 0px 15px 20px 0px var(--tra-coal);
  -moz-box-shadow: 0px 15px 20px 0px var(--tra-coal);
  box-shadow: 0px 15px 20px 0px var(--tra-coal);
}

/*------------------------------------------*/
/*  CONTENT BOX ICON
/*------------------------------------------*/

.cbox-1 .ico-wrap {
  position: relative;
  margin-right: 1.625rem;
}

.cbox-1-ico {
  text-align: center;
  position: relative;
  width: 2.25rem;
  height: 2.25rem;
  font-size: 1.125rem;
  line-height: 2rem;
  font-weight: 600;
  border: 3px solid transparent;
  border-radius: 100%;
  -webkit-transition: all 450ms ease-in-out;
  -moz-transition: all 450ms ease-in-out;
  -o-transition: all 450ms ease-in-out;
  -ms-transition: all 450ms ease-in-out;
  transition: all 450ms ease-in-out; 
}

.cbox-1-line {
  position: absolute;
  bottom: 14px;
  left: 50%;
  width: 2px;
  background-color: var(--smoke);
  height: calc(100% - 70px);
  -webkit-transform: translateX(-50%);
  -ms-transform: translateX(-50%);
  transform: translateX(-50%);
}

.cbox-1:hover .cbox-1-ico {
  background-color: transparent;
}

.cbox-1:hover .cbox-1-ico.bg--black { color: var(--black); border-color: var(--black); }
.cbox-1:hover .cbox-1-ico.bg--coal { color: var(--coal); border-color: var(--coal); }
.cbox-1:hover .cbox-1-ico.bg--blue { color: var(--blue); border-color: var(--blue); }
.cbox-1:hover .cbox-1-ico.bg--green { color: var(--green); border-color: var(--green); }
.cbox-1:hover .cbox-1-ico.bg--indigo { color: var(--indigo); border-color: var(--indigo); }
.cbox-1:hover .cbox-1-ico.bg--pink { color: var(--pink); border-color: var(--pink); }
.cbox-1:hover .cbox-1-ico.bg--purple { color: var(--purple); border-color: var(--purple); }
.cbox-1:hover .cbox-1-ico.bg--skyblue { color: var(--sky-blue); border-color: var(--sky-blue); }

.cbox-2 .ico-wrap { 
  margin-left: 8px; 
  margin-right: 4px;
}

.cbox-2.ico-10 span {
  position: relative;
  top: 0;
  right: 7px;
}

.cbox-2.ico-15 span {
  position: relative;
  top: 1px;
  right: 8px;
}

.cbox-2.ico-10 [class*="flaticon-"]:before,
.cbox-2.ico-10 [class*="flaticon-"]:after {  
  font-size: 0.6rem;
}

.cbox-2.ico-15 [class*="flaticon-"]:before,
.cbox-2.ico-15 [class*="flaticon-"]:after {   
  font-size: 0.75rem;
}

.cbox-4-ico {   
  display: inline-block!important;  
  float: left;
  text-align: center;
}

.cbox-5-wrapper {
  margin-top: 18px;
}

.box-title {   
  margin-bottom: 14px;
}

.box-title span {   
  position: relative;
  top: 10px;
  right: 4px;
}

.box-title h6 {  
  display: inline-block;
  margin-bottom: 0;
}

/*------------------------------------------*/
/*  CBOX #3
/*------------------------------------------*/

.cbox-3 .ico-wrap {
  position: relative;
  text-align: center;
  margin-top: 0;
  margin-right: 1.25rem;
}

.cbox-3 .cbox-3-ico {
  position: relative;
  top: 5px;
}

.cbox-3-ico span[class*="flaticon-"]:before,
.cbox-3-ico span[class*="flaticon-"]:after {  
  font-size: 2.95rem;
}

/*------------------------------------------*/
/*  CONTENT BOX TEXT
/*------------------------------------------*/

.cbox-1-txt,
.cbox-2-txt,
.cbox-3-txt {
  overflow: hidden;
  flex: 1;
  max-width: 100%;
}

.cbox-1-txt {
  margin-bottom: 30px;
}

.cbox-3-txt {
  margin-bottom: 35px;
}

.two-txt .cbox-3-txt {
  margin-bottom: 25px;
}

.cbox-1:last-child .cbox-1-txt,
.cbox-3:last-child .cbox-3-txt {
  margin-bottom: 0;
}

.cbox-4-txt {
  display: inline-block!important;
  padding-left: 18px;
}

.cbox-6-txt {
  margin-bottom: 35px;
}

.cbox-6:last-child .cbox-6-txt {
  margin-bottom: 0;
}

/*------------------------------------------*/
/*  CONTENT BOX TITLE
/*------------------------------------------*/ 

.cbox-title {
  margin-bottom: 18px;
}

.cbox-title span {
  float: left;
  line-height: 1!important;
  margin-right: 20px; 
}

.cbox-title span[class*="flaticon-"]:before,
.cbox-title span[class*="flaticon-"]:after {   
  font-size: 2.35rem;
}

.cbox-title h5 {  
  line-height: 2.1875rem!important;
  margin-bottom: 0;
}

/*------------------------------------------*/
/*  CONTENT BOX TYPOGRAPHY
/*------------------------------------------*/

.cbox-1-txt h5,
.cbox-3-txt h5 {
  margin-bottom: 15px;
}

.cbox-5 p,
.cbox-4-txt h6,
.cbox-1-txt p,
.cbox-3-txt p,
.cbox-4-txt p,
.cbox-6-txt p { 
  margin-bottom: 0;
}

.cbox-2-txt p { 
  margin-bottom: 8px;
}

.cbox-2-last .cbox-1-txt p { 
  margin-bottom: 1rem;
}

/*------------------------------------------*/
/*  ACCORDION  
/*------------------------------------------*/

.txt-block .accordion-1 .accordion-item {
  background-color: transparent;
  padding: 22px 0 26px;
  border: none;
  border-bottom: 1.5px solid var(--smoke);
  border-radius: 0 0;
}

.txt-block .accordion-thumb {
  margin: 0;
  padding: 0;
  cursor: pointer;
  position: relative;
}

.txt-block .accordion-1 .accordion-item .accordion-thumb:after,
.txt-block .accordion-1 .accordion-item.is-active .accordion-thumb:after {
  font-family: Flaticon;
  position: absolute;
  color: var(--coal);
  font-size: 0.95rem;
  font-weight: 400; 
  top: 1px;
  right: 5px;
}

.txt-block .accordion-1 .accordion-item .accordion-thumb:after {
  content: "\f1c3"; 
}

.txt-block .accordion-1 .accordion-item.is-active .accordion-thumb:after {
  content: "\f1c2";
}

.txt-block .accordion-1 .accordion-panel {
  color: var(--gray);
  margin: 0;
  padding: 0 0 0 5px;
  display: none;
}

.txt-block .accordion-1 .accordion-thumb h5 {
  color: var(--coal);
  line-height: 1;
  padding-left: 5px;
  margin-bottom: 0;
}

.txt-block .accordion-1 .accordion-item.is-active .accordion-thumb h5 {
  margin-bottom: 24px;
}

/*------------------------------------------*/
/*  IMG BLOCK IMAGE
/*------------------------------------------*/ 

.img-block {
  text-align: center;
}

.img-block-hidden,
.ct-06 .img-block {
  margin-bottom: -100px;
}

.img-block-txt,
.img-block-btn {
  text-align: center;
  margin-top: 50px;
}

.img-block-hidden .video-btn {
  top: calc(50% - 30px);
}

.bc-3-img .video-btn {
  top: calc(50% - 70px);
}

.ct-01 .img-block.right-column,
.ct-02 .img-block.right-column,
.ct-03 .img-block.right-column {
  margin-left: 30px;
}

.ct-01 .img-block.left-column,
.ct-02 .img-block.left-column,
.ct-03 .img-block.left-column {
  margin-right: 30px;
}

.ct-04 .img-block img {
  max-width: none;
  display: inline-block;
  margin-left: 30px;
}

.ct-07-images {
  margin-bottom: 40px;
}

.bc-2-img.right-column {
  margin-left: 20px;
}

.bc-2-img.left-column {
  margin-right: 20px;
}

.bc-3-img {
  margin-bottom: -200px;
}

.bc-4-img {
  width: 100%;
  height: 505px;
  background-size: cover;
  background-position: center center; 
  background-repeat: no-repeat;
  /* background-image: url(~images/home/bc-4-img.jpg); */
}




/* ==========================================================================
  09. STATISTIC
  =========================================================================== */

.statistic-7-wrapper {
  padding: 50px 20px 50px 60px;
}

.statistic-8-wrapper {
  text-align: center;
  padding: 0 15px;
}

/*------------------------------------------*/
/*  STATISTIC BLOCK
/*------------------------------------------*/

.statistic-1-wrapper .statistic-block-digit {   
  width: 36%;
  display: inline-block!important;  
  float: left;
}

.statistic-1-wrapper .statistic-block-txt {
  width: 64%;
  display: inline-block!important;
  padding-left: 16px;
}

#sb-3-1,
#sb-3-2 {
  width: 48%;
  display: inline-block;
  float: left;
}

.statistic-4-wrapper .statistic-block {
  padding: 10px 40px;
}

#sb-4-1, 
#sb-4-2 {
  border-right: 1px solid var(--tra-smoke);
}

#statistic-6 .statistic-block-txt {
  display: inline-block;
  padding: 0 5%;
}

#statistic-6 .statistic-block-txt:before {
  display: block;
  content: "";
  height: 2px;
  width: 90px;
  background: var(--black);
  margin: 25px auto 20px;
  position: relative;
}

.statistic-8-wrapper .statistic-block {
  padding: 10px 30px 10px 0;
}

#sb-8-1, 
#sb-8-2 {
  border-right: 1px solid var(--silver);
}

/*------------------------------------------*/
/*  STATISTIC BLOCK ICON
/*------------------------------------------*/

.statistic-ico {
  margin-bottom: 15px;
}

.statistic-block .txt-block-rating {
  margin-bottom: -3px;
}

/*------------------------------------------*/
/*  STATISTIC BLOCK NUMBER
/*------------------------------------------*/

h3.statistic-number,
h2.statistic-number {
  line-height: 1; 
  font-weight: 700;
  letter-spacing: -1px;
  margin-bottom: 0;
}

#sb-3-1 h2.statistic-number,
#sb-3-2 h2.statistic-number {
  margin-bottom: 15px;
}

/*------------------------------------------*/
/*  STATISTIC BLOCK TYPOGRAPHY
/*------------------------------------------*/

.statistic-2-wrapper h2 {
  margin-bottom: 15px;
}

#statistic-6 h2.statistic-number {
  display: inline-block;
  font-size: 4rem;
  letter-spacing: -2px;
}

#statistic-6 h2.statistic-number small {
  font-size: 2.5rem;
  font-weight: 700;
  margin-left: 5px;
}

.statistic-9-wrapper h3,
.statistic-9-wrapper h2 {
  margin-top: 30px;
  margin-bottom: 15px;
}

.statistic-4-wrapper h5 {
  margin-top: 25px;
  margin-bottom: 15px;
}

.statistic-7-wrapper h5 {
  line-height: 1.4;
}

.statistic-1-wrapper p {
  position: relative;
  line-height: 1.35;
  top: 4px;
  margin-bottom: 0;
}

.txt-block-stat p,
.statistic-block-txt p,
.statistic-2-wrapper p,
.statistic-4-wrapper p,
.statistic-9-wrapper p,
.statistic-6-wrapper .statistic-block p {
  margin-bottom: 0;
}

.statistic-5-wrapper p {
  margin-top: 12px;
  margin-bottom: 0;
}

.statistic-7-wrapper .statistic-block p {
  margin-top: 7px;
  margin-bottom: 0;
}

.statistic-8-wrapper p {
  margin-top: 20px;
  margin-bottom: 0;
}

.statistic-8-wrapper img {
  width: auto;
  max-width: inherit;
  max-height: 55px;
  margin-top: 20px;
}

.statistic-section .action-btn {
  text-align: center;
  margin-top: 60px;
}




/* ==========================================================================
  10. PROJECTS
  ========================================================================== */

/*------------------------------------------*/
/*  FILTER BUTTONS
/*------------------------------------------*/ 

.portfolio-section .filter-btns button {
  font-size: 1.0625rem;
  margin: 0 25px;
}

/*------------------------------------------*/
/*  FILTER ITEM
/*------------------------------------------*/ 

#portfolio-1 .filter-item {
  position: relative;
  width: 50%;
  padding: 0 10px;
  margin-bottom: 30px;
}

.filter-item .hover-overlay,
.filter-item .hover-overlay img {
  overflow: hidden;
}

/*------------------------------------------*/
/*  PROJECT CATEGIRY
/*------------------------------------------*/

.project-category {
  position: absolute;
  opacity: 0;
  top: -15%;
  left: 35px;
  margin-bottom: 0;
  -webkit-transition: all 400ms ease-in-out;
  -moz-transition: all 400ms ease-in-out;
  -o-transition: all 400ms ease-in-out;
  -ms-transition: all 400ms ease-in-out;
  transition: all 400ms ease-in-out;
}

.project-category p {
  padding: 8px 25px;
  background-color: rgba(255, 255, 255, .65);
  color: var(--black);
  font-weight: 500;
  line-height: 1;
  margin-bottom: 0;
  -webkit-border-radius: 100px;
  -moz-border-radius: 100px;
  -o-border-radius: 100px;
  border-radius: 100px;
}

/*------------------------------------------*/
/*  PROJECT DATA
/*------------------------------------------*/

.project-description {
  width: 100%;
  position: absolute;
  bottom: 0;
  left: 0;
  opacity: 0;
  z-index: 70;
  background: -webkit-linear-gradient(top, rgba(25, 25, 25, 0), rgba(25, 25, 25, .6));
  background: linear-gradient(top, rgba(25, 25, 25, 0), rgba(25, 25, 25, .6));
  padding: 130px 65px 32px 25px;
  -webkit-transition: all 450ms ease-in-out;
  -moz-transition: all 450ms ease-in-out;
  -o-transition: all 450ms ease-in-out;
  -ms-transition: all 450ms ease-in-out;
  transition: all 450ms ease-in-out;
}

.project-data-txt {
  position: absolute;
  opacity: 0;
  bottom: 45px;
  left: -15%;
  -webkit-transition: all 450ms ease-in-out;
  -moz-transition: all 450ms ease-in-out;
  -o-transition: all 450ms ease-in-out;
  -ms-transition: all 450ms ease-in-out;
  transition: all 450ms ease-in-out;
}

.project-data-txt h5 {
  line-height: 1.4;
  margin-bottom: 8px;
}

/*------------------------------------------*/
/*  PROJECT LINK
/*------------------------------------------*/

.project-source {
  text-align: center;
  position: absolute;
  bottom: 30px;
  right: -15%;
  width: 45px;
  height: 45px;
  background-color: transparent;
  border: 3px solid var(--white);
  -webkit-border-radius: 100%;
  -moz-border-radius: 100%;
  border-radius: 100%; 
  -webkit-transition: all 450ms ease-in-out;
  -moz-transition: all 450ms ease-in-out;
  -o-transition: all 450ms ease-in-out;
  -ms-transition: all 450ms ease-in-out;
  transition: all 450ms ease-in-out;
}

.project-source a span {
  position: relative;
  top: 5px;
  -webkit-transition: all 450ms ease-in-out;
  -moz-transition: all 450ms ease-in-out;
  -o-transition: all 450ms ease-in-out;
  -ms-transition: all 450ms ease-in-out;
  transition: all 450ms ease-in-out;
}

.project-source:hover {
  background-color: rgba(255, 255, 255, .65);
  border-color: transparent;
}

.project-source:hover span {
  color: var(--coal);
}

/*------------------------------------------*/
/*  PROJECT RATING
/*------------------------------------------*/

.project-rating [class*="flaticon-"]:before, 
.project-rating [class*="flaticon-"]:after { 
  color: var(--yellow);
  font-size: 0.925rem; 
  line-height: 1;
  margin-right: 1px;  
} 

.project-rating span {
  position: relative;
  font-size: 0.9rem;
  line-height: 1;
  font-weight: 500;
  top: 0;
  left: 2px;
}

/*------------------------------------------*/
/*  Overlay Background 
/*------------------------------------------*/ 

.portfolio-section .item-overlay {
  background: rgba(20, 20, 20, .15);
}

/*------------------------------------------*/
/*  Image Hover Effect 
/*------------------------------------------*/ 

#portfolio-1 .filter-item:hover img {
  transform: scale(1.1);
  -ms-transform: scale(1.1);
  -webkit-transform: scale(1.1);
  -o-transform: scale(1.1);
  -moz-transform: scale(1.1);
}

.filter-item:hover .item-overlay,
.filter-item:hover .project-source,
.filter-item:hover .project-category,
.filter-item:hover .project-data-txt, 
.filter-item:hover .project-description {
  opacity: 1; 
  -moz-opacity: 1; 
}

.filter-item:hover .project-category {
  top: 25px;
}

.filter-item:hover .project-data-txt {
  left: 40px;
}

.filter-item:hover .project-source {
  right: 30px;
}




/* ==========================================================================
  11. PROJECT DETAILS
  ========================================================================== */

.project-title {
  text-align: center;
  padding: 0 5%;
  margin-bottom: 60px;
}

.project-category {
  margin-bottom: 35px;
}

.project-category span {
  font-size: 1.05rem;
  font-weight: 400;
  background-color: rgba(102, 51, 255, .08);
  padding: 10px 36px;
  -webkit-border-radius: 100px;
  -moz-border-radius: 100px;
  -o-border-radius: 100px;
  border-radius: 100px;
}

.project-title p {
  margin-top: 25px;
  margin-bottom: 0;
}

/*------------------------------------------*/
/*  PROJECT DATA
/*------------------------------------------*/

.project-data {
  padding-bottom: 50px;
  margin: 60px 0 50px;
}

.project-data p {
  margin-bottom: 4px;
}

.project-data p span {
  color: var(--coal);
  font-weight: 600;
  margin-right: 5px;
}

/*------------------------------------------*/
/*  PROJECT DESCRIPTION
/*------------------------------------------*/

.project-txt h5,
.project-txt h6 {
  margin-top: 35px;
  margin-bottom: 20px;
}

.project-inner-img {
  margin: 50px 0;
}

.project-inner-img.d-flex { 
  margin-left: -15px; 
  margin-right: -15px; 
}

.project-preview {
  position: relative;
  overflow: hidden;
}

/*------------------------------------------*/
/*  Image Link
/*------------------------------------------*/

.project-link {
  width: 100%;
  position: absolute;
  top: 55%;
  transform: translateY(-50%);
  opacity: 0;
  text-align: center;
  color: #fff;
  -webkit-transition: all 400ms ease-in-out;
  -moz-transition: all 400ms ease-in-out;
  -o-transition: all 400ms ease-in-out;
  -ms-transition: all 400ms ease-in-out;
  transition: all 400ms ease-in-out;
}

/*------------------------------------------*/
/*  Image Hover Effect 
/*------------------------------------------*/ 

.project-preview:hover img {
  transform: scale(1.05);
  -ms-transform: scale(1.05);
  -webkit-transform: scale(1.05);
  -o-transform: scale(1.05);
  -moz-transform: scale(1.05);
}

.project-preview:hover .project-link {
  top: 50%;
  opacity: 1; 
  -moz-opacity: 1; 
}

.project-preview:hover .item-overlay {
  opacity: 1; 
  -moz-opacity: 1; 
}

/*------------------------------------------*/
/*  MORE PROJECTS BUTTON
/*------------------------------------------*/

#project-details .more-projects {
  text-align: right;
  margin-top: 65px;
}

#project-details .more-projects h3 {
  display: inline-block;
  text-decoration: underline;
  margin-bottom: 0;
}

#project-details .more-projects span {
  position: relative;
  top: 3px;
  left: 5px;
}




/* ==========================================================================
  12. TEAM
  =========================================================================== */

/*------------------------------------------*/
/*  TEAM MEMBER PHOTO
/*------------------------------------------*/

.team-member-photo {
  position: relative;
  overflow: hidden;
  text-align: center;
  margin-bottom: 25px;
}

/*------------------------------------------*/
/*  PHOTO HOVER ZOOM
/*------------------------------------------*/ 

.team-member:hover img {
  transform: scale(1.05);
  -ms-transform: scale(1.05);
  -webkit-transform: scale(1.05);
  -o-transform: scale(1.05);
  -moz-transform: scale(1.05);
}

.team-member:hover .item-overlay {
  opacity: .45; 
  -moz-opacity: .45; 
}

/*------------------------------------------*/
/*  TEAM MEMBER TYPOGRAPHY
/*------------------------------------------*/

.team-member-data {
  text-align: center;
}

.team-member-data h6 {
  margin-bottom: 8px;
}

.team-member-data p {
  font-family: 'Plus Jakarta Sans', sans-serif;
  line-height: 1;
  font-weight: 400;
  letter-spacing: 0;
  margin-bottom: 0;
}




/* ==========================================================================
  13. CAREERS
  =========================================================================== */

.career-item {
  padding: 30px 0;
}

/*------------------------------------------*/
/*  OPEN ROLE DESCRIPTION 
/*------------------------------------------*/

.position-data {
  margin-top: 3px;
}

.position-data li {
  width: auto!important;
  display: inline-block!important;
  vertical-align: top;
  clear: none !important;
}

.rb-divider {
  position: relative;
  top: 1.25px;
  transform: rotate(-90deg);
  margin-left: 2px;
}

.position-data.ico-15 [class*="flaticon-"]:before, 
.position-data.ico-15 [class*="flaticon-"]:after { font-size: 0.8rem; }

.position-data li p {
  display: inline-block;
  float: left;
  margin-bottom: 0;
}

/*------------------------------------------*/
/*  POSITION WORKPLACE
/*------------------------------------------*/

.position-workplace {
  text-align: center;
  margin-top: 5px;
}

/*------------------------------------------*/
/*  POSITION APPLY
/*------------------------------------------*/

.posting-apply a.btn {
  font-size: 0.85rem;
  padding: 0.6rem 1rem;
}

/*------------------------------------------*/
/*  MORE BUTTON
/*------------------------------------------*/

.careers-section .more-btn {
  text-align: center;
  margin-top: 30px;
}




/* ==========================================================================
  14. JOB POSITION
  =========================================================================== */

#job-position .inner-page-title {
  text-align: left;
  padding-bottom: 60px;
  margin-bottom: 50px;
  border-bottom: 1px solid var(--silver);
}

#job-position .inner-page-title p {
  margin-top: 30px;
}

#job-position .inner-page-title p span {
  color: var(--black);
  font-weight: 600;
}

#job-position .inner-page-title .btn {
  padding: 0.7rem 1.25rem;
  margin-top: 8px;
}

/*------------------------------------------*/
/*  JOB POSITION TYPOGRAPHY
/*------------------------------------------*/

.position-info h5 {
  margin-top: 60px;
  margin-bottom: 30px;
}

.position-info p span {
  color: var(--black);
  font-weight: 600;
}

.position-info .btn {
  margin-top: 35px;
}




/* ==========================================================================
  15. PRICING
  =========================================================================== */

/*------------------------------------------*/
/*  Price Table
/*------------------------------------------*/

.p-table {
  margin-bottom: 40px;
}

.pricing-1-table { 
  padding: 50px 35px 45px; 
  border: 1.5px solid transparent;
}

.text-center .pricing-1-table {
  padding: 60px 35px 50px; 
}

/*------------------------------------------*/
/*  TABLE HEADER 
/*------------------------------------------*/

.pricing-table-header {
  position: relative;
}

.pricing-table-header h5 {
  margin-bottom: 30px;
}

/*------------------------------------------*/
/*  PRICING TABLE DISCOUNT
/*------------------------------------------*/

.pricing-discount {
  position: absolute;
  top: -2px;
  right: -10px;
  padding: 8px 18px;
}

.pricing-1-table .pricing-discount {
  top: -2px;
  right: -5px;
  padding: 7px 13px;
}

.text-center .pricing-1-table .pricing-discount {
  top: -35px;
  right: -8px;
}

.pricing-discount h6 {
  font-size: 1.1rem;
  line-height: 1;
  margin-bottom: 0;
}

.pricing-1-table .pricing-discount h6 {
  font-size: 0.915rem;
}

.price.color--black .white--color {
  color: var(--white);
}

/*------------------------------------------*/
/*  PRICE
/*------------------------------------------*/

.price span {
  font-family: 'Plus Jakarta Sans', sans-serif;
  font-size: 3rem;
  line-height: 1;
  font-weight: 600;
  letter-spacing: -2px;
} 

/*------------------------------------------*/
/*  DOLLAR SIGN
/*------------------------------------------*/

.price sup {
  font-family: 'Plus Jakarta Sans', sans-serif;
  font-size: 2.05rem;
  font-weight: 600;
  top: -5px;
  right: 2px;
  letter-spacing: -1px;
}

/*------------------------------------------*/
/*  Validity
/*------------------------------------------*/

.price sup.validity {
  font-family: 'Plus Jakarta Sans', sans-serif;
  font-size: 1.4rem;
  font-weight: 400;
  top: -2px;
  left: 0;
}

/*------------------------------------------*/
/*  Text
/*------------------------------------------*/

.price p {
  margin-top: 25px;
  margin-bottom: 25px;
}

.text-center .price p {
  padding: 0 2%;
}

.pricing-section .btn-txt a {
  text-decoration: underline;
}

/*------------------------------------------*/
/*  PRICING TABLE HORIZONTAL LINE
/*------------------------------------------*/

.pricing-1-table hr {
  margin-top: 35px;
}

/*------------------------------------------*/
/*  PRICING FEATURES
/*------------------------------------------*/

.pricing-features li {
  padding: 11px 8px;
}

.pricing-features li p {
  line-height: 1;
  font-weight: 400;
  margin-bottom: 0;
}

.pricing-features li p span {
  position: relative;
  top: -2px;
  right: 5px;
}

.pricing-features.ico-10 li p span {
  top: 0;
  right: 4px;
}

.pricing-features.ico--blue span { color: var(--blue); }
.pricing-features.ico--green span { color: var(--green); }
.pricing-features.ico--indigo span { color: var(--indigo); }
.pricing-features.ico--ocean span { color: var(--ocean); }
.pricing-features.ico--pink span { color: var(--pink); }
.pricing-features.ico--purple span { color: var(--purple); }
.pricing-features.ico--sky-blue span { color: var(--sky-blue); }

.pricing-features.ico-5 [class*="flaticon-"]:before, 
.pricing-features.ico-5 [class*="flaticon-"]:after { font-size: 0.5rem; }

.pricing-features li.disabled-option p,
.pricing-features li.disabled-option span {
  color: var(--disable)!important;
}

/*------------------------------------------*/
/*  PRICING TABLE BUTTON
/*------------------------------------------*/

.pt-btn {
  display: block;
  width: 100%;
  margin-top: 25px;
}

.p-table p.btn-txt {
  margin: 14px 0 0 0;
}

/*------------------------------------------*/
/*  PRICING TOGGLE BUTTON
/*------------------------------------------*/

.ext-toggle-btn {
  text-align: center;
}

.toggle-btn.inn-toggle-btn {
  position: absolute;
}

/*------------------------------------------*/
/*  TOGGLE BUTTON TYPOGRAPHY
/*------------------------------------------*/

.toggle-btn-md .toggler-txt {
  font-size: 1.1rem;
  font-weight: 400;
  line-height: 36px;
}

.toggle-btn-lg .toggler-txt {
  font-size: 1.15rem;
  font-weight: 400;
  line-height: 40px;
}

.toggle-btn p {
  margin-top: 10px;
  letter-spacing: -0.25px;
  margin-bottom: 0;
}

/*------------------------------------------*/
/*  TOGGLE BUTTON SWITCHER
/*------------------------------------------*/

.switch-wrap {
  position: relative;
  display: inline-block;
  min-width: 50px;
  height: 32px;
  vertical-align: middle;
}

.toggle-btn-md .switch-wrap {
  min-width: 65px;
  height: 36px;
  margin: 0 8px;
}

.toggle-btn-lg .switch-wrap {
  min-width: 70px;
  height: 40px;
  margin: 0 14px;
}

.switcher {
  display: block;
  cursor: pointer;
  color: var(--white);
  font-size: 0.925rem;
  font-weight: 500;
  height: 32px;
  padding: 0 15px 0 35px;
  position: relative;
  border-radius: 32px;
  -webkit-transition: all 150ms ease-in-out;
  -moz-transition: all 150ms ease-in-out;
  -o-transition: all 150ms ease-in-out;
  -ms-transition: all 150ms ease-in-out;
  transition: all 150ms ease-in-out;
}

.toggle-btn-md .switcher {
  height: 36px;
  padding: 0 20px 0 40px;
  border-radius: 36px;
}

.toggle-btn-lg .switcher {
  height: 40px;
  padding: 0 20px 0 40px;
  border-radius: 40px;
}

.switcher.bg--tra-white {
  background-color: var(--white);
  border: 2px solid var(--silver);
}

.switcher.bg--ghost-white {
  background-color: rgba(128, 143, 174, .45);
  border: 2px solid rgba(128, 143, 174, .15);
}

.switcher.bg--gray {
  background-color: var(--silver);
  border: 2px solid var(--silver);
}

.switcher .show-annual, 
.switcher .show-monthly {
  line-height: 32px;
}

.toggle-btn-md .switcher .show-annual,
.toggle-btn-md .switcher .show-monthly {
  line-height: 36px;
}

.toggle-btn-lg .switcher .show-annual,
.toggle-btn-lg .switcher .show-monthly {
  line-height: 40px;
}

.switcher .show-monthly {
  display: block;
}

.switcher .show-annual {
  display: none;
}

.switcher:before {
  position: absolute;
  left: 4px;
  bottom: 4px;
  content: " ";
  background-color: var(--white);
  width: 24px;
  height: 24px;
  border-radius: 50%;
  -webkit-transition: all 450ms ease-in-out;
  -moz-transition: all 450ms ease-in-out;
  -o-transition: all 450ms ease-in-out;
  -ms-transition: all 450ms ease-in-out;
  transition: all 450ms ease-in-out;
}

.toggle-btn-md .switcher:before {
  left: 5px;
  bottom: 5px;
  width: 26px;
  height: 26px;
}

.toggle-btn-lg .switcher:before {
  left: 5px;
  bottom: 5px;
  width: 30px;
  height: 30px;
}

.switcher.bg--gray:before,
.switcher.bg--tra-white:before,
.switcher.bg--ghost-white:before {
  background-color: var(--silver);
  left: 4px;
  bottom: 3.5px;
  -webkit-box-shadow: 0 1px 1px 0 var(--disable);
  -moz-box-shadow: 0 1px 1px 0 var(--disable);
  box-shadow: 0 1px 1px 0 var(--disable);
}

.switcher.bg--gray:before,
.switcher.bg--ghost-white:before {
  background-color: var(--white);
}

.switch-wrap input {
  display: none;
}

.switch-wrap input:checked + .switcher {
  padding: 0 35px 0 15px;
}

.toggle-btn-md .switch-wrap input:checked + .switcher,
.toggle-btn-lg .switch-wrap input:checked + .switcher {
  padding: 0 40px 0 20px;
}

.switch-wrap input:checked + .switcher.switcher--blue { background-color: var(--blue); border-color: var(--blue); }
.switch-wrap input:checked + .switcher.switcher--green { background-color: var(--green); border-color: var(--green); }
.switch-wrap input:checked + .switcher.switcher--indigo { background-color: var(--indigo); border-color: var(--indigo); }
.switch-wrap input:checked + .switcher.switcher--ocean { background-color: var(--ocean); border-color: var(--ocean); }
.switch-wrap input:checked + .switcher.switcher--pink { background-color: var(--pink); border-color: var(--pink); }
.switch-wrap input:checked + .switcher.switcher--purple { background-color: var(--purple); border-color: var(--purple); }
.switch-wrap input:checked + .switcher.switcher-sky-blue { background-color: var(--sky-blue); border-color: var(--sky-blue); }

.switch-wrap input:checked + .switcher:before {
  left: auto;
  right: 4px;
  -webkit-transition: all 350ms ease-in-out;
  -moz-transition: all 350ms ease-in-out;
  -o-transition: all 350ms ease-in-out;
  -ms-transition: all 350ms ease-in-out;
  transition: all 350ms ease-in-out;
}

.switch-wrap input:checked + .switcher.bg--tra-white:before {
  bottom: 3px;
  background-color: var(--white);
  -webkit-box-shadow: 0 0;
  -moz-box-shadow: 0 0;
  box-shadow: 0 0;
}

.switch-wrap input:checked + .switcher .show-annual {
  display: block;
}

.switch-wrap input:checked + .switcher .show-monthly {
  display: none;
}

/*------------------------------------------*/
/*  PRICING NOTICE TEXT
/*------------------------------------------*/

.pricing-notice {
  text-align: center;
  margin-top: 20px;
}

.pricing-notice p {
  padding: 0 8%;
  margin-bottom: 0;
}

.pricing-notice p span {
  font-weight: 500;
}

/*------------------------------------------*/
/*  COMPARE PRICING TABLE
/*------------------------------------------*/

.table>:not(caption)>*>* {
  padding: 17px 0;
  border: none!important;
  background-color: transparent!important;
}

.comp-table .pricing-header {
  max-width: 700px;
}

.comp-table .table thead tr,
.comp-table .table tbody tr {
  border-bottom: 1px solid var(--silver)!important;
}

.comp-table .table tbody tr.table-last-tr {
  border-bottom: none!important;
}

.comp-table .table-responsive thead th {
  font-size: 1.0625rem;
  font-family: 'Plus Jakarta Sans', sans-serif;
  font-weight: 700;
}

.comp-table .table-responsive tbody th {
  font-size: 0.95rem;
  font-family: 'Plus Jakarta Sans', sans-serif;
  font-weight: 700;
}

.comp-table .table-responsive tbody td {
  position: relative;
  font-size: 0.95rem;
  line-height: inherit;
  top: 1px;
}

.comp-table .table-responsive tbody td span {
  position: relative;
  top: 1px;
}

.table-responsive .ico-15 [class*="flaticon-"]:before, 
.table-responsive .ico-15 [class*="flaticon-"]:after { font-size: 0.7rem; }

.table-responsive .ico-20 [class*="flaticon-"]:before, 
.table-responsive .ico-20 [class*="flaticon-"]:after { font-size: 0.9rem; }

.comp-table .disabled-option {
  color: var(--light-gray);
}

/*------------------------------------------*/
/*  PRICING COMPARE PAYMENT
/*------------------------------------------*/

.comp-table-payment h6 {
  margin-bottom: 20px;
}

.comp-table-payment p {
  margin-bottom: 0;
}




/* ==========================================================================
  16. RATING
  =========================================================================== */

.rating-section .rating-1-wrapper.text-center {
  padding: 0 16%;
}

.rating-section .rating-2-wrapper.text-center {
  padding: 0 4%;
}

.rating-title {
  text-align: center;
}

.rating-title h5 {
  margin-bottom: 0;
}

/*------------------------------------------*/
/*  RATING BOX LOGO
/*------------------------------------------*/

.rbox-1-img {
  margin-bottom: 15px;
}

.rbox-1-img img {
  width: auto;
  max-width: inherit;
  max-height: 35px;
}

.rbox-2-txt img {
  position: relative;
  width: auto;
  max-width: inherit;
  max-height: 30px;
  top: -1px;
  left: 4px;
}

/*------------------------------------------*/
/*  RATING BOX TYPOGRAPHY
/*------------------------------------------*/

.rbox-2-txt {
  margin-top: 5px;
}

.rbox-1 .star-rating {
  display: inline-block;
  font-size: 1rem;
  line-height: 15px;
  font-weight: 500;
  vertical-align: inherit;
  background-color: var(--white);
  border: 1px solid transparent;
  padding: 5px 18px;
  -webkit-box-shadow: 0 4px 12px 0 rgba(0, 0, 0, .08); 
  -moz-box-shadow: 0 4px 12px 0 rgba(0, 0, 0, .08); 
  box-shadow: 0 4px 12px 0 rgba(0, 0, 0, .08); 
}

.rbox-1 .star-rating span {
  position: relative;
  color: #ffb30c;
  top: -1px;
}

.rbox-1 .star-rating.ico-15 [class*="flaticon-"]:before, 
.rbox-1 .star-rating.ico-15 [class*="flaticon-"]:after { 
  font-size: 0.9rem; 
} 

.rbox-1 .star-rating { 
  color: var(--coal);
  font-size: 0.86rem; 
  line-height: 1; 
}

p.rbox-2-txt {
  color: var(--coal);
  font-size: 0.95rem; 
  line-height: 21px;
  font-weight: 500;
  margin-bottom: 0;
}




/* ==========================================================================
  17. TESTIMONIALS
  =========================================================================== */

#reviews-3 {
  margin: 50px 0 40px;
}

.reviews-3-wrapper {
  padding: 0 70px;
}

.reviews-4-wrapper {
  overflow: hidden;
}

.reviews-4-txt.left-column {
  padding: 0 30px 0 65px;
}

/*------------------------------------------*/
/*  TESTIMONIAL
/*------------------------------------------*/

.review-1 { 
  position: relative;
  padding: 35px 35px 40px;
  margin: 0 12px 30px;                                
}

.review-2 { 
  padding: 0 30px;
  margin-bottom: 30px;
}

.review-3 {
  position: relative;
  background-color: var(--white);
  border: 1px solid transparent;
  padding: 25px 33px 30px;
}

#rw-3-1 {
  margin: -50px -20px 30px 20px;
}

#rw-3-2 {
  margin: 0 15px -40px -15px;
}

#reviews-5 .filter-item { 
  position: relative;
  width: 33.33%;
  padding: 0 12px;
  margin-bottom: 30px;                           
}

.review-5 { 
  position: relative;
  padding: 35px 30px 40px;  
  border: 1px solid transparent;                           
}

.theme--dark .review-5.bg--theme { 
  background-color: var(--theme-color)!important; 
  border-color: transparent!important;                        
}

.review-5-logo {
  margin-left: -5px;
  margin-bottom: 65px;
}

.review-5-logo img {
  width: auto;
  max-width: inherit;
  max-height: 50px;
}

/*------------------------------------------*/
/*  TESTIMONIAL TEXT
/*------------------------------------------*/

.review-1 .review-ico,
.review-5 .review-ico {   
  position: absolute;
  top: 15px;
  right: 25px;
  opacity: .12;
}

.review-2-txt h5 {
  margin-top: 20px;
  margin-bottom: 15px;
}

.review-3-txt h6.h6-md,
.review-3-txt h6.h6-lg {
  line-height: 1.4;
  margin-top: 10px;
  margin-bottom: 12px;
}

.reviews-4-txt h5 {
  line-height: 1.4;
  margin-top: 5px;
  margin-bottom: 30px;
}

.review-1 .review-rating,
.review-5 .review-rating {
  margin-bottom: 10px;
}

.review-1 .review-rating.ico-15 [class*="flaticon-"]:before, 
.review-5 .review-rating.ico-15 [class*="flaticon-"]:before, 
.review-1 .review-rating.ico-15 [class*="flaticon-"]:after,
.review-5 .review-rating.ico-15 [class*="flaticon-"]:after { font-size: 1rem; }

#reviews-3 .txt-block p {
  padding-right: 5%;
  margin-top: 20px;
  margin-bottom: 0;
}

#reviews-3 .txt-block .btn {
  margin-top: 30px;
}

/*------------------------------------------*/
/*  TESTIMONIAL DATA
/*------------------------------------------*/ 

.review-avatar {
  margin: 0 auto; 
  float: left;
}

.review-5-avatar {
  position: absolute;
  right: 32px;
  bottom: 35px;
}

.review-avatar img,
.review-5-avatar img {
  width: 50px;
  height: 50px;
  -webkit-border-radius: 100%;
  -moz-border-radius: 100%;
  -o-border-radius: 100%;
  border-radius: 100%;
}

/*------------------------------------------*/
/*  TESTIMONIAL AUTHOR
/*------------------------------------------*/ 

.review-1 .review-author,
.review-5 .review-author {
  margin-top: 10px;
}

.review-5 .review-author.mt-30 {
  margin-top: 30px;
}

.review-3 .review-author {
  position: relative;
  display: inline-block;
  text-align: left;
  padding: 0 0 0 14px;
  margin-top: 1px;
}

.reviews-4-txt .review-author {
  margin-top: 25px;
}

.review-author h6 {
  line-height: 1;
  margin-bottom: 10px;
}

.reviews-4-txt .review-author h6 {
  margin-bottom: 12px;
}

.review-author p {
  font-size: 0.9rem;
  line-height: 1;
  font-weight: 300;
  margin-bottom: 0;
}

.reviews-4-txt .review-author p {
  font-size: 1rem;
}

.review-author p a {
  font-weight: 400;
}

/*------------------------------------------*/
/*  TESTIMONIAL CAROUSEL DOTS
/*------------------------------------------*/

.reviews-section button {
  background-color: transparent;
  border: none;
  padding: 0 2px;
}

.owl-theme .owl-dots .owl-dot span {
  margin: 0 7px;
}

.owl-dots button:focus {
  outline: none;
  text-decoration: none;
}




/* ==========================================================================
  18. BRANDS
  =========================================================================== */

.brands-3-wrapper {
  padding: 0 3%;
}

.brands-4-wrapper {
  padding-left: 25px;
  margin-top: -10px;
}

#brand-3-1, 
#brand-3-2, 
#brand-3-3, 
#brand-3-4, 
#brand-3-5 { margin-bottom: 50px; }

#brand-4-1, 
#brand-4-2, 
#brand-4-3,
#brand-4-4, 
#brand-4-5, 
#brand-4-6 { margin-bottom: 35px; }

/*------------------------------------------*/
/*  BRANDS SECTION TITLE
/*------------------------------------------*/ 

.brands-title {
  text-align: center;
}

.brands-title h6 {
  margin-bottom: 50px;
}

.brands-title h5 {
  margin-bottom: 60px;
}

.brands-title p {
  margin-bottom: 40px;
}

/*------------------------------------------*/
/*  BRANDS LOGO IMAGE
/*------------------------------------------*/ 

.brand-logo {
  overflow: hidden;
  position: relative;
  top: 0;
  -webkit-transition: all 400ms ease-in-out;
  -moz-transition: all 400ms ease-in-out;
  -o-transition: all 400ms ease-in-out;
  -ms-transition: all 400ms ease-in-out;
  transition: all 400ms ease-in-out;
} 

.brands-carousel-5 .brand-logo {
  padding: 0 25px;
} 

#brands-3 .brand-logo,
#brands-4 .brand-logo,
.brands-carousel-6 .brand-logo {
  padding: 0 14px;
}

.brands-section .more-btn {
  text-align: center;
  margin-top: 35px;
}

.brands-section .owl-dots {
  display: none;
}




/* ==========================================================================
  19. INTEGRATIONS
  =========================================================================== */

.int-3-wrapper,
.int-4-wrapper {
  padding: 0 6%;
}

/*------------------------------------------*/
/*  FILTER BUTTONS
/*------------------------------------------*/ 

.filter-btns button {
  background-color: transparent; 
  font-family: 'Plus Jakarta Sans', sans-serif;
  color: var(--black);
  font-size: 1.0625rem;
  line-height: 1;
  font-weight: 600;
  margin: 0 14px;
  padding: 0.5rem 0.1rem;
  border: none;
  cursor: pointer;
  border-bottom: 3px solid transparent;
  outline: 0px none;
  -webkit-transition: all 400ms ease-in-out;
  -moz-transition: all 400ms ease-in-out;
  -o-transition: all 400ms ease-in-out;
  -ms-transition: all 400ms ease-in-out;
  transition: all 400ms ease-in-out;
}

/*------------------------------------------*/
/*  Filter Button Active
/*------------------------------------------*/ 

.filter-btns button.is-checked {
  color: var(--theme-color);
  cursor: default;
  border-color: var(--theme-color);
  outline: 0px none;
}

/*------------------------------------------*/
/*  FILTER ITEM
/*------------------------------------------*/ 

.filter-item {
  position: relative;
  width: 33.33%;
  padding: 0 10px;
  margin-bottom: 20px;
}

/*------------------------------------------*/
/*  INTEGRATION TOOL
/*------------------------------------------*/ 

.int_tool-1 {
  display: flex;
  position: relative;
  flex-flow: row wrap;
  align-items: stretch!important;
  justify-content: flex-start;
  background-color: var(--white);
  border: 1px solid var(--silver);
  padding: 22px;
  margin-bottom: 28px;
  -webkit-transition: all 400ms ease-in-out;
  -moz-transition: all 400ms ease-in-out;
  -o-transition: all 400ms ease-in-out;
  -ms-transition: all 400ms ease-in-out;
  transition: all 400ms ease-in-out;
}

.int_tool-2 {
  background-color: var(--white);
  padding: 20px 26px 22px;
  border: 1px solid var(--silver);
  -webkit-transition: all 400ms ease-in-out;
  -moz-transition: all 400ms ease-in-out;
  -o-transition: all 400ms ease-in-out;
  -ms-transition: all 400ms ease-in-out;
  transition: all 400ms ease-in-out;
}

.int-2-wrapper .int_tool-2 {
  margin-bottom: 28px;
}

.int_tool-3,
.int_tool-4 {
  text-align: center;
  vertical-align: center;
  background-color: var(--white);
  border: 1px solid var(--silver);
  padding: 30px;
  margin-bottom: 30px;
  -webkit-transition: all 400ms ease-in-out;
  -moz-transition: all 400ms ease-in-out;
  -o-transition: all 400ms ease-in-out;
  -ms-transition: all 400ms ease-in-out;
  transition: all 400ms ease-in-out;
}

.int_tool-4 {
  padding: 25px 20px;
}

/*------------------------------------------*/
/*  INTEGRATION TOOL LOGO
/*------------------------------------------*/ 

.int_tool-1 .tool_logo {
  position: relative;
  margin-right: 24px;
}

.int_tool-1 .tool_logo img {
  width: 60px;
  height: 60px;
}

.int_tool-2 .tool_logo img {
  width: 40px;
  height: 40px;
}

.int_tool-3 img {
  width: 65px;
  height: 65px;
}

.int_tool-4 img {
  width: 58px;
  height: 58px;
}

/*------------------------------------------*/
/*  INTEGRATION TOOL TEXT
/*------------------------------------------*/ 

.tool_txt {
  overflow: hidden;
  flex: 1;
  max-width: 100%;
}

/*------------------------------------------*/
/*  INTEGRATION TOOL TYPOGRAPHY
/*------------------------------------------*/ 

.tool_txt h6 {
  font-size: 1.1875rem;
  margin-top: 2px;
}

.tool_logo h6 {
  position: relative;
  display: inline-block;
  line-height: 40px;
  top: 3px;
  margin-left: 10px;
}

.int_tool-4 h6 {
  font-size: 0.925rem;
  line-height: 1;
  margin-top: 18px;
}

.tool_txt p {
  margin-top: 3px;
  margin-bottom: 0;
}

.tool_description p {
  font-size: 0.95rem;
  margin-top: 14px;
  margin-bottom: 0;
}

/*------------------------------------------*/
/*  INTEGRATION TOOL HOVER
/*------------------------------------------*/ 

.int_tool-1:hover,
.int_tool-2:hover,
.int_tool-3:hover,
.int_tool-4:hover {
  border-color: transparent;
  -webkit-box-shadow: 0 4px 12px 0 var(--tra-black);
  -moz-box-shadow: 0 4px 12px 0 var(--tra-black);
  box-shadow: 0 4px 12px 0 var(--tra-black);
} 




/* ==========================================================================
  20. BANNER
  =========================================================================== */

.banner-1-wrapper {
  overflow: hidden;
  padding: 80px 60px;
  border: 1px solid transparent;
}

.banner-2-wrapper {
  position: relative;
  overflow: hidden;
  text-align: center;
} 

.banner-2-wrapper .banner-overlay {
  padding: 80px 70px 90px;
}

.banner-4-wrapper {
  overflow: hidden;
  padding: 55px 60px;
}

/*------------------------------------------*/
/*  BANNER TYPOGRAPHY
/*------------------------------------------*/

.banner-section .section-id {
  font-weight: 600;
  padding-top: 10px;
  margin-bottom: 30px;
}

.banner-2-txt h2,
.banner-3-txt h2 {
  margin-bottom: 22px;
}

.banner-3-txt h2.h2-xl,
.banner-3-txt h2.h2-title {
  margin-bottom: 35px;
}

.banner-section p {
  margin-bottom: 30px;
}

#banner-1.banner-section p {
  margin: 20px 0 25px;
}

#banner-2.banner-section p {
  padding: 0 4%;
}

/*------------------------------------------*/
/*  BANNER BUTTON
/*------------------------------------------*/

.banner-1-txt .btn {
  margin-right: 10px;
}

.banner-4-btn {
  position: relative;
  display: inline-block;
  margin: 0 auto;
  top: 10px;
}

.banner-4-btn p {
  margin: 12px 0 0!important;
}

.banner-section p.btn-txt {
  margin-top: 20px;
  margin-bottom: 0!important;
}




/* ==========================================================================
  21.DOWNLOAD
  =========================================================================== */

.version-release {
  margin-bottom: 80px;
}

.release-data {
  padding-bottom: 30px;
  border-bottom: 1px dashed var(--silver);
  margin-bottom: 30px;
}

.release-data h5 {
  margin-bottom: 40px;
}

span.version-data {
  color: var(--coal);
  font-size: 2rem;
  line-height: 1;
  margin-right: 20px;
  margin-bottom: 0;
}

span.release-date {
  color: var(--light-gray);
  font-size: 1.175rem;
  line-height: 1;
  margin-bottom: 0;
}

span.release-date span {
  color: var(--coal);
  font-weight: 500;
  margin-left: 20px;
}

.release-download {
  position: absolute;
  right: 5px;
  bottom: 0;
}

.release-highlights .simple-list p {
  margin-bottom: 2px;
}




/* ==========================================================================
  22. FAQS
  =========================================================================== */

#faqs-3 .inner-page-title {
  margin-bottom: 55px;
}

#faqs-2 .question {
  margin-bottom: 30px;
}

.faqs-2-questions .left-column {
  padding-right: 15px;
}

.faqs-2-questions .right-column {
  padding-left: 15px;
}

/*------------------------------------------*/
/*  FAQs TYPOGRAPHY
/*------------------------------------------*/

#faqs-2 .question h6,
#faqs-2 .question h5 {
  line-height: 1.5;
  padding-right: 5%;
  margin-bottom: 15px;
}

.faqs-3-question h5 {
  line-height: 1.5;
  margin-bottom: 20px;
}

#faqs-1 .accordion-item p,
#faqs-3 .accordion-item p {
 color: var(--gray);
}

/*------------------------------------------*/
/*  FAQS ACCORDION  
/*------------------------------------------*/

.faqs-section .accordion-item {
  background-color: transparent!important;
  border: none;
  border-bottom: 1px solid var(--silver);
}

#faqs-3.faqs-section .accordion-item {
  border-bottom: 1px solid var(--silver);
}

.faqs-section .accordion-item.acc-last-item,
#faqs-3.faqs-section .accordion-item.acc-last-item {
  border-bottom: none;
}

/*------------------------------------------*/
/*  Question
/*------------------------------------------*/

.faqs-section .accordion-thumb {
  margin: 0;
  padding: 25px 0;
  cursor: pointer;
  position: relative;
}

#faqs-3.faqs-section .accordion-thumb {
  padding: 25px 0 30px;
}

.faqs-section .accordion-item .accordion-thumb:after,
.faqs-section .accordion-item.is-active .accordion-thumb:after {
  font-family: Flaticon;
  font-weight: 300; 
  content: "\f1c3";
  position: absolute;
  font-size: 0.9rem;
  top: 22px;
  right: 5px;
}

#faqs-3.faqs-section .accordion-item .accordion-thumb:after,
#faqs-3.faqs-section .accordion-item.is-active .accordion-thumb:after {
  top: 32px;
  right: 2px;
}

.faqs-section .accordion-item.is-active .accordion-thumb:after {
  content: "\f1c2";
}

#faqs-3.faqs-section .accordion-item.is-active .accordion-thumb {
 border-bottom: 1px solid var(--silver);
}

/*------------------------------------------*/
/*  Answer
/*------------------------------------------*/

.faqs-section .accordion-panel {
  margin: 0;
  padding: 0 0 15px 0;
  display: none;
}

#faqs-3 .accordion-panel {
  padding: 45px 0 15px 0;
}

.faqs-section .accordion-thumb h5 {
  line-height: 1;
  margin-bottom: 0;
}

/*------------------------------------------*/
/*  MORE QUESTIONS BUTTON
/*------------------------------------------*/ 

.more-questions {
  text-align: center;
  margin-top: 40px;
}

#faqs-1 .more-questions {
  margin-top: 50px;
}

#faqs-3 .more-questions {
  margin: 50px auto 0; 
}

.more-questions-txt {
  display: inline-block;
  padding: 22px 46px;
}

.more-questions-txt p {
  line-height: 1;
  margin-bottom: 0;
}

.more-questions-txt a {
  font-weight: 500;
  text-decoration: underline;
}

.more-questions a {
  text-decoration: underline;
}




/* ==========================================================================
  23. HELP CENTER
  =========================================================================== */

.help-category {
  text-align: center;
  border: 2px solid var(--smoke);
  padding: 45px 35px;
  margin-bottom: 30px;
  -webkit-transition: all 400ms ease-in-out;
  -moz-transition: all 400ms ease-in-out;
  -o-transition: all 400ms ease-in-out;
  -ms-transition: all 400ms ease-in-out;
  transition: all 400ms ease-in-out; 
}

.help-category:hover {
  border-color: var(--theme-color); 
}

.help-category h6 {
  margin-top: 10px;
  margin-bottom: 12px;
}

.help-category p {
  margin-bottom: 0;
}

/*------------------------------------------*/
/*  HELP CATEGORY
/*------------------------------------------*/ 

.help-category-title {
  position: relative;
  overflow: hidden;
  padding: 60px 40px 45px;
  margin-bottom: 80px;
}

.help-category-title h3 {
  line-height: 1;
  margin-bottom: 0!important;
}

.help-category-title p {
  margin-top: 15px;
  margin-bottom: 0;
}

.help-category-title p span {
  margin-right: 10px;
}

#help-category h4 {
  margin-bottom: 60px;
}

.help-category-item {
  padding-bottom: 40px;
  margin-bottom: 40px;
}

.help-category-item p {
  margin-top: 15px;
  margin-bottom: 20px;
}

.help-category-data span {
  font-size: 0.9rem;
}

.read-time {
  margin-left: 20px;
}

.read-time span,
.updated-date span {
  color: var(--black);
  font-weight: 600;
}




/* ==========================================================================
  24. HELP ARTICLE
  =========================================================================== */

/*------------------------------------------*/
/*  HELP ARTICLE TITLE
/*------------------------------------------*/ 

.breadcrumb-wrapper {
  margin-bottom: 35px;
}

.breadcrumb li {
  font-size: 0.9rem;
}

.breadcrumb li a {
  text-decoration: underline;
}

.help-article-title h3 {
  margin-bottom: 20px;
}

.help-article-title img {
  margin: 40px 0;
}

/*------------------------------------------*/
/*  TABLE OF CONTENTS
/*------------------------------------------*/ 

.table-of-contents {
  padding: 50px 0;
  margin: 50px 0;
}

.table-of-contents h6 {
  margin-bottom: 20px;
}

.table-of-contents p {
  margin-bottom: 8px;
}

.table-of-contents p a {
  color: var(--theme-color);
  text-decoration: underline;
}

.table-of-contents p a:hover {
  color: var(--coal);
}

/*------------------------------------------*/
/*  HELP ARTICLE TEXT BLOCK
/*------------------------------------------*/ 

#help-article .txt-block {
  padding-bottom: 50px;
  margin-bottom: 50px;
}

#help-article .txt-block h6 {
  margin-top: 40px;
  margin-bottom: 15px;
}

#help-article .txt-block p span {
  color: var(--black);
  font-weight: 600;
}

#help-article .txt-block p a {
  text-decoration: underline;
}

#help-article .txt-block img {
  margin-top: 25px;
  margin-bottom: 25px;
}

/*------------------------------------------*/
/*  HELP VOTE
/*------------------------------------------*/

.help-vote-form {
  text-align: center;
  margin-top: 70px;
}

.help-vote-form h6 {
  margin-bottom: 25px;
}




/* ==========================================================================
  25. BLOG
  =========================================================================== */

#blog-page .section-title p {
  margin-top: 25px;
  padding: 0 5%;
}

/*------------------------------------------*/
/*  BLOG POSTS CATEGORY
/*------------------------------------------*/

.posts-category {
  margin-top: 40px;
  margin-bottom: 60px;
}

.posts-category h4 {
  line-height: 1;
  margin-bottom: 0;
}

.posts-category span {
  position: relative;
  top: 1px;
}

/*------------------------------------------*/
/*  BLOG POST
/*------------------------------------------*/ 

#blog-1 .blog-post {
  margin-bottom: 40px;
}

.square-post,
#blog-page .blog-post {
  margin-bottom: 60px;
}

/*------------------------------------------*/
/*  BLOG POST IMAGE
/*------------------------------------------*/ 

#blog-1 .blog-post-img,
#blog-page .blog-post-img {
  overflow: hidden;
  text-align: center;
}

/*------------------------------------------*/
/*  BLOG POST META
/*------------------------------------------*/

.blog-post-meta {
  margin-bottom: 6px;
}

.post-meta-list li {
  width: auto!important;
  display: inline-block!important;
  vertical-align: top;
  clear: none !important;
}

.post-meta-list p {
  line-height: 1;
  margin-bottom: 0;
}

.meta-list-divider {
  position: relative;
  top: 0px;
  transform: rotate(-90deg);
  padding: 0 1px;
}

/*------------------------------------------*/
/*  BLOG POST TYPOGRAPHY
/*------------------------------------------*/ 

.blog-post-txt {
  margin-top: 30px;
  padding-left: 5px;
  padding-right: 5px;
}

.blog-post-txt h6,
.blog-post-txt h5 {
  margin-bottom: 14px;
}

.blog-post-txt p {
  margin-bottom: 0;
}

p.post-comments {
  margin-top: 10px;
}

/*------------------------------------------*/
/*  SQUARE BLOG POST
/*------------------------------------------*/

.square-post-wrapper {
  overflow: hidden;
}

.square-post .blog-post-txt {
  padding: 0 30px 0 60px;
}

.square-post .blog-post-meta {
  margin-bottom: 20px;
}

.square-post .blog-post-txt h4 {
  line-height: 1.4;
  margin-bottom: 20px;
}

.square-post .post-comments {
  margin-top: 20px;
}

/*------------------------------------------*/
/*  Image Hover Effect 
/*------------------------------------------*/ 

.blog-post:hover img {
  transform: scale(1.08);
  -ms-transform: scale(1.08);
  -webkit-transform: scale(1.08);
  -o-transform: scale(1.08);
  -moz-transform: scale(1.08);
}




/* ==========================================================================
  26. SINGLE BLOG POST
  =========================================================================== */

/*------------------------------------------*/
/*  Single Post Title
/*------------------------------------------*/

.single-post-title {
  margin-bottom: 60px;
}

.single-post-title h3,
.single-post-title h2 {
  padding: 0 10px;
}

.single-post-title p.p-lg {
  margin-top: 28px;
  padding: 0 5%;
  margin-bottom: 0;
}

#single-post .blog-post-meta {
  margin-bottom: 18px;
}

#single-post .blog-post-meta .meta-list-divider {
  top: 2px;
}

/*------------------------------------------*/
/*  Single Post Text
/*------------------------------------------*/

.single-post-txt h5,
.single-post-txt h4 {
  margin-top: 30px;
  margin-bottom: 30px;
}

.single-post-txt h5 span {
  display: block;
}

.single-post-txt p span {
  color: var(--black);
  font-weight: 600;
}

.single-post-txt p a {
  color: var(--black);
  font-weight: 400;
  text-decoration: underline;
}

/*------------------------------------------*/
/*  Single Post Blockquote
/*------------------------------------------*/

.single-post-txt figure {
  margin-bottom: 40px;
}

.single-post-txt .blockquote p {
  font-size: 1.5rem;
  line-height: 1.45;
  margin-bottom: 40px;
}

.single-post-txt .blockquote-footer {
  font-size: 1.125rem;
}

/*------------------------------------------*/
/*  Single Post Image
/*------------------------------------------*/

.post-inner-img {
  text-align: center;
  margin-top: 50px;
  margin-bottom: 50px;
}

/*------------------------------------------*/
/*  POST DATA
/*------------------------------------------*/ 

.post-data {
  margin-top: 80px;
  padding: 80px 0 100px;
}

/*------------------------------------------*/
/*  POST AUTHOR
/*------------------------------------------*/ 

.post-author-avatar {
  margin: 0 auto; 
  float: left;
  margin-right: 20px;
}

.post-author-avatar img {
  width: 70px;
  height: 70px;
  -webkit-border-radius: 100%;
  -moz-border-radius: 100%;
  -o-border-radius: 100%;
  border-radius: 100%;
}

.post-author-txt {
  position: relative;
  top: 4px;
}

.post-author-txt p {
  line-height: 1;
  margin-top: 12px;
  margin-bottom: 0;
}

.post-author-txt p a {
  color: var(--black);
  font-weight: 500;
}

/*------------------------------------------*/
/*  POST SHARE ICONS
/*-----------------------------------------*/

.post-share-list p {
  margin-bottom: 12px;
}

.share-social-icons {
  display: inline-block; 
  padding-left: 0;
}

.share-social-icons li {
  width: auto !important;
  display: inline-block !important;
  vertical-align: top;
  clear: none !important;
  padding: 0;
}

.share-social-icons a.share-ico span {
  color: var(--coal);
  opacity: .6;
  margin-left: 12px;
  -webkit-transition: all 450ms ease-in-out;
  -moz-transition: all 450ms ease-in-out;
  -o-transition: all 450ms ease-in-out;
  -ms-transition: all 450ms ease-in-out;
  transition: all 450ms ease-in-out;
}

.share-social-icons a.share-ico:hover span {
  opacity: 1;
}

/*------------------------------------------*/
/*  SUBSCRIBE BANNER
/*------------------------------------------*/

.subscribe-banner {
  position: relative;
  overflow: hidden;
  padding: 50px 40px;
}

.subscribe-banner-txt p {
  margin-top: 15px;
  margin-bottom: 0;
}

.subscribe-banner-btn {
  position: relative;
  z-index: 3;
  margin-top: 10px;
}

/*------------------------------------------*/
/*  POST COMMENTS
/*------------------------------------------*/

#post-comments {
  padding-top: 100px;
}

#post-comments hr {
  margin-top: 24px; 
  margin-bottom: 40px; 
}

#post-comments h5 {
  margin-bottom: 60px;
}

#post-comments img {
  width: 68px;
  height: 68px;
  -webkit-border-radius: 100%;
  -moz-border-radius: 100%;
  -o-border-radius: 100%;
  border-radius: 100%;
}

.comment-body {
  margin-left: 25px;
}

.comment-meta {
  margin-bottom: 10px;
}

.comment-meta h6 {
  line-height: 1;
  margin-bottom: 0;
}

.comment-date {
  font-size: 16px;
  font-weight: 400;
  margin-bottom: 5px;
}

/*------------------------------------------*/
/*  Comment Reply 
/*------------------------------------------*/

.btn-reply {
  margin-left: 3px;
}

.btn-reply a {
  font-size: 17px;
  font-weight: 400; 
}

.btn-reply span {
  position: relative;
  top: 3px;
  margin-right: 4px;
  left: 3px;
}

.btn-reply.ico-20 [class*="flaticon-"]:before, 
.btn-reply.ico-20 [class*="flaticon-"]:after { font-size: 0.975rem; }

.btn-reply a:hover {
  color: var(--black);
}

/*------------------------------------------*/
/*  SINGLE POST COMMENT FORM
/*------------------------------------------*/

#leave-comment h5 {
  padding-bottom: 0;
  margin-top: 80px;
  margin-bottom: 10px;
  border: none;
}

.comment-form {
  position: relative;
  margin-top: 60px;
}

.comment-form p {
  position: relative;
  color: var(--coal);
  line-height: 1;
  font-weight: 500;
  margin-bottom: 15px;
  padding-left: 8px;
  display: block;
}

.comment-form p span {
  position: relative;
  color: #ff3366;
  top: -3px;
}

.comment-form .form-control {
  height: 62px;
  background-color: var(--white);
  border: 1.5px solid var(--silver);
  box-shadow: 0 0 0 0;
  color: var(--coal);
  font-size: 1rem;
  line-height: 1;
  font-weight: 400;
  padding: 0 20px;
  margin-bottom: 30px;
  -webkit-border-radius: 6px;
  -moz-border-radius: 6px;
  -o-border-radius: 6px;
  border-radius: 6px;
  -webkit-transition: all 300ms ease-in-out;
  -moz-transition: all 300ms ease-in-out;
  -o-transition: all 300ms ease-in-out;
  -ms-transition: all 300ms ease-in-out;
  transition: all 300ms ease-in-out;
}

.comment-form textarea { min-height: 220px; }
.comment-form textarea.form-control { 
  padding: 25px 20px;
}

/*------------------------------------------*/
/*  Form Input Placeholder
/*------------------------------------------*/

.comment-form .form-control::-moz-placeholder { color: var(--light-gray); } 
.comment-form .form-control:-ms-input-placeholder { color: var(--light-gray); } 
.comment-form .form-control::-webkit-input-placeholder { color: var(--light-gray); } 

/*------------------------------------------*/
/*  Comment Form Input Focus
/*------------------------------------------*/

.comment-form .form-control:focus {
  outline: 0px none;
  box-shadow: none;
  background-color: var(--white);
  border-color: var(--theme-color);
}

/*------------------------------------------*/
/*  Comment Form Button
/*------------------------------------------*/

.comment-form .btn {
  margin-top: 5px;
}

.comment-form .btn:focus {
  outline: 0px none;
  box-shadow: none;
}

/*------------------------------------------*/
/*  Comment Form Message
/*------------------------------------------*/

.comment-form-msg {
  width: 100%!important;
  display: block;
  margin-top: 15px;
  padding-left: 0;
}

.comment-form .loading {
  color: #00b2e4;
  font-size: 1.0625rem;
  line-height: 1;
  font-weight: 500;
}

.comment-form .error {
  color: #e74c3c;
  font-size: 0.85rem;
  line-height: 1;
  font-weight: 400;
  margin-bottom: 10px;
  padding-left: 10px;
}




/* ==========================================================================
  27. NEWSLETTER
  =========================================================================== */

#newsletter-1 .newsletter-overlay {
  padding: 80px 0;
} 

#newsletter-2 .newsletter-overlay {
  padding: 80px 0 70px;
} 

/*------------------------------------------*/
/*  NEWSLETTER TYPOGRAPHY
/*------------------------------------------*/ 

.newsletter-txt h2 {
  margin-bottom: 35px;
}

.newsletter-txt h4,
.newsletter-txt h3,
.newsletter-txt p.p-sm {
  margin-bottom: 0;
}

.newsletter-txt a {
  font-weight: 500;
  text-decoration: underline;
}

#newsletter-2 .newsletter-txt p {
  padding: 0 10%;
}

/*------------------------------------------*/
/*  NEWSLETTER FORM
/*------------------------------------------*/ 

#newsletter-1 .newsletter-form {
  padding-left: 10%;
  margin-top: 30px;
}

#newsletter-2 .newsletter-form {
  padding: 0 5%;
  margin-top: 40px;
}

/*------------------------------------------*/
/*  Newsletter Form Input
/*------------------------------------------*/

.newsletter-section .form-control {
  height: 58px;
  background-color: var(--white);
  border: 1.5px solid var(--silver);
  font-size: 1rem;
  color: var(--coal);
  font-weight: 400;
  padding: 0 15px;  
  margin-right: 12px;
  box-shadow: none;
  -webkit-border-radius: 6px;
  -moz-border-radius: 6px;
  -o-border-radius: 6px;
  border-radius: 6px;
}

#newsletter-2.newsletter-section .form-control {
  border: none;
}

.input-group:not(.has-validation)>:not(:last-child):not(.dropdown-toggle):not(.dropdown-menu):not(.form-floating) {
  border-top-right-radius: 6px;
  border-bottom-right-radius: 6px;
}

/*------------------------------------------*/
/*  Newsletter Form Input Focus
/*------------------------------------------*/

.newsletter-section .form-control:focus {
  background-color: var(--white);
  outline: 0;
  box-shadow: none;
  border-color: var(--theme-color);
}

/*------------------------------------------*/
/*  Newsletter Form Input Placeholder
/*------------------------------------------*/

.newsletter-section .form-control::-moz-placeholder { color: var(--light-gray); } 
.newsletter-section .form-control:-ms-input-placeholder { color: var(--light-gray); } 
.newsletter-section .form-control::-webkit-input-placeholder { color: var(--light-gray); } 

/*------------------------------------------*/
/*  Newsletter Form Button
/*------------------------------------------*/

.newsletter-section .btn {
  display: block; 
  width: 100%!important;
  height: 58px;
  font-size: 0.925rem;
  padding: 0.7rem 1.3rem;
}

#newsletter-2.newsletter-section .btn {
  font-size: 0.975rem;
  padding: 0.7rem 1.5rem;
}

/*------------------------------------------*/
/*  Newsletter Form Notification
/*------------------------------------------*/

.newsletter-section .form-notification {
  color: #3eb1ff;
  font-size: 0.9rem;
  font-weight: 400;
  line-height: 1;
  margin-top: 15px;
  margin-left: 5px;
}

.newsletter-section .text-center .form-notification {
  margin-left: 0;
}

.newsletter-section .form-notification.valid,
.newsletter-section .form-notification.error {
  color: #fc2f4b;
}

.newsletter-section .newsletter-form.valid {
  color: #0fbc49;
}




/* ==========================================================================
  28. CONTACTS
  =========================================================================== */

/*------------------------------------------*/
/*  CONTACT INFO
/*------------------------------------------*/

.contacts-info p {
  padding-right: 5%;
  margin: 25px 0 0;
}

.contacts-info h6 {
  margin: 35px 0 20px;
}

.contacts-info-socials li {
  width: auto!important;
  display: inline-block!important;
  vertical-align: top;
  clear: none !important;
  padding-right: 14px; 
}

.contacts-info-socials li span { 
  position: relative;
  top: 4px; 
  opacity: .7;
  -webkit-transition: all 400ms ease-in-out;
  -moz-transition: all 400ms ease-in-out;
  -o-transition: all 400ms ease-in-out;
  -ms-transition: all 400ms ease-in-out;
  transition: all 400ms ease-in-out;
}

.contacts-info-socials li span:hover { 
  opacity: 1;
}

/*------------------------------------------*/
/*  CONTACT BOX
/*------------------------------------------*/

.contact-box {
  margin-bottom: 30px;
}

.contact-box p {
  color: var(--gray);
  padding-right: 2%;
  margin: 18px 0 0;
}

.contact-box span {
  position: relative;
  top: 1.5px;
  left: 1px;
}

/*------------------------------------------*/
/*  CONTACT FORM INPUT
/*------------------------------------------*/

.contact-form .form-control,
.contact-form .form-select {
  height: 60px;
  background-color: var(--white);
  border: 1.5px solid var(--silver);
  box-shadow: 0 0 0 0;
  color: var(--coal);
  font-size: 1rem;
  line-height: 1;
  font-weight: 400;
  padding: 0 20px;
  margin-bottom: 20px;
  -webkit-border-radius: 6px;
  -moz-border-radius: 6px;
  border-radius: 6px;
  -webkit-transition: all 300ms ease-in-out;
  -moz-transition: all 300ms ease-in-out;
  -o-transition: all 300ms ease-in-out;
  -ms-transition: all 300ms ease-in-out;
  transition: all 300ms ease-in-out;
}

.contact-form .form-select {
  display: inline-block;
  width: 100%;
  color: var(--light-gray);
  box-shadow: none;
  -webkit-border-radius: 6px;
  -moz-border-radius: 6px;
  border-radius: 6px;
}

/*------------------------------------------*/
/*  Contact Form Textarea
/*------------------------------------------*/

.contact-form textarea { min-height: 220px; }
.contact-form textarea.form-control { 
  padding: 20px 15px;
}

/*------------------------------------------*/
/*  Contact Form Button
/*------------------------------------------*/

.contact-form .form-btn { 
  margin-top: 15px;
}

/*------------------------------------------*/
/*  Contact Form Placeholder
/*------------------------------------------*/

.contact-form .form-control::-moz-placeholder { color: var(--light-gray); } 
.contact-form .form-control:-ms-input-placeholder { color: var(--light-gray); } 
.contact-form .form-control::-webkit-input-placeholder { color: var(--light-gray); } 

/*------------------------------------------*/
/*  Contact Form Input Focus
/*------------------------------------------*/

.contact-form .form-control:focus,
.contact-form .form-select:focus {
  outline: 0px none;
  box-shadow: none;
  background-color: var(--white);
  border-color: var(--theme-color);
}

/*------------------------------------------*/
/*  Contact Form Message
/*------------------------------------------*/

.contact-form-msg {
  width: 100%!important;
  display: block;
  margin-top: 20px;
  padding-left: 0;
}

.contact-form .loading {
  color: #00b2e4;
  font-size: 1.0625rem;
  line-height: 1;
  font-weight: 500;
  padding-left: 15px;
}

.contact-form  .error {
  color: #e74c3c;
  font-size: 0.85rem;
  line-height: 1;
  font-weight: 400;
  margin-bottom: 10px;
  padding-left: 10px;
}




/* ==========================================================================
  29. FOOTER
  =========================================================================== */

.footer {
  padding-bottom: 50px;
}

.footer-info,
.footer-links {
  margin-bottom: 40px;
}

#footer-1 .fl-2,
#footer-1 .fl-3,
#footer-1 .fl-4 {
  padding-left: 10%;
}

#footer-1 .fl-3,
#footer-1 .fl-5,
#footer-1 .footer-contacts {
  margin-bottom: 30px;
}

#footer-3 .footer-links,
#footer-11 .footer-links {
  padding-left: 13%;
}

#footer-2 .footer-info,
#footer-5 .footer-info {
  padding-right: 8%;
}

#footer-5 .fl-2 {
  padding-left: 50px;
}

#footer-7 .footer-links {
  padding-left: 10%;
}

/*------------------------------------------*/
/*  FOOTER LINKS MOBILE
/*------------------------------------------*/

.footer h6.m-title {
  display: none;
  position: relative;
}

.footer h6.m-title:after {
  font-family: Flaticon;
  font-weight: 300; 
  content: "\f1cf";
  position: absolute;
  font-size: 0.9rem;
  top: 5px;
  right: 5px;
}

.footer h6.m-title.expanded:after {
  content: "\f1d0";
}

/*------------------------------------------*/
/*  FOOTER LOGO
/*------------------------------------------*/

img.footer-logo {
  width: auto;
  max-width: inherit;
  max-height: 36px;
  margin-bottom: 25px;
}

#footer-6 img.footer-logo {
  margin-bottom: 0;
}

/*------------------------------------------*/
/*  FOOTER TYPOGRAPHY
/*------------------------------------------*/

.footer h6 {
  font-size: 1.0815rem;
  line-height: 1;
  margin-bottom: 22px;
}

.footer .footer-links h6.h6-lg {
  margin-bottom: 14px;
}

.footer a {
  color: var(--gray);
}

.footer.bg--black a {
  color: var(--snow);
}

.footer-contacts p {
  margin-bottom: 4px;
}

.footer-phone {
  margin-top: 12px;
  margin-bottom: 4px;
}

.footer .footer-links h6.h6-lg a {
  text-decoration: underline;
}

.footer.color--white a,
.footer.color--white li a {
  color: var(--snow);
}

.footer.color--white a:hover,
.footer.color--white li a:hover {
  color: var(--white)!important;
}

#footer-9 .footer-info p.p-sm {
  font-size: 0.875rem;
  margin-bottom: 20px;
}

/*------------------------------------------*/
/*  FOOTER MAIL LINK
/*------------------------------------------*/

.footer-mail-link {
  margin: 10px 0 0 3px;
}

.footer-mail-link a {
  font-size: 1rem;
  font-weight: 400;
}

.footer-mail-link span {
  position: relative;
  top: 5px;
  right: 5px;
}

.footer-mail-link.ico-20 [class*="flaticon-"]:before, 
.footer-mail-link.ico-20 [class*="flaticon-"]:after { font-size: 1.175rem; }

/*------------------------------------------*/
/*  FOOTER INFO
/*------------------------------------------*/

.footer-info-socials {
  margin-top: 5px;
}

.footer-info-socials li {
  width: auto!important;
  display: inline-block!important;
  vertical-align: top;
  clear: none !important;
}

.footer-info-socials li {
  padding-right: 15px; 
}

.footer-info-socials li span { 
  position: relative;
  opacity: .8;
  -webkit-transition: all 400ms ease-in-out;
  -moz-transition: all 400ms ease-in-out;
  -o-transition: all 400ms ease-in-out;
  -ms-transition: all 400ms ease-in-out;
  transition: all 400ms ease-in-out;
}

.footer-info-socials.ico-25 [class*="flaticon-"]:before, 
.footer-info-socials.ico-25 [class*="flaticon-"]:after { font-size: 1.3rem; }

.footer-info-socials li:hover span { 
  opacity: 1;
}

/*------------------------------------------*/
/*  FOOTER LINKS
/*------------------------------------------*/

#footer-10 .footer-links {
  margin-bottom: 20px;
}

.foo-links li {
  width: auto!important;
  display: block!important;
  vertical-align: top;
  clear: none !important;
  margin: 0;
  padding: 0;
}

#footer-10 .foo-links li {
  display: inline-block!important;
  padding: 0 20px;
}

.foo-links.ico-10 li span {
  position: relative;
  top: 1.5px;
  right: 4px;
}

.foo-links li p {
  font-weight: 400;
  margin-bottom: 10px;
}

.foo-links li:last-child p {
  margin-bottom: 0;
}

/*------------------------------------------*/
/*  FOOTER SOCIAL
/*------------------------------------------*/

.footer-socials {
  margin: 0;
  display: inline-block; 
}

.footer-socials li {
  float: left;
  width: auto!important;
  display: inline-block!important;
  vertical-align: top;
  clear: none!important;
  margin: 0;
}

.footer-socials a {
  display: block;
  margin-right: 15px;
}

.footer-socials.ico-20 a,
.footer-socials.ico-25 a { 
  margin-right: 16px; 
} 

.footer-socials.ico-25 [class*="flaticon-"]:before, 
.footer-socials.ico-25 [class*="flaticon-"]:after { font-size: 1.325rem; }

/*------------------------------------------*/
/*  FOOTER STORE BAGES
/*------------------------------------------*/

.footer .store {
  float: none;
  margin-right: 0;
  margin-bottom: 15px;
}

/*------------------------------------------*/
/*  FOOTER NEWSLETTER FORM
/*------------------------------------------*/

.footer-form {
  margin-bottom: 20px;
}

.footer .newsletter-form {
  margin-top: 25px;
}

.footer-form .newsletter-form .input-group {
  background-color: var(--form-control);
  border: 1.5px solid var(--smoke);
  padding: 9px 2px;
  -webkit-transition: all 400ms ease-in-out;
  -moz-transition: all 400ms ease-in-out;
  -o-transition: all 400ms ease-in-out;
  -ms-transition: all 400ms ease-in-out;
  transition: all 400ms ease-in-out;
}

.footer-form .form-control {
  height: 32px;
  background-color: transparent;
  border: none;
  color: var(--coal);
  font-size: 1rem;
  font-weight: 400;
  padding: 0 10px;
  box-shadow: none;
}

/*------------------------------------------*/
/*  Newsletter Form Input Focus
/*------------------------------------------*/

.footer-form .newsletter-form .input-group:focus-within {
  background-color: var(--white)!important;
  border-color: var(--theme-color)!important;
}

.footer-form .form-control:focus {
  border: none;
  border-right: none;
  outline: 0;
  box-shadow: none;
}

/*------------------------------------------*/
/*  Footer Form Placeholder
/*------------------------------------------*/

.footer-form .form-control::-moz-placeholder { color: var(--gray); } 
.footer-form .form-control:-ms-input-placeholder { color: var(--gray); } 
.footer-form .form-control::-webkit-input-placeholder { color: var(--gray); } 

/*------------------------------------------*/
/*  Newsletter Form Button
/*------------------------------------------*/

.footer-form .btn {
  height: 32px;
  padding: 0 10px;
}

.footer-form .ico-15 [class*="flaticon-"]:before, 
.footer-form .ico-15 [class*="flaticon-"]:after { line-height: 28px!important; }

/*------------------------------------------*/
/*  Footer Form Notification
/*------------------------------------------*/

.footer-form .form-notification {
  color: #1680fb;
  font-size: 0.85rem;
  line-height: 1.25;
  font-weight: 400;
  margin-top: 12px;
  margin-left: 5px;
}

.footer-form .form-notification.error {
  color: #fc2f4b;
}

.footer-form .form-notification.valid {
  color: #48af4b;
}

/*------------------------------------------*/
/*  BOTTOM FOOTER
/*------------------------------------------*/

.footer hr {
  margin-top: 30px;
  margin-bottom: 50px;
}

/*------------------------------------------*/
/*  BOTTOM FOOTER COPYRIGHT
/*------------------------------------------*/

.footer-copyright p {
  margin-bottom: 0;
}

/*------------------------------------------*/
/*  BOTTOM FOOTER SECONDARY LINK
/*------------------------------------------*/

.bottom-secondary-link p {
  margin-bottom: 0;
}

.bottom-secondary-link p span {
  position: relative;
  top: 1.5px;
  padding: 0 2px;
}

.bottom-secondary-link.ico-15 [class*="flaticon-"]:before, 
.bottom-secondary-link.ico-15 [class*="flaticon-"]:after { font-size: 0.75rem; }

/*------------------------------------------*/
/*  BOTTOM FOOTER LINKS
/*------------------------------------------*/

.bottom-footer-list li,
.bottom-footer-socials li,
.bottom-footer-payment li {
  width: auto!important;
  display: inline-block!important;
  vertical-align: top;
  clear: none !important;
}

.bottom-foo-socials li,
.bottom-footer-socials li {
  padding-left: 14px; 
}

#footer-10 .bottom-footer-socials li {
  padding: 0 10px; 
}

.footer-list-divider {
  position: relative;
  top: -0.5px;
  transform: rotate(-90deg);
  margin-left: 4px;
}

.bottom-footer-list.ico-15 [class*="flaticon-"]:before, 
.bottom-footer-list.ico-15 [class*="flaticon-"]:after { font-size: 0.8rem; }

.bottom-footer-list li p {
  display: inline-block;
  float: left;
  margin-bottom: 0;
}

.bottom-footer-socials li span { 
  position: relative;
  top: 4px; 
  opacity: .7;
  -webkit-transition: all 400ms ease-in-out;
  -moz-transition: all 400ms ease-in-out;
  -o-transition: all 400ms ease-in-out;
  -ms-transition: all 400ms ease-in-out;
  transition: all 400ms ease-in-out;
}

.bottom-footer-socials li span:hover { 
  opacity: 1;
}

.bottom-foo-socials li span { 
  position: relative;
  top: 1px; 
  right: 1px;
  opacity: .8;
}

.bottom-footer-payment li span {
  line-height: 1!important;
  position: relative;
  top: 4px; 
  opacity: .8;
}

.bottom-footer-socials.ico-20 [class*="flaticon-"]:before, 
.bottom-footer-socials.ico-20 [class*="flaticon-"]:after { font-size: 1.115rem; }

.bottom-footer-socials.ico-25 [class*="flaticon-"]:before, 
.bottom-footer-socials.ico-25 [class*="flaticon-"]:after { font-size: 1.25rem; }




/* ==========================================================================
  30. INNER PAGE WRAPPER
  =========================================================================== */

.inner-page-hero {
  padding-top: 180px;
}

.page-hero-section {
  position: relative;
  width: 100%;
  background-repeat: no-repeat;
  background-position: center center;   
  background-size: cover;
  background-attachment: scroll!important;
  padding-top: 180px;
  padding-bottom: 100px;
}

/*------------------------------------------*/
/*  INNER PAGE TITLE
/*------------------------------------------*/

.inner-page-title {
  text-align: center;
  margin-bottom: 80px;
}

.inner-page-title h2 {
  margin-bottom: 0;
}

.inner-page-title p {
  line-height: 1;
  margin-top: 24px;
  margin-bottom: 0;
}

.page-hero-section p {
  margin-top: 16px;
  margin-bottom: 0;
}




/* ==========================================================================
  31. PAGE PAGINATION
  =========================================================================== */

.page-link {
  color: var(--gray);
  font-size: 1.1rem;
  font-weight: 500;
  padding: 2px 14px;
  margin: 0 8px;
  background-color: transparent;
  border: 2px solid transparent;
  -webkit-border-radius: 4px;
  -moz-border-radius: 4px;
  -o-border-radius: 4px;
  border-radius: 4px;
  -webkit-transition: all 400ms ease-in-out;
  -moz-transition: all 400ms ease-in-out;
  -o-transition: all 400ms ease-in-out;
  -ms-transition: all 400ms ease-in-out;
  transition: all 400ms ease-in-out; 
}

.pagination.ico-20 [class*="flaticon-"]:before, 
.pagination.ico-20 [class*="flaticon-"]:after { font-size: 0.9rem; } 

.page-item:first-child .page-link {
  border-top-left-radius: 3px;
  border-bottom-left-radius: 3px;
}

.page-item:last-child .page-link {
  border-top-right-radius: 3px;
  border-bottom-right-radius: 3px;
}

.page-item.disabled .page-link {
  color: var(--gray);
  background-color: transparent;
  border: 2px solid transparent;
}

.page-link:hover {
  color: var(--gray);
  background-color: transparent;
  border: 2px solid var(--gray);
}

.page-link:focus {
  color: var(--gray);
  background-color: transparent;
  border: 2px solid transparent;
  box-shadow: 0 0;
}




/* ==========================================================================
  32. TERMS, PRIVACY, COOKIES PAGES
  =========================================================================== */

/*------------------------------------------*/
/*  TERMS PAGE TYPOGRAPHY
/*------------------------------------------*/

.txt-block.legal-info h5 {
  margin-top: 70px;
  margin-bottom: 30px;
}

.txt-block.legal-info h6 {
  margin-top: 30px;
  margin-bottom: 30px;
}

.txt-block.legal-info h6 span,
.txt-block.legal-info h5 span {
  margin-right: 4px;
}

.legal-info p span {
  color: var(--black);
  font-weight: 500;
}

.legal-info a {
  font-weight: 500;
  text-decoration: underline;
}




/* ==========================================================================
  33. SIGN-IN / SIGN-UP PAGE
  =========================================================================== */

#login,
#signup {
  position: relative;
  min-height: 100vh;
  padding-top: 100px;
  padding-bottom: 120px;
  z-index: 3;
}

.login-1 .col-lg-5 {
  padding-right: 0;
}

.signup-1 .col-lg-5 {
  padding-left: 0;
}

#login.login-1,
#signup.signup-1 {
  background-color: var(--white);
}

.login-1:after,
.signup-1:after {
  position: absolute;
  content: '';
  z-index: -1;
  width: 60%;
  height: 100%;
  bottom: 0;
  background-repeat: no-repeat;
  background-position: bottom center;   
  background-size: cover;
}

.login-1:after {
  left: 0;
  /* background-image: url(~images/home/login-1.jpg);  */
}

.signup-1:after {
  left: 40%;
  /* background-image: url(~images/home/signup-1.jpg); */
}

#login.login-2,
#signup.signup-2 {
  width: 100%;
  /* background: url(~images/home/login.jpg); */
  background-repeat: no-repeat;
  background-position: center center;   
  background-size: cover;
}

#login.login-3,
#signup.signup-3 {
  /* background-image: url(~images/home/login.jpg); */
}

.register-page-wrapper {
  overflow: hidden;
  position: relative;
  z-index: 1;
  -webkit-box-shadow: 0 10px 20px 0 rgba(0, 0, 0, .12); 
  -moz-box-shadow: 0 10px 20px 0 rgba(0, 0, 0, .12); 
  box-shadow: 0 10px 20px 0 rgba(0, 0, 0, .12); 
}

#login .register-page-wrapper {
  /* background: url(~images/home/login-wrapper.jpg); */
  background-position: left top;
}

#signup .register-page-wrapper {
  /* background: url(~images/home/signup-wrapper.jpg); */
  background-position: right center;
}

#login .register-page-wrapper:after,
#signup .register-page-wrapper:after {
  position: absolute;
  content: '';
  z-index: -1;
  width: 50%;
  height: 100%;
  top: 0;
  background-color: #fff;
}

#login .register-page-wrapper:after {
  left: 50%;
}

#signup .register-page-wrapper:after {
  left: 0;
}

#reset-password {
  /* background: url(~images/home/reset-password.jpg); */
  background-position: center center;
  min-height: 100vh;
  padding-top: 80px;
  padding-bottom: 100px;
}

/*------------------------------------------*/
/*  REGISTER PAGE LOGO
/*------------------------------------------*/

.register-page-txt img {
  width: auto;
  max-width: inherit;
  max-height: 32px;
  margin-bottom: 55px;
}

.login-page-logo {
  text-align: center;
  margin-bottom: 45px;
}

.login-page-logo img {
  width: auto;
  max-width: inherit;
  max-height: 45px;
}

/*------------------------------------------*/
/*  REGISTER PAGE TEXT
/*------------------------------------------*/

.login-3 .register-page-txt,
.signup-3 .register-page-txt {
  padding: 70px 50px;
}

.register-page-txt h3 {
  line-height: 1.15;
  font-weight: 700;
  margin-bottom: 6px;
}

#login .register-page-copyright {
  position: absolute;
  left: 60px;
  bottom: 45px;
}

#signup .register-page-copyright {
  position: absolute;
  right: 60px;
  bottom: 45px;
}

.register-page-copyright p {
  font-size: 0.85rem;
  line-height: 1;
  margin-bottom: 0;
}

/*------------------------------------------*/
/*  REGISTER PAGE FORM
/*------------------------------------------*/

.login-1 .register-page-form {
  padding-left: 20%;
}

.signup-1 .register-page-form {
  padding-right: 20%;
}

.login-2 .register-page-form,
.signup-2 .register-page-form {
  background-color: var(--white);
  border: 1px solid var(--snow);
  margin: 0 2%;
  padding: 45px 30px 40px;
}

.login-3 .register-page-form,
.signup-3 .register-page-form {
  padding: 60px;
}

.reset-page-wrapper form { 
  border: 1px solid var(--snow);
  margin: 0 2%;
  padding: 45px 15px 5px;
  -webkit-box-shadow: 0px 15px 20px 0px var(--tra-coal);
  -moz-box-shadow: 0px 15px 20px 0px var(--tra-coal);
  box-shadow: 0px 15px 20px 0px var(--tra-coal);
}

/*------------------------------------------*/
/*  REGISTER PAGE TITLE
/*------------------------------------------*/

.register-form-title {
  text-align: center;
  margin-bottom: 40px;
}

.login-2 .register-form-title,
.signup-2 .register-form-title {
  margin-bottom: 30px;
}

.register-form-title h5,
.register-form-title h4 {
  line-height: 1;
  margin-bottom: 15px;
}

.register-form-title p {
  margin-bottom: 0;
}

.register-form-title a {
  font-weight: 600;
  text-decoration: underline;
}

.reset-form-title {
  margin-bottom: 22px;
}

.reset-form-title h5 {
  line-height: 1;
  margin-bottom: 0;
}

.reset-form-title p.p-sm {
  font-size: 0.9rem;
  margin-top: 20px;
  margin-bottom: 0;
}

.rf-m-title {
  display: none;
}

/*------------------------------------------*/
/*  REGISTER PAGE TYPOGRAPHY
/*------------------------------------------*/

.register-page-form p.input-header {
  position: relative;
  color: var(--coal);
  font-size: 0.85rem;
  line-height: 1;
  font-weight: 500;
  margin-bottom: 12px;
  padding-left: 5px;
}

.reset-password-link p {
  position: relative;
  font-size: 0.875rem;
  line-height: 1;
  font-weight: 600;
  margin-top: 25px;
  margin-bottom: 0;
}

.login-3 .reset-password-link p {
  margin-top: -3px;
  margin-bottom: 23px;
}

.reset-password-link p a {
  text-decoration: underline;
}

.form-data span {
  display: block;
  font-size: 0.85rem;
  margin-bottom: 0;
}

.reset-password-form .form-data p { 
  margin-bottom: 12px; 
}

.form-data p a,
.form-data span a {
  color: var(--coal);
  font-weight: 500;
  text-decoration: underline;
}

.register-page-form p.create-account {
  font-size: 0.925rem;
  line-height: 1;
  margin-top: 30px;
  margin-bottom: 0;
}

.register-page-form p.create-account a {
  font-weight: 500;
  text-decoration: underline;
}

/*------------------------------------------*/
/*  REGISTER PAGE SEPARATOR LINE
/*------------------------------------------*/

.separator-line {
  display: flex;
  width: 100%;
  -webkit-box-pack: center;
  justify-content: center;
  -webkit-box-align: center;
  align-items: center;
  text-align: center;
  font-family: 'Plus Jakarta Sans', sans-serif;
  font-size: 0.9rem;
  font-weight: 500;
  margin: 25px 0;
}

.separator-line::before, 
.separator-line::after {
  content: "";
  border-top: 2px solid var(--tra-black);
  margin: 0 15px 0 0;
  flex: 1 0 20px;
}

.separator-line::after {
  margin: 0 0 0 15px;
}

/*------------------------------------------*/
/*  REGISTER PAGE FORM INPUT
/*------------------------------------------*/

.reset-page-wrapper .form-control,
.register-page-form .form-control {
  height: 54px;
  background-color: #fafbfe;
  border: 1.5px solid #d2d2d2;
  color: var(--coal);
  font-size: 1rem;
  line-height: 1;
  font-weight: 400;
  margin-bottom: 25px;
  padding: 5px 20px;
  -webkit-border-radius: 6px;
  -moz-border-radius: 6px;
  border-radius: 6px;
  -webkit-transition: all 300ms ease-in-out;
  -moz-transition: all 300ms ease-in-out;
  -o-transition: all 300ms ease-in-out;
  -ms-transition: all 300ms ease-in-out;
  transition: all 300ms ease-in-out;
}

.reset-page-wrapper .form-control {
  text-align: center;
  margin-bottom: 18px;
}

.register-page-form .form-control::-moz-placeholder { color: var(--gray); } 
.register-page-form .form-control:-ms-input-placeholder { color: var(--gray); } 
.register-page-form .form-control::-webkit-input-placeholder { color: var(--gray); } 

.reset-page-wrapper .form-control::-moz-placeholder { color: var(--gray); } 
.reset-page-wrapper .form-control:-ms-input-placeholder { color: var(--gray); } 
.reset-page-wrapper .form-control::-webkit-input-placeholder { color: var(--gray); } 

/*------------------------------------------*/
/*  REGISTER PAGE FORM FOCUS
/*------------------------------------------*/

.reset-page-wrapper .form-control:focus,
.register-page-form .form-control:focus {
  outline: 0px none;
  box-shadow: none;
  background-color: var(--white);
  border-color: var(--theme-color);
}

/*------------------------------------------*/
/*  REGISTER PAGE FORM BUTTON
/*------------------------------------------*/

#signup .register-page-form .btn.submit, 
#login .register-page-form .btn.submit {
  width: 100%;
  height: 54px;
  margin-top: 0;
}

#signup .register-page-form .btn.submit {
  margin-top: 25px;
}

.reset-page-wrapper .btn.submit {
  width: 100%;
  height: 54px;
  margin-bottom: 20px;
}

.register-page-form .btn-google {
  width: 100%;
  color: var(--coal);
  font-size: 0.95rem;
  background-color: var(--white);
  border: 1px solid var(--silver);
}

.btn-google img {
  position: relative;
  width: 22px;
  height: 22px;
  top: -2px;
  right: 5px;
}

.register-page-form .btn-google:hover {
  border: 1px solid var(--coal);
}

/*------------------------------------------*/
/*  REGISTER PAGE FORM MESSAGE
/*------------------------------------------*/

.reset-form-msg {
  text-align: center;
  width: 100%!important;
  display: block;
}

.reset-password-form .loading {
  color: #00b2e4;
  font-size: 1.0625rem;
  line-height: 1;
  font-weight: 500;
  padding-left: 15px;
}

.reset-password-form .error {
  position: relative;
  color: #e74c3c;
  font-size: 0.85rem;
  line-height: 1;
  font-weight: 400;
  top: -10px;
  margin-bottom: 12px;
  padding-left: 10px;
}

/*------------------------------------------*/
/*  FORM HIDE PASSWORD
/*------------------------------------------*/

.wrap-input {
  position: relative;
}

.btn-show-pass {
  color: var(--light-gray); 
  align-items: center;
  position: absolute;
  height: 100%;
  top: 15px;
  right: 20px;
  cursor: pointer;
  -webkit-transition: all 0.4s;
  -o-transition: all 0.4s;
  -moz-transition: all 0.4s;
  transition: all 0.4s;
}

.btn-show-pass.ico-20 [class^="flaticon-"]:before, 
.btn-show-pass.ico-20 [class^="flaticon-"]:after {
  font-size: 1.125rem;
}

.btn-show-pass:hover {
  color: var(--theme-color);
}

.eye-pass.flaticon-invisible {
  color: var(--gray);
}




/* ==========================================================================
  34. PAGE 404
  =========================================================================== */

#page-404 {
  /* background-image: url(~images/home/error-page.jpg); */
  min-height: 100vh;
  padding-top: 200px;
}

.page-404-txt {
  padding-bottom: 100px;
}

.page-404-txt h2 {
  margin-bottom: 20px;
}

.page-404-txt h6 {
  color: #f0f0f0;
  line-height: 1.6;
  font-weight: 400;
  padding: 0 5%;
  margin-bottom: 35px;
}

.page-404-img {
  padding: 0 10%;
  margin-bottom: 40px;
}

.page-404-footer {
  padding: 40px 0;
}

.page-404-footer p {
  color: #f0f0f0;
}




/* ==========================================================================
  35. MODAL
  ========================================================================== */ 

.modal-body {
  padding: 0!important;
}  

#modal-1 .modal-dialog {
  width: 420px;
}

#modal-2 .modal-dialog.modal-xl {
  width: 750px;
}

#modal-1.modal .modal-content,
#modal-2.modal .modal-content {
  overflow: hidden;
  -webkit-border-radius: 10px; 
  -moz-border-radius: 10px; 
  -o-border-radius: 10px; 
  border-radius: 10px;
}

#modal-1 .modal-body-img {
  padding-top: 30px;
}

#modal-2.modal .bg-img {
  /* background: url(~images/home/modal-request.jpg); */
  background-position: left center;  
}

#modal-1 .modal-body-content {
  padding: 20px 30px 10px;
}

#modal-2 .modal-body-content {
  padding: 60px 35px 50px;
}

/*------------------------------------------*/
/*  MODAL WINDOW CLOSE BUTTON
/*------------------------------------------*/ 

.modal .btn-close {
  position: absolute;
  z-index: 1;
  right: 20px!important;
  top: 20px!important;
  height: 1.5rem;
  width: 1.5rem;
  background: rgba(14, 14, 14, .11)!important;
  border-radius: 50%;
  padding: 0;
  opacity: .9;
  z-index: 9999;
}

#modal-2.modal .btn-close {
  background: rgba(14, 14, 14, .3)!important;
}

.modal .btn-close span {
  position: relative;
  line-height: 24px!important;
  top: -2px;
}

.modal .btn-close.ico-10 [class^="flaticon-"]:before, 
.modal .btn-close.ico-10 [class^="flaticon-"]:after {
  font-size: 0.6rem;
}

.modal .btn-close:focus,
#modal-2.modal .btn-close:focus {
  outline: none!important;
  box-shadow: none!important;
  text-decoration: none!important;
}

/*------------------------------------------*/
/*  MODAL WINDOW NEWSLETTER FORM
/*------------------------------------------*/ 

.modal-body .newsletter-form {
  margin-top: 25px;
}

.modal .newsletter-form .form-control {
  text-align: center;
  height: 58px;
  background-color: #f3f4f5;
  border: 1.5px solid transparent;
  font-size: 1rem;
  color: var(--coal);
  font-weight: 400;
  padding: 0 15px;  
  margin-bottom: 20px;
  box-shadow: none;
  -webkit-border-radius: 6px;
  -moz-border-radius: 6px;
  -o-border-radius: 6px;
  border-radius: 6px;
  -webkit-transition: all 400ms ease-in-out;
  -moz-transition: all 400ms ease-in-out;
  -o-transition: all 400ms ease-in-out;
  -ms-transition: all 400ms ease-in-out;
  transition: all 400ms ease-in-out; 
}

.modal .input-group:not(.has-validation)>:not(:last-child):not(.dropdown-toggle):not(.dropdown-menu):not(.form-floating) {
  border-top-right-radius: 6px;
  border-bottom-right-radius: 6px;
}

/*------------------------------------------*/
/*  Form Input Focus
/*------------------------------------------*/

.modal .newsletter-form .form-control:focus {
  outline: 0;
  box-shadow: none;
  background-color: var(--white);
  border-color: var(--theme-color);
}

/*------------------------------------------*/
/*  Form Input Placeholder
/*------------------------------------------*/

.modal .newsletter-form .form-control::-moz-placeholder { color: var(--light-gray); } 
.modal .newsletter-form .form-control:-ms-input-placeholder { color: var(--light-gray); } 
.modal .newsletter-form .form-control::-webkit-input-placeholder { color: var(--light-gray); } 

/*------------------------------------------*/
/*  Form Button
/*------------------------------------------*/

.modal .input-group-btn { 
  display: block; 
  width: 100%!important; 
}

.modal .newsletter-form .btn {
  display: block; 
  width: 100%!important;
  height: 58px;
  font-size: 0.975rem;
  margin: 0;
}

/*------------------------------------------*/
/*  Form Notification
/*------------------------------------------*/

.modal .form-notification {
  text-align: center;
  color: #1680fb;
  font-size: 0.85rem;
  font-weight: 400;
  line-height: 1;
  margin-top: 12px;
}

.modal .form-notification.valid,
.modal .form-notification.error {
   color: #fc2f4b;
}

.modal .newsletter-form.valid {
  color: #0fbc49;
}

/*------------------------------------------*/
/*  MODAL WINDOW REQUEST FORM
/*------------------------------------------*/ 

#modal-2 .request-form {
  margin: 0;
}

#modal-2 .request-form .col-md-12 {
  padding: 0;
}

#modal-2 .request-form-title p {
  margin-top: 18px;
  margin-bottom: 25px;
}

#modal-2 .request-form .form-control { 
  height: 60px;
  background-color: #f3f4f5;
  border: 1.5px solid transparent;
  font-size: 1rem;
  color: var(--coal);
  font-weight: 400;
  text-align: left;
  padding: 0 15px;  
  margin-bottom: 20px;
  -webkit-border-radius: 6px;
  -moz-border-radius: 6px;
  -o-border-radius: 6px;
  border-radius: 6px;
}

#modal-2 .request-form .btn {
  display: block; 
  width: 100%!important;
  height: 58px;
  margin: 0;
}

/*------------------------------------------*/
/*  Form Input Placeholder
/*------------------------------------------*/

#modal-2 .request-form .form-control::-moz-placeholder { color: var(--light-gray); } 
#modal-2 .request-form .form-control:-ms-input-placeholder { color: var(--light-gray); } 
#modal-2 .request-form .form-control::-webkit-input-placeholder { color: var(--light-gray); } 


/*------------------------------------------*/
/*  Form Input Focus
/*------------------------------------------*/

#modal-2 .request-form .form-control:focus {
  outline: 0;
  box-shadow: none;
  background-color: var(--white);
  border-color: var(--theme-color);
}

/*------------------------------------------*/
/*  Comment Form Message
/*------------------------------------------*/

#modal-2 .request-form-msg {
  width: 100%!important;
  display: block;
  margin-top: 15px;
  padding-left: 0;
}

#modal-2 .request-form .loading {
  color: #1680fb;
  font-size: 1.0625rem;
  line-height: 1;
  font-weight: 400;
}

#modal-2 .request-form .error {
  color: #e74c3c;
  font-size: 0.85rem;
  line-height: 1;
  font-weight: 400;
  margin-bottom: 10px;
  padding-left: 10px;
}




/* ==========================================================================
  36. COOKIES
  =========================================================================== */

#cookies {
  position: fixed;
  bottom: 20px;
  left: 33%;
  z-index: 200;
  width: 34%;
  padding: 20px 25px;
  border: 1px solid #ddd;
  border-radius: 8px;
  -webkit-box-shadow: 0 4px 12px 0 var(--tra-black);
  -moz-box-shadow: 0 4px 12px 0 var(--tra-black);
  box-shadow: 0 4px 12px 0 var(--tra-black);
}

#cookies p {
  font-size: 0.8rem;
  line-height: 1.5;
  margin: 0;
}

#cookies p span {
  display: block;
  margin-top: 12px;
}

#cookies-ok {
  cursor: pointer;
  display: inline-block;
  font-size: 0.75rem;
  font-weight: 500;
  background-color: var(--theme-color)!important;
  border: 2px solid var(--theme-color)!important;
  padding: 6px 16px;
  -moz-border-radius: 6px; 
  -webkit-border-radius: 6px; 
  border-radius: 6px; 
}

#cookies-ok:hover {
  background-color: var(--black)!important;
  border-color: var(--black)!important;
}

#cookies-info {
  color: #151515!important;
  font-weight: 500;
  text-decoration: underline;
  margin-left: 15px;
}

#cookies-info:hover {
  color: var(--theme-color)!important;
}

#cookies-close {
  position: absolute;
  right: 14px;
  top: 4px;
  height: 12px;
  width: 12px;
}

#cookies-close svg { 
  width: 10px; 
  height: 10px; 
}




/* ==========================================================================
  37. COLOR SCHEME
  ========================================================================== */ 

.bg--theme { 
  background-color: var(--theme-color);
}

/*------------------------------------------*/
/*  BUTTON
/*------------------------------------------*/

.btn--theme {
  color: var(--white)!important;
  border-color: var(--theme-color)!important;
  background-color: var(--theme-color)!important;
}

.hover--theme:hover,
.color--white .hover--theme:hover {
  color: var(--white)!important;
  border-color: var(--theme-btn-hover)!important;
  background-color: var(--theme-btn-hover)!important;
}

.btn--tra-theme, 
.hover--tra-theme:hover, 
.color--white .btn--tra-theme {
  color: var(--theme-color)!important;
  background-color: transparent!important;
  border-color: var(--theme-color)!important;
}

/*------------------------------------------*/
/*  COLOR
/*------------------------------------------*/

.color--theme, 
.color--theme h1, 
.color--theme h2, 
.color--theme h3, 
.color--theme h4, 
.color--theme h5, 
.color--theme h6, 
.color--theme p, 
.color--theme a, 
.color--theme li, 
.color--theme i, 
.color--theme span,
.color--white .color--theme { color: var(--theme-color); }

/*------------------------------------------*/
/*  PRELOADER SPINNER
/*------------------------------------------*/

.loading--theme .loader {
  border: 7px solid var(--theme-color);
}

.loading--theme .loader-inner {
  background-color: var(--theme-color);
}

/*------------------------------------------*/
/*  HEADER & NAVIGATION
/*------------------------------------------*/

.nav-theme .sm-info { 
  background-color: var(--theme-color); 
}

.wsmenu > .wsmenu-list.nav-theme > li > ul.sub-menu > li > a:hover,
.wsmenu > .wsmenu-list.nav-theme > li > ul.sub-menu > li > ul.sub-menu > li > a:hover,
.wsmenu > .wsmenu-list.nav-theme > li > .wsmegamenu.w-75 ul.link-list > li > a:hover,
.wsmenu > .wsmenu-list.nav-theme > li > .wsmegamenu.halfmenu ul.link-list > li > a:hover {
  color: var(--theme-color);
}

/*------------------------------------------*/
/*  CONTENT
/*------------------------------------------*/

.cbox-1:hover .cbox-1-ico.bg--theme { 
  color: var(--theme-color); 
  border-color: var(--theme-color); 
}

/*------------------------------------------*/
/*  PRICING
/*------------------------------------------*/

.pricing-features.ico--theme span { color: var(--theme-color); }
.switch-wrap input:checked + .switcher.switcher--theme { background-color: var(--theme-color); border-color: var(--theme-color); }

/*------------------------------------------*/
/*  PAGINATION
/*------------------------------------------*/

.theme-pagination .page-item.active .page-link {
  color: var(--white)!important;
  background-color: var(--theme-color);
  border: 2px solid var(--theme-color);
}




/* ==========================================================================
  38. DARK MODE
  ========================================================================== */ 

body.theme--dark {
  --dark-theme-bg: #13161f;
  --black: #181b27;
  --coal: #f9f9f9;
  --gray: #c8c8c8;
  --ink: #101219;
  --form-control: #191c28;
  --header-color: #fff;
  --header-link: #dee1ea;
  --silver: rgba(43, 48, 64, 1);
  --smoke: rgba(43, 48, 64, .7);
  --white-smoke: #191c28;
}

body.theme--dark { 
  background-color: var(--dark-theme-bg);  
  color: var(--gray); 
}

.theme--dark .bg--white { 
  background-color: var(--white-smoke);
}

.theme--dark .bg--snow { 
  background-color: var(--dark-theme-bg);
}

.theme--dark .gr--whitesmoke,
.theme--dark .shape--gr-whitesmoke:after {
  background-image: linear-gradient(180deg, rgba(29, 33, 44, .8) 50%, rgba(29, 33, 44, .05) 100%);
}

.theme--dark .gr--snow { 
  background-image: linear-gradient(180deg, rgba(29, 33, 44, .6) 40%, rgba(29, 33, 44, .01) 100%);
}

.theme--dark .inner-page-hero.gr--whitesmoke {
  background-image: linear-gradient(180deg, rgba(19, 22, 31, 1) 50%, rgba(19, 22, 31, 1) 100%);
}

/*------------------------------------------*/
/*  SECTION DIVIDER
/*------------------------------------------*/

.theme--dark hr.divider,
.theme--dark .footer hr {
  background-image: linear-gradient(90deg, rgba(122,125,147,0) 0, #7a7d93 38%, #7a7d93 64%, rgba(122,125,147,0) 99%); 
}

.theme--dark hr.divider.divider-light {
  background-color: #fff;
  background-image: linear-gradient(90deg, rgba(206,211,246,0) 0, #dedede 38%, #dedede 64%, rgba(206,211,246,0) 99%);
  opacity: 1;
}

/*------------------------------------------*/
/*  TYPOGRAPHY
/*------------------------------------------*/

.theme--dark a { 
  color: var(--gray); 
}

.theme--dark a:hover, 
.theme--dark a.tra-link:hover span { 
  color: var(--white); 
}

/*------------------------------------------*/
/*  WHITE BUTTON
/*------------------------------------------*/

.theme--dark .btn--white,
.theme--dark .hover--white:hover {
  color: var(--black)!important;
  background-color: var(--white)!important;
  border-color: var(--white)!important;
}

.theme--dark .btn--tra-white,
.theme--dark .hover--tra-white:hover {
  color: var(--white)!important;
  background-color: transparent!important;
  border-color: var(--white)!important;
}

.theme--dark .white-scroll .scroll .hover--tra-white:hover {
  color: var(--white)!important;
  background-color: transparent!important;
  border-color: var(--white)!important;
}

/*------------------------------------------*/
/*  BLACK BUTTON
/*------------------------------------------*/

.theme--dark .btn--tra-black,
.theme--dark .hover--tra-black:hover {
  color: var(--gray)!important;
  background-color: transparent!important;
  border-color: var(--gray)!important;
}

.theme--dark .navbar-dark .hover--tra-black:hover {
  color: var(--black)!important;
  background-color:transparent!important;
  border-color: var(--black)!important;
}

.theme--dark .white-scroll .hover--tra-black:hover {
  color: var(--white)!important;
  border-color: var(--white)!important;
  background-color: transparent!important;
}

.theme--dark .navbar-dark .scroll .hover--tra-black:hover {
  color: var(--white)!important;
  background-color: transparent!important;
  border-color: var(--white)!important;
}

/*------------------------------------------*/
/*  COAL BUTTON
/*------------------------------------------*/

.theme--dark .btn--coal {
  color: var(--white)!important;
  border-color: var(--coal)!important;
  background-color: var(--coal)!important;
}

.theme--dark .hover--coal:hover,
.theme--dark .color--white .hover--coal:hover {
  color: #222!important;
  border-color: var(--coal)!important;
  background-color: var(--coal)!important;
}

.theme--dark .btn--tra-coal, 
.theme--dark .hover--tra-coal:hover, 
.theme--dark .color--white .btn--tra-coal {
  color: var(--coal)!important;
  background-color: transparent!important;
  border-color: var(--coal)!important;
}

/*------------------------------------------*/
/*  THEME BUTTON
/*------------------------------------------*/

.theme--dark .btn--theme {
  color: var(--white)!important;
  border-color: var(--theme-color)!important;
  background-color: var(--theme-color)!important;
}

.theme--dark .hover--theme:hover,
.theme--dark .color--white .hover--theme:hover {
  color: var(--white)!important;
  border-color: var(--theme-btn-hover)!important;
  background-color: var(--theme-btn-hover)!important;
}

.theme--dark .btn--tra-theme, 
.theme--dark .hover--tra-theme:hover, 
.theme--dark .color--white .btn--tra-theme {
  color: var(--theme-color)!important;
  background-color: transparent!important;
  border-color: var(--theme-color)!important;
}

/*------------------------------------------*/
/*  TEXT COLORS
/*------------------------------------------*/

.theme--dark .color--black,
.theme--dark .color--black h2, 
.theme--dark .color--black h3, 
.theme--dark .color--black h4, 
.theme--dark .color--black h5, 
.theme--dark .color--black h6, 
.theme--dark .color--black p, 
.theme--dark .color--black a,
.theme--dark .color--black li, 
.theme--dark .color--black i,
.theme--dark .color--black span, 
.theme--dark .color--white .color--black { color: var(--white); }

.theme--dark .color--theme,
.theme--dark .color--theme h2, 
.theme--dark .color--theme h3, 
.theme--dark .color--theme h4, 
.theme--dark .color--theme h5, 
.theme--dark .color--theme h6, 
.theme--dark .color--theme p, 
.theme--dark .color--theme a,
.theme--dark .color--theme li, 
.theme--dark .color--theme i,
.theme--dark .color--theme span, 
.theme--dark .color--white .color--theme { color: var(--theme-color); }

/*------------------------------------------*/
/*   NAVIGATION MENU
/*------------------------------------------*/

.theme--dark .dark-menu .wsmainfull,
.theme--dark .white-menu .wsmainfull {
  background-color: var(--ink)!important;
  -webkit-box-shadow: 0 2px 3px var(--tra-ink);
  -moz-box-shadow: 0 2px 3px var(--tra-ink);
  box-shadow: 0 2px 3px var(--tra-ink);
}

/*------------------------------------------*/
/*  HEADER LINK
/*------------------------------------------*/

.theme--dark .navbar-dark .wsmenu > .wsmenu-list > li > a,
.theme--dark .navbar-light .wsmenu > .wsmenu-list > li > a,
.theme--dark .navbar-dark.inner-page-header .wsmenu > .wsmenu-list > li > a,
.theme--dark .navbar-dark.light-hero-header .wsmenu > .wsmenu-list > li > a {
  color: var(--header-link);
}

.theme--dark .navbar-dark .wsmenu-list > li > a.h-link:hover,
.theme--dark .navbar-light .wsmenu-list > li > a.h-link:hover,
.theme--dark .navbar-light .wsmenu > .wsmenu-list > li > a:hover,
.theme--dark .navbar-dark.inner-page-header .wsmenu > .wsmenu-list > li > a:hover,
.theme--dark .navbar-dark.light-hero-header .wsmenu > .wsmenu-list > li > a:hover {
  color: #eee!important;
}

.theme--dark .navbar-light .wsmenu > .wsmenu-list > li > a.btn:hover {
  color: var(--white)!important;
}

/*------------------------------------------*/
/*  HEADER SUBMENU / MEGAMENU
/*------------------------------------------*/

.theme--dark .wsmenu > .wsmenu-list > li > ul.sub-menu,
.theme--dark .wsmenu > .wsmenu-list > li > .wsmegamenu,
.theme--dark .wsmenu > .wsmenu-list > li > ul.sub-menu > li > ul.sub-menu,
.theme--dark .wsmenu > .wsmenu-list > li > .wsmegamenu.halfmenu {
  background-color: var(--white-smoke)!important; 
  border: solid 1px var(--smoke)!important; 
  -webkit-box-shadow: 0 2px 3px rgba(1, 1, 1, .1);
  -moz-box-shadow: 0 2px 3px rgba(1, 1, 1, .1);
  box-shadow: 0 2px 3px rgba(1, 1, 1, .1);
}

.theme--dark .wsmenu > .wsmenu-list > li > ul.sub-menu:before,
.theme--dark .wsmenu > .wsmenu-list > li.mg_link:hover > a:after,
.theme--dark .wsmenu > .wsmenu-list > li > .wsmegamenu.halfmenu:before {
  background-color: var(--ink);
  border-left: solid var(--ink);
  border-top: solid 1px var(--ink);
}

.theme--dark .wsmenu > .wsmenu-list > li > ul.sub-menu > li > a,
.theme--dark .wsmenu > .wsmenu-list > li > ul.sub-menu > li > ul.sub-menu > li > a,
.theme--dark .wsmenu > .wsmenu-list > li > .wsmegamenu.w-75 ul.link-list > li > a,
.theme--dark .wsmenu > .wsmenu-list > li > .wsmegamenu.halfmenu ul.link-list > li > a {
  color: var(--header-link);
  background-color: transparent!important;
}

.theme--dark .scroll .wsmenu > .wsmenu-list > li > ul.sub-menu > li > a,
.theme--dark .theme--dark .wsmenu > .wsmenu-list > li > ul.sub-menu > li > ul.sub-menu > li > a,
.theme--dark .scroll .wsmenu > .wsmenu-list > li > .wsmegamenu.w-75 ul.link-list > li > a,
.theme--dark .scroll .wsmenu > .wsmenu-list > li > .wsmegamenu.halfmenu ul.link-list > li > a {
  color: var(--header-link);
}

/*------------------------------------------*/
/*  NAVBAR SCROLL
/*------------------------------------------*/

.theme--dark .tra-menu .wsmainfull.scroll,
.theme--dark .white-menu .wsmainfull.scroll,
.theme--dark .dark-menu.dark-scroll .wsmainfull.scroll {
  background-color: var(--ink)!important;
  padding: 0;
  -webkit-box-shadow: 0 25px 50px -12px rgba(0, 0, 0, .25);
  -moz-box-shadow: 0 25px 50px -12px rgba(0, 0, 0, .25);
  box-shadow: 0 25px 50px -12px rgba(0, 0, 0, .25);
}

.theme--dark .tra-menu.navbar-dark .scroll .wsmenu > .wsmenu-list > li > a,
.theme--dark .tra-menu.navbar-light .scroll .wsmenu > .wsmenu-list > li > a,
.theme--dark .tra-menu.navbar-dark.white-scroll .scroll .wsmenu > .wsmenu-list > li > a.h-link, 
.theme--dark .tra-menu.navbar-light.white-scroll .scroll .wsmenu > .wsmenu-list > li > a.h-link {
  color: var(--header-link);
}

.theme--dark .navbar-light.white-scroll .scroll .wsmenu-list > li > a.h-link:hover {
  color: var(--white)!important;
}

.theme--dark .wsmenu > .wsmenu-list > li > ul.sub-menu > li > a:hover,
.theme--dark .wsmenu > .wsmenu-list > li > .wsmegamenu.w-75 ul.link-list > li > a:hover,
.theme--dark .wsmenu > .wsmenu-list > li > ul.sub-menu > li > ul.sub-menu > li > a:hover,
.theme--dark .wsmenu > .wsmenu-list > li > .wsmegamenu.halfmenu ul.link-list > li > a:hover {
  background-color: rgba(44, 49, 66, .72)!important;
  color: var(--white);
}

.theme--dark .navbar-light .scroll .btn.btn--tra-white {
  color: var(--white)!important;
  border-color: var(--white)!important;
  background-color: transparent!important;
}

/*------------------------------------------*/
/*  Logo Image
/*------------------------------------------*/

.theme--dark .navbar-dark .logo-black,
.theme--dark .navbar-dark .scroll .logo-black {
  display: none;
}

.theme--dark .navbar-dark .logo-white,
.theme--dark .navbar-dark .logo-black,
.theme--dark .navbar-dark .scroll .logo-white {
  display: block;
}

.theme--dark .navbar-dark .logo-white {
  display: none;
}

/*------------------------------------------*/
/*  HERO
/*------------------------------------------*/

.theme--dark .img-link a span {
  color: var(--black);
}

.theme--dark .img-link:hover a span {
  color: var(--white);
}

/*------------------------------------------*/
/*  FEATURES
/*------------------------------------------*/

.theme--dark .shape-ico.color--theme path,
.theme--dark .shape-ico.color--theme path,
.theme--dark .shape-ico.color--theme path {
  fill: rgba(43, 48, 64, .7);
}

.theme--dark #features-3.shape--whitesmoke:after,
.theme--dark #features-4.shape--whitesmoke:after {
  background-color: rgba(35, 35, 35, .2);
}

.theme--dark .fbox-3,
.theme--dark .fbox-4,
.theme--dark .fbox-5,
.theme--dark .fbox-6,
.theme--dark .fbox-9,
.theme--dark .fbox-10 {
  background-color: var(--white-smoke);
  border-color: var(--silver);
} 

/*------------------------------------------*/
/*  CONTENT
/*------------------------------------------*/

.theme--dark .cbox-1:hover .cbox-1-ico.bg--theme,
.theme--dark .cbox-1:hover .cbox-1-ico.bg--coal,
.theme--dark .cbox-1:hover .cbox-1-ico.bg--blue,
.theme--dark .cbox-1:hover .cbox-1-ico.bg--green,
.theme--dark .cbox-1:hover .cbox-1-ico.bg--indigo,
.theme--dark .cbox-1:hover .cbox-1-ico.bg--pink,
.theme--dark .cbox-1:hover .cbox-1-ico.bg--purple,
.theme--dark .cbox-1:hover .cbox-1-ico.bg--skyblue { color: var(--white); }

.theme--dark .bc-04-box.bg--white {
  background-color: var(--dark-theme-bg);
}

.theme--dark .bc-04-box,
.theme--dark .bc-01-wrapper,
.theme--dark .bc-02-wrapper {
  background-color: var(--white-smoke);
  border-color: var(--silver);
} 

/*------------------------------------------*/
/*  STATISTIC
/*------------------------------------------*/

.theme--dark #statistic-6 .statistic-block-txt:before {
  background: var(--tra-white);
}

/*------------------------------------------*/
/*  JOB POSITION
/*------------------------------------------*/

.theme--dark .position-info p span,
.theme--dark #job-position .inner-page-title p span {
  color: var(--white);
}

/*------------------------------------------*/
/*  PRICING
/*------------------------------------------*/

.theme--dark .pricing-1-table { 
  border-color: var(--silver);
}

.theme--dark .pricing-discount h6 {
  color: var(--black);
}

.theme--dark .pricing-features.ico--theme span { color: var(--theme-color); }
.theme--dark .pricing-features.ico--blue span { color: var(--blue); }
.theme--dark .pricing-features.ico--denim span { color: var(--denim); }
.theme--dark .pricing-features.ico--green span { color: var(--green); }
.theme--dark .pricing-features.ico--indigo span { color: var(--indigo); }
.theme--dark .pricing-features.ico--ocean span { color: var(--ocean); }
.theme--dark .pricing-features.ico--pink span { color: var(--pink); }
.theme--dark .pricing-features.ico--purple span { color: var(--purple); }
.theme--dark .pricing-features.ico--sky-blue span { color: var(--sky-blue); }

.theme--dark .table>:not(caption)>*>* {
  color: var(--white)!important;
}

.theme--dark .comp-table .table thead th {
  color: var(--white)!important;
}

.theme--dark .comp-table .table thead tr,
.theme--dark .comp-table .table tbody tr {
  border-bottom: 1px solid var(--silver)!important;
}

/*------------------------------------------*/
/*  RATING
/*------------------------------------------*/

.theme--dark .rbox-1 .star-rating {
  background-color: var(--white-smoke);
  border-color: var(--silver);
}

/*------------------------------------------*/
/*  REVIEWS
/*------------------------------------------*/

.theme--dark .review-3,
.theme--dark .review-5 {
   background-color: var(--white-smoke);
  border-color: var(--silver);
}

.theme--dark .owl-theme .owl-dots .owl-dot span {
  background: #3a3a3a;
}

.theme--dark .owl-theme .owl-dots .owl-dot.active span {
  background: var(--theme-color);
}

/*------------------------------------------*/
/*  INTEGRATIONS
/*------------------------------------------*/

.theme--dark .filter-btns button {
  color: var(--white);
}

.theme--dark .int_tool-1,
.theme--dark .int_tool-2,
.theme--dark .int_tool-3,
.theme--dark .int_tool-4 {
  background-color: var(--white-smoke);
  border-color: var(--smoke);
}

/*------------------------------------------*/
/*  BANNER
/*------------------------------------------*/

.theme--dark .banner-1-wrapper {
  border-color: var(--silver);
}

/*------------------------------------------*/
/*  FAQs
/*------------------------------------------*/

.theme--dark .accordion-panel,
.theme--dark .faqs-3-answer {
  color: var(--gray);
}

.theme--dark .more-questions-txt {
  border: 1px solid var(--silver);
}

.theme--dark .faqs-section .accordion-item .accordion-thumb:after,
.theme--dark .faqs-section .accordion-item.is-active .accordion-thumb:after {
  color: var(--gray);
}

.theme--dark .breadcrumb li,
.theme--dark .read-time span,
.theme--dark .updated-date span {
  color: var(--white);
}

/*------------------------------------------*/
/*  NEWSLETTER
/*------------------------------------------*/

.theme--dark .newsletter-section .form-control {
  background-color: var(--white-smoke);
}

.theme--dark #newsletter-2.newsletter-section .form-control {
  background-color: var(--white);
  color: var(--black);
}

.theme--dark .newsletter-section .form-control:focus {
  background-color: var(--dark-theme-bg);
  border-color: var(--gray);
}

/*------------------------------------------*/
/*  BLOG
/*------------------------------------------*/

.theme--dark .blog-post-txt h5 a,
.theme--dark .blog-post-txt h4 a,
.theme--dark .single-post-txt p span, 
.theme--dark .single-post-txt p a, 
.theme--dark .post-author-txt p a { 
  color: var(--white);
}

.theme--dark .blog-post-txt.color--white p {
  color: var(--gray);
}

.theme--dark .comment-form .form-control { 
  background-color: var(--white-smoke); 
  border-color: var(--silver); 
}

.theme--dark .comment-form .form-control:focus {
  background-color: var(--dark-theme-bg); 
  border-color: var(--gray); 
}

/*------------------------------------------*/
/*  CONTACTS
/*------------------------------------------*/

.theme--dark .contact-form .form-control,
.theme--dark .contact-form .form-select { 
  background-color: var(--white-smoke); 
  border-color: var(--silver); 
}

.theme--dark .contact-form .form-control:focus,
.theme--dark .contact-form .form-select:focus { 
  background-color: var(--dark-theme-bg); 
  border-color: var(--gray); 
}

/*------------------------------------------*/
/*  FOOTER
/*------------------------------------------*/

.theme--dark .footer-form .newsletter-form .input-group:focus-within {
  background-color: var(--dark-theme-bg)!important;
  border-color: var(--gray)!important;
}

/*------------------------------------------*/
/*  TERMS, PRIVACY, COOKIES PAGES
/*------------------------------------------*/

.theme--dark .legal-info p span {
  color: var(--white);
}

/*------------------------------------------*/
/*  SIGN IN / SIGN UP
/*------------------------------------------*/

.theme--dark .reset-password-form.bg--white {
  background-color: var(--dark-theme-bg);
}

.theme--dark .reset-page-wrapper .form-control,
.theme--dark .register-page-form .form-control {
  background-color: var(--white-smoke);
  border-color: var(--silver); 
}

.theme--dark .reset-page-wrapper .form-control:focus,
.theme--dark .register-page-form .form-control:focus {
  background-color: var(--dark-theme-bg);
  border-color: var(--gray);
}

/*------------------------------------------*/
/*  MODAL
/*------------------------------------------*/

.theme--dark .modal .modal-body-content h5 {
  color: #313435;
}

.theme--dark #modal-2 .modal-body-content p {
  color: #6c757d;
}

/*------------------------------------------*/
/*  STYLE CHANGER 
/*------------------------------------------*/

.theme--dark #stlChanger .chBody {
  background: #222737;
  border: 1px solid #222737;
}




/* ==========================================================================
  38. SCROLL TO TOP
  ========================================================================== */  
 
#scrollUp {
  display: none;
  width: 44px;
  height: 44px;
  position: fixed;
  bottom: 45px;
  right: 25px;
  -webkit-border-radius: 100%;
  -moz-border-radius: 100%;
  border-radius: 100%;
  background-image: url(~images/home/back-to-top.png);
  background-repeat: no-repeat;
  background-position: 50% 48%;
  background-color: rgba(10, 10, 10, .25);
  -webkit-transition: all 400ms ease-in-out;
  -moz-transition: all 400ms ease-in-out;
  -o-transition: all 400ms ease-in-out;
  -ms-transition: all 400ms ease-in-out;
  transition: all 400ms ease-in-out; 
}

#scrollUp:hover {
  background-color: rgba(0, 0, 0, .4);
}

nav a#pull {  
  display: none;  
}



