@font-face {
    font-family: "flaticon";
    src: url("~fonts/home/flaticon.woff2?992e96d75373fbbee2dc074409316eda") format("woff2"),
url("~fonts/home/flaticon.woff?992e96d75373fbbee2dc074409316eda") format("woff"),
url("~fonts/home/flaticon.eot?992e96d75373fbbee2dc074409316eda#iefix") format("embedded-opentype"),
url("~fonts/home/flaticon.ttf?992e96d75373fbbee2dc074409316eda") format("truetype"),
url("~fonts/home/flaticon.svg?992e96d75373fbbee2dc074409316eda#flaticon_soltech") format("svg")
}

span[class^="flaticon-"]:before, span[class*=" flaticon-"]:before {
  font-family: flaticon!important;
  font-style: normal;
  font-weight: normal!important;
  font-variant: normal;
  text-transform: none;
  font-size: 20px;
  line-height: 1!important;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}

.flaticon-labyrinth:before {
    content: "\f101";
}
.flaticon-coding-3:before {
    content: "\f102";
}
.flaticon-hexagon:before {
    content: "\f103";
}
.flaticon-chatbot:before {
    content: "\f104";
}
.flaticon-swords:before {
    content: "\f105";
}
.flaticon-presentation:before {
    content: "\f106";
}
.flaticon-proof-of-concept:before {
    content: "\f107";
}
.flaticon-paid-search:before {
    content: "\f108";
}
.flaticon-open-enrollment:before {
    content: "\f109";
}
.flaticon-mobile-app:before {
    content: "\f10a";
}
.flaticon-online-shopping:before {
    content: "\f10b";
}
.flaticon-smartphone:before {
    content: "\f10c";
}
.flaticon-network-1:before {
    content: "\f10d";
}
.flaticon-map:before {
    content: "\f10e";
}
.flaticon-layout-1:before {
    content: "\f10f";
}
.flaticon-pie-chart-1:before {
    content: "\f110";
}
.flaticon-megaphone:before {
    content: "\f111";
}
.flaticon-delegate:before {
    content: "\f112";
}
.flaticon-search:before {
    content: "\f113";
}
.flaticon-connection:before {
    content: "\f114";
}
.flaticon-coding-2:before {
    content: "\f115";
}
.flaticon-creative-process:before {
    content: "\f116";
}
.flaticon-creative-spark:before {
    content: "\f117";
}
.flaticon-fast-food:before {
    content: "\f118";
}
.flaticon-chat:before {
    content: "\f119";
}
.flaticon-group:before {
    content: "\f11a";
}
.flaticon-read:before {
    content: "\f11b";
}
.flaticon-innovation:before {
    content: "\f11c";
}
.flaticon-shield-2:before {
    content: "\f11d";
}
.flaticon-trophy:before {
    content: "\f11e";
}
.flaticon-album:before {
    content: "\f11f";
}
.flaticon-ecommerce:before {
    content: "\f120";
}
.flaticon-process:before {
    content: "\f121";
}
.flaticon-interface:before {
    content: "\f122";
}
.flaticon-computer-mouse:before {
    content: "\f123";
}
.flaticon-graphic:before {
    content: "\f124";
}
.flaticon-success:before {
    content: "\f125";
}
.flaticon-heartbeat:before {
    content: "\f126";
}
.flaticon-questioning:before {
    content: "\f127";
}
.flaticon-coding-1:before {
    content: "\f128";
}
.flaticon-responsive-design:before {
    content: "\f129";
}
.flaticon-icon-434833:before {
    content: "\f12a";
}
.flaticon-coding:before {
    content: "\f12b";
}
.flaticon-avatar:before {
    content: "\f12c";
}
.flaticon-flow-chart:before {
    content: "\f12d";
}
.flaticon-drop-down-menu:before {
    content: "\f12e";
}
.flaticon-lan:before {
    content: "\f12f";
}
.flaticon-puzzle:before {
    content: "\f130";
}
.flaticon-server:before {
    content: "\f131";
}
.flaticon-maintenance:before {
    content: "\f132";
}
.flaticon-wireframe:before {
    content: "\f133";
}
.flaticon-engineering:before {
    content: "\f134";
}
.flaticon-layers-1:before {
    content: "\f135";
}
.flaticon-analytics-1:before {
    content: "\f136";
}
.flaticon-algorithm-1:before {
    content: "\f137";
}
.flaticon-hierarchical-structure:before {
    content: "\f138";
}
.flaticon-mail:before {
    content: "\f139";
}
.flaticon-pie-graph:before {
    content: "\f13a";
}
.flaticon-api:before {
    content: "\f13b";
}
.flaticon-windows-1:before {
    content: "\f13c";
}
.flaticon-planet:before {
    content: "\f13d";
}
.flaticon-player:before {
    content: "\f13e";
}
.flaticon-analytics:before {
    content: "\f13f";
}
.flaticon-podium:before {
    content: "\f140";
}
.flaticon-investment-1:before {
    content: "\f141";
}
.flaticon-pay-per-click:before {
    content: "\f142";
}
.flaticon-network:before {
    content: "\f143";
}
.flaticon-networking:before {
    content: "\f144";
}
.flaticon-rotate:before {
    content: "\f145";
}
.flaticon-maximize-1:before {
    content: "\f146";
}
.flaticon-click:before {
    content: "\f147";
}
.flaticon-security:before {
    content: "\f148";
}
.flaticon-version:before {
    content: "\f149";
}
.flaticon-calendar-2:before {
    content: "\f14a";
}
.flaticon-prioritize:before {
    content: "\f14b";
}
.flaticon-mechanics:before {
    content: "\f14c";
}
.flaticon-language:before {
    content: "\f14d";
}
.flaticon-color-palette:before {
    content: "\f14e";
}
.flaticon-pension:before {
    content: "\f14f";
}
.flaticon-cloud:before {
    content: "\f150";
}
.flaticon-around-the-world:before {
    content: "\f151";
}
.flaticon-screenplay:before {
    content: "\f152";
}
.flaticon-data-center:before {
    content: "\f153";
}
.flaticon-profits:before {
    content: "\f154";
}
.flaticon-shopping-cart:before {
    content: "\f155";
}
.flaticon-affiliate:before {
    content: "\f156";
}
.flaticon-growth-1:before {
    content: "\f157";
}
.flaticon-partner:before {
    content: "\f158";
}
.flaticon-wallet-1:before {
    content: "\f159";
}
.flaticon-search-engine:before {
    content: "\f15a";
}
.flaticon-browser:before {
    content: "\f15b";
}
.flaticon-folder-2:before {
    content: "\f15c";
}
.flaticon-money:before {
    content: "\f15d";
}
.flaticon-idea:before {
    content: "\f15e";
}
.flaticon-training:before {
    content: "\f15f";
}
.flaticon-calendar-1:before {
    content: "\f160";
}
.flaticon-chronometer:before {
    content: "\f161";
}
.flaticon-investment:before {
    content: "\f162";
}
.flaticon-suit:before {
    content: "\f163";
}
.flaticon-online:before {
    content: "\f164";
}
.flaticon-maximize:before {
    content: "\f165";
}
.flaticon-visionary:before {
    content: "\f166";
}
.flaticon-pie-chart:before {
    content: "\f167";
}
.flaticon-diagram:before {
    content: "\f168";
}
.flaticon-graphics:before {
    content: "\f169";
}
.flaticon-business-report:before {
    content: "\f16a";
}
.flaticon-excel:before {
    content: "\f16b";
}
.flaticon-key-value-database:before {
    content: "\f16c";
}
.flaticon-workflow-1:before {
    content: "\f16d";
}
.flaticon-manager:before {
    content: "\f16e";
}
.flaticon-workflow:before {
    content: "\f16f";
}
.flaticon-growth:before {
    content: "\f170";
}
.flaticon-rocket-launch:before {
    content: "\f171";
}
.flaticon-algorithm:before {
    content: "\f172";
}
.flaticon-layers:before {
    content: "\f173";
}
.flaticon-loading:before {
    content: "\f174";
}
.flaticon-infographics:before {
    content: "\f175";
}
.flaticon-insult:before {
    content: "\f176";
}
.flaticon-start-here:before {
    content: "\f177";
}
.flaticon-price-label:before {
    content: "\f178";
}
.flaticon-target:before {
    content: "\f179";
}
.flaticon-return-on-investment:before {
    content: "\f17a";
}
.flaticon-device:before {
    content: "\f17b";
}
.flaticon-configuration:before {
    content: "\f17c";
}
.flaticon-brush:before {
    content: "\f17d";
}
.flaticon-swap:before {
    content: "\f17e";
}
.flaticon-diamond:before {
    content: "\f17f";
}
.flaticon-shield-1:before {
    content: "\f180";
}
.flaticon-shield:before {
    content: "\f181";
}
.flaticon-database:before {
    content: "\f182";
}
.flaticon-terminal:before {
    content: "\f183";
}
.flaticon-layout:before {
    content: "\f184";
}
.flaticon-git-1:before {
    content: "\f185";
}
.flaticon-gauge:before {
    content: "\f186";
}
.flaticon-git:before {
    content: "\f187";
}
.flaticon-filter:before {
    content: "\f188";
}
.flaticon-edit:before {
    content: "\f189";
}
.flaticon-open-mail:before {
    content: "\f18a";
}
.flaticon-bottom-alignment:before {
    content: "\f18b";
}
.flaticon-link:before {
    content: "\f18c";
}
.flaticon-tablet:before {
    content: "\f18d";
}
.flaticon-users-avatar:before {
    content: "\f18e";
}
.flaticon-paperclip:before {
    content: "\f18f";
}
.flaticon-chart-pie:before {
    content: "\f190";
}
.flaticon-save-file:before {
    content: "\f191";
}
.flaticon-swatchbook:before {
    content: "\f192";
}
.flaticon-shuffle:before {
    content: "\f193";
}
.flaticon-ring-bell:before {
    content: "\f194";
}
.flaticon-folder-1:before {
    content: "\f195";
}
.flaticon-bag:before {
    content: "\f196";
}
.flaticon-report:before {
    content: "\f197";
}
.flaticon-screen:before {
    content: "\f198";
}
.flaticon-paragraph:before {
    content: "\f199";
}
.flaticon-app:before {
    content: "\f19a";
}
.flaticon-wallet:before {
    content: "\f19b";
}
.flaticon-cash-money:before {
    content: "\f19c";
}
.flaticon-bulb:before {
    content: "\f19d";
}
.flaticon-extension:before {
    content: "\f19e";
}
.flaticon-gear:before {
    content: "\f19f";
}
.flaticon-suitcase:before {
    content: "\f1a0";
}
.flaticon-bill:before {
    content: "\f1a1";
}
.flaticon-gesture:before {
    content: "\f1a2";
}
.flaticon-3:before {
    content: "\f1a3";
}
.flaticon-icon-8094020:before {
    content: "\f1a4";
}
.flaticon-2:before {
    content: "\f1a5";
}
.flaticon-1:before {
    content: "\f1a6";
}
.flaticon-088:before {
    content: "\f1a7";
}
.flaticon-tag:before {
    content: "\f1a8";
}
.flaticon-calendar:before {
    content: "\f1a9";
}
.flaticon-folder:before {
    content: "\f1aa";
}
.flaticon-question-mark:before {
    content: "\f1ab";
}
.flaticon-home:before {
    content: "\f1ac";
}
.flaticon-email-1:before {
    content: "\f1ad";
}
.flaticon-location-pin-1:before {
    content: "\f1ae";
}
.flaticon-printer:before {
    content: "\f1af";
}
.flaticon-bookmark-1:before {
    content: "\f1b0";
}
.flaticon-comment:before {
    content: "\f1b1";
}
.flaticon-heart-1:before {
    content: "\f1b2";
}
.flaticon-edit-info:before {
    content: "\f1b3";
}
.flaticon-share-post:before {
    content: "\f1b4";
}
.flaticon-history:before {
    content: "\f1b5";
}
.flaticon-user:before {
    content: "\f1b6";
}
.flaticon-message:before {
    content: "\f1b7";
}
.flaticon-right-arrow:before {
    content: "\f1b8";
}
.flaticon-arrows:before {
    content: "\f1b9";
}
.flaticon-padlock:before {
    content: "\f1ba";
}
.flaticon-email:before {
    content: "\f1bb";
}
.flaticon-location-pin:before {
    content: "\f1bc";
}
.flaticon-phone-call:before {
    content: "\f1bd";
}
.flaticon-ellipsis:before {
    content: "\f1be";
}
.flaticon-check-1:before {
    content: "\f1bf";
}
.flaticon-minus-1:before {
    content: "\f1c0";
}
.flaticon-plus-1:before {
    content: "\f1c1";
}
.flaticon-minus:before {
    content: "\f1c2";
}
.flaticon-plus:before {
    content: "\f1c3";
}
.flaticon-cancel:before {
    content: "\f1c4";
}
.flaticon-caret-arrow-up:before {
    content: "\f1c5";
}
.flaticon-sort-down:before {
    content: "\f1c6";
}
.flaticon-sort-arrows-couple-pointing-up-and-down:before {
    content: "\f1c7";
}
.flaticon-diskette:before {
    content: "\f1c8";
}
.flaticon-upload:before {
    content: "\f1c9";
}
.flaticon-download:before {
    content: "\f1ca";
}
.flaticon-send:before {
    content: "\f1cb";
}
.flaticon-bookmark:before {
    content: "\f1cc";
}
.flaticon-double-quotes:before {
    content: "\f1cd";
}
.flaticon-next:before {
    content: "\f1ce";
}
.flaticon-down-arrow:before {
    content: "\f1cf";
}
.flaticon-up-arrow:before {
    content: "\f1d0";
}
.flaticon-quote:before {
    content: "\f1d1";
}
.flaticon-ring:before {
    content: "\f1d2";
}
.flaticon-circle:before {
    content: "\f1d3";
}
.flaticon-heart:before {
    content: "\f1d4";
}
.flaticon-play:before {
    content: "\f1d5";
}
.flaticon-play-button:before {
    content: "\f1d6";
}
.flaticon-invisible:before {
    content: "\f1d7";
}
.flaticon-visibility:before {
    content: "\f1d8";
}
.flaticon-check:before {
    content: "\f1d9";
}
.flaticon-reply-arrow:before {
    content: "\f1da";
}
.flaticon-magnifying-glass:before {
    content: "\f1db";
}
.flaticon-half-star-shape:before {
    content: "\f1dc";
}
.flaticon-star-1:before {
    content: "\f1dd";
}
.flaticon-star-half-empty:before {
    content: "\f1de";
}
.flaticon-star:before {
    content: "\f1df";
}
.flaticon-codepen:before {
    content: "\f1e0";
}
.flaticon-stack-overflow:before {
    content: "\f1e1";
}
.flaticon-telegram:before {
    content: "\f1e2";
}
.flaticon-kakao-talk:before {
    content: "\f1e3";
}
.flaticon-line:before {
    content: "\f1e4";
}
.flaticon-instagram:before {
    content: "\f1e5";
}
.flaticon-pinterest-logo:before {
    content: "\f1e6";
}
.flaticon-linkedin-logo:before {
    content: "\f1e7";
}
.flaticon-js:before {
    content: "\f1e8";
}
.flaticon-python:before {
    content: "\f1e9";
}
.flaticon-linux-platform:before {
    content: "\f1ea";
}
.flaticon-sketch:before {
    content: "\f1eb";
}
.flaticon-twitter-1:before {
    content: "\f1ec";
}
.flaticon-slack:before {
    content: "\f1ed";
}
.flaticon-wordpress:before {
    content: "\f1ee";
}
.flaticon-open-source:before {
    content: "\f1ef";
}
.flaticon-paypal:before {
    content: "\f1f0";
}
.flaticon-html-5:before {
    content: "\f1f1";
}
.flaticon-css-3:before {
    content: "\f1f2";
}
.flaticon-windows:before {
    content: "\f1f3";
}
.flaticon-apple:before {
    content: "\f1f4";
}
.flaticon-dropbox:before {
    content: "\f1f5";
}
.flaticon-messenger:before {
    content: "\f1f6";
}
.flaticon-google-drive:before {
    content: "\f1f7";
}
.flaticon-dribbble:before {
    content: "\f1f8";
}
.flaticon-skype:before {
    content: "\f1f9";
}
.flaticon-github:before {
    content: "\f1fa";
}
.flaticon-youtube:before {
    content: "\f1fb";
}
.flaticon-whatsapp:before {
    content: "\f1fc";
}
.flaticon-twitter:before {
    content: "\f1fd";
}
.flaticon-facebook:before {
    content: "\f1fe";
}

