



/*
  Template Name: SolTech - Premium Marketing Landing Pages Pack
  Theme URL: https://themeforest.net/user/dsathemes
  Description: SolTech - Premium Marketing Landing Pages Pack
  Author: DSAThemes
  Author URL: https://themeforest.net/user/dsathemes
  Version: 1.2.0
  Website: www.dsathemes.com
  Tags: HTML5, DSAThemes, Creative, Agency, Digital Business, IT Solutions, Digital Product, Marketinge, SaaS, Startup
*/




/* ------------------------------------------------------------------------ */
/* ------------------------------------------------------------------------ */
/* ------------------------------------------------------------------------ */

@media (min-width: 1399px) {

  /* SIGN IN / SIGN UP */
  #login, #signup { padding-top: 200px; padding-bottom: 220px; }
  .login-1 .register-page-form { padding-left: 30%; }
  .signup-1 .register-page-form { padding-right: 30%; }


}




/* ------------------------------------------------------------------------ */
/* ------------------------------------------------------------------------ */
/* ------------------------------------------------------------------------ */

@media (min-width: 1200px) {

  /*------------------------------------------*/
  /*  BASE
  /*------------------------------------------*/

  html { font-size: 115.6%; } 

  /* Button */
  .btn { padding: 0.8rem 1.7rem; }
  
  .btn.ico-30.ico-left { padding: 0.286rem 0; }
  .btns-group .btn.ico-30.ico-left { padding: 0.286rem 0.5rem; }
  .btn.ico-20 [class*="flaticon-"]:before, .btn.ico-20 [class*="flaticon-"]:after { top: 3px; }

  /* Watch Video Link  */
  .watch-video-link { width: 2.8rem; height: 2.8rem; }
  .watch-video-link span[class^="flaticon-"]:before, 
  .watch-video-link span[class^="flaticon-"]:after { font-size: 1.75rem; line-height: 2.8rem!important; }

  /*------------------------------------------*/
  /*  NAVIGATION MENU    
  /*------------------------------------------*/

  /* Nav Link */
  .wsmenu > .wsmenu-list > li > a { font-size: 0.9375rem; }

  /* Button */
  .wsmenu > .wsmenu-list > li a.btn { font-size: 0.9rem; line-height: 28px; padding: 6px 22px 8px; margin: 12px 5px 0; }
  .wsmenu > .wsmenu-list > li a.btn.reg-btn { padding: 4px 20px 6px; }

  /* SubMenu, HalfMenu, MegaMenu */
  .wsmenu > .wsmenu-list > li > ul.sub-menu > li > a, 
  .wsmenu > .wsmenu-list > li > ul.sub-menu > li > ul.sub-menu > li > a,
  .wsmenu > .wsmenu-list > li > .wsmegamenu.w-75 ul.link-list > li > a,
  .wsmenu > .wsmenu-list > li > .wsmegamenu.halfmenu ul.link-list > li > a { font-size: 0.9rem; }

  /*------------------------------------------*/
  /*  CONTENT
  /*------------------------------------------*/

  /* FOOTER */
  .footer p { font-size: 0.95rem; }
  .footer-form .form-control { font-size: 0.95rem; }


}




/* ------------------------------------------------------------------------ */
/* ------------------------------------------------------------------------ */
/* ------------------------------------------------------------------------ */

@media (min-width: 992px) and (max-width: 1199.95px) {

  /*------------------------------------------*/
  /*  BASE
  /*------------------------------------------*/

  .py-60 { padding: 50px 0; }
  .py-70 { padding: 60px 0; }
  .py-80 { padding: 60px 0; }
  .py-90 { padding: 70px 0; }
  .py-100 { padding: 80px 0; }

  .pt-30 { padding-top: 24px; }
  .pt-40 { padding-top: 30px; }
  .pt-50 { padding-top: 40px; }
  .pt-60 { padding-top: 50px; }
  .pt-70 { padding-top: 60px; }
  .pt-80 { padding-top: 60px; }
  .pt-90 { padding-top: 70px; }
  .pt-100 { padding-top: 80px; }

  .pb-30 { padding-bottom: 24px; }
  .pb-40 { padding-bottom: 30px; }
  .pb-50 { padding-bottom: 40px; }
  .pb-60 { padding-bottom: 50px; }
  .pb-70 { padding-bottom: 60px; }
  .pb-80 { padding-bottom: 60px; }
  .pb-90 { padding-bottom: 70px; }
  .pb-100 { padding-bottom: 80px; }

  .mt-30 { margin-top: 24px; }
  .mt-40 { margin-top: 30px; }
  .mt-45 { margin-top: 35px; }
  .mt-50 { margin-top: 40px; }
  .mt-55 { margin-top: 45px; }
  .mt-60 { margin-top: 50px; }
  .mt-65 { margin-top: 55px; }
  .mt-70 { margin-top: 60px; }
  .mt-75 { margin-top: 60px; }
  .mt-80 { margin-top: 60px; }

  .mb-30 { margin-bottom: 24px; }
  .mb-40 { margin-bottom: 30px; }
  .mb-45 { margin-bottom: 35px; }
  .mb-50 { margin-bottom: 40px; }
  .mb-55 { margin-bottom: 45px; }
  .mb-60 { margin-bottom: 50px; }
  .mb-65 { margin-bottom: 55px; }
  .mb-70 { margin-bottom: 60px; }
  .mb-75 { margin-bottom: 60px; }
  .mb-80 { margin-bottom: 60px; }

  .bg--shape-top:after { width: 500px; height: 500px; top: -8%; right: 10%; background-color: rgba(119, 67, 142, .15); }

  /* Headers */
  h6 { font-size: 1rem; }              /* 16px */
  h6.h6-md { font-size: 1.125rem; }    /* 18px */
  h6.h6-lg { font-size: 1.18755rem; }  /* 19px */
  h6.h6-xl { font-size: 1.25rem; }     /* 20px */

  h5 { font-size: 1.3125rem; }         /* 21px */
  h5.h5-md { font-size: 1.375rem; }    /* 22px */
  h5.h5-lg { font-size: 1.5rem; }      /* 24px */
  h5.h5-xl { font-size: 1.625rem; }    /* 26px */

  h4 { font-size: 1.75rem; }           /* 28px */
  h4.h4-md { font-size: 1.875rem; }    /* 30px */
  h4.h4-lg { font-size: 2rem; }        /* 32px */
  h4.h4-xl { font-size: 2.125rem; }    /* 34px */

  h3 { font-size: 2.25rem; }           /* 36px */
  h3.h3-md { font-size: 2.3125rem; }   /* 37px */
  h3.h3-lg { font-size: 2.4375rem; }   /* 39px */  
  h3.h3-xl { font-size: 2.5rem; }      /* 40px */

  h2 { font-size: 2.625rem; }          /* 42px */
  h2.h2-md { font-size: 2.75rem; }     /* 44px */ 
  h2.h2-lg { font-size: 2.875rem; }    /* 46px */ 
  h2.h2-xl { font-size: 3rem; }        /* 48px */ 
  h2.h2-title { font-size: 3.125rem; } /* 50px */ 

  h6, h5, h4, h3, h2, h1 { line-height: 1.35; }

  /* Button */
  .btn.btn-sm { font-size: 0.975rem; padding: 0.65rem 1.4rem; }
  .btn.ico-30.ico-left { padding: 0.3845rem 0; }
  .btns-group .btn.ico-30.ico-left, .btns-group .btn.ico-30.ico-right { padding: 0.3845rem 0.5rem; }

  /* Buttons Group */
  .btns-group .btn:first-child { margin-right: 12px; }

  /* Button Icon */
  .btn.ico-20 [class*="flaticon-"]:before, 
  .btn.ico-20 [class*="flaticon-"]:after { font-size: 1.15rem; line-height: 0.5rem!important;top: 3.5px; }

  .btn.ico-30.ico-left span.ico-30-txt { margin-right: 0; }
  .btn.ico-30.ico-right span.ico-30-txt { margin-left: 0; }
  .btn.ico-left span { margin-right: 4px; }
  .btn.ico-right span { margin-left: 4px; }

  /* Button Text */
  p.btn-txt, p.btns-group-txt { margin: 12px 0 0 0; }
  .text-center p.btns-group-txt { margin-top: 18px; }
  .btn-rating { margin: 15px 0 0 0; }

  /* Transparent Link */
  a.tra-link { font-size: 1.03125rem; }
  .tra-link.ico-20 [class*="flaticon-"]:before, 
  .tra-link.ico-20 [class*="flaticon-"]:after { font-size: 0.785rem; line-height: 0.7rem!important; top: 2px; left: 2px; }

  /* Watch Video Link */
  .watch-video-link { margin-right: 10px; }
  p.video-txt-lg { font-size: 1.125rem; }
  p.video-txt-sm { font-size: 1rem; }

  /* Vector Icon Background */ 
  .ico-50 .shape-ico svg { width: 110px; height: 110px; top: -30px; left: calc(50% - 55px); }
  .ico-55 .shape-ico svg { width: 115px; height: 115px; top: -30px; left: calc(50% - 60px); }
  .ico-60 .shape-ico svg { width: 120px; height: 120px; top: -25px; left: calc(50% - 55px); }

  /* Vector Rounded Icons */
  .ico-rounded { width: 74px; height: 74px; }
  .ico-rounded-md { width: 80px; height: 80px; }
  .ico-rounded-lg { width: 90px; height: 90px; }

  .ico-rounded [class*="flaticon-"]:before, 
  .ico-rounded [class*="flaticon-"]:after { font-size: 2.35rem; line-height: 74px!important; }

  .ico-rounded-md [class*="flaticon-"]:before, 
  .ico-rounded-md [class*="flaticon-"]:after { font-size: 2.5rem; line-height: 80px!important; }

  .ico-rounded-lg [class*="flaticon-"]:before, 
  .ico-rounded-lg [class*="flaticon-"]:after { font-size: 2.875rem; line-height: 90px!important; }

  /* PNG Icons */
  .img-80 img { width: 70px; height: 70px; }
  .img-75 img { width: 65px; height: 65px; }
  .img-70 img, .img-65 img, .img-60 img { width: 63px; height: 63px; }

  /* Section ID */
  .section-id { font-size: 0.9rem; margin-bottom: 25px; }

  /* Section Title */
  .section-title.mb-70 { margin-bottom: 55px; }
  .section-title p { margin-top: 16px; }
  .section-title .btns-group { margin-top: 28px; }

  /* Users */
  .txt-block .users { margin-top: 16px; }
  .users img { max-height: 40px; }
  .users p { font-size: 0.95rem; top: 12px; left: 10px; }

  /*------------------------------------------*/
  /*  NAVIGATION MENU    
  /*------------------------------------------*/

  /* Header Logo */
  .desktoplogo img { max-height: 50px; }

  /* Nav Link */
  .wsmenu > .wsmenu-list > li > a { font-size: 0.9875rem; margin: 0 5px; padding: 10px 15px; }

  /* Nav Link Info */
  span.sm-info { width: 18px; height: 18px; font-size: 0.725rem; line-height: 17px; margin-left: 5px; }

  /* Button */
  .wsmenu > .wsmenu-list > li a.btn { font-size: 0.95rem; line-height: 28px; margin-top: 15px; padding: 3px 22px 5px; }
  .wsmenu > .wsmenu-list > li a.btn.reg-btn { padding: 3px 20px 5px; }

  .wsmenu > .wsmenu-list > li.lang { margin-right: 12px; }
  .wsmenu > .wsmenu-list > li.lang > a { padding: 10px 14px 10px 6px; }
  .wsmenu > .wsmenu-list > li.lang > ul.sub-menu { min-width: 110px!important; padding: 8px 5px; }
  .wsmenu > .wsmenu-list > li.lang > ul.sub-menu > li > a { font-size: 0.95rem; }

  .wsmenu > .wsmenu-list > li a.store.header-store { margin-top: 15px; margin-left: 0; }
  .store.header-store img { max-height: 40px; }

  .header-socials a { margin-left: 13px; }
  .header-socials.ico-20 [class*="flaticon-"]:before, .header-socials.ico-20 [class*="flaticon-"]:after { font-size: 1.175rem; } 

  /* SubMenu, HalfMenu, MegaMenu */
  .wsmenu > .wsmenu-list > li > ul.sub-menu { min-width: 175px; padding: 6px; }
  .wsmenu > .wsmenu-list > li > ul.sub-menu > li > ul.sub-menu {min-width: 180px; padding: 6px; }
  .wsmenu > .wsmenu-list > li > .wsmegamenu.halfmenu { width: 35%; padding: 6px 0; }
  .wsmenu > .wsmenu-list > li > .wsmegamenu.w-75 { width: 76%!important; left: 22%; padding: 12px 5px; }

  .wsmenu > .wsmenu-list > li > ul.sub-menu > li > a, 
  .wsmenu > .wsmenu-list > li > .wsmegamenu.w-75 ul.link-list > li > a,
  .wsmenu > .wsmenu-list > li > .wsmegamenu.halfmenu ul.link-list > li > a { font-size: 0.95rem; padding: 9px 12px; }
  .wsmenu > .wsmenu-list > li > ul.sub-menu > li > ul.sub-menu > li > a { font-size: 0.935rem; padding: 9px 12px; }

  .wsmenu > .wsmenu-list > li > ul.sub-menu > li > a:hover, 
  .wsmenu > .wsmenu-list > li > ul.sub-menu > li > ul.sub-menu > li > a:hover,
  .wsmenu > .wsmenu-list > li > .wsmegamenu.w-75 ul.link-list > li > a:hover,
  .wsmenu > .wsmenu-list > li > .wsmegamenu.halfmenu ul.link-list > li > a:hover { padding: 9px 12px; }

  .wsmenu > .wsmenu-list > li > ul.sub-menu > li > a span { top: 9px; right: 10px; }

  /*------------------------------------------*/
  /*  HERO   
  /*------------------------------------------*/

  .hero-section.blur--purple:after { height: 90%; top: -40%; left: 10%; }

  .slideshow, .slideshow .slides { height: 580px; }
  #hero-20 .slideshow, #hero-20 .slideshow .slides { height: 560px; }

  .img-link { bottom: 24px; right: 24px; width: 48px; height: 48px; }
  .img-link a span { top: 5.5px; }

  /* HERO QUICK FORM */
  .hero-section .quick-form .form-control, .hero-section .quick-form .btn { height: 60px; font-size: 1.15rem; }
  .hero-section .quick-form.form-half .form-control { height: 54px; font-size: 1rem; padding: 0 14px; }
  .hero-section .quick-form.form-half .btn { height: 54px; font-size: 0.985rem; padding: 13px 35px; margin-left: 10px; }

  .hero-section .text-center .quick-form-msg { margin: 20px 0 -5px 0; }

  /* HERO DIGITS */
  .hero-digits { padding-right: 10px; }
  .hero-digits h2.statistic-number { font-size: 3.15rem; }
  .hero-digits h2.statistic-number small { font-size: 2rem; }

  /* HERO-1 */
  #hero-1 { padding-bottom: 80px; }
  .hero-1-txt h2 { font-size: 3.25rem; line-height: 1.3; }
  .hero-1-txt p.p-lg, .hero-1-txt p.p-xl { margin: 20px 0 25px; }
  .hero-1-img { margin: 0 0 0 -10px; }

  /* HERO-2 */
  #hero-2 { padding-top: 150px; }
  .hero-2-txt h2 { font-size: 3.15rem; }
  .hero-2-txt p.p-lg { padding: 0; margin-top: 15px; margin-bottom: 25px; }
  .hero-2-img { margin-top: 50px; padding: 0 2%; }

  /* HERO-3 */
  #hero-3 { padding-top: 140px; padding-bottom: 80px; }
  .hero-3-txt { padding-right: 3%; }
  .hero-3-txt h2 { font-size: 3.15rem; }
  .hero-3-txt p.p-md { padding-right: 0; margin-top: 15px; }
  .hero-3-txt .users { margin-top: 15px; }
  .hero-3-img { padding-right: 25px; }

  /* HERO-4 */
  #hero-4 { padding-top: 150px; padding-bottom: 40px; }
  .hero-4-txt p.p-lg { margin: 20px 0 25px; }
  .hero-4-img { margin: 0 -95% 0 10px; }

  /* HERO-5 */
  #hero-5 { padding-top: 120px; padding-bottom: 60px; }
  #hero-5:after { width: 40%; }
  .hero-5-txt { margin-left: 0; }
  .hero-5-txt h2 { font-size: 3.125rem; line-height: 1.3; }
  .hero-5-txt p.p-md { padding-right: 0%; margin: 20px 0 25px; }

  /* HERO-6 */
  #hero-6 { padding-top: 150px; padding-bottom: 90px; }
  .hero-6-txt h2 { font-size: 3.1rem; padding-right: 5%; }
  .hero-6-txt p.p-lg { margin-top: 20px; }
  #hero-6 .quick-form { margin-top: 25px; margin-right: 3%; }
  .hero-6-img { padding-left: 10px; }

  /* HERO-7 */
  #hero-7 { padding-top: 130px; }
  .hero-7-txt { margin-top: -20px; }
  .hero-7-txt h2 { font-size: 3.5rem; line-height: 1.3; padding-right: 1%; }
  .hero-7-txt p.p-lg, .hero-7-txt p.p-xl { margin: 18px 0 25px; }
  .hero-7-img { margin: 0 0 0 -10px; }

  /* HERO-8 */
  #hero-8 { padding-top: 150px; }
  .hero-8-txt h2 { font-size: 3.15rem; }
  .hero-8-txt p.p-lg { padding: 0; margin-top: 15px; margin-bottom: 25px; }
  .hero-8-txt p.btn-txt { margin-top: 25px; }
  #hero-8 .quick-form { margin: 30px 9% 0; }
  .hero-8-img { margin: 50px 15px 0; }

  /* HERO-9 */
  #hero-9 { padding-top: 150px; padding-bottom: 70px; }
  .hero-9-txt {margin-top: -30px; }
  .hero-9-txt h2 { font-size: 3.1rem; padding-right: 5%; }
  .hero-9-txt p.p-lg { margin-top: 20px; }
  #hero-9 .quick-form { margin-top: 25px; margin-right: 3%; }
  .hero-9-img { padding-right: 10px; }

  /* HERO-10 */
  #hero-10 { padding-top: 150px; }
  .hero-10-txt { margin-bottom: 50px; }
  .hero-10-txt h2 { font-size: 3.15rem; padding: 0 3%; }
  .hero-10-txt p.p-lg { padding: 0; margin-top: 15px; margin-bottom: 25px; }
  #hero-10 .hero-overlay { padding: 80px 60px 0; }

  /* HERO-11 */
  #hero-11 { padding-bottom: 80px; }
  .hero-11-txt h2 { font-size: 3.25rem; line-height: 1.3; }
  .hero-11-txt p.p-md { margin: 20px 0 25px; padding-right: 0; }
  .hero-11-img { margin-left: -15px; }

  /* HERO-12 */
  .hero-12-wrapper { padding: 70px 50px; }
  .hero-12-txt h2 { font-size: 2.9rem; }
  .hero-12-txt p.p-lg { margin-top: 18px; margin-bottom: 25px; }
 
  /* HERO-13 */
  #hero-13 { padding-top: 150px; }
  .hero-13-txt h2 { font-size: 3.15rem; }
  .hero-13-txt p.p-xl { padding: 0 5%; margin-top: 16px; }
  #hero-13 .quick-form { margin: 30px 13% 0; }
  .hero-13-txt p.btn-txt { margin-top: 30px; }

 /* HERO-14 */
  #hero-14 { padding-top: 140px; }
  #hero-14:after { height: 6%; }
  .hero-14-txt { margin-top: -75px; padding-right: 0; }
  .hero-14-txt .section-id { padding: 10px 20px; font-size: 0.845rem; margin-bottom: 30px; }
  .hero-14-txt h2 { font-size: 3.25rem; line-height: 1.3; }
  .hero-14-txt p.p-md { margin-top: 25px; margin-bottom: 20px; }
  .hero-14-img { margin: 0 -75% 0 0; }

  /* HERO-15 */
  #hero-15 .hero-overlay { padding-top: 150px; padding-bottom: 80px; }
  .hero-15-txt h2 { font-size: 3.15rem; }
  .hero-15-txt p.p-lg { padding: 0 3%; margin-top: 15px; margin-bottom: 24px; }

  /* HERO-16 */
  #hero-16 { padding-top: 150px; padding-bottom: 90px; }
  .hero-16-txt h2 { font-size: 3.25rem; line-height: 1.3; }
  .hero-16-txt p.p-lg { margin-top: 20px; }
  #hero-16 .quick-form { margin-top: 25px; margin-right: 3%; }
  #hero-16 .users { margin-top: 16px; }
  .hero-16-img { padding-left: 10px; }

  /* HERO-17 */
  #hero-17 { padding-top: 150px; }
  .hero-17-txt h2 { font-size: 3.25rem; padding: 0; }
  .hero-17-txt p.p-xl { padding: 0 4%;  margin-top: 16px; }
  .hero-17-txt .btns-group { margin-top: 26px; }
  .hero-17-img { margin-top: 60px; }

  /* HERO-18 */
  #hero-18 { padding-top: 170px; }
  .hero-18-txt { margin-bottom: 70px; }
  .hero-18-txt .section-id { margin-bottom: 30px; }
  .hero-18-txt h2 { font-size: 3.5rem; padding: 0 3%; }
  .hero-18-txt p.p-xl { padding: 0; margin-top: 16px; }
  .hero-18-txt .btns-group { margin-top: 25px; }

  .h18-1 { margin-bottom: 24px; }

  .hero-bg-shape { width: 90%; height: 100%; left: 5%; right: 5%; opacity: .25; }

  /* HERO-19 */
  #hero-19 { padding-top: 150px; }
  .hero-19-txt h2 { font-size: 3.15rem; }
  .hero-19-txt p.p-xl { padding: 0 5%; margin-top: 16px; }
  #hero-19 .quick-form { margin: 30px 13% 0; }
  .hero-19-txt p.btn-txt { margin-top: 30px; }
  .hero-19-img { margin: 50px 10px 0; }

  /* HERO-20 */
  #hero-20 .caption h2 { font-size: 3.75rem; padding: 0 5%; }
  #hero-20 .caption p.p-xl { padding: 0 8%; margin-top: 18px; }
  #hero-20 .caption .btn { margin-top: 30px; }

  /* HERO-21 */
  #hero-21 { padding-top: 150px; }
  .hero-21-shape { width: 76%; top: 5%; left: 12%; opacity: .6; }
  .hero-21-txt h2 { font-size: 3.25rem; padding: 0; }
  .hero-21-txt p.p-xl { padding: 0 8%;  margin-top: 16px; }
  .hero-21-txt .btns-group { margin-top: 26px; }
  .hero-21-img { margin-top: 60px; }

  /* HERO-22 */
  #hero-22 .caption { margin-top: 50px; }
  #hero-22 .caption h2 { font-size: 3.75rem; padding: 0 5%; }
  #hero-22 .caption p.p-xl { padding: 0 8%; margin-top: 18px; }
  #hero-22 .caption .btn { margin-top: 30px; }

  /* HERO-23 */
  #hero-23 { padding-top: 150px; }
  .hero-23-txt h2 { font-size: 3.15rem; }
  .hero-23-txt p.p-lg { padding: 0; margin-top: 15px; margin-bottom: 25px; }
  .hero-23-img { margin-top: 50px; padding: 0 2%; }

  /* HERO-24 */
  #hero-24 { padding-top: 160px; padding-bottom: 30px; }
  .hero-24-txt { margin-top: -50px; padding-left: 15px; }
  .hero-24-txt h2 { font-size: 3rem; }
  .hero-24-txt p.p-lg, .hero-24-txt .users { margin-top: 20px; }

  #hero-24-form { padding: 28px 22px; margin-left: 0; }
  #hero-24-form h5 { margin-bottom: 10px; }
  #hero-24-form p { margin-bottom: 16px; }
  #hero-24-form .btn { height: 54px; padding: 16px; }

  /* HERO-25 */
  #hero-25 { padding-top: 150px; }
  .hero-25-txt h2 { font-size: 3.65rem; }
  .hero-25-txt p.p-lg { padding: 0; margin-top: 15px; margin-bottom: 25px; }
  .hero-25-img { margin-top: 50px; margin-bottom: -90px; }

  /* HERO-26 */
  #hero-26 { padding-top: 150px; }
  .hero-26-txt h2 { font-size: 3.7rem; }
  .hero-26-txt p.p-lg { padding: 0; margin-top: 15px; margin-bottom: 25px; }
  .hero-26-img { margin: 50px 0 0; }

  /* HERO-27 */
  #hero-27 { padding-top: 45px; }
  .hero-27-txt { padding-right: 0; }

  .hero-27-txt .hero-logo { margin-top: 15px; margin-bottom: 35px; }
  .hero-27-txt .hero-logo img { max-height: 30px; }
  .hero-27-txt h2 { font-size: 3.15rem; line-height: 1.25; margin-bottom: 30px; }
  .hero-27-txt p { margin-top: 60px; }

  #hero-27 .img-link { bottom: 15px; left: 16px; width: 65px; height: 65px; }

  /* HERO REQUEST FORM */
  .request-form .form-control { height: 56px; margin-bottom: 18px; }

  /*------------------------------------------*/
  /*  CONTENT  
  /*------------------------------------------*/

  /* ABOUT */
  .about-1-title { padding: 0 4%; margin-bottom: 40px; }
  .about-2-title { padding-right: 2%; }

  .about-1-title h2 { padding: 0 2%; }
  .about-1-title p { margin-top: 18px; }

  #about-3 .txt-block h5 { margin-bottom: 16px; }

  /* FEATURES */
  #features-11 .col { padding: 0 10px; }

  #features-3.py-100.shape--bg:after { width: 50%; height: 68%; top: 16%; left: 50%; }
  #features-3.pt-100.shape--bg:after { width: 50%; height: 76%; top: 18%; left: 50%; }
  #features-4.py-100.shape--bg:after { width: 50%; height: 68%; top: 16%; }
  #features-4.pt-100.shape--bg:after { width: 50%; height: 76%; top: 18%; }

  .fbox-3-wrapper { padding-left: 30px; }
  .fbox-4-wrapper { padding-right: 30px; }

  .rows-2 .fb-1, .rows-2 .fb-2, .rows-2 .fb-3 { margin-bottom: 40px; }
  .rows-3 .fb-1, .rows-3 .fb-2, .rows-3 .fb-3, .rows-3 .fb-4 { margin-bottom: 30px; }

  #fb-3-1, #fb-3-3, #fb-4-1, #fb-4-3, .rows-4 .fb-1, .rows-4 .fb-2, .rows-4 .fb-3,  .rows-4 .fb-4, .fbox-6.fb-1, 
  .fbox-6.fb-2, .fbox-6.fb-3, .fbox-12.fb-1, .fbox-12.fb-2, .rows-4 .fbox-2.fb-1, .rows-4 .fbox-2.fb-2, .rows-4 .fbox-2.fb-3, 
  .rows-4 .fbox-2.fb-4, .rows-4 .fbox-2.fb-5, .rows-4 .fbox-2.fb-6 { margin-bottom: 24px; }

  .fbox-3, .fbox-4 { padding: 30px; } 
  .fbox-5 { padding: 30px 20px; }
  .fbox-6 { padding: 30px 25px; }

  .fbox-9 { padding: 35px 20px; } 
  .ico-title .fbox-9.fb-1, .ico-title .fbox-9.fb-2, .ico-title .fbox-9.fb-3 { padding: 25px 20px; } 
  .fbox-9.fb-4, .fbox-9.fb-5, .fbox-9.fb-6, .ico-title .fbox-9.fb-4, 
  .ico-title .fbox-9.fb-5, .ico-title .fbox-9.fb-6 { padding: 25px 20px 0; }

  .fbox-10, .fbox-10.fb-5, .fbox-10.fb-6, .fbox-10.fb-7, .fbox-10.fb-8 { padding: 40px 30px; } 
  .ico-title .fbox-10.fb-5, .ico-title .fbox-10.fb-6 { padding: 35px 30px 28px; } 
  .ico-title .fbox-10.fb-7, .ico-title .fbox-10.fb-8 { padding: 35px 30px 0; } 

  .fbox-7 .fbox-img { margin-bottom: 25px; }

  .fbox-ico[class*="img-"] { margin-bottom: 20px; }
  .fbox-img.h-175 img, .fbox-img.h-180 img { max-height: 155px; }
  .fbox-8 .fbox-img.h-175, .fbox-8 .fbox-img.h-180 { padding-top: 30px; margin: 0 0 50px; }  

  .fbox-9.fb-1 .fbox-9-img, .fbox-9.fb-2 .fbox-9-img, .fbox-9.fb-3 .fbox-9-img { margin: 20px 0 0; }
  .fbox-9.fb-4 .fbox-9-img, .fbox-9.fb-5 .fbox-9-img, .fbox-9.fb-6 .fbox-9-img { margin: 20px 0 -18px; }
  .fbox-9.fb-7 .fbox-9-img, .fbox-9.fb-8 .fbox-9-img, .fbox-9.fb-9 .fbox-9-img { margin: 0 0 20px; }

  .fbox-10.fb-1 .fbox-10-img, .fbox-10.fb-7 .fbox-10-img, .fbox-10.fb-8 .fbox-10-img { margin: 30px 0 0; }
  .fbox-10.fb-2 .fbox-10-img, .fbox-10.fb-3 .fbox-10-img, .fbox-10.fb-4 .fbox-10-img { margin: 0 0 30px; }
  .fbox-10.fb-5 .fbox-10-img, .fbox-10.fb-6 .fbox-10-img { margin: 30px 0 0; }

  .fbox-ico-wrap { margin-right: 21px; }
  .fbox-ico-wrap .ico-50 [class*="flaticon-"]:before, 
  .fbox-ico-wrap .ico-50 [class*="flaticon-"]:after { font-size: 2.95rem; }

  .fbox-ico, .fbox-ico.ico-rounded { margin-bottom: 18px; }
  .fbox-5 .fbox-ico { margin-bottom: 20x; }
  .fbox-ico[class*="img-"], .fbox-ico.ico-rounded-md, .fbox-ico.ico-rounded-lg { margin-bottom: 21px; }
  .t-icon { margin-bottom: 3px; }
  .fbox-11-ico { margin-bottom: 14px; }

  .fbox-3 h6, .fbox-4 h6 { margin: 18px 0 10px; }
  .rows-3 .fbox-2 h5, .fbox-wrapper .row-cols-md-2 h6, 
  .fbox-wrapper .row-cols-md-3 h6, .fbox-6 h6, .fbox-12 h5 { margin-bottom: 10px; }
  .fbox-9 h6, .fbox-10 h5, .fbox-11 h6 { margin-bottom: 12px; }
  .fbox-10.fb-5 h5, .fbox-10.fb-6 h5 { margin-bottom: 16px; }
  .ico-title .fbox-10.fb-5 h5, .ico-title .fbox-10.fb-6 h5 { margin-bottom: 14px; }  

  .fbox-5 .txt-block-tra-link { margin-top: 12px; }

  /* CONTENT */
  .ct-05-img .section-title.mb-70 { margin-bottom: 40px; }
  .bc-03 .section-title h2 { padding: 0 3%; }
  .bc-03 .section-title p { padding: 0; }

  .ct-06 .section-overlay { margin-bottom: 80px; }
  .bc-01 .section-overlay { padding: 70px 50px; }
  .bc-02 .section-overlay { padding: 55px 40px; }
  .bc-02 .ico-title .section-overlay { padding: 35px 40px; }
  .bc-02.pt-30 .section-overlay, .bc-02.pb-30 .section-overlay { padding: 35px 35px 35px 40px; }
  .bc-02.pt-30.bc-02-top { padding-top: 20px; margin-top: 60px; }
  .bc-03 .section-overlay { padding: 70px 50px 0; }
  
  .bc-01 .txt-block.left-column { padding-right: 10px; }
  .bc-01 .txt-block.right-column { padding-left: 10px; }

  .ct-07-txt { padding-left: 0; }

  .ct-02-top { margin-bottom: 40px; }

  .txt-box { margin-bottom: 20px; }
  .bc-04-box { padding: 35px 25px; }
  #bc-4-3 { margin-top: 24px; }
  .two-txt .cbox-3-txt { margin-bottom: 20px; }

  .txt-block h3, .txt-block h2 { margin-bottom: 20px; }
  .txt-block h5, .bc-3-txt h5 { margin-bottom: 16px; }
  h5.h5-title { margin-top: 16px 0; }

  .two-txt .txt-box { margin-bottom: 15px; }

  .bc-04-box h2 { font-size: 3rem; margin-bottom: 14px; }
  .bc-04-box h2 small { font-size: 2.65rem; }
  .bc-04-box h2 span { font-size: 2.75rem; }

  .txt-block .btn { margin-top: 25px; }
  .txt-block-tra-link { margin-top: 16px; }

  .cbox-1 .ico-wrap { margin-right: 1.25rem; }
  .cbox-1-ico { width: 2rem; height: 2rem; font-size: 1rem; line-height: 1.65rem; margin-top: 3px; }

  .txt-block .cbox-3 { margin-right: -15px; }
  .cbox-3 .ico-wrap { margin-right: 1.3rem; }
  .cbox-3 .cbox-3-ico { top: 4px; }
  .cbox-3-ico span[class*="flaticon-"]:before, .cbox-3-ico span[class*="flaticon-"]:after { font-size: 2.75rem; }

  .cbox-1-txt, .cbox-3-txt { margin-bottom: 25px; }
  .cbox-6-txt { margin-bottom: 30px; }
  .cbox-1-txt h5, .cbox-3-txt h5 { margin-bottom: 10px; }

  .cbox-4 { width: 340px; min-width: 340px; padding: 15px 24px; }
  .cbox-4.mt-20 { margin-top: 10px; }

  .cbox-title { margin-bottom: 14px; }
  .cbox-title span { margin-right: 16px; }

  .cbox-5-wrapper { margin-top: -3px; }
  .box-title { margin-bottom: 12px; }
  .box-title h6 { position: relative; top: 2px; }

  .txt-block .accordion-1 .accordion-item { padding: 20px 0 25px; }
  .txt-block .accordion-1 .accordion-item .accordion-thumb:after,
  .txt-block .accordion-1 .accordion-item.is-active .accordion-thumb:after { font-size: 0.95rem; top: -1px; right: 5px; }

  .txt-block .accordion-1 .accordion-item.is-active .accordion-thumb h5 { margin-bottom: 20px; }

  /* Advantages */
  .text-center .advantages { margin-top: 15px; }

  /* Compatibility */
  .compatibility { margin-top: 10px; }

  /* Tools List / Payments Icons */
  .tools-list p, .payment-methods p { margin-bottom: 10px; }
  .payment-icons img { max-height: 32px; }

  .tools-list.ico-35 [class*="flaticon-"]:before, 
  .tools-list.ico-35 [class*="flaticon-"]:after { font-size: 2.1875rem;line-height: 2.1875rem!important; margin-right: 4px; }

  /* Quote */
  .quote-avatar img { width: 54px; height: 54px; }
  .quote-author { padding: 0 0 0 10px; } 
  .quote.pr-15, .quote.pr-40, .quote.pr-50, .quote.pr-60 { padding-right: 0; }

  /* Content Image */
  .img-block-hidden, .ct-06 .img-block { margin-bottom: -80px; }
  .img-block-txt, .img-block-btn { margin-top: 45px; }

  .ct-01 .img-block.right-column, .ct-02 .img-block.right-column, .ct-03 .img-block.right-column { margin-left: 20px; }
  .ct-01 .img-block.left-column, .ct-02 .img-block.left-column, .ct-03 .img-block.left-column { margin-right: 20px; }
  .ct-04 .img-block img { max-width: 170%; }

  .ct-07-images { margin-bottom: 30px; }

  .bc-2-img.right-column { margin-left: 10px; }
  .bc-2-img.left-column { margin-right: 10px; }
  .bc-4-img { height: 422px; }

  /* STATISTIC */
  .statistic-7-wrapper { padding: 40px 10px 40px 50px; }
  .statistic-8-wrapper { padding: 0; }

  #statistic-6 .statistic-block-txt:before { height: 2px; width: 90px; margin: 22px auto 18px; }

  .statistic-1-wrapper .statistic-block-digit { width: 32%; }
  .statistic-1-wrapper .statistic-block-txt { width: 68%; padding-left: 15px; }
  .statistic-2-wrapper .txt-block { padding-right: 5%; }
  .statistic-4-wrapper .statistic-block { padding: 10px 20px 10px 40px; }

  #sb-2-1 { padding-left: 5%; }

  .statistic-ico { margin-bottom: 10px; }

  .statistic-2-wrapper h2,  #sb-3-1 h2.statistic-number, #sb-3-2 h2.statistic-number { margin-bottom: 10px; }
  .statistic-4-wrapper h5 { margin: 20px 0 10px; }
  #statistic-6 h2.statistic-number { font-size: 3.85rem; letter-spacing: -2px; }
  #statistic-6 h2.statistic-number small { font-size: 2.35rem; margin-left: 4px; }
  .statistic-9-wrapper h3, .statistic-9-wrapper h2 { margin: 25px 0 12px; }

  .statistic-1-wrapper p { top: 1px; }
  .statistic-5-wrapper p { margin-top: 10px; }
  .statistic-8-wrapper p { margin-top: 16px; }

  .statistic-8-wrapper img { max-height: 48px; margin-top: 16px; }
  .statistic-section .action-btn { margin-top: 45px; }

  /* PROJECTS */
  #portfolio-1.pb-70 { padding-bottom: 56px; }
  #portfolio-1 .filter-btns.mb-70 { margin-bottom: 45px; }
  #portfolio-1 .filter-item { margin-bottom: 24px; }

  .project-category { left: 28px; }
  .project-category p { padding: 7px 16px; margin-bottom: 0; }

  .project-data-txt { bottom: 30px; }
  .project-source { width: 38px; height: 38px; bottom: 22px; }
  .project-source a span { top: 3.5px; }

  .filter-item:hover .project-category { top: 25px; }
  .filter-item:hover .project-data-txt { left: 30px; }
  .filter-item:hover .project-source { right: 22px; }

  /* PROJECT DETAILS */
  .project-title { padding: 0 8%; margin-bottom: 40px; }
  .project-title p { margin-top: 20px; }
  .project-data { padding-bottom: 40px; margin: 40px 0; }

  .project-txt h5, .project-txt h6 { margin: 30px 0 15px; }

  .project-inner-img { margin: 40px 0; }
  .project-inner-img.d-flex { margin-left: -12px; margin-right: -12px; }
  
  #project-details .more-projects { margin-top: 50px; }
  #project-details .more-projects span { top: 1px; left: 5px; }
  #project-details .more-projects.ico-25 [class*="flaticon-"]:before, 
  #project-details .more-projects.ico-25 [class*="flaticon-"]:after { font-size: 1.275rem; } 

  /* TEAM */
  .team-member-photo { margin-bottom: 20px; }

  /* CAREERS */
  .career-item { padding: 25px 0; }

  /* JOB POSITION */
  #job-position .inner-page-title { padding-bottom: 50px; margin-bottom: 45px; }
  #job-position .inner-page-title p { margin-top: 16px; }
  #job-position .inner-page-title .btn { padding: 0.7rem 1.1rem; margin-top: 2px; }

  .position-info h5 { margin: 40px 0 20px; }
  .position-info .btn { margin-top: 25px; }

  /* PRICING */
  #pricing-1 .section-title.mb-70 { margin-bottom: 50px; }
  .pricing-1-wrapper .col { padding: 0 8px; }
  .pricing-1-table { padding: 40px 25px 35px; }
  .text-center .pricing-1-table { padding: 50px 25px 40px; }

  .pricing-table-header h5 { margin-bottom: 25px; }

  .pricing-1-table .pricing-discount { top: -2px; right: 0; padding: 7px 13px; }
  .text-center .pricing-1-table .pricing-discount { top: -40px; right: -5px; }

  /* Price */
  .price span { font-size: 2.85rem; letter-spacing: -1.5px; } 
  .price sup { font-size: 2rem; top: -4px; right: 2px; }
  .price sup.validity { font-size: 1.45rem; }

  .price p { margin: 20px 0 15px; }
  .text-center .price p { padding: 0; }

  .pricing-features.mt-25 { margin-top: 20px; }

  .pricing-1-table hr { margin-top: 30px; }

  .pricing-1-table .pt-btn { margin-top: 20px; }

  /* Pricing Notice */
  .col-lg-10 .pricing-notice p { padding: 0 4%; }
  .pricing-notice .btn.mt-25 { margin-top: 20px; }

  /* Pricing Toggle Button */
  .toggle-btn.toggle-btn-md.mt-40 { margin-top: 30px; }
  .toggle-btn-md .toggler-txt { font-size: 1.0625rem; line-height: 32px; }
  .toggle-btn-lg .toggler-txt { font-size: 1.1rem; line-height: 36px; }

  .switch-wrap { min-width: 50px; height: 30px; }
  .toggle-btn-md .switch-wrap { min-width: 55px; height: 32px; margin: 0 6px; }
  .toggle-btn-lg .switch-wrap { min-width: 60px; height: 36px; margin: 0 12px; }

  .switcher { font-size: 0.9rem; height: 30px; }
  .toggle-btn-md .switcher { height: 32px; padding: 0 10px 0 30px; border-radius: 32px; }
  .toggle-btn-lg .switcher { height: 36px; padding: 0 15px 0 35px; border-radius: 36px; }

  .switcher .show-annual, .switcher .show-monthly { line-height: 30px; }
  .toggle-btn-md .switcher .show-annual, .toggle-btn-md .switcher .show-monthly { line-height: 32px; }
  .toggle-btn-lg .switcher .show-annual, .toggle-btn-lg .switcher .show-monthly { line-height: 36px; }

  .switcher:before { left: 4px; bottom: 4px; width: 22px; height: 22px; }
  .toggle-btn-md .switcher:before { left: 3px; bottom: 3px; width: 22px; height: 22px; }
  .toggle-btn-lg .switcher:before { left: 5px; bottom: 5px; width: 26px; height: 26px; }
  .switcher.bg--grey:before, .switcher.bg--tra-white:before, .switcher.bg--ghost-white:before { left: 4px; bottom: 3px; }

  .toggle-btn-md .switch-wrap input:checked + .switcher { padding: 0 30px 0 10px; }
  .toggle-btn-lg .switch-wrap input:checked + .switcher { padding: 0 35px 0 15px; }

  /* Pricing Compare */
  .table>:not(caption)>*>* { padding: 15px 0; }
  .comp-table .table-responsive.mb-50 { margin-bottom: 30px; }
  .comp-table .table-responsive thead th { font-size: 1.0625rem; }
  .comp-table .table-responsive tbody th { font-size: 1rem; }
  .comp-table .table-responsive tbody td { font-size: 1rem; top: 1px; }
  .comp-table .table-responsive tbody td span { top: 2px; }

  .table-responsive .ico-15 [class*="flaticon-"]:before, 
  .table-responsive .ico-15 [class*="flaticon-"]:after { font-size: 0.775rem; }

  .table-responsive .ico-20 [class*="flaticon-"]:before, 
  .table-responsive .ico-20 [class*="flaticon-"]:after { font-size: 1rem; }

  .comp-table-payment h6 { margin-bottom: 14px; }
  .comp-table-payment p { font-size: 0.965rem; }

  /* RATING */
  .rating-section .rating-1-wrapper.text-center { padding: 0 12%; }
  .rating-section .rating-2-wrapper.text-center { padding: 0 3%; }

  .rbox-1-img img { max-height: 33px; } 
  .rbox-1 .star-rating { font-size: 0.9rem; line-height: 1; }
  p.rbox-2-txt { font-size: 1rem; }

  /* TESTIMONIALS */
  #reviews-3 { margin: 40px 0 30px; }
  #reviews-5 { padding-bottom: 56px; }
  .reviews-3-wrapper { padding: 0 60px; }
  .reviews-4-txt.left-column { padding: 0 25px 0 45px; }

  .review-1 { padding: 28px 25px 32px; margin: 0 10px 30px; }
  .review-2 { padding: 0 22px; }
  .review-3 { padding: 22px 22px 26px; }
  #rw-3-1 { margin: -40px -10px 25px 10px; }
  #rw-3-2 { margin: 0 5px -30px -5px; }

  #reviews-5 .filter-item { padding: 0 10px; margin-bottom: 24px; }
  .review-5 { padding: 26px 23px 30px; }

  .review-5-logo { margin-left: 0; margin-bottom: 35px; }
  .review-5-logo img { max-height: 40px; }

  .review-2-txt h5 { margin: 10px 0 12px; }
  .reviews-4-txt h5 { margin: 5px 0 15px; }
  #reviews-3 .txt-block p { padding-right: 0; margin-top: 15px; }
  .review-1 .review-rating, .review-5 .review-rating { margin-bottom: 6px; }
  #reviews-3 .txt-block .btn { margin-top: 25px; }

  .review-5-avatar { right: 26px; bottom: 30px; }
  .review-5-avatar img { width: 42px; height: 42px; }

  .reviews-4-txt .review-author { margin-top: 20px; }
  .review-5 .review-author.mt-30 { margin-top: 24px; }

  /* BRANDS */
  .brands-2-wrapper { padding: 0 10px; }
  .brands-3-wrapper { padding: 0 2%; }
  .brands-4-wrapper { padding-left: 15px; margin-top: -5px; }

  #brand-3-1, #brand-3-2, #brand-3-3, #brand-3-4, #brand-3-5 { margin-bottom: 35px; }

  #brand-4-1, #brand-4-2, #brand-4-3, #brand-4-4, #brand-4-5, #brand-4-6 { margin-bottom: 30px; }

  .brands-title h6 { margin-bottom: 40px; }
  .brands-title h5 { margin-bottom: 45px; }
  .brands-title p { padding: 0 10%; margin-bottom: 30px; }
  .brands-carousel-5 .brand-logo, .brands-carousel-6 .brand-logo { padding: 0 12px; } 
  #brands-4 .brand-logo { padding: 0 12px; }

  .brands-section .more-btn { margin-top: 25px; }

  /* INTEGRATIONS */
  #integrations-4.pt-100.pb-70 { padding-bottom: 46px; }

  .int-3-wrapper, .int-4-wrapper { padding: 0 4%; }

  .int_tool-1 { padding: 15px 20px; margin-bottom: 24px; }
  .int_tool-2 { padding: 15px 20px; }
  .int_tool-3 { padding: 25px; margin-bottom: 24px; }
  .int_tool-4 { padding: 20px; margin-bottom: 24px; }
  .int-2-wrapper .int_tool-2 { margin-bottom: 24px; }

  .int_tool-1 .tool_logo { margin-right: 20px; }
  .int_tool-1 .tool_logo img { width: 48px; height: 48px; }
  .int_tool-2 .tool_logo img { width: 36px; height: 36px; }
  .int_tool-3 img { width: 55px; height: 55px; }
  .int_tool-4 img { width: 50px; height: 50px; }
  
  .tool_txt h6 { font-size: 1.1875rem; margin-top: 3px; }
  .tool_logo h6 { line-height: 36px; top: 3px; margin-left: 10px; }
  .int_tool-4 h6 { font-size: 0.95rem; margin-top: 14px; }
  .tool_txt p { margin-top: 0; }
  .tool_description p { font-size: 0.925rem; margin-top: 12px; }

  .integrations-section .more-btn.mt-30 { margin-top: 20px; }

  /* BANNER */
  .banner-1-wrapper { padding: 60px 50px; }
  .banner-2-wrapper .banner-overlay { padding: 60px 65px 70px; } 
  .banner-4-wrapper { padding: 45px 50px; }

  .banner-section .section-id { margin-bottom: 25px; }

  .banner-2-txt h2, .banner-3-txt h2 { margin-bottom: 18px; }
  .banner-3-txt h2.h2-xl, .banner-3-txt h2.h2-title { margin-bottom: 30px; }

  .banner-section p { margin-bottom: 25px; }
  #banner-1.banner-section p { margin: 16px 0 20px!important; }
  #banner-2.banner-section p { padding: 0; }

  .banner-1-txt .btn { margin-right: 12px; }
  .banner-4-btn { top: 8px; }
  .banner-4-btn p { margin: 10px 0 0!important; }
  .banner-section p.btn-txt { margin-top: 15px; }

  /* DOWNLOAD */
  .version-release { margin-bottom: 60px; }
  .release-data { padding-bottom: 25px; margin-bottom: 25px; }
  .release-data h5 { margin-bottom: 30px; }

  span.version-data { font-size: 1.9rem; }
  span.release-date { font-size: 1.1rem; }

  /* FAQs */
  #faqs-1 .section-title, #faqs-3 .section-title { margin-bottom: 40px; } 
  #faqs-2 .section-title { margin-bottom: 50px; } 

  #faqs-2 .question { margin-bottom: 25px; }
  #faqs-2 .question h6, #faqs-2 .question h5 { margin-bottom: 10px; }
  .faqs-3-question h5 { margin-bottom: 12px; }

  .accordion-thumb { padding: 22px 0; }
  #faqs-3.faqs-section .accordion-thumb { padding: 22px 0 28px; }

  .accordion-panel { padding: 0 0 5px 0; }
  #faqs-3 .accordion-panel { padding: 35px 0 20px 0; } 
  #faqs-3 .accordion-panel-item.mb-35 { margin-bottom: 25px; }

  .accordion-item .accordion-thumb:after, 
  .accordion-item.is-active .accordion-thumb:after { font-size: 0.925rem; top: 19px; }

  #faqs-3.faqs-section .accordion-item .accordion-thumb:after, 
  #faqs-3.faqs-section .accordion-item.is-active .accordion-thumb:after { font-size: 1rem; top: 27px; right: 2px; }

  .more-questions { margin-top: 20px; }
  #faqs-1 .more-questions { margin-top: 40px; }
  #faqs-3 .more-questions { margin: 35px auto 0; }
  .more-questions-txt { padding: 20px 45px; }

  /* HELP CENTER */
  .help-category { padding: 40px 25px; margin-bottom: 24px; }
  .help-category h6 { margin: 10px 0; }

  /* HELP CATEGORY */
  .help-category-title { padding: 55px 30px 35px; margin-bottom: 60px; }
  .help-category-title p { margin-top: 12px; }

  #help-category h4 { margin-bottom: 45px; }
  .help-category-item { padding-bottom: 30px; margin-bottom: 30px; }

  .help-category-item p { margin: 12px 0 15px; }
  .help-category-data span { font-size: 0.95rem; }
  .read-time { margin-left: 15px; }

  /* HELP ARTICLE */
  .breadcrumb-wrapper { margin-bottom: 30px; }
  .help-article-title h3 { margin-bottom: 15px; }
  .help-article-title img { margin: 35px 0; }

  .table-of-contents { padding: 30px 0 35px; margin: 35px 0; }
  .table-of-contents h6 { margin-bottom: 15px; }

  #help-article .txt-block { padding-bottom: 35px; margin-bottom: 35px; }
  #help-article .txt-block h6 { margin: 30px 0 15px; }

  .help-vote-form { margin-top: 60px; }

  /* BLOG */
  #blog-page .section-title h2 { padding: 0 3%; }
  #blog-page .section-title p { margin-top: 20px; padding: 0 2%; }
  .posts-category { margin: 20px 0 40px; }
  .posts-category.ico-20 [class*="flaticon-"]:before, 
  .posts-category.ico-20 [class*="flaticon-"]:after { font-size: 1.0625rem; } 

  .square-post, #blog-page .blog-post { margin-bottom: 40px; }

  .blog-post-meta { margin-bottom: 2px; }
  .meta-list-divider { padding: 0 1px; }
  .blog-post-txt h6, .blog-post-txt h5 { margin-bottom: 10px; }

  .square-post .blog-post-txt { padding: 0 30px 0 50px; }
  .square-post .blog-post-meta { margin-bottom: 15px; }
  .square-post .blog-post-txt h4 { margin-bottom: 20px; }
  .square-post .post-comments { margin-top: 15px; }

  /* SINGLE BLOG POST */
  .single-post-title { margin-bottom: 50px; }
  .single-post-title { padding: 0 10%; }
  .single-post-title p.p-lg { margin-top: 20px; padding: 0; }

  .single-post-txt h5, .single-post-txt h4 { margin: 20px 0 22px; }

  #single-post .blog-post-meta { margin-bottom: 15px; }
  .single-post-txt figure { margin-bottom: 20px; }
  .single-post-txt .blockquote p { font-size: 1.225rem; margin-bottom: 30px; }
  .single-post-txt .blockquote-footer { font-size: 1.05rem; }

  .post-inner-img { margin: 40px 0; }

  .post-data { margin-top: 70px; padding: 70px 0 80px; }
  .post-author-avatar img { width: 65px; height: 65px; }

  .subscribe-banner { padding: 40px 35px; }
  .subscribe-banner-txt p { margin: 10px 0 0; }

  #post-comments { padding-top: 80px; }
  #post-comments img { width: 60px; height: 60px; }
  .comment-meta { margin-bottom: 5px; }
  .comment-meta h6 { font-size: 1.1875rem; margin-bottom: 5px; }
  .comment-date, .btn-reply a { font-size: 1rem; } 

  #leave-comment h5 { margin-top: 80px; }

  .comment-form { margin-top: 45px; }
  .comment-form .form-control { height: 56px; font-size: 1.05rem; }
  .comment-form textarea { min-height: 190px; }

  .comment-form .btn { margin-top: 0; }

  /* NEWSLETTER */
  #newsletter-1 .newsletter-overlay { padding: 60px 0; } 
  #newsletter-2 .newsletter-overlay { padding: 65px 0 55px; } 

  #newsletter-1 .newsletter-txt { padding-right: 8%; } 

  #newsletter-1 .newsletter-form { margin-top: 25px; padding-left: 8%; }
  #newsletter-2 .newsletter-form { padding: 0 6%; margin-top: 30px; }

  .newsletter-section .form-control { height: 52px; }
  .newsletter-section .btn { height: 52px; font-size: 0.925rem; padding: 0.7rem 1.4rem; }

  /* CONTACTS */
  #contacts-1.pb-60 { padding-bottom: 40px; }
  .contacts-info p { margin: 20px 0 0; }
  .contacts-info h6 { margin: 25px 0 16px; }
  .contacts-info-socials li { padding-right: 12px; }

  .contact-box { margin-bottom: 20px; }
  .contact-box p { padding-right: 0; margin-top: 14px; }

  .contact-form .form-control, .contact-form .form-select { height: 56px; font-size: 1.05rem; padding: 0 15px; }
  .contact-form textarea { min-height: 190px; }
  .contact-form textarea.form-control { padding: 15px; }

  .contact-form .form-btn { margin-top: 5px; }
  .contact-form-msg { margin-top: 15px; }

  /* FOOTER */
  .footer { padding-bottom: 35px; }

  #footer-1 .fl-3, #footer-11 .fl-5, #footer-1 .footer-contacts { margin-bottom: 25px; }
  #footer-3 .footer-links, #footer-7 .footer-links { padding-left: 0; }
  #footer-2 .footer-info, #footer-5 .footer-info { padding-right: 0; }
  #footer-5 .fl-2 { padding-left: 15px; }

  img.footer-logo { max-height: 30px; margin-bottom: 20px; }

  .footer h6 { margin-bottom: 20px; }
  #footer-3 .footer-info p { padding-right: 10%; }
  .footer-phone { margin-top: 10px; }
  .footer-mail-link span { top: 4px; right: 5px; }

  .footer-socials.ico-20 a, .footer-socials.ico-25 a { margin-right: 14px; } 
  .footer-socials.ico-25 [class*="flaticon-"]:before, .footer-socials.ico-25 [class*="flaticon-"]:after { font-size: 1.25rem; }

  .footer-form .form-control, .footer-form .btn { height: 28px; }

  .footer-form .ico-15 [class*="flaticon-"]:before, 
  .footer-form .ico-15 [class*="flaticon-"]:after { line-height: 24px!important; }

  .footer hr { margin-top: 10px; margin-bottom: 35px; }

  /* INNER PAGE WRAPPER */
  .inner-page-hero { padding-top: 160px; }
  .inner-page-title { margin-bottom: 60px; }
  .inner-page-title p { margin-top: 18px; }

  /* TERMS, PRIVACY, COOKIES */
  .txt-block.legal-info h5 { margin: 45px 0 20px; }
  .txt-block.legal-info h6 { margin: 20px 0; }
  .txt-block.legal-info h6 span, .txt-block.legal-info h5 span { margin-right: 2px; }

  /* SIGN IN / SIGN UP */
  #login, #signup { padding: 80px 0; }
  .reset-page-wrapper form { margin: 0; padding: 40px 12px 5px; }

  .register-page-txt img { max-height: 30px; margin-bottom: 45px; }
  .login-page-logo { margin-bottom: 35px; }
  .login-page-logo img { max-height: 35px; }

  .login-3 .register-page-txt, .signup-3 .register-page-txt { padding: 60px 40px; }
  .register-page-txt p { margin: 18px 0 0; }

  #login .register-page-copyright { left: 45px; bottom: 40px; }
  #signup .register-page-copyright { right: 45px; bottom: 40px; }
  .register-page-copyright p { font-size: 0.9rem; }

  .login-1 .register-page-form { padding: 0 6% 0 12%; }
  .signup-1 .register-page-form { padding: 0 12% 0 6%; }

  .login-3 .register-page-form { padding: 50px 45px 50px 35px; }
  .signup-3 .register-page-form { padding: 50px 35px 50px 45px; }

  .login-2 .register-page-form, .signup-2 .register-page-form { margin: 0; padding: 40px 25px 35px; }

  .register-form-title { margin-bottom: 30px; }
  .login-2 .register-form-title, .signup-2 .register-form-title { margin-bottom: 24px; }
  .reset-form-title { margin-bottom: 18px; }

  .register-form-title h5, .register-form-title h4 { margin-bottom: 12px; }

  .register-page-form p.input-header { font-size: 0.9rem; margin-bottom: 10px; padding-left: 5px; }
  .reset-password-link p { font-size: 0.95rem; margin-top: 20px; }
  .login-3 .reset-password-link p { margin-top: -2px; margin-bottom: 22px; }
  .form-data span { font-size: 0.865rem; }
  .reset-password-form .form-data p { font-size: 1rem; margin-bottom: 10px; }
  .register-page-form p.create-account { margin-top: 20px; }
  .reset-form-title p.p-sm { font-size: 0.925rem; margin-top: 16px; }

  .separator-line { margin: 20px 0; }

  .reset-page-wrapper .form-control, 
  .register-page-form .form-control { height: 56px; font-size: 1.05rem; padding: 0 15px; margin-bottom: 22px; }
  .reset-page-wrapper .form-control { margin-bottom: 16px; }
  #login .register-page-form .btn.submit { height: 56px; font-size: 1.05rem; margin-top: 0; }
  #signup .register-page-form .btn.submit { height: 56px; font-size: 1.05rem; margin-top: 22px; }
  .reset-page-wrapper .btn.submit { height: 56px; font-size: 1.05rem; margin-bottom: 16px; }

  /* PAGE 404 */
  #page-404 { padding-top: 170px; }
  .page-404-txt { padding-bottom: 80px; }
  .page-404-txt h2 { margin-bottom: 16px; }
  .page-404-txt h6 { padding: 0 5%; margin-bottom: 30px; }
  .page-404-img { padding: 0 10%; margin-bottom: 34px; }
  .page-404-footer { padding: 35px 0; }

  /* COOKIES */
  #cookies { bottom: 15px; left: 28%; width: 44%; padding: 17px 22px; }
  #cookies p { font-size: 0.95rem; }
  #cookies-ok { font-size: 0.9rem; padding: 6px 16px; }
  #cookies-info { margin-left: 14px; }

  /* MODAL */
  #modal-1 .modal-dialog { width: 350px; }
  #modal-2 .modal-dialog.modal-xl { width: 680px; }

  #modal-1 .modal-body-content { padding: 15px 25px 10px; }
  #modal-2 .modal-body-content { padding: 50px 30px 40px; }

  .modal-body .newsletter-form { margin-top: 20px; }
  .modal .newsletter-form .form-control { height: 52px; font-size: 0.975rem; margin-bottom: 16px; }
  .modal .newsletter-form .btn { height: 52px; font-size: 0.975rem; }

  #modal-2 .request-form-title p { margin-top: 15px; margin-bottom: 20px; }
  #modal-2 .request-form .form-control, #modal-2 .request-form .btn { height: 56px; font-size: 1.05rem; }


}




/* ------------------------------------------------------------------------ */
/* ------------------------------------------------------------------------ */
/* ------------------------------------------------------------------------ */

@media (min-width: 768px) and (max-width: 991.98px) { 

  /*------------------------------------------*/
  /*  BASE
  /*------------------------------------------*/

  .wow {
    animation-name: none!important;
    visibility: visible!important;
  }

  html { font-size: 85%; } 

  .py-40 { padding: 30px 0; }
  .py-50 { padding: 35px 0; }
  .py-60 { padding: 40px 0; }
  .py-70 { padding: 50px 0; }
  .py-80 { padding: 50px 0; }
  .py-90 { padding: 60px 0; }
  .py-100 { padding: 70px 0; }

  .pt-30 { padding-top: 24px; }
  .pt-40 { padding-top: 30px; }
  .pt-50 { padding-top: 35px; }
  .pt-60 { padding-top: 40px; }
  .pt-70 { padding-top: 50px; }
  .pt-80 { padding-top: 50px; }
  .pt-90 { padding-top: 60px; }
  .pt-100 { padding-top: 70px; }

  .pb-30 { padding-bottom: 24px; }
  .pb-40 { padding-bottom: 30px; }
  .pb-50 { padding-bottom: 35px; }
  .pb-60 { padding-bottom: 40px; }
  .pb-70 { padding-bottom: 50px; }
  .pb-80 { padding-bottom: 50px; }
  .pb-90 { padding-bottom: 60px; }
  .pb-100 { padding-bottom: 70px; }

  .mt-30 { margin-top: 24px; }
  .mt-40 { margin-top: 30px; }
  .mt-45 { margin-top: 30px; }
  .mt-50 { margin-top: 35px; }
  .mt-55 { margin-top: 35px; }
  .mt-60 { margin-top: 40px; }
  .mt-65 { margin-top: 45px; }
  .mt-70 { margin-top: 50px; }
  .mt-75 { margin-top: 50px; }
  .mt-80 { margin-top: 50px; }

  .mb-30 { margin-bottom: 24px; }
  .mb-40 { margin-bottom: 30px; }
  .mb-45 { margin-bottom: 30px; }
  .mb-50 { margin-bottom: 35px; }
  .mb-55 { margin-bottom: 35px; }
  .mb-60 { margin-bottom: 40px; }
  .mb-65 { margin-bottom: 45px; }
  .mb-70 { margin-bottom: 50px; }
  .mb-75 { margin-bottom: 50px; }
  .mb-80 { margin-bottom: 50px; }

  /* Background Shape */
  .shape--01:after { height: 50%; }
  .shape--02:after { height: 50%; top: 50%; }
  .bg--shape:after { width: 650px; height: 650px; top: 19%; left: 18%; }
  .bg--shape-top:after { width: 500px; height: 500px; top: -8%; right: 10%; background-color: rgba(119, 67, 142, .13); }

  /* Headers */
  h6 { font-size: 1rem; }                            /* 13.6px */
  h6.h6-md { font-size: 1.066176rem; }               /* 14.5px */
  h6.h6-lg, h6.h6-xl { font-size: 1.397058rem; }     /* 19px */
 
  h5 { font-size: 1.397058rem; }                     /* 19px */
  h5.h5-md, h5.h5-lg, h5.h5-xl { font-size: 1.470588rem; }  /* 20px */

  h4, h4.h4-md, .h4-lg { font-size: 1.764705rem; }   /* 24px */ 
  h4.h4-xl { font-size: 1.91176rem; }                /* 26px */ 

  h3, h3.h3-md, h3.h3-lg, h3.h3-xl { font-size: 1.91176rem; }  /* 26px */ 

  h2 { font-size: 2.0588rem; }                       /* 28px */
  h2.h2-md, h2.h2-lg { font-size: 2.20588rem; }      /* 30px */ 
  h2.h2-xl, h2.h2-title { font-size: 2.5rem; }       /* 34px */ 

  h6, h5, h4, h3, h2, h1 { line-height: 1.35; }

  /* Paragraphs */
  p.p-sm { font-size: 1rem; }                 
  p.p-md, p.p-lg { font-size: 1.066176rem; } 

  ol.digit-list p { margin-bottom: 5px; }

  /* Button */
  .btn.btn-sm { font-size: 0.975rem; padding: 0.65rem 1.35rem; }
  .btn.ico-30.ico-left { padding: 0.3665rem 0; }
  .btns-group .btn.ico-30.ico-left, .btns-group .btn.ico-30.ico-right { padding: 0.31175rem 0.5rem; }

  /* Buttons Group */
  .btns-group .btn:first-child { margin-right: 10px; }

  /* Button Icon */
  .btn.ico-20 [class*="flaticon-"]:before, 
  .btn.ico-20 [class*="flaticon-"]:after { font-size: 1rem; line-height: 0.65rem!important; top: 3px; }
  .btn.ico-30 [class*="flaticon-"]:before, .btn.ico-30 [class*="flaticon-"]:after { font-size: 2rem; }

  .btn.ico-30.ico-left span.ico-30-txt { font-size: 1.065rem; top: -8px; margin-right: 0; }
  .btn.ico-30.ico-right span.ico-30-txt { font-size: 1.065rem; top: -8px; margin-left: 0; }
  .btn.ico-left span { margin-right: 4px; }
  .btn.ico-right span { margin-left: 4px; }

  /* Button Text */
  p.btn-txt { margin: 10px 0 0 0; }
  p.btns-group-txt, .btn-rating { margin: 15px 0 0 0; }
  .text-center p.btns-group-txt { margin-top: 18px; }
  .star-rating span.rating-txt { font-size: 1rem; }

  /* Transparent Link */
  a.tra-link { font-size: 1.03675rem; }
  .tra-link.ico-20 [class*="flaticon-"]:before, 
  .tra-link.ico-20 [class*="flaticon-"]:after { font-size: 0.785rem; line-height: 0.7rem!important; top: 2px; left: 2px; }

  /* Store Badges*/
  .badge-img-xs .store img { max-height: 2.595rem; }
  .badge-img-sm .store img { max-height: 2.72rem; }
  .badge-img-md .store img { max-height: 2.97rem; }
  .badge-img-lg .store img { max-height: 3.095rem; }

  /* Watch Video Link */
  .watch-video { margin-top: 18px; }
  .watch-video-link { margin-right: 10px; }
  .watch-video-link span[class^="flaticon-"]:before, .watch-video-link span[class^="flaticon-"]:after { margin-left: 3px; }
  p.video-txt-lg { font-size: 1.1rem; }
  p.video-txt-sm { font-size: 1rem; }

  /* Video Popup Icon */
  .video-btn-xl { width: 7rem; height: 7rem; margin-top: -3.5rem; margin-left: -3.5rem; }
  .video-btn-lg { width: 6.5rem; height: 6.5rem; margin-top: -3.125rem;; margin-left: -3.125rem;; }
  .video-btn-md { width: 5.625rem; height: 5.625rem; margin-top: -2.8125rem; margin-left: -2.8125rem; }
  .video-btn-sm { width: 5rem; height: 5rem; margin-top: -2.5rem; margin-left: -2.5rem; }

  .video-btn.video-btn-xl [class^="flaticon-"]:before, 
  .video-btn.video-btn-lg [class^="flaticon-"]:after { font-size: 5.5rem; line-height: 7rem!important; margin-left: 8px; }

  .video-btn.video-btn-lg [class^="flaticon-"]:before, 
  .video-btn.video-btn-lg [class^="flaticon-"]:after { font-size: 5rem; line-height: 6.25rem!important; margin-left: 10px; }

  .video-btn.video-btn-md [class^="flaticon-"]:before, 
  .video-btn.video-btn-lg [class^="flaticon-"]:after { font-size: 4.5rem; line-height: 5.625rem!important; margin-left: 8px; }

  .video-btn.video-btn-sm [class^="flaticon-"]:before, 
  .video-btn.video-btn-md [class^="flaticon-"]:after { font-size: 4.35rem; line-height: 5rem!important; margin-left: 8px; }

  /* Vector Icon Background */ 
  .ico-50 .shape-ico svg { width: 95px; height: 95px; top: -25px; left: calc(50% - 50px); }
  .ico-55 .shape-ico svg { width: 100px; height: 100px; top: -30px; left: calc(50% - 55px); }
  .ico-60 .shape-ico svg { width: 105px; height: 105px; top: -20px; left: calc(50% - 50px); }

  /* Vector Rounded Icons */
  .ico-rounded, .ico-rounded-md { width: 70px; height: 70px; }
  .ico-rounded-lg { width: 80px; height: 80px; }

  .ico-rounded [class*="flaticon-"]:before, 
  .ico-rounded-md [class*="flaticon-"]:before,
  .ico-rounded [class*="flaticon-"]:after,
  .ico-rounded-md [class*="flaticon-"]:after { font-size: 2.5rem; line-height: 70px!important; }

  .ico-rounded-lg [class*="flaticon-"]:before, 
  .ico-rounded-lg [class*="flaticon-"]:after { font-size: 2.875rem; line-height: 80px!important; }

  /* PNG Icons */
  .img-80 img { width: 62px; height: 62px; }
  .img-75 img { width: 57px; height: 57px; }
  .img-70 img, .img-65 img, .img-60 img { width: 55px; height: 55px; }

  /* Section ID */
  .section-id { font-size: 0.9rem; margin-bottom: 20px; }

  /* Section Title */
  .bc-04 .section-title.mb-70 { margin-bottom: 45px; }
  .section-title p { margin-top: 14px; }
  .section-title .btns-group { margin-top: 20px; }

  /* Users */
  .txt-block .users { margin-top: 12px; }
  .users img { max-height: 36px; }
  .users p { font-size: 0.975rem; top: 11px; left: 7px; }
  .txt-block .users p { top: 10px; left: 4px; }

  /*------------------------------------------*/
  /*  NAVIGATION MENU    
  /*------------------------------------------*/

  .headtoppart { display: none; }

  .wsmobileheader .smllogo { display: block; margin-top: 15px; padding-left: 22px; }
  .smllogo img { width: auto; max-width: inherit; max-height: 50px; }

  .wsmobileheader { height: 80px; box-shadow: 0 0 2px rgba(50, 50, 50, 0.4); } 
  .tra-menu .wsmobileheader, .white-menu .wsmobileheader { background-color: var(--white); }
  .dark-menu .wsmobileheader, .black-scroll .wsmobileheader { background-color: var(--ink); }
  .tra-menu.border-header .wsmainfull { border: none; }

  .theme--dark .tra-menu .wsmobileheader, 
  .theme--dark .white-menu .wsmobileheader { 
    background-color: var(--ink)!important; 
    -webkit-box-shadow: 0 2px 3px var(--tra-ink);
    -moz-box-shadow: 0 2px 3px var(--tra-ink);
    box-shadow: 0 2px 3px var(--tra-ink);
  }

  /* Mobile Header Burger Menu */
  .wsanimated-arrow { padding: 14px 22px 14px 0px; margin: 24px 28px 0 0; }
  .wsanimated-arrow span:before { top: -8px; width: 26px; }
  .wsanimated-arrow span:after { bottom: -8px; width: 20px; }

  .dark-menu .wsanimated-arrow span, .dark-menu .wsanimated-arrow span:before, 
  .dark-menu .wsanimated-arrow span:after, .black-scroll .wsanimated-arrow span, 
  .black-scroll .wsanimated-arrow span:before, .black-scroll .wsanimated-arrow span:after { background: var(--white); }

  .theme--dark .wsanimated-arrow span, .theme--dark .wsanimated-arrow span:before, 
  .theme--dark .wsanimated-arrow span:after { background: #b1b7cd; }

  .wsactive .wsanimated-arrow span:before, .wsactive .wsanimated-arrow.active span:after { width: 28px; top: 0; }
  .wsactive .wsanimated-arrow span:after { width: 28px; top: 0; }
  .wsactive .wsanimated-arrow span { background: transparent; }

  .wsmenu > .wsmenu-list > li > .wsmenu-click { height: 64px; }

  /* Dark Header Settings */
  .dark-menu .wsmenu > .wsmenu-list, .black-scroll .wsmenu > .wsmenu-list { background-color: var(--metal)!important; }

  .dark-menu .wsmenu > .wsmenu-list > li > a, 
  .black-scroll .wsmenu > .wsmenu-list > li > a { border-bottom-color: var(--tra-white); }

  .dark-menu .wsmenu > .wsmenu-list > li > .wsmenu-click > i:before,
  .black-scroll .wsmenu > .wsmenu-list > li > .wsmenu-click > i:before { border-color: rgba(255, 255, 255, .85); }

  /* Nav Link */
  .wsmenu > .wsmenu-list > li > a { font-size: 1.25rem; line-height: 46px; padding: 5px 32px 4px 20px; margin: 0; }
  .tra-menu .wsmenu > .wsmenu-list > li > a.h-link, 
  .tra-menu.navbar-light .wsmenu > .wsmenu-list > li > a.h-link { color: var(--gray); }
  .black-scroll .wsmenu > .wsmenu-list > li > a.h-link { color: var(--white); }
  .wsmenu > .wsmenu-list > li > .wsmenu-click > i { height: 10px; width: 10px; margin: 21px 18px 0px 0px; }
  .wsmenu > .wsmenu-list > li .wsmenu-click.ws-activearrow > i { transform: rotate(-45deg); margin-top: 25px; }

  .theme--dark .navbar-light .wsmenu > .wsmenu-list > li > a,
  .theme--dark .navbar-dark .wsmenu > .wsmenu-list > li > a { color: var(--white)!important; }

  /* Nav Link Info */
  span.sm-info { width: 22px; height: 22px; font-size: 0.95rem; line-height: 20px; top: -1px; }

  /* SubMenu / HalfMenu / MegaMenu */
  .wsmenu > .wsmenu-list > li > ul.sub-menu { width: 100%!important; min-width: 100%!important; }
  .wsmenu > .wsmenu-list > li > .wsmegamenu.halfmenu { width: 100%!important; padding: 10px; }
  .wsmenu > .wsmenu-list > li > .wsmegamenu.w-75 { width: 100%!important; left: 0; padding: 12px 10px; }

  .wsmenu > .wsmenu-list > li.mobile-last-link > ul.sub-menu { border-bottom: none!important; }

  .wsmegamenu.w-75 .col-lg-3, .wsmegamenu.halfmenu .col-lg-6 { padding: 0; }

  .wsmenu > .wsmenu-list > li > ul.sub-menu, .wsmenu > .wsmenu-list > li > .wsmegamenu.w-75,
  .wsmenu > .wsmenu-list > li > .wsmegamenu.halfmenu { top: 0; -webkit-border-radius: 0; -moz-border-radius: 0; 
  -o-border-radius: 0; -webkit-box-shadow: 0 0; -moz-box-shadow: 0 0; box-shadow: 0 0; border-bottom: 1px solid #ddd; }

  .wsmenu > .wsmenu-list > li > ul.sub-menu, .wsmenu > .wsmenu-list > li > .wsmegamenu { 
    opacity: 1!important; visibility: visible!important; -webkit-transform: rotateX(0deg);
    -moz-transform: rotateX(0deg);-ms-transform: rotateX(0deg);-o-transform: rotateX(0deg);transform: rotateX(0deg); 
  }

  .dark-menu .wsmenu > .wsmenu-list > li > ul.sub-menu, .dark-menu .wsmenu > .wsmenu-list > li > .wsmegamenu,
  .dark-menu .wsmenu > .wsmenu-list > li > .wsmegamenu.halfmenu, .black-scroll .wsmenu > .wsmenu-list > li > ul.sub-menu, 
  .black-scroll .wsmenu > .wsmenu-list > li > .wsmegamenu, .black-scroll .wsmenu > .wsmenu-list > li > .wsmegamenu.halfmenu { 
    border-bottom: 1px solid var(--tra-white)!important; -webkit-box-shadow: 0 0; -moz-box-shadow: 0 0;box-shadow: 0 0; 
  }

  .black-scroll .wsmenu > .wsmenu-list > li > ul.sub-menu, .black-scroll .wsmenu > .wsmenu-list > li > .wsmegamenu,
  .black-scroll .wsmenu > .wsmenu-list > li > .wsmegamenu.halfmenu { background-color: var(--metal)!important; }

  .wsmenu > .wsmenu-list > li > ul.sub-menu > li > ul.sub-menu {
    width: 100%!important; 
    min-width: 100%!important;
    box-shadow: 0 0;
    border: none;
    position: static;
    left: 0;
    top: 0;
    margin: 0;
    padding: 5px 0 0 0;
  }

  /* Sub Menu / Half Menu / Mega Menu Links */
  .wsmenu > .wsmenu-list > li > ul.sub-menu > li > a, 
  .wsmenu > .wsmenu-list > li > .wsmegamenu .link-list li a,
  .wsmenu > .wsmenu-list > li > ul.sub-menu > li > ul.sub-menu > li > a,
  .wsmenu > .wsmenu-list > li > .wsmegamenu.w-75 ul.link-list > li > a,
  .wsmenu > .wsmenu-list > li > .wsmegamenu.halfmenu ul.link-list > li > a { 
    color: var(--gray); font-size: 1.2rem; padding: 15px 14px; 
  }

  /* Black Scroll */
  .black-scroll .wsmenu > .wsmenu-list > li > ul.sub-menu > li > a,
  .black-scroll .wsmenu > .wsmenu-list > li > .wsmegamenu.w-75 ul.link-list > li > a,
  .black-scroll .wsmenu > .wsmenu-list > li > .wsmegamenu.halfmenu ul.link-list > li > a {
    color: #ddd;
    background-color: rgba(255, 255, 255, 0)!important;
  }

  .navbar-light .wsmenu-list > li > a.h-link:hover { color: var(--coal)!important; }

  /* Sub Menu / Half Menu / Mega Menu Links Hover */
  .wsmenu > .wsmenu-list > li > ul.sub-menu > li > a:hover, 
  .wsmenu > .wsmenu-list > li > .wsmegamenu .link-list li a:hover,
  .wsmenu > .wsmenu-list > li > .wsmegamenu.w-75 ul.link-list > li > a:hover,
  .wsmenu > .wsmenu-list > li > ul.sub-menu > li > ul.sub-menu > li > a:hover,
  .wsmenu > .wsmenu-list > li > .wsmegamenu.halfmenu ul.link-list > li > a:hover { padding: 15px 14px; text-decoration: none; }

  /* Black Scroll */
  .black-scroll .wsmenu > .wsmenu-list > li > ul.sub-menu > li > a:hover,
  .black-scroll .wsmenu > .wsmenu-list > li > .wsmegamenu.w-75 ul.link-list > li > a:hover,
  .black-scroll .wsmenu > .wsmenu-list > li > .wsmegamenu.halfmenu ul.link-list > li > a:hover {
    background-color: rgba(255, 255, 255, .05)!important;
  }

  .wsmenu > .wsmenu-list > li > .wsmegamenu ul li.title { font-size: 1.2rem; padding: 0 14px; margin-bottom: 10px; } 

  .dark-menu .title, .black-scroll .title { color: #b1b7cd!important; } 

  /* Button */
  .wsmenu > .wsmenu-list > li a.btn {  
    font-size: 1.25rem;
    line-height: 44px; 
    border-width: 2px;
    padding: 5px 22px;
    margin: 1px 0 2px 0;
    -webkit-border-radius: 0; 
    -moz-border-radius: 0; 
    border-radius: 0; 
  }

  .wsmenu > .wsmenu-list > li.lang > ul.sub-menu > li > a { font-size: 1.2rem; padding: 12px 14px; }
  .wsmenu > .wsmenu-list > li.lang > ul.sub-menu > li > a:hover { padding: 12px 14px; }

  .wsmenu > .wsmenu-list > li > ul.sub-menu > li > a span { display: none; }
  .wsmenu > .wsmenu-list > li > ul.sub-menu > li .wsmenu-click02 > i {
    height: 10px; width: 10px; transform: rotate(-225deg); margin: 17px 18px 0px 0px; 
  }
  .wsmenu > .wsmenu-list > li > ul.sub-menu > li .wsmenu-click02>i.wsmenu-rotate { margin-top: 22px; }

  .wsmenu > .wsmenu-list > li a.btn.reg-btn { line-height: 44px; padding: 5px 22px; }
  .wsmenu > .wsmenu-list > li a.btn.fst-btn-link, .wsmenu > .wsmenu-list > li.reg-fst-link > a { margin-left: 0; }

  /* Header Store Badges */
  .wsmenu > .wsmenu-list > li a.store.header-store { margin: 5px 0 0 12px; display: block; float: none; border-bottom: none; }
  .store.header-store img { max-height: 46px; }

  /* Header Social Links */
  .wsmenu > .wsmenu-list > li.header-socials { display: block; }
  .header-socials a { margin-left: 20px; margin-right: 5px; }
  .header-socials.ico-20 [class*="flaticon-"]:before, .header-socials.ico-25 [class*="flaticon-"]:after { font-size: 1.65rem; } 

  /* Navbar Transparent Buttons */
  .white-scroll .btn--tra-white,
  .white-scroll .btn--tra-black { 
    color: var(--white)!important; 
    background-color: var(--coal)!important; 
    border-color: var(--coal)!important; 
  }

  .black-scroll .btn--tra-white, 
  .black-scroll .btn--tra-black { 
    color: var(--coal)!important; 
    background-color: var(--white)!important; 
    border-color: var(--white)!important; 
  }

  .white-scroll .btn--tra-gray, 
  .black-scroll .btn--tra-gray { 
    color: var(--coal)!important; 
    background-color: var(--smoke)!important; 
    border-color: var(--smoke)!important; 
  }

  /* Navbar Buttons Hover */
  .white-scroll .hover--white:hover,
  .white-scroll .hover--tra-white:hover { 
    color: var(--coal)!important; 
    border-color: var(--coal)!important; 
    background-color: transparent!important; 
  }

  .theme--dark .white-scroll .hover--tra-white:hover { 
    color: var(--white)!important; 
    border-color: var(--white)!important; 
    background-color: transparent!important; 
  }

  .theme--dark .navbar-dark .hover--tra-black:hover,
  .theme--dark .navbar-dark .scroll .hover--tra-black:hover {
    color: var(--white)!important;
    background-color: transparent!important;
    border-color: var(--white)!important; 
  }

  .theme--dark .wsmenu > .wsmenu-list { background-color: var(--metal)!important; }
  .theme--dark .wsmenu > .wsmenu-list > li > .wsmenu-click > i:before { border-color: var(--white); }

  /*------------------------------------------*/
  /*  HERO   
  /*------------------------------------------*/

  .hero-section.blur--purple:after { height: 90%; top: -42%; left: 10%; }

  .hero-section { margin-top: 80px; }

  .slideshow, .slideshow .slides { height: 460px; }
  #hero-20 .slideshow, #hero-20 .slideshow .slides { height: 450px; }

  .img-link { bottom: 20px; right: 20px; width: 40px; height: 40px; }
  .img-link a span { top: 4.5px; }

  /* HERO QUICK FORM */
  .hero-section .quick-form .form-control, .hero-section .quick-form .btn { height: 48px; font-size: 1.125rem; }
  .hero-section .quick-form.form-half .form-control { height: 48px; font-size: 1.0625rem; padding: 0 12px; }
  .hero-section .quick-form .btn { height: 48px; font-size: 1rem; padding: 13px 40px; margin-left: 6px; } 
  .hero-section .quick-form.form-half .btn { height: 48px; font-size: 1rem; padding: 13px 22px; margin-left: 6px; }

  .hero-section .text-center .quick-form-msg { margin: 20px 0 -5px 0; }

  /* HERO DIGITS */
  .hero-digits { padding-right: 0 }
  #hd-1-1, #hd-1-2 { padding-right: 10px; } 
  .hero-digits h2.statistic-number { font-size: 2.85rem; }
  .hero-digits h2.statistic-number small { font-size: 2rem; }

  /* HERO-1 */
  #hero-1 { padding: 70px 0; }
  .hero-1-txt h2 { font-size: 2.79411rem; }
  .hero-1-txt p.p-lg, .hero-1-txt p.p-xl { margin: 15px 0 22px; }
  .hero-1-img { margin: 0 -5px; }

  /* HERO-2 */
  #hero-2 { padding-top: 80px; }
  #hero-2:after { height: 11%; }
  .hero-2-txt h2 { font-size: 3rem; }
  .hero-2-txt p.p-lg { padding: 0; margin-top: 12px; margin-bottom: 22px; }
  .hero-2-img { margin-top: 40px; padding: 0; }

  /* HERO-3 */
  #hero-3 { padding-top: 80px; padding-bottom: 80px; }
  .hero-3-txt h2 { font-size: 3rem; }
  .hero-3-txt p.p-md { padding-right: 0; margin-top: 12px; }
  .hero-3-txt .users { margin-top: 10px; }
  .hero-3-img { padding-right: 5px; }

  /* HERO-4 */
  #hero-4 { padding-top: 70px; padding-bottom: 35px; }
  .hero-4-txt h2 { font-size: 2.79411rem; }
  .hero-4-txt p.p-lg { margin: 15px 0 22px; }
  .hero-4-img { margin: 0 -98% 0 15px; }

  /* HERO-5 */
  #hero-5 { padding-top: 50px; padding-bottom: 40px; }
  #hero-5:after { width: 40%; }
  .hero-5-txt { margin-left: 0; }
  .hero-5-txt h2 { font-size: 2.85rem; line-height: 1.3; }
  .hero-5-txt p.p-md { padding-right: 0%; margin-top: 15px; margin-bottom: 20px; }

  /* HERO-6 */
  #hero-6 { padding-top: 80px; padding-bottom: 80px; }
  .hero-6-txt h2 { font-size: 2.9rem; }
  .hero-6-txt p.p-lg { margin-top: 15px; margin-right: -10px; }
  #hero-6 .quick-form { margin-top: 20px; margin-right: 2%; }
  .hero-6-img { padding-left: 5px; }

  /* HERO-7 */
  #hero-7 { padding-top: 70px; }
  .hero-7-txt { margin-top: -20px; }
  .hero-7-txt h2 { font-size: 2.85rem; padding-right: 4%; }
  .hero-7-txt p.p-lg, .hero-7-txt p.p-xl { margin: 12px 0 22px; }
  #hero-7 .users { margin-top: 14px; }
  #hero-7 .users img { max-height: 35px; }
  .hero-7-img { margin: 0 -5% 0 -3%; }

  /* HERO-8 */
  #hero-8 { padding-top: 80px; }
  .hero-8-txt h2 { font-size: 3rem; }
  .hero-8-txt p.p-lg { padding: 0; margin-top: 12px; margin-bottom: 22px; }
  .hero-8-txt p.btn-txt { margin-top: 25px; }
  #hero-8 .quick-form { margin: 22px 6% 0; }
  .hero-8-img { margin-top: 45px; }

  /* HERO-9 */
  #hero-9 { padding-top: 80px; padding-bottom: 70px; }
  #hero-9 .wave-shape-bottom svg { height: 45px; }
  .hero-9-txt { margin-top: 0; }
  .hero-9-txt h2 { font-size: 2.9rem; }
  .hero-9-txt p.p-lg { margin-top: 15px; margin-right: -10px; }
  #hero-9 .quick-form { margin-top: 20px; margin-right: 2%; }
  .hero-9-img { padding-left: 5px; }

  /* HERO-10 */
  #hero-10 { padding-top: 80px; }
  .hero-10-txt { margin-bottom: 40px; }
  .hero-10-txt h2 { font-size: 3rem; padding: 0 3%; }
  .hero-10-txt p.p-lg { padding: 0; margin-top: 12px; margin-bottom: 22px; }
  #hero-10 .hero-overlay { padding: 65px 45px 0; }
  .hero-10-img .video-btn { top: calc(50% - 50px); }

  /* HERO-11 */
  #hero-11 { padding: 70px 0; }
  .hero-11-txt h2 { font-size: 2.7rem; }
  .hero-11-txt p.p-md { margin: 15px 0 18px; padding-right: 3%; }
  .hero-11-img { margin: 0 -5px; }

  /* HERO-12 */
  #hero-12 { padding-top: 40px; }
  .hero-12-wrapper { padding: 55px 40px; margin: 0 -10px; }
  .hero-12-txt h2 { font-size: 2.45rem; }
  .hero-12-txt p.p-lg { margin-top: 14px; margin-bottom: 20px; }

  /* HERO-13 */
  #hero-13 { padding-top: 80px; }
  .hero-13-txt h2 { font-size: 3.1rem; }
  .hero-13-txt p.p-xl { padding: 0; margin-top: 15px; }
  #hero-13 .quick-form { margin: 25px 9% 0; }
  .hero-13-txt p.btn-txt { margin-top: 25px; }

  /* HERO-14 */
  #hero-14 { padding-top: 80px; }
  .hero-14-txt { margin-top: -75px; padding-right: 0; }
  .hero-14-txt .section-id { padding: 10px 20px; font-size: 0.845rem; margin-bottom: 22px; }
  .hero-14-txt h2 { font-size: 2.79411rem; }
  .hero-14-txt p.p-md { margin: 18px 0 15px; }
  .hero-14-img { margin: 0 -90% 0 0; }

  /* HERO-15 */
  #hero-15 .hero-overlay { padding-top: 80px; padding-bottom: 70px; }
  .hero-15-txt h2 { font-size: 3rem; }
  .hero-15-txt p.p-lg { padding: 0 3%; margin-top: 15px; margin-bottom: 22px; }

  /* HERO-16 */
  #hero-16 { padding-top: 80px; padding-bottom: 80px; }
  .hero-16-txt h2 { font-size: 2.79411rem; }
  .hero-16-txt p.p-lg { margin-top: 15px; margin-right: -10px; }
  #hero-16 .quick-form { margin-top: 20px; margin-right: 2%; }
  #hero-16 .users { margin-top: 0; }
  #hero-16 .users img { max-height: 38px; }
  .hero-16-img { padding-left: 5px; }

  /* HERO-17 */
  #hero-17 { padding-top: 80px; }
  #hero-17:after { height: 9%; }
  .hero-17-txt h2 { font-size: 3rem; padding: 0 4%; }
  .hero-17-txt p.p-xl { padding: 0;  margin-top: 16px; }
  .hero-17-txt .btns-group { margin-top: 24px; }
  .hero-17-img { margin-top: 50px; }

  /* HERO-18 */
  #hero-18 { padding-top: 80px; }
  .hero-18-txt { margin-bottom: 50px; }
  .hero-18-txt .section-id { margin-bottom: 25px; }
  .hero-18-txt h2 { font-size: 3.05rem; }
  .hero-18-txt p.p-xl { padding: 0; margin-top: 14px; }
  .hero-18-txt .btns-group { margin-top: 22px; }

  .h18-1 { margin-bottom: 24px; }

  .hero-bg-shape { width: 100%; height: 100%; top: -5%; left: 0; right: 0; opacity: .25; }

  /* HERO-19 */
  #hero-19 { padding-top: 80px; }
  .hero-19-txt h2 { font-size: 3.1rem; }
  .hero-19-txt p.p-xl { padding: 0; margin-top: 15px; }
  #hero-19 .quick-form { margin: 25px 9% 0; }
  .hero-19-txt p.btn-txt { margin-top: 25px; }
  .hero-19-img { margin-top: 40px; }

  /* HERO-20 */
  #hero-20 { padding-top: 30px; }
  #hero-20 .caption { margin-top: 0; }
  #hero-20 .caption h2 { font-size: 3.5rem; padding: 0 10%; }
  #hero-20 .caption p.p-xl { padding: 0 8%; margin-top: 15px; }
  #hero-20 .caption .btn { margin-top: 26px; }

  /* HERO-21 */
  #hero-21 { padding-top: 80px; }
  .hero-21-shape { width: 100%; top: -2%; left: 0; opacity: .5; }
  #hero-21:after { height: 14%; }
  .hero-21-txt h2 { font-size: 3rem; }
  .hero-21-txt p.p-xl { padding: 0;  margin-top: 16px; }
  .hero-21-txt .btns-group { margin-top: 24px; }
  .hero-21-img { margin-top: 50px; }

  /* HERO-22 */
  #hero-22 .caption { margin-top: 5px; }
  #hero-22 .caption h2 { font-size: 3.75rem; padding: 0 8%; }
  #hero-22 .caption p.p-xl { padding: 0 8%; margin-top: 15px; }
  #hero-22 .caption .btn { margin-top: 26px; }

  /* HERO-23 */
  #hero-23 { padding-top: 70px; }
  .hero-23-txt h2 { font-size: 3rem; }
  .hero-23-txt p.p-lg { padding: 0; margin-top: 12px; margin-bottom: 22px; }
  .hero-23-img { margin-top: 40px; padding: 0; }

  /* HERO-24 */
  #hero-24 { padding-top: 80px; padding-bottom: 15px; }
  .hero-24-txt { margin-top: -45px; padding-right: 0; }
  .hero-24-txt h2 { font-size: 2.85rem; }
  .hero-24-txt p.p-lg { margin-top: 15px; }
  .hero-24-txt .users { margin-top: 10px; }

  #hero-24-form { padding: 20px 20px 24px; margin-left: 15px; }
  #hero-24-form h5 { font-size: 1.5rem; margin-bottom: 10px; }
  #hero-24-form p { margin-bottom: 15px; }
  #hero-24-form p.p-sm {  padding: 0!important; }
  #hero-24-form .btn { height: 52px; font-size: 1.1rem; padding: 16px; }

  /* HERO-25 */
  #hero-25 { padding-top: 70px; }
  .hero-25-txt h2 { font-size: 3.5rem; }
  .hero-25-txt p.p-lg { padding: 0 3%; margin-top: 12px; margin-bottom: 22px; }
  .hero-25-txt p.btn-txt { margin-top: 15px; }
  .hero-25-img { margin-top: 40px; margin-bottom: -100px; }
  .hero-25-img .video-btn { top: calc(50% - 30px); }

  /* HERO-26 */
  #hero-26 { padding-top: 80px; }
  .hero-26-txt { padding: 0 8%; }
  .hero-26-txt h2 { font-size: 3.7rem; }
  .hero-26-txt p.p-lg { padding: 0; margin-top: 15px; margin-bottom: 25px; }
  .hero-26-img { margin: 45px -1% 0; }

  /* HERO-27 */
  #hero-27 { padding-top: 50px; }
  .hero-27-txt { padding-right: 0; }

  .hero-27-txt .hero-logo { display: none; }

  .hero-27-txt h2 { font-size: 3rem; line-height: 1.25; margin-bottom: 25px; }
  .hero-27-txt p { margin-top: 50px; }

  #hero-27 .img-link { bottom: 8px; left: 10px; width: 50px; height: 50px; }
  #hero-27 .img-link a span { top: 7px; }

  /* HERO REQUEST FORM */
  .request-form .form-control { height: 52px; font-size: 1.1rem; margin-bottom: 18px; }
  
  /*------------------------------------------*/
  /*  CONTENT
  /*------------------------------------------*/

  /* ABOUT */
  .about-1-title { margin-bottom: 25px; }
  .about-2-title { padding-right: 2%; }

  .about-1-title h2 { padding: 0 4%; }
  .about-1-title p { margin-top: 16px; }

  #about-3 .txt-block h5 { margin-bottom: 14px; }

  .about-1-img { margin: 0 -25%; }

  /* FEATURES */
  .fbox-12-wrapper { padding-left: 0; }

  #features-1 .col { padding: 0 9px; }
  #features-5 .col, #features-9 .col-md-4, #features-11 .col { padding: 0 6px; }

  #features-3.py-100.shape--bg:after { width: 52%; height: 68%; top: 16%; left: 48%; }
  #features-3.pt-100.shape--bg:after { width: 52%; height: 75%; top: 19%; left: 48%; }
  #features-4.py-100.shape--bg:after { width: 52%; height: 68%; top: 16%; }
  #features-4.pt-100.shape--bg:after { width: 52%; height: 75%; top: 19%; }

  .fbox-3-wrapper { padding-left: 0; }
  .fbox-4-wrapper { padding-right: 0; }

  .row-cols-md-2 .fb-1, .row-cols-md-2 .fb-2, .rows-2 .fb-1, .rows-2 .fb-2, .rows-2 .fb-3  { margin-bottom: 35px; }
  .rows-3 .fb-1, .rows-3 .fb-2, .rows-3 .fb-3, .rows-3 .fb-4 { margin-bottom: 30px; }
  #fb-3-1, #fb-3-3, #fb-4-1, #fb-4-3, .rows-4 .fb-1, .rows-4 .fb-2, .rows-4 .fb-3, .rows-4 .fb-4, 
  .rows-4 .fb-5, .rows-4 .fb-6, .fbox-6.fb-1, .fbox-6.fb-2, .fbox-6.fb-3.rows-4 .fbox-2.fb-1, .rows-4 .fbox-2.fb-2, 
  .rows-4 .fbox-2.fb-3, .rows-4 .fbox-2.fb-4, .rows-4 .fbox-2.fb-5, .rows-4 .fbox-2.fb-6 { margin-bottom: 24px; }

  .fbox-12.fb-1, .fbox-12.fb-2 { margin-bottom: 20px; }

  .fbox-3, .fbox-4 { padding: 22px 16px; } 
  .fbox-5 { padding: 30px 16px; }
  .fbox-6 { padding: 30px 30px; }

  .fbox-9 { padding: 25px 16px; }
  .ico-title .fbox-9.fb-1, .ico-title .fbox-9.fb-2, .ico-title .fbox-9.fb-3 { padding: 20px 16px; }
  .fbox-9.fb-4, .fbox-9.fb-5, .fbox-9.fb-6, .ico-title .fbox-9.fb-4, 
  .ico-title .fbox-9.fb-5, .ico-title .fbox-9.fb-6 { padding: 20px 16px 0; }

  .fbox-10, .fbox-10.fb-5, .fbox-10.fb-6, .fbox-10.fb-7, .fbox-10.fb-8 { padding: 25px 18px; } 
  .ico-title .fbox-10.fb-5, .ico-title .fbox-10.fb-6 { padding: 25px 18px 20px; } 
  .ico-title .fbox-10.fb-7, .ico-title .fbox-10.fb-8 { padding: 25px 18px 0; } 

  .fbox-11.fb-1, .fbox-11.fb-2 { margin-bottom: 20px; } 

  .fbox-7 .fbox-img { margin-bottom: 20px; }

  .fbox-img.h-175 img, .fbox-img.h-180 img { max-height: 125px; }
  .fbox-8 .fbox-img.h-175 img, .fbox-8 .fbox-img.h-180 img { max-height: 120px; }
  .fbox-8 .fbox-img.h-175, .fbox-8 .fbox-img.h-180 { padding-top: 20px; margin: 0 0 45px; } 

  .fbox-9.fb-1 .fbox-9-img, .fbox-9.fb-2 .fbox-9-img, .fbox-9.fb-3 .fbox-9-img { margin: 15px 0 0; }
  .fbox-9.fb-4 .fbox-9-img, .fbox-9.fb-5 .fbox-9-img, .fbox-9.fb-6 .fbox-9-img { margin: 15px 0 -16px; }
  .fbox-9.fb-7 .fbox-9-img, .fbox-9.fb-8 .fbox-9-img, .fbox-9.fb-9 .fbox-9-img { margin: 0 0 15px; }

  .fbox-10.fb-1 .fbox-10-img, .fbox-10.fb-7 .fbox-10-img, .fbox-10.fb-8 .fbox-10-img { margin: 25px 4% 0; }
  .fbox-10.fb-2 .fbox-10-img, .fbox-10.fb-3 .fbox-10-img, .fbox-10.fb-4 .fbox-10-img { margin: 0 4% 25px; }
  .fbox-10.fb-5 .fbox-10-img, .fbox-10.fb-6 .fbox-10-img { margin: 30px 0 0; }

  .fbox-ico-wrap { margin-right: 16px; }
  .fbox-wrapper .row-cols-md-2 .ico-50 [class*="flaticon-"]:before, 
  .fbox-wrapper .row-cols-md-2 .ico-50 [class*="flaticon-"]:after { font-size: 3.65rem; }

  .fbox-wrapper .row-cols-md-2 .fbox-2 .ico-50 [class*="flaticon-"]:before, 
  .fbox-wrapper .row-cols-md-2 .fbox-2 .ico-50 [class*="flaticon-"]:after { font-size: 3.4rem; }

  .fbox-wrapper .row-cols-md-2 .ico-rounded { width: 80px; height: 80px; }
  .fbox-wrapper .row-cols-md-2 .ico-rounded [class*="flaticon-"]:before, 
  .fbox-wrapper .row-cols-md-2 .ico-rounded [class*="flaticon-"]:after { font-size: 2.875rem; line-height: 80px!important; }

  .fbox-ico, .fbox-ico.ico-rounded, .fbox-5 .fbox-ico { margin-bottom: 18px; }
  .fbox-ico[class*="img-"], .fbox-ico.ico-rounded-md, .fbox-ico.ico-rounded-lg { margin-bottom: 20px; }
  .t-icon { margin-bottom: 2px; }
  .fbox-6 .fbox-ico { margin-bottom: 14px; }

  .fbox-11-ico { margin-bottom: 14px; }

  .fbox-3 h6, .fbox-4 h6 { font-size: 1.15rem; margin: 18px 0 10px; }
  .rows-3 .fbox-2 h5, .fbox-wrapper .row-cols-md-2 h6, 
  .fbox-wrapper .row-cols-md-3 h6, .fbox-6 h6, .fbox-9 h6, .fbox-12 h5 { margin-bottom: 10px; }
  .fbox-5 h6.h6-md { font-size: 1.397058rem; }
  .fbox-10 h5, .ico-title .fbox-10.fb-5 h5, .ico-title .fbox-10.fb-6 h5 { margin-bottom: 12px; }
  .fbox-10.fb-5 h5, .fbox-10.fb-6 h5 { margin-bottom: 16px; }
  .fbox-11 h6 { font-size: 1.25rem; margin-bottom: 12px; }

  .fbox-5 .txt-block-tra-link { margin-top: 12px; }

  /* CONTENT */
  .ct-05 .section-title.mb-70 {margin-bottom: 40px; }
  .bc-03 .section-title h2 { padding: 0 3%; }
  .bc-03 .section-title p { padding: 0; }

  .ct-06 .section-overlay { margin-bottom: 80px; }
  .bc-01 .section-overlay { padding: 60px 35px; }
  .bc-02 .section-overlay { padding: 40px 30px; }
  .bc-02 .ico-title .section-overlay { padding: 30px; }
  .bc-02.pt-30 .section-overlay, .bc-02.pb-30 .section-overlay { padding: 30px 20px; }
  .bc-02.pt-30.bc-02-top { padding-top: 20px; margin-top: 50px; }
  .bc-03 .section-overlay { padding: 60px 40px 0; }
  
  .bc-01 .txt-block.left-column { padding-right: 5px; }
  .bc-01 .txt-block.right-column { padding-left: 5px; }

  .two-txt .txt-block.left-column { padding-right: 0; }
  .ct-07-txt { padding-left: 0; }

  .txt-box, .bc-1-wrapper .txt-box { margin-bottom: 15px; }
  .two-txt .txt-box { margin-bottom: 12px; }
  .bc-04-box { padding: 35px 50px; }
  #bc-4-3 { margin-top: 24px; }
  
  .txt-block h3, .txt-block h2 { margin-bottom: 14px; }
  .txt-block h5, .bc-04-box h2 { margin-bottom: 12px; }
  h5.h5-title { margin: 12px 0; }

  ul.simple-list li:not(:last-child) p { margin-bottom: 8px; }

  .txt-block-stat.mt-30 { margin-top: 20px; }
                  
  .txt-block .btn { margin-top: 18px; }
  .txt-block-tra-link { margin-top: 16px; }
  .txt-block .stores-badge { margin-top: 5px; }

  .cbox-1 .ico-wrap { margin-right: 1.25rem; }
  .cbox-1-ico { width: 2rem; height: 2rem; font-size: 1rem; line-height: 1.65rem; margin-top: 2px; }

  .ct-04 .cbox-3 { margin-right: -15px; }
  .cbox-3 .ico-wrap { margin-right: 1.25rem; }
  .cbox-3 .cbox-3-ico { top: 4px; }
  .cbox-3-ico span[class*="flaticon-"]:before, .cbox-3-ico span[class*="flaticon-"]:after { font-size: 2.75rem; }

  .cbox-1-txt, .cbox-3-txt { margin-bottom: 20px; }
  .two-txt .cbox-3-txt { margin-bottom: 18px; }
  .cbox-6-txt { margin-bottom: 22px; }
  .cbox-1-txt h5, .cbox-3-txt h5 { margin-bottom: 10px; }

  .cbox-4 { width: 280px; min-width: 280px; padding: 13px 22px; }
  .cbox-4.mt-20 { margin-top: 8px; }
  .cbox-4.mb-30 { margin-bottom: 20px; }
  .cbox-4-txt { padding-left: 13px; }

  .cbox-5-wrapper { margin-top: -3px; }
  .cbox-title { margin-bottom: 12px; }
  .cbox-title span { margin-right: 16px; }

  .txt-block .accordion-1 .accordion-item { padding: 18px 0 23px; }
  .txt-block .accordion-1 .accordion-item .accordion-thumb:after,
  .txt-block .accordion-1 .accordion-item.is-active .accordion-thumb:after { font-size: 0.95rem; top: -1px; right: 5px; }

  .txt-block .accordion-1 .accordion-thumb h5 { padding-left: 0; }
  .txt-block .accordion-1 .accordion-item.is-active .accordion-thumb h5 { margin-bottom: 16px; }
  .txt-block .accordion-1 .accordion-panel { padding: 0; }

  /* Advantages */
  .text-center .advantages { margin-top: 15px; }

  /* Compatibility */
  .compatibility { margin-top: 5px; }

  /* Tools List / Payments Icons */
  .tools-list p, .payment-methods p { margin-bottom: 10px; }
  .payment-icons img { max-height: 26px; }

  .tools-list.ico-35 [class*="flaticon-"]:before, 
  .tools-list.ico-35 [class*="flaticon-"]:after { font-size: 2rem; line-height: 2rem!important; margin-right: 4px; }

  /* Quote */
  .quote-avatar img { width: 54px; height: 54px; }
  .quote p { font-size: 1.05rem; padding-left: 14px; margin-top: 15px; }
  .quote-author { padding: 0 0 0 10px; } 
  .quote.pr-15, .quote.pr-40, .quote.pr-50, .quote.pr-60 { padding-right: 0; }

  /* Content Image */
  .ct-07-images { margin: 30px 0 35px; }
  .img-block-hidden, .ct-06 .img-block { margin-bottom: -80px; }
  .img-block-txt, .img-block-btn { margin-top: 40px; }

  .bc-3-img .video-btn { top: calc(50% - 60px); }

  .ct-01 .img-block.right-column, .ct-02 .img-block.right-column, 
  .ct-03 .img-block.right-column, .bc-4-img.right-column { margin-left: 0; }

  .ct-01 .img-block.left-column, .ct-02 .img-block.left-column, 
  .ct-03 .img-block.left-column, .bc-4-img.left-column { margin-right: 0; }

  .ct-04 .img-block img { max-width: 215%; margin-left: 15px; }

  .bc-2-img.left-column { margin-right: 5px; }
  .bc-2-img.right-column { margin-left: 5px; }
  .bc-3-img { margin-bottom: -150px; }
  .bc-4-img { display: none; }

  /* STATISTIC */
  .statistic-7-wrapper { text-align: center; padding: 40px 50px; }
  .statistic-8-wrapper { padding: 0; }
  .statistic-7-wrapper .col-lg-4 { margin-bottom: 30px; }
  #sb-2-1 { padding-left: 10%; }

  #statistic-6 .statistic-block-txt { padding: 0; }
  #statistic-6 .statistic-block-txt:before { width: 70px; margin: 20px auto 15px; }

  #statistic-1 .statistic-block { display: block!important; text-align: center; }
  .statistic-1-wrapper .statistic-block-digit { width: 100%; display: block!important; float: none; margin-bottom: 12px; }
  .statistic-1-wrapper .statistic-block-txt { width: 100%; display: inline-block!important; padding: 0 8%; }
  .statistic-4-wrapper .statistic-block { padding: 10px 0 10px 15px; }
  .statistic-8-wrapper .statistic-block { padding: 10px 15px 10px 0; }

  .statistic-ico { margin-bottom: 10px; }

  #statistic-1 h2.statistic-number { font-size: 2.9rem; line-height: 1; letter-spacing: -1px; }
  .statistic-2-wrapper h2,  #sb-3-1 h2.statistic-number, #sb-3-2 h2.statistic-number { margin-bottom: 12px; }
  .statistic-4-wrapper h5 { margin: 16px 0 12px; }
  .statistic-7-wrapper h2 { font-size: 2.75rem; }
  .statistic-9-wrapper h3, .statistic-9-wrapper h2 { margin: 25px 0 12px; }

  .statistic-1-wrapper p { line-height: 1.5; top: 0; }
  .statistic-5-wrapper p { margin-top: 12px; }
  .statistic-7-wrapper .statistic-block p { margin-top: 10px; }
  .statistic-8-wrapper p { margin-top: 16px; }

  .statistic-8-wrapper img { max-height: 40px; margin-top: 16px; }
  .statistic-section .action-btn { margin-top: 40px; }

  /* PROJECTS */
  #portfolio-1.pb-70 { padding-bottom: 46px; }
  #portfolio-1 .filter-btns.mb-70 { margin-bottom: 40px; }
  #portfolio-1 .filter-item { margin-bottom: 24px; }

  .project-category { left: 24px; }
  .project-category p { padding: 7px 16px; margin-bottom: 0; }

  .project-data-txt { bottom: 25px; }
  .project-data-txt h5 { padding-right: 15%; }

  .project-source { width: 32px; height: 32px; bottom: 18px; }
  .project-source a span { top: 3px; }

  .filter-item:hover .project-category { top: 20px; }
  .filter-item:hover .project-data-txt { left: 25px; }
  .filter-item:hover .project-source { right: 18px; }

  /* PROJECT DETAILS */
  .project-title { padding: 0 8%; margin-bottom: 40px; }
  .project-title p { margin-top: 15px; }
  .project-data { padding-bottom: 40px; margin: 40px 0; }
  .project-data p { margin-bottom: 4px; }

  .project-txt h5, .project-txt h6 { margin: 30px 0 15px; }

  .project-inner-img { margin: 35px 0; }
  .project-inner-img.d-flex { margin-left: -12px; margin-right: -12px; }

  #project-details .more-projects { margin-top: 45px; }
  #project-details .more-projects span { top: 1px; left: 4px; }
  #project-details .more-projects.ico-25 [class*="flaticon-"]:before, 
  #project-details .more-projects.ico-25 [class*="flaticon-"]:after { font-size: 1.175rem; } 

  /* TEAM */
  .team-member-photo { margin-bottom: 20px; }
  .team-member-data h6 { font-size: 1.25rem; }

  /* CAREERS */
  .career-item { padding: 25px 0; }
  .careers-section .more-btn { margin-top: 15px; }

  /* JOB POSITION */
  #job-position .inner-page-title { padding-bottom: 40px; margin-bottom: 35px; }
  #job-position .inner-page-title p { margin-top: 15px; }
  #job-position .inner-page-title .btn { padding: 0.7rem 1.1rem; margin-top: 2px; }

  .position-info h5 { margin: 35px 0 15px; }
  .position-info .btn { margin-top: 20px; }

  /* PRICING */
  #pricing-1 .section-title.mb-70 { margin-bottom: 40px; }
  #pricing-1 .container, .pricing-1-wrapper .col { padding: 0 5px; }

  .pricing-1-table { padding: 30px 25px 25px; }
  .text-center .pricing-1-table { padding: 35px 25px 25px; }

  .pricing-table-header h5 { margin-bottom: 20px; }

  .pricing-1-table hr { margin-top: 30px; }
 
  .pricing-discount { top: 0; right: -2px; padding: 6px 11px; }
  .pricing-1-table .pricing-discount { top: -12px; right: -3px; padding: 5px 9px; }
  .text-center .pricing-1-table .pricing-discount { top: -30px; right: -5px; }
  .pricing-discount h6 { font-size: 1rem; }
  .pricing-1-table .pricing-discount h6 { font-size: 0.9rem; }

  /* Price */
  .price span { font-size: 2.45rem; letter-spacing: -1.5px; } 
  .price sup { font-size: 2.125rem; top: -2px; }
  .price sup.validity { font-size: 1.5rem; }

  .pt-btn { font-size: 0.95rem; padding: 0.785rem 0.5rem; margin-top: 25px; }
  .pricing-1-table .pt-btn { margin-top: 15px; }
  .p-table p.btn-txt { font-size: 0.935rem; }

  .price p { font-size: 0.95rem; margin: 14px 0; }
  .text-center .price p { padding: 0; }
  .pricing-table-header p.p-md { font-size: 0.915rem; }

  .pricing-features.mt-25 { margin-top: 15px; }
  .pricing-features li, .text-center .pricing-features li { padding: 9px 0; }
  .pricing-features span { display: none; }
  .pricing-features p.p-md { font-size: 0.975rem; line-height: 1.5; }

  /* Pricing Notice */
  .pricing-notice { margin-top: 0; }
  .col-lg-9 .pricing-notice p { padding: 0 15%; }
  .col-lg-10 .pricing-notice p { padding: 0 8%; }
  .pricing-notice .btn.mt-25 { margin-top: 20px; }

  /* Pricing Toggle Button */
  .pricing-section .toggle-btn.mt-30, .toggle-btn.toggle-btn-md.mt-40 { margin-top: 20px; }
  .toggle-btn-md .toggler-txt, .toggle-btn-lg .toggler-txt { font-size: 1rem; line-height: 32px; }

  .switch-wrap { min-width: 50px; height: 30px; }
  .toggle-btn-md .switch-wrap, .toggle-btn-lg .switch-wrap { min-width: 55px; height: 32px; margin: 0 6px; }

  .switcher { font-size: 0.85rem; height: 30px; }
  .toggle-btn-md .switcher, .toggle-btn-lg .switcher { height: 32px; padding: 0 10px 0 30px; border-radius: 32px; }

  .switcher .show-annual, .switcher .show-monthly { line-height: 30px; }
  .toggle-btn-md .switcher .show-annual, .toggle-btn-md .switcher .show-monthly,
  .toggle-btn-lg .switcher .show-annual, .toggle-btn-lg .switcher .show-monthly { line-height: 32px; }

  .switcher:before { left: 4px; bottom: 4px; width: 22px; height: 22px; }
  .toggle-btn-md .switcher:before, .toggle-btn-lg .switcher:before { left: 3px; bottom: 3px; width: 22px; height: 22px; }
  .switcher.bg--grey:before, .switcher.bg--tra-white:before, .switcher.bg--ghost-white:before { left: 4px; bottom: 3px; }

  .toggle-btn-md .switch-wrap input:checked + .switcher, 
  .toggle-btn-lg .switch-wrap input:checked + .switcher { padding: 0 30px 0 10px; }

  /* Pricing Compare */
  .table-responsive.mb-50 { margin-bottom: 30px; }
  .table>:not(caption)>*>* { padding: 12px 0x; }
  .comp-table .table-responsive thead th { font-size: 1.1rem; }
  .comp-table .table-responsive tbody th { font-size: 1.0625rem; }
  .comp-table .table-responsive tbody td { font-size: 1.0625rem; top: 1px; }

  .comp-table .table-responsive tbody td span { top: 1px; }

  .table-responsive .ico-15 [class*="flaticon-"]:before, 
  .table-responsive .ico-15 [class*="flaticon-"]:after { font-size: 0.715rem; }

  .table-responsive .ico-20 [class*="flaticon-"]:before, 
  .table-responsive .ico-20 [class*="flaticon-"]:after { font-size: 0.925rem; }

  .comp-table-payment .col { padding: 0 10px; }
  .comp-table-payment h6 { font-size: 1.15rem; margin-bottom: 14px; }
  .payment-icons li { padding-right: 2px; }
  .comp-table-payment .payment-icons { margin-top: 18px; }
  .comp-table-payment .payment-icons img { width: auto; max-width: inherit; max-height: 22px; }

  /* RATING */
  .rating-section .rating-1-wrapper.text-center, .rating-section .rating-2-wrapper.text-center { padding: 0; }

  .rbox-1 .star-rating { font-size: 1rem; }
  .rbox-1 .star-rating span { top: -1.5px; }
  .rbox-2-txt { margin-top: 8px; }
  p.rbox-2-txt { font-size: 1.125rem; line-height: 1; }

  .rbox-1-img { margin-bottom: 12px; }
  .rbox-1-img img { max-height: 28px; }
  .rbox-2-txt img { max-height: 28px; margin: 10px auto 0; }

  .rbox-2 .ico-20 [class*="flaticon-"]:before, .rbox-2 .ico-20 [class*="flaticon-"]:after { font-size: 1.125rem; } 

  /* TESTIMONIALS */
  #reviews-3 { margin: 0; }
  #reviews-5 { padding-bottom: 46px; }

  #reviews-3 .container { max-width: 100%; padding: 0; }
  .reviews-3-wrapper { padding: 70px 40px; border-radius: 0 0!important;}
  .reviews-4-txt.left-column { padding: 0 20px 0 35px; }
  #reviews-3 .txt-block { text-align: center; margin-bottom: 40px; }

  .review-1 { padding: 25px 20px 30px; margin: 0 10px 30px; }
  .review-2 { padding: 0 20px; }
  .review-3 { padding: 20px 20px 25px; }
  #rw-3-1 { width: 48%; display: inline-block; float: left; margin: 0 20px 0 0; }
  #rw-3-2 { width: 48%; display: inline-block; margin: 0; }

  #reviews-5 .filter-item { width: 50%; padding: 0 10px; margin-bottom: 24px; }
  .review-5 { padding: 25px 20px 30px; }

  .review-5-logo { margin-left: 0; margin-bottom: 35px; }
  .review-5-logo img { max-height: 40px; }

  .review-1 .review-rating { margin-bottom: 6px; }
  .review-rating.ico-15 [class*="flaticon-"]:before, .review-rating.ico-15 [class*="flaticon-"]:after { font-size: 1.125rem; }

  .review-2-txt h5 { margin: 10px 0 12px; }
  .reviews-4-txt h5 { margin: 0 0 15px; }
  #reviews-3 .txt-block p { padding: 0; margin-top: 15px; }
  #reviews-3 .txt-block .btn { margin-top: 22px; }

  .review-5-avatar { right: 20px; bottom: 30px; }
  .review-avatar img, .review-5-avatar img { width: 40px; height: 40px; }

  .review-1 .review-author { margin-top: 0; }
  .reviews-4-txt .review-author { margin-top: 20px; }
  .review-5 .review-author.mt-30 { margin-top: 20px; }

  /* BRANDS */
  .brands-2-wrapper .col { padding: 0 10px; }
  .brands-4-wrapper { padding-left: 0; margin-top: -5px; }

  #brand-3-1, #brand-3-2, #brand-3-3, #brand-3-4, #brand-3-5 { margin-bottom: 35px; }
  #brand-4-1, #brand-4-2, #brand-4-3, #brand-4-4, #brand-4-5, #brand-4-6 { margin-bottom: 25px; }

  .brands-title h6 { margin-bottom: 30px; }
  .brands-title h5 { margin-bottom: 40px; }
  .brands-title p { padding: 0 10%; margin-bottom: 25px; }

  .brands-carousel-5 .brand-logo, .brands-carousel-6 .brand-logo { padding: 0 10px; } 
  #brands-3 .brand-logo { padding: 0 3px; } 
  #brands-4 .brand-logo { padding: 0 9px; }

  .brands-section .more-btn { margin-top: 25px; }

  /* INTEGRATIONS */
  #integrations-4.pt-100.pb-70 { padding-bottom: 46px; }
  .int-3-wrapper, .int-4-wrapper { padding: 0; }

  .filter-btns button { font-size: 1.15rem; margin: 0 12px; }

  .filter-item { width: 50%; padding: 0 10px; margin-bottom: 20px; }
  .int_tool-1 { padding: 18px 22px; margin-bottom: 20px; }
  .int_tool-2 { padding: 16px 22px; }
  .int_tool-3 { padding: 20px; margin-bottom: 24px; }
  .int_tool-4 { padding: 16px; margin-bottom: 24px; }
  .int-2-wrapper .int_tool-2 { margin-bottom: 20px; }

  .int_tool-1 .tool_logo { margin-right: 20px; }
  .int_tool-1 .tool_logo img { width: 50px; height: 50px; }
  .int_tool-2 .tool_logo img { width: 36px; height: 36px; }
  .int_tool-3 img { width: 50px; height: 50px; }
  .int_tool-4 img { width: 46px; height: 46px; }

  .tool_txt h6 { font-size: 1.323529rem; margin-top: 3px; }
  .tool_logo h6 { line-height: 36px; top: 3px; margin-left: 10px; }
  .int_tool-4 h6 { font-size: 0.95rem; margin-top: 14px; }
  .tool_txt p { font-size: 1.15rem; margin-top: 2px; }
  .tool_description p { font-size: 1rem; margin-top: 12px; }

  .integrations-section .more-btn.mt-30 { margin-top: 20px; }

  /* BANNER */
  .banner-1-wrapper { padding: 50px 40px; }
  .banner-2-wrapper .banner-overlay { padding: 50px; } 
  #banner-3 .banner-overlay.pt-80.pb-90 { padding: 45px 0 55px; }
  .banner-4-wrapper { padding: 35px 40px; }

  .banner-section .section-id { margin-bottom: 18px; }

  .banner-2-txt h2 { margin-bottom: 14px; }
  .banner-3-txt h2, .banner-9-txt h2 { margin-bottom: 14px; }
  .banner-3-txt h2.h2-xl, .banner-3-txt h2.h2-title { margin-bottom: 25px; }

  .banner-section p { margin-bottom: 20px; }
  #banner-1.banner-section p { margin: 14px 0 18px; }
  #banner-2.banner-section p { padding: 0; }

  .banner-1-txt .btn { margin-right: 12px; }
  .banner-4-btn { top: 8px; }
  .banner-4-btn p { margin: 10px 0 0!important; }
  .banner-section p.btn-txt { margin-top: 15px; }

  /* DOWNLOAD */
  .version-release { margin-bottom: 50px; }
  .release-data { padding-bottom: 20px; margin-bottom: 20px; }
  .release-data h5 { margin-bottom: 30px; }

  /* FAQs */
  #faqs-1 .section-title, #faqs-3 .section-title { margin-bottom: 30px; } 
  #faqs-2 .section-title { margin-bottom: 40px; } 

  #faqs-2 .question { margin-bottom: 20px; }
  .faqs-2-questions .left-column { padding-right: 0; }
  .faqs-2-questions .right-column { padding-left: 0; }

  #faqs-2 .question h6, #faqs-2 .question h5 { padding-right: 0; margin-bottom: 10px; }
  .faqs-3-question h5 { margin-bottom: 12px; }

  .accordion-thumb { padding: 20px 0; }
  #faqs-3.faqs-section .accordion-thumb { padding: 20px 0 25px; }

  .accordion-panel { padding: 0 0 5px 0; }
  #faqs-3 .accordion-panel { padding: 25px 0 10px 0; } 
  #faqs-3 .accordion-panel-item.mb-35 { margin-bottom: 25px; }

  .accordion-item .accordion-thumb:after, 
  .accordion-item.is-active .accordion-thumb:after { font-size: 0.925rem; top: 20px; }

  #faqs-3.faqs-section .accordion-item .accordion-thumb:after, 
  #faqs-3.faqs-section .accordion-item.is-active .accordion-thumb:after { font-size: 1rem; top: 24px; right: 2px; }

  .more-questions { margin-top: 30px; }
  #faqs-1 .more-questions { margin-top: 35px; }
  #faqs-3 .more-questions { margin: 30px auto 0; }

  .more-questions-txt { padding: 18px 35px; }
  .more-questions-txt p { font-size: 1.125rem; }

  /* HELP CENTER */
  .help-category { padding: 35px 35px; margin-bottom: 24px; }
  .help-category h6 { margin-top: 10px; margin-bottom: 10px; }

  /* HELP CATEGORY */
  .help-category-title { padding: 50px 30px 30px; margin-bottom: 50px; }
  .help-category-title p { margin-top: 12px; }g

  #help-category h4 { margin-bottom: 35px; }
  .help-category-item { padding-bottom: 30px; margin-bottom: 30px; }

  .help-category-item p { margin: 12px 0 14px; }
  .help-category-data span { font-size: 0.95rem; }
  .read-time { margin-left: 10px; }

  /* HELP ARTICLE */
  .help-article-title { text-align: center; }
  .breadcrumb-wrapper { display: inline-block; margin-bottom: 15px; }
  .breadcrumb li { font-size: 1rem; }

  .help-article-title h3 { margin-bottom: 14px; padding: 0 15%; }
  .help-article-title p.p-md { text-align: center; }
  .help-article-title p { text-align: left; }
  .help-article-title img { margin: 35px 0; }

  .table-of-contents { padding: 25px 0 32px; margin: 30px 0 25px; }
  .table-of-contents h6 { margin-bottom: 15px; }

  #help-article .txt-block { padding-bottom: 30px; margin-bottom: 30px; }
  #help-article .txt-block h6 { margin: 20px 0 15px; }

  .help-vote-form { margin-top: 50px; }
  .help-vote-form h6 { margin-bottom: 20px; }

  /* BLOG */
  #blog-page .section-title h2 { padding: 0 7%; }
  #blog-page .section-title p { margin-top: 18px; padding: 0 2%; }

  .posts-category { margin: 20px 0 30px; }
  .posts-category.ico-20 [class*="flaticon-"]:before, 
  .posts-category.ico-20 [class*="flaticon-"]:after { font-size: 1.0625rem; } 

  #blog-1 .blog-post { margin-bottom: 30px; }
  .square-post, #blog-page .blog-post { margin-bottom: 40px; }
  #bp-1-3 { display: none; }

  .blog-post-txt { margin-top: 30px; padding-left: 0; padding-right: 0; }

  .blog-post-meta { margin-bottom: 2px; }
  .meta-list-divider { padding: 0 1px; }
  .blog-post-txt h6, .blog-post-txt h5 { margin-bottom: 10px; }

  .square-post .blog-post-txt { padding: 0 10px 0 25px; }
  .square-post .blog-post-meta { margin-bottom: 10px; }
  .square-post .blog-post-txt h4 { font-size: 1.62rem; margin-bottom: 12px; }
  .square-post .post-comments { margin-top: 10px; }

  .posts-wrapper .col-md-12 .blog-post-img { display: inline-block; width: 50%; float: left;  }
  .posts-wrapper .col-md-12 .blog-post-txt { display: inline-block; width: 50%; margin-top: 30px; padding-left: 30px; float: none;}

  /* SINGLE BLOG POST */
  .single-post-title { margin-bottom: 35px; }
  .single-post-title { padding: 0 4%; }
  .single-post-title p.p-lg { margin-top: 16px; padding: 0; }

  #single-post .blog-post-meta { margin-bottom: 14px; }

  #single-post .blog-post-img.pb-60 { padding-bottom: 30px; }

  .single-post-txt h5, .single-post-txt h4 { margin: 15px 0 18px; }

  .single-post-txt figure { margin-bottom: 15px; }
  .single-post-txt .blockquote p { font-size: 1.25rem; margin-bottom: 25px; }
  .single-post-txt .blockquote-footer { font-size: 1rem; }

  .post-inner-img { margin: 30px 0; }

  .post-data { margin-top: 50px; padding: 50px 0; }
  .post-author { margin-top: 0; }
  .post-author-avatar { margin-right: 14px; }
  .post-author-avatar img { width: 55px; height: 55px; }
  .post-author-txt p { margin-top: 7px; }

  .subscribe-banner { padding: 30px; }
  .subscribe-banner-txt p { margin: 10px 0 0; }

  #post-comments { padding-top: 50px; }
  #post-comments h5 { margin-bottom: 40px; }
  .comment-body { margin-left: 20px; }
  #post-comments img { width: 52px; height: 52px; }
  .comment-meta { margin-bottom: 5px; }
  .comment-meta h6 { font-size: 1.225rem; margin-bottom: 5px; }
  .comment-date, .btn-reply a { font-size: 1.0625rem; } 
  .btn-reply.ico-20 [class*="flaticon-"]:before, .btn-reply.ico-20 [class*="flaticon-"]:after { font-size: 1.0625rem; }

  #leave-comment h5 { margin-top: 50px; }

  .comment-form { margin-top: 40px; }
  .comment-form p { margin-bottom: 12px; }

  .comment-form .form-control { height: 52px; font-size: 1.1rem; padding: 0 15px; }
  .comment-form textarea { min-height: 190px; }
  .comment-form textarea.form-control { padding: 20px 15px; }

  .comment-form .btn { font-size: 1.1rem; height: 50px; margin-top: 0; }

  /* NEWSLETTER */
  #newsletter-1 .newsletter-overlay { text-align: center; padding: 50px 18% 40px; }
  #newsletter-2 .newsletter-overlay { padding: 55px 0 45px; } 

  .newsletter-txt h4, .newsletter-txt h3 { padding: 0 5%; margin-bottom: 24px; }

  #newsletter-1 .newsletter-form { margin-top: 0; padding: 0 5%; }
  #newsletter-2 .newsletter-form { padding: 0 8%; margin-top: 25px; }

  .newsletter-section .form-control { height: 50px; font-size: 1.125rem; margin-right: 8px; }
  .newsletter-section .btn { height: 50px; font-size: 1.0625rem; padding: 0.7rem 1.45rem; }
  #newsletter-2.newsletter-section .btn { font-size: 1.0625rem; }

  /* CONTACTS */
  .contacts-info { margin-top: 5px; }
  .contacts-info p { margin: 15px 0 0; }
  .contacts-info h6 { margin: 20px 0 14px; }
  .contacts-info-socials li { padding-right: 12px; }

  .contact-box { margin-bottom: 20px; }
  .contact-box p { padding-right: 1%; margin-top: 12px; }
  .contact-box span { top: 0.5px; left: 2px; }

  .contact-form .form-control, .contact-form .form-select { height: 52px; font-size: 1.1rem; padding: 0 15px; margin-bottom: 15px;}
  .contact-form textarea { min-height: 170px; }
  .contact-form textarea.form-control { padding: 15px 15px; }

  .contact-form .form-btn { margin-top: 5px; }
  .contact-form-msg { margin-top: 15px; }

  /* FOOTER */
  .footer { padding-bottom: 25px; }

  #footer-1 .fl-3, #footer-1 .fl-5, #footer-1 .footer-contacts { margin-bottom: 20px; }
  #footer-3 .footer-info { padding-right: 4%; }
  #footer-3 .footer-links, #footer-7 .footer-links, #footer-11 .footer-links { padding-left: 0; }
  #footer-4 .footer-links { padding-left: 10%; }
  #footer-5 .fl-2 { padding-left: 40px; }
  #footer-10 .footer-links { margin-bottom: 15px; }

  img.footer-logo { max-height: 30px; margin-bottom: 16px; }
  #footer-8 img.footer-logo { margin-bottom: 0; }

  .footer h6 { font-size: 1.21323rem; margin-bottom: 16px; }  /* 16.5px */

  .footer-phone { margin-top: 10px; }
  .footer-mail-link { margin: 0 0 0 6px; }
  .footer-mail-link span { top: 4px; right: 5px; }
  .footer .foo-links li p { margin-bottom: 8px; }

  .footer hr { margin: 5px 0 25px; }

  .footer-socials.ico-20 a, .footer-socials.ico-25 a { margin-right: 11px; } 
  .footer-socials.ico-20 li:last-child a, .footer-socials.ico-25 li:last-child a { margin-right: 0; } 

  .footer-socials.ico-25 [class*="flaticon-"]:before, 
  .footer-socials.ico-25 [class*="flaticon-"]:after { font-size: 1.325rem; }

  .footer-form .form-control, .footer-form .btn { height: 28px; }
  .footer-form .ico-15 [class*="flaticon-"]:before, 
  .footer-form .ico-15 [class*="flaticon-"]:after { line-height: 24px!important; }

  /* INNER PAGE WRAPPER */
  .inner-page-hero { margin-top: 80px; padding-top: 70px; }
  .page-hero-section-overlay { margin-top: 80px; padding-top: 60px; }

  .inner-page-title { margin-bottom: 45px; }
  .inner-page-title h2 { margin-bottom: 0; }
  .inner-page-title p { margin-top: 15px; }
  .page-hero-section h2 { line-height: 1.25; margin-bottom: 20px; }

  /* TERMS, PRIVACY, COOKIES */
  .txt-block.legal-info h5 { margin: 40px 0 15px; }
  .txt-block.legal-info h6 { margin: 15px 0; }
  .txt-block.legal-info h6 span, .txt-block.legal-info h5 span { margin-right: 2px; }

  /* SIGN IN / SIGN UP */
  #login, #signup { padding: 80px 0; }

  .login-1:after, .signup-1:after { width: 55%; }
  .signup-1:after { left: 45%; }

  .register-page-txt img { max-height: 26px; margin-bottom: 40px; }
  .login-page-logo { margin-bottom: 35px; }
  .login-page-logo img { max-height: 35px; }

  .login-3 .register-page-txt, .signup-3 .register-page-txt { padding: 50px 25px 0; }
  .register-page-txt p { margin: 18px 0 0; }
  #login .register-page-copyright { left: 25px; bottom: 40px; }
  #signup .register-page-copyright { right: 75px; bottom: 40px; }

  .login-1 .register-page-form { padding: 0 2% 0 17%; }
  .signup-1 .register-page-form { padding: 0 17% 0 2%; }

  .login-2 .register-page-form, .signup-2 .register-page-form { margin: 0 5%; padding: 35px 20px 30px; }

  .login-3 .register-page-form { padding: 45px 30px 45px 25px; }
  .signup-3 .register-page-form { padding: 45px 25px 45px 30px; }

  .reset-page-wrapper form { margin: 0 3%; padding: 36px 15px 5px; }

  .register-form-title { margin-bottom: 24px; }
  .login-2 .register-form-title, .signup-2 .register-form-title { margin-bottom: 20px; }
  .reset-form-title { margin-bottom: 18px; }

  .register-form-title h5, .register-form-title h4 { margin-bottom: 10px; }
  .reset-form-title h5 { font-size: 1.6rem; }

  .register-page-form p.input-header { font-size: 0.95rem; margin-bottom: 10px; padding-left: 5px; }
  .reset-password-link p { font-size: 1rem; margin-top: 18px; }
  .login-3 .reset-password-link p { margin-top: -2px; margin-bottom: 22px; }
  .form-data span { font-size: 0.9rem; }
  .reset-password-form .form-data p { font-size: 1.0625rem; margin-bottom: 10px; }
  .register-page-form p.create-account { font-size: 1rem; margin-top: 20px; }
  .reset-form-title p.p-sm { font-size: 1.0625rem; margin-top: 16px; }

  .separator-line {font-size: 1rem;  margin: 16px 0; }

  .reset-page-wrapper .form-control, 
  .register-page-form .form-control { height: 52px; font-size: 1.1rem; padding: 0 15px; margin-bottom: 20px; }
  .reset-page-wrapper .form-control { margin-bottom: 16px; }
  #login .register-page-form .btn.submit { height: 52px; font-size: 1.1rem; margin-top: 0; }
  #signup .register-page-form .btn.submit { height: 52px; font-size: 1.1rem; margin-top: 18px; }
  .reset-page-wrapper .btn.submit { height: 52px; font-size: 1.1rem; margin-bottom: 16px; }

  .btn-google img { width: 18px; height: 18px; top: -2px; }
  .register-page-form .btn-google { font-size: 1rem; }

  /* PAGE 404 */
  #page-404 { margin-top: 80px; padding-top: 120px; }
  .page-404-txt { padding-bottom: 80px; }
  .page-404-txt h2 { font-size: 3.5rem; margin-bottom: 16px; }
  .page-404-txt h6 { padding: 0 5%; margin-bottom: 30px; }
  .page-404-img { padding: 0 6%; margin-bottom: 35px; }
  .page-404-footer { padding: 35px 0; }

  /* COOKIES */
  #cookies { bottom: 15px; left: 26%; width: 48%; padding: 17px 20px; }
  #cookies p { font-size: 0.95rem; }
  #cookies-ok { font-size: 0.95rem; padding: 6px 16px; }
  #cookies-info { margin-left: 14px; }

  /* MODAL */
  #modal-1 .modal-dialog { width: 320px; }
  #modal-2 .modal-dialog.modal-xl { max-width: 580px!important; }

  #modal-1 .modal-body-content { padding: 15px 20px 10px; }
  #modal-2 .modal-body-content { padding: 40px 25px 30px; }

  #modal-2 .request-form-title p { margin-top: 12px; margin-bottom: 15px; }

  .modal-body .newsletter-form { margin-top: 18px; }
  .modal .newsletter-form .form-control,
  #modal-2 .request-form .form-control { height: 52px; font-size: 1.1rem; margin-bottom: 16px; }
  .modal .newsletter-form .btn, #modal-2 .request-form .btn { height: 52px; font-size: 1.1rem; }


}




/* ------------------------------------------------------------------------ */
/* ------------------------------------------------------------------------ */
/* ------------------------------------------------------------------------ */

@media (max-width: 767px) { 

  /*------------------------------------------*/
  /*  BASE
  /*------------------------------------------*/

  .animate__animated {
    -o-transition-property: none !important;
    -moz-transition-property: none !important;
    -ms-transition-property: none !important;
    -webkit-transition-property: none !important;
    transition-property: none !important;
    -o-transform: none !important;
    -moz-transform: none !important;
    -ms-transform: none !important;
    -webkit-transform: none !important;
    transform: none !important;
    -webkit-animation: none !important;
    -moz-animation: none !important;
    -o-animation: none !important;
    -ms-animation: none !important;
    animation: none !important;
    opacity: 1;
  }

  .wow {
    animation-name: none!important;
    visibility: visible!important;
  }

  .block--shadow { 
    -webkit-box-shadow: 0 3px 4px 0 var(--tra-black); 
    -moz-box-shadow: 0 3px 4px 0 var(--tra-black); 
    box-shadow: 0 3px 4px 0 var(--tra-black); 
  }

  #hero-18.bg--shape:after, #about-1.bg--shape:after, #contacts-1.bg--shape:after { display: none; }

  .py-40 { padding: 30px 0; }
  .py-50 { padding: 35px 0; }
  .py-60 { padding: 40px 0; }
  .py-70 { padding: 50px 0; }
  .py-80 { padding: 50px 0; }
  .py-90 { padding: 60px 0; }
  .py-100 { padding: 70px 0; }

  .pt-40 { padding-top: 30px; }
  .pt-50 { padding-top: 35px; }
  .pt-60 { padding-top: 40px; }
  .pt-70 { padding-top: 50px; }
  .pt-80 { padding-top: 50px; }
  .pt-90 { padding-top: 60px; }
  .pt-100 { padding-top: 70px; }

  .pb-40 { padding-bottom: 30px; }
  .pb-50 { padding-bottom: 35px; }
  .pb-60 { padding-bottom: 40px; }
  .pb-70 { padding-bottom: 50px; }
  .pb-80 { padding-bottom: 50px; }
  .pb-90 { padding-bottom: 60px; }
  .pb-100 { padding-bottom: 70px; }

  .mt-30 { margin-top: 24px; }
  .mt-40 { margin-top: 30px; }
  .mt-45 { margin-top: 30px; }
  .mt-50 { margin-top: 35px; }
  .mt-55 { margin-top: 35px; }
  .mt-60 { margin-top: 40px; }
  .mt-65 { margin-top: 45px; }
  .mt-70 { margin-top: 50px; }
  .mt-75 { margin-top: 50px; }
  .mt-80 { margin-top: 50px; }

  .mb-30 { margin-bottom: 24px; }
  .mb-40 { margin-bottom: 30px; }
  .mb-45 { margin-bottom: 30px; }
  .mb-50 { margin-bottom: 35px; }
  .mb-55 { margin-bottom: 35px; }
  .mb-60 { margin-bottom: 40px; }
  .mb-65 { margin-bottom: 45px; }
  .mb-70 { margin-bottom: 50px; }
  .mb-75 { margin-bottom: 50px; }
  .mb-80 { margin-bottom: 50px; }

  /* Background Shape */
  .shape--01:after { height: 60%; }
  .shape--02:after { height: 60%; top: 40%; }

  .ts-0 { padding-top: 80px; }

  p.p-sm { font-size: 1rem; }

  /* Button Text */
  p.btn-txt { margin: 12px 0 0 0; }
  p.btns-group-txt { margin: 12px 0 0 0; }
  .text-center p.btns-group-txt { margin-top: 16px; }

  /* Users */
  .txt-block .users { margin-top: 14px; }

  /*------------------------------------------*/
  /*  NAVIGATION MENU    
  /*------------------------------------------*/

  .headtoppart { display: none; }

  .wsmobileheader .smllogo { display: block; margin-top: 15px; padding-left: 22px; }
  .smllogo img { width: auto; max-width: inherit; max-height: 50px; }

  .wsmobileheader { height: 80px; box-shadow: 0 0 2px rgba(50, 50, 50, 0.4); } 
  .tra-menu .wsmobileheader, .white-menu .wsmobileheader { background-color: var(--white); }
  .dark-menu .wsmobileheader, .black-scroll .wsmobileheader { background-color: var(--ink); }
  .tra-menu.border-header .wsmainfull { border: none; }

  .theme--dark .tra-menu .wsmobileheader, 
  .theme--dark .white-menu .wsmobileheader { 
    background-color: var(--ink)!important; 
    -webkit-box-shadow: 0 2px 3px var(--tra-ink);
    -moz-box-shadow: 0 2px 3px var(--tra-ink);
    box-shadow: 0 2px 3px var(--tra-ink);
  }

  /* Mobile Header Burger Menu */
  .wsanimated-arrow { padding: 14px 22px 14px 0px; margin: 24px 28px 0 0; }
  .wsanimated-arrow span:before { top: -8px; width: 26px; }
  .wsanimated-arrow span:after { bottom: -8px; width: 20px; }

  .dark-menu .wsanimated-arrow span, .dark-menu .wsanimated-arrow span:before, 
  .dark-menu .wsanimated-arrow span:after, .black-scroll .wsanimated-arrow span, 
  .black-scroll .wsanimated-arrow span:before, .black-scroll .wsanimated-arrow span:after { background: var(--white); }

  .theme--dark .wsanimated-arrow span, .theme--dark .wsanimated-arrow span:before, 
  .theme--dark .wsanimated-arrow span:after { background: #b1b7cd; }

  .wsactive .wsanimated-arrow span:before, .wsactive .wsanimated-arrow.active span:after { width: 28px; top: 0; }
  .wsactive .wsanimated-arrow span:after { width: 28px; top: 0; }
  .wsactive .wsanimated-arrow span { background: transparent; }

  .wsmenu > .wsmenu-list > li > .wsmenu-click { height: 64px; }
  .wsmenu > .wsmenu-list > li.mobile-last-link > a { border-bottom: none; }

  /* Dark Header Settings */
  .dark-menu .wsmenu > .wsmenu-list, .black-scroll .wsmenu > .wsmenu-list { background-color: var(--metal)!important; }

  .dark-menu .wsmenu > .wsmenu-list > li > a, 
  .black-scroll .wsmenu > .wsmenu-list > li > a { border-bottom-color: var(--tra-white); }

  .dark-menu .wsmenu > .wsmenu-list > li > .wsmenu-click > i:before,
  .black-scroll .wsmenu > .wsmenu-list > li > .wsmenu-click > i:before { border-color: rgba(255, 255, 255, .85); }

  /* Nav Link */
  .wsmenu > .wsmenu-list > li > a { font-size: 1.125rem; line-height: 46px; padding: 5px 32px 4px 20px; margin: 0; }
  .tra-menu .wsmenu > .wsmenu-list > li > a.h-link,
   .tra-menu.navbar-light .wsmenu > .wsmenu-list > li > a.h-link { color: var(--gray); }
  .black-scroll .wsmenu > .wsmenu-list > li > a.h-link { color: var(--white); }
  .wsmenu > .wsmenu-list > li > .wsmenu-click > i { height: 10px; width: 10px; margin: 21px 18px 0px 0px; }
  .wsmenu > .wsmenu-list > li .wsmenu-click.ws-activearrow > i { transform: rotate(-45deg); margin-top: 25px; }

  .theme--dark .navbar-light .wsmenu > .wsmenu-list > li > a,
  .theme--dark .navbar-dark .wsmenu > .wsmenu-list > li > a { color: var(--white)!important; }

  /* SubMenu / HalfMenu / MegaMenu */
  .wsmenu > .wsmenu-list > li > ul.sub-menu { width: 100%!important; min-width: 100%!important; }
  .wsmenu > .wsmenu-list > li > .wsmegamenu.halfmenu { width: 100%!important; padding: 10px; }
  .wsmenu > .wsmenu-list > li > .wsmegamenu.w-75 { width: 100%!important; left: 0; padding: 12px 10px; }

  .wsmenu > .wsmenu-list > li.mobile-last-link > ul.sub-menu { border-bottom: none!important; }

  .wsmegamenu.w-75 .col-lg-3, .wsmegamenu.halfmenu .col-lg-6 { padding: 0; }

  .wsmenu > .wsmenu-list > li > ul.sub-menu, .wsmenu > .wsmenu-list > li > .wsmegamenu.w-75,
  .wsmenu > .wsmenu-list > li > .wsmegamenu.halfmenu { top: 0; -webkit-border-radius: 0; -moz-border-radius: 0; 
  -o-border-radius: 0; -webkit-box-shadow: 0 0; -moz-box-shadow: 0 0; box-shadow: 0 0; border-bottom: 1px solid #ddd; }

  .wsmenu > .wsmenu-list > li > ul.sub-menu, .wsmenu > .wsmenu-list > li > .wsmegamenu { 
    opacity: 1!important; visibility: visible!important; -webkit-transform: rotateX(0deg);
    -moz-transform: rotateX(0deg);-ms-transform: rotateX(0deg);-o-transform: rotateX(0deg);transform: rotateX(0deg); 
  }

  .dark-menu .wsmenu > .wsmenu-list > li > ul.sub-menu, 
  .dark-menu .wsmenu > .wsmenu-list > li > .wsmegamenu,
  .dark-menu .wsmenu > .wsmenu-list > li > .wsmegamenu.halfmenu, 
  .black-scroll .wsmenu > .wsmenu-list > li > ul.sub-menu, 
  .black-scroll .wsmenu > .wsmenu-list > li > .wsmegamenu, 
  .black-scroll .wsmenu > .wsmenu-list > li > .wsmegamenu.halfmenu { 
    border-bottom: 1px solid var(--tra-white)!important; -webkit-box-shadow: 0 0; -moz-box-shadow: 0 0;box-shadow: 0 0;
  }

  .black-scroll .wsmenu > .wsmenu-list > li > ul.sub-menu, .black-scroll .wsmenu > .wsmenu-list > li > .wsmegamenu,
  .black-scroll .wsmenu > .wsmenu-list > li > .wsmegamenu.halfmenu { background-color: var(--metal)!important; }

  .wsmenu > .wsmenu-list > li > ul.sub-menu > li > ul.sub-menu {
    width: 100%!important; 
    min-width: 100%!important;
    box-shadow: 0 0;
    border: none;
    position: static;
    left: 0;
    top: 0;
    margin: 0;
    padding: 5px 0 0 0;
  }

  .wsmenu > .wsmenu-list > li > ul.sub-menu > li > a span { display: none; }
  .wsmenu > .wsmenu-list > li > ul.sub-menu > li .wsmenu-click02 > i {
    height: 9px; width: 9px; transform: rotate(-225deg); margin: 17px 18px 0px 0px; 
  }
  .wsmenu > .wsmenu-list > li > ul.sub-menu > li .wsmenu-click02>i.wsmenu-rotate { margin-top: 22px; }

  /* Sub Menu / Half Menu / Mega Menu Links */
  .wsmenu > .wsmenu-list > li > ul.sub-menu > li > a, 
  .wsmenu > .wsmenu-list > li > .wsmegamenu .link-list li a,
  .wsmenu > .wsmenu-list > li > ul.sub-menu > li > ul.sub-menu > li > a,
  .wsmenu > .wsmenu-list > li > .wsmegamenu.w-75 ul.link-list > li > a,
  .wsmenu > .wsmenu-list > li > .wsmegamenu.halfmenu ul.link-list > li > a {
    color: var(--gray); font-size: 1.0625rem; padding: 15px 14px;
  }

  /* Black Scroll */
  .black-scroll .wsmenu > .wsmenu-list > li > ul.sub-menu > li > a,
  .black-scroll .wsmenu > .wsmenu-list > li > .wsmegamenu.w-75 ul.link-list > li > a,
  .black-scroll .wsmenu > .wsmenu-list > li > .wsmegamenu.halfmenu ul.link-list > li > a {
    color: #ddd;
    background-color: rgba(255, 255, 255, 0)!important;
  }

  .navbar-light .wsmenu-list > li > a.h-link:hover { color: var(--coal)!important; }

  /* Sub Menu / Half Menu / Mega Menu Links Hover */
  .wsmenu > .wsmenu-list > li > ul.sub-menu > li > a:hover, 
  .wsmenu > .wsmenu-list > li > .wsmegamenu .link-list li a:hover,
  .wsmenu > .wsmenu-list > li > .wsmegamenu.w-75 ul.link-list > li > a:hover,
  .wsmenu > .wsmenu-list > li > ul.sub-menu > li > ul.sub-menu > li > a:hover,
  .wsmenu > .wsmenu-list > li > .wsmegamenu.halfmenu ul.link-list > li > a:hover { padding: 15px 14px; text-decoration: none; }

  /* Black Scroll */
  .black-scroll .wsmenu > .wsmenu-list > li > ul.sub-menu > li > a:hover,
  .black-scroll .wsmenu > .wsmenu-list > li > .wsmegamenu.w-75 ul.link-list > li > a:hover,
  .black-scroll .wsmenu > .wsmenu-list > li > .wsmegamenu.halfmenu ul.link-list > li > a:hover {
    background-color: rgba(255, 255, 255, .05)!important;
  }

  /* Button */
  .wsmenu > .wsmenu-list > li a.btn {  
    font-size: 1.125rem;
    line-height: 44px; 
    border-width: 2px;
    padding: 5px 22px;
    margin: 1px 0 2px 0;
    -webkit-border-radius: 0; 
    -moz-border-radius: 0; 
    border-radius: 0; 
  }

  .wsmenu > .wsmenu-list > li.lang > ul.sub-menu > li > a { font-size: 1.0625rem; padding: 12px 14px; }
  .wsmenu > .wsmenu-list > li.lang > ul.sub-menu > li > a:hover { padding: 12px 14px; }

  .wsmenu > .wsmenu-list > li a.btn.reg-btn { line-height: 44px; padding: 5px 22px; }
  .wsmenu > .wsmenu-list > li a.btn.fst-btn-link, .wsmenu > .wsmenu-list > li.reg-fst-link > a { margin-left: 0; }

  /* Header Store Badges */
  .wsmenu > .wsmenu-list > li a.store.header-store { margin: 5px 0 0 12px; display: block; float: none; border-bottom: none; }
  .store.header-store img { max-height: 46px; }

  /* Header Social Links */
  .wsmenu > .wsmenu-list > li.header-socials { display: block; }
  .header-socials a { margin-left: 20px; margin-right: 5px; }
  .header-socials.ico-20 [class*="flaticon-"]:before, .header-socials.ico-25 [class*="flaticon-"]:after { font-size: 1.45rem; } 

  /* Navbar Transparent Buttons */
  .white-scroll .btn--tra-white,
  .white-scroll .btn--tra-black { 
    color: var(--white)!important; 
    background-color: var(--coal)!important; 
    border-color: var(--coal)!important; 
  }

  .black-scroll .btn--tra-white, 
  .black-scroll .btn--tra-black { 
    color: var(--coal)!important; 
    background-color: var(--white)!important; 
    border-color: var(--white)!important; 
  }

  .white-scroll .btn--tra-gray, 
  .black-scroll .btn--tra-gray { 
    color: var(--coal)!important; 
    background-color: var(--smoke)!important; 
    border-color: var(--smoke)!important; 
  }

  /* Navbar Buttons Hover */
  .white-scroll .hover--white:hover,
  .white-scroll .hover--tra-white:hover { 
    color: var(--coal)!important; 
    border-color: var(--coal)!important; 
    background-color: transparent!important; 
  }

  theme--dark .white-scroll .hover--tra-white:hover { 
    color: var(--white)!important; 
    border-color: var(--white)!important; 
    background-color: transparent!important; 
  }

  .theme--dark .navbar-dark .hover--tra-black:hover,
  .theme--dark .navbar-dark .scroll .hover--tra-black:hover {
    color: var(--white)!important;
    background-color: transparent!important;
    border-color: var(--white)!important; 
  }

  .theme--dark .wsmenu > .wsmenu-list { background-color: var(--metal)!important; }
  .theme--dark .wsmenu > .wsmenu-list > li > .wsmenu-click > i:before { border-color: var(--white); }

  /*------------------------------------------*/
  /*   HERO   
  /*------------------------------------------*/

  .hero-section { margin-top: 80px; }

  /* HERO QUICK FORM */
  .hero-section .quick-form .form-control { text-align: center; margin-bottom: 15px; }
  .hero-section .quick-form .input-group-btn { display: block; width: 100%!important; }
  .hero-section .quick-form .btn { display: block; width: 100%!important; margin-left: 0; }
  .hero-section .quick-form.form-half .btn { margin-left: 0; }

  /* HERO DIGITS */
  .hero-digits { display: inline-block; margin: 0 auto; padding-right: 0; }
  #hd-1-1, #hd-1-2 { width: 50%; padding: 0 10px; } 

  /* HERO-1 */
  #hero-1 { text-align: center; padding: 70px 0; }
  .hero-1-img { margin: 40px 0 0; }

  /* HERO-2 */
  #hero-2 { padding-top: 80px; }
  .hero-2-txt p.p-lg { padding: 0; }
  .hero-2-img { margin-top: 40px; padding: 0; }

  /* HERO-3 */
  #hero-3 { text-align: center; padding-top: 80px; }
  .hero-3-txt p.p-md { padding-right: 0; }
  .hero-3-img { padding-right: 0; margin-top: 40px; }

  /* HERO-4 */
  #hero-4 { text-align: center; padding-top: 80px; padding-bottom: 0; margin-bottom: 65px; }
  .hero-4-img { margin: 40px 0 -40px 0; }

  /* HERO-5 */
  #hero-5 { text-align: center; padding-top: 80px; padding-bottom: 0; }
  #hero-5:after { width: 100%; height: 95%; left: 0; }
  .hero-5-txt { margin-left: 0; }
  .hero-5-txt p.p-md { padding-right: 0; }
  .hero-5-img { margin: 40px 0 0; }

  /* HERO-6 */
  #hero-6 { padding-top: 80px; text-align: center; }
  #hero-6 .quick-form { margin-right: 0; }
  .hero-6-img { padding-left: 0; margin-top: 40px; }

  /* HERO-7 */
  #hero-7 { text-align: center; padding-top: 70px; }
  .hero-7-img { margin: 40px 0 0; }

  /* HERO-8 */
  #hero-8 { padding-top: 80px; }
  .hero-8-txt p.p-lg { padding: 0; }
  .hero-8-img { margin-top: 40px; }

  /* HERO-9 */
  #hero-9 { padding-top: 80px; text-align: center; }
  .hero-9-txt { margin-top: 0; }
  #hero-9 .quick-form { margin-right: 0; }
  .hero-9-img { padding-left: 0; margin-top: 40px; }

  /* HERO-10 */
  #hero-10 { padding-top: 80px; }
  .hero-10-txt { margin-bottom: 40px; }
  #hero-10 .hero-overlay { padding: 65px 45px 0; }

  /* HERO-11 */
  #hero-11 { text-align: center; padding-top: 80px; padding-bottom: 80px; }
  .hero-11-img { margin: 40px 0 0; }

  /* HERO-12 */
  #hero-12 { text-align: center; padding-top: 70px; }
  .hero-12-wrapper { padding: 65px 60px; }
  .hero-12-img { margin-top: 40px; margin-left: 0; }

  /* HERO-13 */
  #hero-13, #hero-17, #hero-18, #hero-19, #hero-21 { padding-top: 80px; }

  /* HERO-14 */
  #hero-14 { text-align: center; padding-top: 80px; }
  .hero-14-txt { margin-top: 0; padding-right: 0; }
  .hero-14-txt .section-id { margin-bottom: 30px; }
  .hero-14-img { margin: 40px 0 0; }

  /* HERO-15 */
  #hero-15 .hero-overlay { padding-top: 80px; padding-bottom: 80px; }

  /* HERO-16 */
  #hero-16 { padding-top: 80px; text-align: center; }
  #hero-16 .quick-form { margin-right: 0; }
  .hero-16-img { padding-left: 0; margin-top: 40px; }

  /* HERO-18 */
  .h18-1 { margin-bottom: 24px; }

  /* HERO-20 */
  #hero-20 { padding-top: 0; }
  #hero-20 .container { max-width: 100%; padding: 0; }
  #hero-20 .slideshow { border-radius: 0 0; }
  #hero-20 .caption { margin-top: 0; }

  /* HERO-22 */
  #hero-22 .caption { margin-top: 0; }

  /* HERO-23 */
  #hero-23 { padding-top: 80px; }
  .hero-23-txt p.p-lg { padding: 0; }
  .hero-23-img { margin-top: 40px; padding: 0; }

  /* HERO-24 */
  #hero-24 { text-align: center; padding-top: 80px; padding-bottom: 0; margin-bottom: 60px; }
  .hero-24-txt { margin-top: 0; padding-right: 0; }
  #hero-24-form { 
    margin: 50px 0 -60px; 
    -webkit-box-shadow: 0px 15px 20px 0px var(--tra-black);
    -moz-box-shadow: 0px 15px 20px 0px var(--tra-black);
    box-shadow: 0px 15px 20px 0px var(--tra-black);
  }

 /* HERO-25 */
  #hero-25 {  overflow: visible; padding-top: 80px; margin-bottom: 50px; }
  .hero-25-txt p.p-lg { padding: 0; }
  .hero-25-txt p.btn-txt { margin-top: 15px; }
  .hero-25-img { margin-top: 40px; margin-bottom: -50px; }
  .hero-25-img .video-btn { top: calc(50% - 1px); }

  /* HERO-26 */
  #hero-26 { padding-top: 80px; }
  .hero-26-img { margin: 45px -1% 0; }

  /* HERO-27 */
  #hero-27 { text-align: center; padding-top: 50px; }
  .hero-27-txt { padding-right: 0; }
  .hero-27-txt p { margin-top: 25px; margin-bottom: 40px; }

  .hero-27-txt .hero-logo { display: none; }

  /*------------------------------------------*/
  /*  CONTENT
  /*------------------------------------------*/

  /* ABOUT */
  .about-1-title { margin-bottom: 30px; }
  .about-2-title { padding: 0; margin-bottom: 15px; }

  .about-1-title p { margin-top: 16px; }

  #a3-1 { margin-bottom: 30px; }
  #about-3 .txt-block h5 { margin-bottom: 14px; }

  .about-1-img { margin: 0 -26%; }

  /* FEATURES */
  .fbox-12-wrapper { padding-left: 0; }

  #features-3.py-100.shape--bg:after { width: 100%; height: 70%; top: 30%; left: 0; }
  #features-3.pt-100.shape--bg:after { width: 100%; height: 63%; top: 33.25%; left: 0; }
  #features-4.py-100.shape--bg:after { width: 100%; height: 70%; top: 30%; }
  #features-4.pt-100.shape--bg:after { width: 100%; height: 66%; top: 30.25%; }

  .fbox-3-wrapper { text-align: center; padding-left: 0; }
  .fbox-4-wrapper { text-align: center; padding-right: 0; }

  .fb-1, .fb-2, .rows-2 .fb-1, .rows-2 .fb-2, .rows-2 .fb-3, .rows-2 .fb-4, .rows-2 .fb-5,.row-cols-md-2 .fb-3, 
  .rows-3 .fb-1, .rows-3 .fb-2, .rows-3 .fb-3, .rows-3 .fb-4, .rows-3 .fb-5, .rows-4 .fb-1, .rows-4 .fb-2, .rows-4 .fb-3, 
  .rows-4 .fb-4, .rows-4 .fb-5, .rows-4 .fb-6,#fb-3-1, #fb-3-2, #fb-3-3, #fb-4-1, #fb-4-2, #fb-4-3, .fbox-6.fb-4, 
  .fbox-9.fb-1, .fbox-9.fb-2, .fbox-9.fb-4, .fbox-9.fb-5, .fbox-9.fb-7, .fbox-9.fb-8, .fbox-10.fb-1, .fbox-10.fb-3, 
  .fbox-10.fb-5, .fbox-10.fb-7, .rows-4 .fbox-2.fb-1, .rows-4 .fbox-2.fb-2, .rows-4 .fbox-2.fb-3, .rows-4 .fbox-2.fb-4, 
  .rows-4 .fbox-2.fb-5, .rows-4 .fbox-2.fb-6 { margin-bottom: 40px; }

  .fbox-12.fb-1, .fbox-12.fb-2, .fbox-12.fb-3 { margin-bottom: 35px; }

  .rows-4 .fb-8 { margin-top: 40px; }
  #features-12 .txt-block { margin-bottom: 40px; }

  .fbox-10.fb-2, .fbox-10.fb-4, .fbox-10.fb-6 { margin-bottom: 0; }
  .fbox-10.fb-1, .fbox-10.fb-5, .fbox-10.fb-6 { display: block; }

  .fbox-5 { text-align: center; padding: 40px; }
  #features-3 .txt-block, #features-4 .txt-block { margin-bottom: 50px; }

  .fbox-2 { text-align: center; display: block; }
  .fbox-ico-wrap { display: block; margin-right: 0; }
  .fbox-2 .fbox-ico { margin-top: 0; }
  .shape-ico span { top: 0; padding-top: 16px; line-height: 1.35; }

  /* CONTENT */
  .bc-01 .container, .bc-03 .container { max-width: 100%; }
  .bc-01-wrapper, .bc-03-wrapper { margin: 0 -15px; border-radius: 0 0!important; }

  .bc-03 .section-title p { padding: 0; }

  .ct-06 .section-overlay { margin-bottom: 80px; }

  .bc-01 .txt-block.left-column { padding-right: 0; }
  .bc-01 .txt-block.right-column { padding-left: 0; }
  .two-txt .txt-block.left-column { margin-bottom: 40px; padding-right: 0; }
  .ct-07-txt { padding-left: 0; }

  #bc-4-2, #bc-4-3 { margin-top: 30px; }

  .cbox-3 { display: block; text-align: center; }
  .cbox-3 .ico-wrap { margin-right: 0; margin-bottom: 10px; }
  .cbox-3 .cbox-3-ico { top: 0; }

  /* Advantages */
  .text-center .advantages { margin-top: 16px; }

  /* Quote */
  .quote-avatar img { width: 54px; height: 54px; }
  .quote p { padding-left: 14px; margin-top: 15px; }
  .quote-author { padding: 0 0 0 10px; } 
  .quote.pr-15, .quote.pr-40, .quote.pr-50, .quote.pr-60 { padding-right: 0; }

  /* Content Image */
  .ct-07-images { margin: 30px 0 40px; }
  .img-block-hidden, .ct-06 .img-block { margin-bottom: -80px; }
  .img-block-txt, .img-block-btn { margin-top: 45px; }

  .bc-3-img .video-btn { top: calc(50% - 30px); }

  .ct-01 .img-block.right-column, .ct-02 .img-block.right-column, 
  .ct-03 .img-block.right-column, .bc-2-img.right-column { margin-left: 0; margin-bottom: 40px; }

  .ct-01 .img-block.left-column, .ct-02 .img-block.left-column, 
  .ct-03 .img-block.left-column, .bc-2-img.left-column { margin-right: 0; margin-bottom: 40px; }
  .bc-02.pt-30 .bc-2-img.right-column, .bc-02.pb-30 .bc-2-img.right-column,
  .bc-02.pt-30 .bc-2-img.left-column, .bc-02.pb-30 .bc-2-img.left-column { margin: 35px 0 0; }

  .ct-04 .img-block img { max-width: 100%; margin-left: 0; margin-bottom: 40px; }
  .ct-07-top { margin-bottom: 30px; }

  .bc-01 .img-block { margin-bottom: 40px; }
  .bc-3-img { margin-bottom: -100px; }
  .bc-4-img { display: none; }

  .img-content.py-100 { padding: 45px 0; }

  /* STATISTIC */
  #statistic-7 .container { max-width: 100%; }
  .statistic-7-wrapper { text-align: center; padding: 70px 50px; margin: 0 -15px; border-radius: 0 0!important; }
  .statistic-8-wrapper { padding: 0; }
  .statistic-block-txt { padding: 0; }

  .statistic-2-wrapper { text-align: center; }
  .statistic-4-wrapper { padding: 0 10%; }
  .statistic-7-wrapper { text-align: center; padding: 50px 40px; }
  .statistic-7-wrapper .col-lg-4 { margin-bottom: 35px; }

  #statistic-1 .statistic-block { display: block!important; text-align: center; }
  .statistic-1-wrapper .statistic-block-digit { width: 100%; display: block!important; float: none; margin-bottom: 15px; }
  .statistic-1-wrapper .statistic-block-txt { width: 100%; display: inline-block!important; padding-left: 0; }
  .statistic-4-wrapper .statistic-block { text-align: center; padding: 0; }
  .statistic-2-wrapper .txt-block { margin-bottom: 30px; }
  .statistic-8-wrapper .statistic-block { padding: 0; }

  #sb-1-1, #sb-1-2, #sb-5-1, #sb-5-2, #sb-6-1, #sb-6-2 { margin-bottom: 40px; }
  #sb-4-1, #sb-4-2 { 
    border-right: none; border-bottom: 1px solid rgba(230, 230, 230, .2); padding-bottom: 40px; margin-bottom: 40px; 
  }
  #sb-8-1, #sb-8-2 { border-right: none; border-bottom: 1px solid var(--silver); padding-bottom: 40px; margin-bottom: 40px; }
  #sb-9-1, #sb-9-2 { margin-bottom: 40px; }

  .statistic-1-wrapper p { line-height: 1.5; top: 0; }
  .statistic-7-wrapper .statistic-block p { margin-top: 12px; }

  /* PROJECTS */
  .portfolio-section .filter-btns button { font-size: 1.0625rem; margin: 0 0 12px; }

  #portfolio-1 .filter-item { width: 100%; padding: 0; }

  .project-category { left: 25px; }

  .project-source { width: 42px; height: 42px; bottom: 22px; }
  .project-source a span { top: 4.5px; }

  .project-data-txt h6, .project-data-txt h5 { margin-bottom: 4px; }

  .filter-item:hover .project-data-txt { left: 30px; }
  .filter-item:hover .project-source { right: 20px; }

  /* JOB POSITION */
  #job-position .inner-page-title { padding-bottom: 40px; margin-bottom: 35px; }
  #job-position .inner-page-title p { margin-top: 12px; margin-bottom: 14px; }
  #job-position .inner-page-title .text-end { text-align: left!important; }
  #job-position .inner-page-title .btn { margin-top: 0; }

  .position-info h5 { margin: 40px 0 25px; }
  .position-info .btn { margin-top: 20px!important; }

  /* PRICING */
  .pricing-table-header h5 { margin-bottom: 15px; }

  .price p { margin: 14px 0 18px; }

  .pricing-features.mt-25 { margin-top: 20px; }

  /* Pricing Notice */
  .pricing-notice { margin-top: 0; }
  .pricing-notice .btn.mt-25 { margin-top: 20px; }

  /* Pricing Toggle Button */
  .pricing-section .toggle-btn.mt-30, .toggle-btn.toggle-btn-md.mt-40 { margin-top: 20px; }
  .toggle-btn-md .toggler-txt, .toggle-btn-lg .toggler-txt { font-size: 1rem; line-height: 32px; }

  .switch-wrap { min-width: 50px; height: 30px; }
  .toggle-btn-md .switch-wrap, .toggle-btn-lg .switch-wrap { min-width: 55px; height: 32px; margin: 0 6px; }

  .switcher { font-size: 0.85rem; height: 30px; }
  .toggle-btn-md .switcher, .toggle-btn-lg .switcher { height: 32px; padding: 0 10px 0 30px; border-radius: 32px; }

  .switcher .show-annual, .switcher .show-monthly { line-height: 30px; }
  .toggle-btn-md .switcher .show-annual, .toggle-btn-md .switcher .show-monthly,
  .toggle-btn-lg .switcher .show-annual, .toggle-btn-lg .switcher .show-monthly { line-height: 32px; }

  .switcher:before { left: 4px; bottom: 4px; width: 22px; height: 22px; }
  .toggle-btn-md .switcher:before, .toggle-btn-lg .switcher:before { left: 3px; bottom: 3px; width: 22px; height: 22px; }
  .switcher.bg--grey:before, .switcher.bg--tra-white:before, .switcher.bg--ghost-white:before { left: 4px; bottom: 3px; }

  .toggle-btn-md .switch-wrap input:checked + .switcher, 
  .toggle-btn-lg .switch-wrap input:checked + .switcher { padding: 0 30px 0 10px; }

  .comp-table-payment .payment-icons { margin-top: 18px; }

  /* RATING */
  .rating-section .rating-1-wrapper.text-center, .rating-section .rating-2-wrapper.text-center { padding: 0; }

  #rb-1-1, #rb-1-2, #rb-2-1, #rb-2-2 { margin-bottom: 30px; }
  .rbox-2-txt img { max-height: 24px; top: -1px; left: 6px; }
  .rbox-2-txt { margin-top: 10px; }
  p.rbox-2-txt { line-height: 24px; }

  /* TESTIMONIALS */
  #reviews-3 { margin-top: 0; margin-bottom: 0; }
  #reviews-3 .container, #reviews-4 .container { max-width: 100%; padding: 0; }
  .reviews-3-wrapper { padding: 70px 40px; margin: 0 -15px; border-radius: 0 0!important; }
  .reviews-4-wrapper { text-align: center; padding: 70px 0; margin: 0 -15px; border-radius: 0 0!important; }

  #reviews-3 .txt-block { text-align: center; margin-bottom: 40px; }
  .reviews-4-txt.left-column { padding: 0 5%; }

  .review-1 { margin: 0 5px 30px; }
  .review-2 { text-align: center; padding: 0 10px; }
  #rw-3-1 { margin: 0 0 30px 0; }
  #rw-3-2 { margin: 0; }
  #reviews-5 .filter-item { width: 100%; padding: 0; margin-bottom: 30px; }

  .reviews-4-txt .review-ico { display: none; }

  .review-2-txt h5 { margin: 10px 0 12px; }
  .reviews-4-txt h5 { margin: 0 0 15px; }
  #reviews-3 .txt-block p { padding: 0; margin-top: 15px; }
  #reviews-3 .txt-block .btn { margin-top: 22px; }

  .review-1 .review-author, .review-5 .review-author { margin-top: 0; }
  .reviews-4-txt .review-author { margin-top: 30px; }

  .reviews-4-img { display: inline-block; width: 150px; height: 150px; margin: 0 auto 14px; }
  .reviews-4-img img { border-radius: 16px; }

  /* BRANDS */
  #brands-4 .txt-block { text-align: center; margin-bottom: 40px; }
  .brands-3-wrapper { padding: 0; }
  .brands-4-wrapper { padding-left: 0; margin-top: 0; }

  /* INTEGRATIONS */
  .int-3-wrapper, .int-4-wrapper { padding: 0; }
  .filter-btns { text-align: center; margin-bottom: 24px; }
  .filter-btns button { 
    width: 100%; 
    background-color: #f4f6f8; 
    border: 1px solid #eee; 
    font-size: 1.135rem; 
    padding: 0.9rem 0.1rem; 
    margin: 0 0 10px; 
    border-radius: 6px;
  }

  .theme--dark .filter-btns button {
    color: var(--white); background-color: var(--white-smoke);border: 1px solid var(--border-dark);
  }
  .filter-btns button.is-checked { color: var(--white); background-color: var(--theme-color); }

  .int_tool-1, .int-2-wrapper .int_tool-2 { margin-bottom: 25px; }
  .filter-item { width: 100%; margin-bottom: 25px; padding: 0; }
  .tool_description p { font-size: 1rem; }

  .integrations-section .more-btn.mt-30 { margin-top: 20px; }

  /* BANNER */
  #banner-2 .container { max-width: 100%; }

  .banner-1-wrapper { text-align: center; padding: 65px 50px 70px; background-position: center left; }
  .banner-2-wrapper { margin: 0 -15px; border-radius: 0 0!important; }

  .banner-4-txt { text-align: center!important; margin-bottom: 14px; }
  .banner-section .text-end { text-align: center!important; }

  .banner-section .section-id { margin-bottom: 18px; }
  .banner-1-txt h4 br { display: none; }

  #banner-1.banner-section p { margin: 16px 0 22px; }

  .banner-1-txt .btn { margin: 0 6px; }
  .banner-4-btn p { margin: 10px 0 0!important; }
  .banner-section p.btn-txt { margin-top: 15px; }

  /* DOWNLOAD */
  #download-1 { padding-bottom: 30px; }
  .version-release { margin-bottom: 40px; }
  .release-data h5 { margin-bottom: 30px!important; }
  .release-data { padding-bottom: 30px; margin-bottom: 25px; } 
  .release-download { position: relative; right: 0; bottom: 0; margin-top: 20px; }

  /* FAQs */
  #faqs-1 .section-title { margin-bottom: 25px; } 
  #faqs-2 .section-title { margin-bottom: 40px; } 

  #faqs-2 .question { margin-bottom: 25px; }
  .faqs-2-questions .left-column { padding-right: 0; }
  .faqs-2-questions .right-column { padding-left: 0; }

  #faqs-2 .question h6, #faqs-2 .question h5 { padding-right: 0; margin-bottom: 10px; }

  .accordion-thumb { padding: 16px 0 20px; }
  .accordion-thumb h5 { line-height: 1.5; padding-right: 10%; }
  .accordion-panel { padding: 0 0 8px 0; }
  .accordion-item .accordion-thumb:after, .accordion-item.is-active .accordion-thumb:after { font-size: 0.925rem; top: 19px; }

  .more-questions, #faqs-1 .more-questions { margin-top: 25px; }

  /* HELP ARTICLE */
  .help-article-title { text-align: center; }
  .breadcrumb-wrapper { display: inline-block; margin-bottom: 15px; }
  .breadcrumb li { font-size: 1rem; }

  .help-article-title p.p-md { text-align: center; }
  .help-article-title p { text-align: left; }

  .table-of-contents { padding: 40px 0; margin: 40px 0; }
  #help-article .txt-block { padding-bottom: 35px; margin-bottom: 35px; }

  /* BLOG */
  #blog-page .section-title p { margin-top: 16px; padding: 0; }

  .posts-category { margin: 10px 0 40px; }
  .posts-category.ico-20 [class*="flaticon-"]:before, 
  .posts-category.ico-20 [class*="flaticon-"]:after { font-size: 1.125rem; } 

  #blog-1 .blog-post { margin-bottom: 30px; }
  .square-post, #blog-page .blog-post { margin-bottom: 40px; }

  .blog-post-txt { padding-left: 0; padding-right: 0; }
  .blog-post-txt p.p-sm { font-size: 1rem; }

  .square-post .blog-post-txt { padding: 20px 35px 45px; }
  .square-post .blog-post-meta { margin-bottom: 12px; }
  .square-post .post-comments { margin-top: 14px; }

  /* SINGLE BLOG POST */
  .single-post-title { margin-bottom: 35px; }
  .single-post-title h3, .single-post-title h2 { padding: 0; }
  .single-post-title p.p-lg { margin-top: 16px; padding: 0; }

  #single-post .blog-post-meta { margin-bottom: 12px; }
 
  .single-post-txt h5, .single-post-txt h4 { margin-top: 18px; margin-bottom: 18px; }

  .single-post-txt figure { margin-bottom: 20px; }

  .post-inner-img { margin: 40px 0; }

  .post-data { margin-top: 50px; }

  .post-share-list.text-end { text-align: left!important; margin-top: 14px; }
  .share-social-icons a.share-ico span { margin-left: 0; margin-right: 14px; }

  .subscribe-banner { text-align: center; padding: 35px 30px; }
  .subscribe-banner-txt p { margin: 10px 0 15px; }
  .subscribe-banner-btn.text-end { text-align: center!important; }
  .subscribe-banner-btn { margin-top: 0; }

  #post-comments { padding-top: 60px; }
  #post-comments h5 { margin-bottom: 40px; }
  .comment-body { margin-left: 20px; }

  #leave-comment h5 { margin-top: 60px; }

  .comment-form .form-control { height: 52px; padding: 0 15px; }
  .comment-form textarea.form-control { padding: 20px 15px; }
  .comment-form .btn { width: 100%; font-size: 1.0625rem; height: 52px; margin-top: 0; }

  /* NEWSLETTER */
  #newsletter-1 .newsletter-overlay { text-align: center; }

  #newsletter-1 .newsletter-form { margin-top: 0; padding-left: 0; }
  .newsletter-section .form-control { text-align: center; margin-right: 0; margin-bottom: 15px; }
  .newsletter-section .input-group-btn { display: block; width: 100%!important; }
  .newsletter-section .btn { display: block; width: 100%!important; margin: 0; }

  /* CONTACTS */
  .contacts-info { margin-bottom: 40px; }
  .contacts-info-socials li span { top: 0; padding-top: 14px; line-height: 1.2; }

  /* FOOTER */
  .footer { padding-bottom: 40px; }

  .footer.ft-2-rows .footer-links { margin-bottom: 0; }
  #footer-1 .footer-form { margin-top: 35px; }
  #footer-1 .footer-contacts { margin-top: -10px; }
  #footer-3 .footer-links, #footer-5 .fl-2 { padding-left: 0; }
  #footer-4 .footer-info { margin-bottom: 35px; }
  #footer-4 .footer-contacts, #footer-7 .footer-connect, #footer-8 .footer-connect { margin-bottom: 40px; }
  #footer-9 .footer-contacts { margin-top: -5px; margin-bottom: 35px; }

  #footer-2 .foo-links.ico-10 { margin-left: 8px; }
  #footer-2 .footer-info, #footer-5 .footer-info { padding-right: 0; }
  
  .footer-stores-badge.text-end, .bottom-footer .bottom-footer-socials.text-end, .footer-links.text-end,
  .bottom-footer .bottom-secondary-link.text-end, .bottom-footer .bottom-footer-list.text-end { text-align: left!important; }
  .footer-links { margin-bottom: 30px; }

  .fl-1, .fl-2, .fl-3, .fl-4 { border-bottom: 2px solid #ddd; margin-bottom: 20px; padding-bottom: 20px; }
  .theme--dark .fl-1, .theme--dark .fl-2, .theme--dark .fl-3, .theme--dark .fl-4 { border-bottom: 2px solid var(--silver); }
  #footer-1 .fl-2, #footer-1 .fl-3, #footer-1 .fl-4 { padding-left: 0; }
  #footer-1 .fl-3 { margin-bottom: 20px; }
  #footer-1 .fl-5 { border-bottom: 2px solid #ddd; margin-bottom: 20px; padding-bottom: 20px; }
  .theme--dark #footer-1 .fl-5 { border-bottom: 2px solid var(--silver); }
  #footer-5 .fl-2, #footer-6 .fl-3, #footer-7 .fl-2, #footer-8 .fl-3, #footer-11 .fl-2, #footer-12 .fl-3 { margin-bottom: 0; }
  #footer-7 .footer-links, #footer-11 .footer-links { padding-left: 0; }
  #footer-10 .footer-links { margin-bottom: 15px; }

  #footer-6 img.footer-logo { margin-bottom: 15px; }
  #footer-8 img.footer-logo { margin-bottom: 10px; }

  .footer h6.m-title { display: block; margin-bottom: 0!important; }
  .footer h6.d-title { display: none!important; }
  .foo-links { display: none; padding-top: 20px; }
  .footer-phone { margin-top: 10px; }

  .footer hr { display: none; }

  .bottom-footer-socials { margin-top: 16px; }
  .bottom-secondary-link, .bottom-footer-list { margin-top: 5px; }
  .bottom-footer-list.bottom-foo-socials { margin-top: 6px; }
  .bottom-footer-socials li { padding-left: 0; padding-right: 14px; }
  .bottom-foo-socials li { padding-left: 0; padding-right: 9px; }

  .footer-info-socials a, .bottom-footer-socials a { padding-top: 15px; }

  .bottom-foo-socials li span, .footer-info-socials li span, 
  .bottom-footer-socials li span { top: 0; padding-top: 14px; line-height: 1.2; }

  /* INNER PAGE WRAPPER */
  .inner-page-hero, .page-hero-section-overlay { padding-top: 80px; margin-top: 80px; }
  .page-hero-section-overlay .txt-block { margin: 0 0 40px; }

  .inner-page-title { margin-bottom: 60px; }
  .inner-page-title p { line-height: 1.6666; margin-top: 15px; margin-bottom: 0; }

  /* TERMS, PRIVACY, COOKIES */
  .txt-block.legal-info h5 { margin: 40px 0 15px; }
  .txt-block.legal-info h6 { margin: 15px 0; }
  .txt-block.legal-info h6 span, .txt-block.legal-info h5 span { margin-right: 2px; }

  /* SIGN IN / SIGN UP */
  #login, #signup, #reset-password { padding: 70px 0; }
  .login-1 .col-lg-5 { padding-right: 12px; }
  .signup-1 .col-lg-5 { padding-left: 12px; }

  .login-1:after, .signup-1:after { width: 100%; left: 0; }
  #login .register-page-wrapper:after, #signup .register-page-wrapper:after { width: 100%; left: 0; }
  .login-3 .register-page-txt, .signup-3 .register-page-txt { display: none; }

  .rf-m-title { display: block; text-align: center; margin-bottom: 20px; }

  .login-1 .register-page-form, 
  .signup-1 .register-page-form { background-color: var(--white); margin: 0 10%; padding: 40px 30px; border-radius: 12px; }

  .reset-page-wrapper .form-control, .register-page-form .form-control { padding: 0 15px; }

  /* PAGE 404 */
  #page-404 { margin-top: 80px; padding-top: 110px; }
  .page-404-txt { padding-bottom: 80px; }
  .page-404-footer { padding-top: 35px; padding-bottom: 35px; }


}




/* ------------------------------------------------------------------------ */
/* ------------------------------------------------------------------------ */
/* ------------------------------------------------------------------------ */

@media (min-width: 480px) and (max-width: 767.98px) {

  /*------------------------------------------*/
  /*  BASE
  /*------------------------------------------*/

  .bg--fixed { width: auto; }
  .bg--scroll { width: auto; background-attachment: scroll!important; }

  .bg--shape:after { width: 1300px; height: 1300px; top: 12%; left: -10%; }
  .bg--shape-top:after { width: 500px; height: 500px; top: -6%; right: 12%; background-color: rgba(119, 67, 142, .15); }

  /* Headers */
  h6, h6.h6-md, h6.h6-lg, h6.h6-xl { font-size: 1.375rem; }   /* 22px; */
  h5, h5.h5-md { font-size: 1.5625rem; }                      /* 25px */
  h5.h5-lg, h5.h5-xl { font-size: 1.6875rem; }                /* 27px */
  h4, h4.h4-md, h4.h4-lg, h4.h4-xl { font-size: 1.8125rem; }  /* 29px */
  h3, h3.h3-md, h3.h3-lg, h3.h3-xl { font-size: 1.9375rem; }  /* 31px */
  h2, h2.h2-md, h2.h2-lg { font-size: 2.125rem; }             /* 34px */
  h2.h2-xl, h2.h2-title { font-size: 2.375rem; }              /* 38px */        

  h6, h5, h4, h3, h2, h1 { line-height: 1.4; }

  /* Paragraphs */
  p.p-sm { font-size: 0.9375rem; } 
  p { font-size: 1rem; }  
  p.p-md, p.p-lg, p.p-xl { font-size: 1.0625rem; }

  /* Button */
  .btn.btn-sm, .btn, .btn.btn-md { font-size: 0.9375rem; padding: 0.8rem 1.45rem; }
  .btns-group .btn.ico-30.ico-left, .btns-group .btn.ico-30.ico-right { padding: 0.3845rem 0.5rem; }

  /* Button Icon */
  .btn.ico-20 [class*="flaticon-"]:before, 
  .btn.ico-20 [class*="flaticon-"]:after { font-size: 1.225rem; line-height: 0.45rem!important;top: 4px; }

  .btn.ico-30 [class*="flaticon-"]:before, .btn.ico-30 [class*="flaticon-"]:after { font-size: 2.05rem; }

  .btn.ico-30.ico-left span.ico-30-txt { font-size: 1.15rem; top: -10px; margin-right: 0; }
  .btn.ico-30.ico-right span.ico-30-txt { font-size: 1.15rem; top: -10px; margin-left: 0; }
  .btn.ico-left span { margin-right: 5px; }
  .btn.ico-right span { margin-left: 5px; }

  /* Button Text */
  p.btn-txt, p.btns-group-txt, .btn-rating { margin: 14px 0 0 0; }
  .text-center p.btns-group-txt { margin-top: 18px; }
  .star-rating span.rating-txt { font-size: 1.0625rem; }

  /* Transparent Link */
  a.tra-link { font-size: 1.03125rem; }
  .tra-link.ico-20 [class*="flaticon-"]:before, 
  .tra-link.ico-20 [class*="flaticon-"]:after { font-size: 0.785rem; line-height: 0.7rem!important; top: 1px; left: 2px; }

  /* Store Badge */
  .store-btn-4 { margin-top: 15px; }

  /* Watch Link */
  .watch-video { margin-top: 22px; }
  p.video-txt-lg { font-size: 1.125rem; }
  p.video-txt-sm { font-size: 1.0625rem; }

  /* Vector Icon Background */ 
  .ico-50 .shape-ico svg { width: 125px; height: 125px; top: -35px; left: calc(50% - 65px); }
  .ico-55 .shape-ico svg { width: 125px; height: 125px; top: -35px; left: calc(50% - 60px); }
  .ico-60 .shape-ico svg { width: 130px; height: 130px; top: -35px; left: calc(50% - 65px); }

  /* Vector Rounded Icons */
  .ico-rounded, .ico-rounded-md, .ico-rounded-lg { width: 94px; height: 94px; }

  .ico-rounded [class*="flaticon-"]:before, 
  .ico-rounded [class*="flaticon-"]:after, 
  .ico-rounded-md [class*="flaticon-"]:before, 
  .ico-rounded-md [class*="flaticon-"]:after, 
  .ico-rounded-lg [class*="flaticon-"]:before, 
  .ico-rounded-lg [class*="flaticon-"]:after { font-size: 3rem; line-height: 94px!important; }

  /* Section ID */
  .section-id { font-size: 0.9rem; margin-bottom: 22px; }

  /* Section Title */
  .section-title.mb-70 {margin-bottom: 35px; }
  .section-title p { margin-top: 15px; }
  .section-title .btns-group { margin-top: 20px; }

  /* Users */
  .txt-block .users { margin-top: 15px; }
  .users img { max-height: 44px; }
  .users p { font-size: 0.975rem; top: 12px; left: 5px; text-align: left!important; }

  /*------------------------------------------*/
  /*   NAVIGATION MENU    
  /*------------------------------------------*/

  /* Header Logo */
  .wsmobileheader .smllogo { display: block; margin-top: 15px; padding-left: 18px; }
  .smllogo img { width: auto; max-width: inherit; max-height: 50px; }

  .wsmenu > .wsmenu-list { width: 345px; margin-right: -345px; }
  .wsactive .wsmobileheader { margin-right: 345px; }
  .overlapblackbg { width: calc(100% - 345px); }

  .wsanimated-arrow { padding: 14px 30px 14px 0px; margin: 25px 18px 0 0; }

  /* Nav Link Info */
  span.sm-info { width: 22px; height: 22px; font-size: 0.8rem; line-height: 20px; top: -1px; margin-left: 8px; }

  .wsmenu > .wsmenu-list > li > a { padding: 5px 32px 4px 20px; margin: 0; }
  .wsmenu > .wsmenu-list > li > .wsmenu-click > i { height: 8px; width: 8px; margin: 24px 18px 0px 0px; }

  .wsmenu > .wsmenu-list > li > ul.sub-menu, .wsmenu > .wsmenu-list > li > .wsmegamenu.w-75,
  .wsmenu > .wsmenu-list > li > .wsmegamenu.halfmenu { top: 0; }

  .wsmenu > .wsmenu-list > li > ul.sub-menu, .wsmenu > .wsmenu-list > li > .wsmegamenu.halfmenu, 
  .wsmenu > .wsmenu-list > li > .wsmegamenu.w-75 { padding: 6px 10px; }

  .wsmenu > .wsmenu-list > li > ul.sub-menu > li > a, 
  .wsmenu > .wsmenu-list > li > ul.sub-menu > li > a:hover, 
  .wsmenu > .wsmenu-list > li > .wsmegamenu .link-list li a,
  .wsmenu > .wsmenu-list > li > .wsmegamenu .link-list li a:hover,
  .wsmenu > .wsmenu-list > li > .wsmegamenu.w-75 ul.link-list > li > a, 
  .wsmenu > .wsmenu-list > li > .wsmegamenu.halfmenu ul.link-list > li > a,
  .wsmenu > .wsmenu-list > li > .wsmegamenu.w-75 ul.link-list > li > a:hover, 
  .wsmenu > .wsmenu-list > li > .wsmegamenu.halfmenu ul.link-list > li > a:hover { padding: 14px 10px; }

  /* Header Store Badges */
  .store.header-store img { max-height: 46px; }

  /* Header Social Links */
  .header-socials.ico-20 [class*="flaticon-"]:before, 
  .header-socials.ico-25 [class*="flaticon-"]:after { line-height: 52px!important; } 

  /*------------------------------------------*/
  /*   HERO   
  /*------------------------------------------*/

  .slideshow, #hero-20 .slideshow, .slideshow .slides, #hero-20 .slideshow .slides { height: 500px; }

  .img-link { bottom: 15px; right: 15px; width: 45px; height: 45px; }
  .img-link a span { top: 4.5px; }

  /* HERO QUICK FORM */
  .hero-section .quick-form .form-control, .hero-section .quick-form .btn { font-size: 1.175rem; height: 60px; }
  .hero-section .text-center .quick-form-msg { margin: 20px 0 -5px 0; }

  /* HERO DIGITS */
  .hero-digits h2.statistic-number { font-size: 3.125rem; }
  .hero-digits h2.statistic-number small { font-size: 2.35rem; margin-left: 3px; }

  /* HERO-1 */
  .hero-1-txt h2 { font-size: 2.5rem; }
  .hero-1-txt p.p-lg, .hero-1-txt p.p-xl { padding: 0 5%; margin: 18px 0 24px; }
  .hero-1-img { margin: 40px 2% 0; }

  /* HERO-2 */
  #hero-2:after { height: 8%; }
  .hero-2-txt h2 { font-size: 3rem; padding: 0 5%; }
  .hero-2-txt p.p-lg { padding: 0 3%; margin-top: 15px; margin-bottom: 25px; }

  /* HERO-3 */
  .hero-3-txt h2 { font-size: 3rem; padding: 0 3%; }
  .hero-3-txt p.p-md { margin-top: 15px; }
  .hero-3-txt .users { margin-top: 18px; }

  /* HERO-4 */
  .hero-4-txt p.p-lg { margin: 18px 0 24px; }

  /* HERO-5 */
  #hero-5:after { height: 96%; left: 0; }
  .hero-5-txt h2 { font-size: 2.85rem; }
  .hero-5-txt p.p-md { margin: 15px 0 25px; }

  /* HERO-6 */
  .hero-6-txt h2 { font-size: 2.9rem; }
  .hero-6-txt p.p-lg { margin-top: 18px; }
  #hero-6 .quick-form { margin: 25px 3% 0; }

  /* HERO-7 */
  .hero-7-txt h2 { font-size: 2.65rem; }
  .hero-7-txt p.p-lg, .hero-7-txt p.p-xl { margin: 14px 0 20px; padding: 0 3%; }
  #hero-7 .users { margin-top: 16px; }
  #hero-7 .users img { max-height: 44px; }
  #hero-7 .users p { font-size: 1rem; }
  .hero-7-img { margin: 35px 2% 0; }

  /* HERO-8 */
  .hero-8-txt h2 { font-size: 3rem; padding: 0 5%; }
  .hero-8-txt p.p-lg { padding: 0 3%; margin-top: 15px; margin-bottom: 25px; }
  .hero-8-txt p.btn-txt { margin-top: 25px; }
  #hero-8 .quick-form { margin: 27px 6% 0; }

  /* HERO-9 */
  #hero-9 .wave-shape-bottom svg { height: 45px; }
  .hero-9-txt h2 { font-size: 2.9rem; }
  .hero-9-txt p.p-lg { margin-top: 18px; }
  #hero-9 .quick-form { margin: 25px 3% 0; }

  /* HERO-10 */
  .hero-10-txt h2 { font-size: 3rem; padding: 0 6%; }
  .hero-10-txt p.p-lg { padding: 0 3%; margin-top: 15px; margin-bottom: 25px; }
  #hero-10 .hero-overlay { padding: 65px 35px 0; }
  .hero-10-wrapper { border-radius: 12px; }
  .hero-10-img { margin-bottom: -18%; }
  .hero-10-img .video-btn { top: calc(50% - 30px); }

  /* HERO-11 */
  .hero-11-txt h2 { font-size: 2.75rem; }
  .hero-11-txt p.p-md { margin: 16px 0 25px; padding: 0; }
  .hero-11-img { margin: 35px 0 0; }

  /* HERO-12 */
  #hero-12 { padding-top: 0; }
  #hero-12 .container { max-width: 100%; }
  .hero-12-wrapper { padding: 70px 35px; margin: 0 -15px; border-radius: 0 0!important; }
  .hero-12-txt h2 { font-size: 2.5rem; }
  .hero-12-txt p.p-lg { margin: 15px 0 25px; }

  /* HERO-13 */
  .hero-13-txt h2 { font-size: 2.45rem; }
  .hero-13-txt p.p-xl { padding: 0 3%; margin-top: 18px; }
  #hero-13 .quick-form { margin: 28px 6% 0; }
  .hero-13-txt p.btn-txt { margin-top: 25px; }

  /* HERO-14 */
  #hero-14 { padding-top: 70px; }
  #hero-14:after { height: 7%; }
  .hero-14-txt { padding: 0 3%; }
  .hero-14-txt .section-id { padding: 11px 25px; font-size: 0.9rem; margin-bottom: 30px; }
  .hero-14-txt h2 { font-size: 2.5rem; }
  .hero-14-txt p.p-md { margin: 16px 0 20px; }
  .hero-14-img { margin-top: 40px; }

  /* HERO-15 */
  .hero-15-txt h2 { font-size: 3rem; padding: 0 5%; }
  .hero-15-txt p.p-lg { padding: 0 3%; margin-top: 15px; margin-bottom: 25px; }

  /* HERO-16 */
  .hero-16-txt h2 { font-size: 2.9rem; }
  .hero-16-txt p.p-lg { margin-top: 18px; }
  #hero-16 .quick-form { margin: 25px 3% 0; }
  #hero-16 .users img { max-height: 48px; }
  .users p { top: 13px; }

  /* HERO-17 */
  #hero-17:after { height: 8%; }
  .hero-17-txt h2 { font-size: 2.5rem; padding: 0; }
  .hero-17-txt p.p-xl { padding: 0 5%; margin-top: 16px; }
  .hero-17-txt .btns-group { margin-top: 24px; }
  .hero-17-img { margin-top: 50px; }

  /* HERO-18 */
  .hero-18-txt { margin-bottom: 50px; }
  .hero-18-txt .section-id { margin-bottom: 25px; }
  .hero-18-txt h2 { font-size: 2.65rem; padding: 0 5%; }
  .hero-18-txt p.p-xl { padding: 0; margin-top: 16px; }
  .hero-18-txt .btns-group { margin-top: 25px; }

  .hero-bg-shape { width: 140%; height: 100%; left: -12%; right: -12%; opacity: .3; }

  /* HERO-19 */
  .hero-19-txt h2 { font-size: 2.45rem; }
  .hero-19-txt p.p-xl { padding: 0 3%; margin-top: 18px; }
  #hero-19 .quick-form { margin: 28px 6% 0; }
  .hero-19-txt p.btn-txt { margin-top: 25px; }
  .hero-19-img { margin-top: 40px; }

  /* HERO-20 */
  #hero-20 .caption {padding: 0 4%; }
  #hero-20 .caption h2 { font-size: 2.75rem; padding: 0 4%; }
  #hero-20 .caption p.p-xl { padding: 0; margin-top: 15px; }
  #hero-20 .caption .btn { margin-top: 26px; }

  /* HERO-21 */
  #hero-21:after { height: 12%; }
  .hero-21-shape { width: 100%; top: 2%; left: 0; opacity: .6; }
  .hero-21-txt h2 { font-size: 2.5rem; padding: 0; }
  .hero-21-txt p.p-xl { padding: 0 5%; margin-top: 16px; }
  .hero-21-txt .btns-group { margin-top: 24px; }
  .hero-21-img { margin-top: 50px; }

  /* HERO-22 */
  #hero-22 .caption h2 { font-size: 2.75rem; padding: 0; }
  #hero-22 .caption p.p-xl { padding: 0; margin-top: 15px; }
  #hero-22 .caption .btn { margin-top: 26px; }

  /* HERO-23 */
  .hero-23-txt h2 { font-size: 2.85rem; }
  .hero-23-txt p.p-lg { padding: 0; margin-top: 15px; margin-bottom: 25px; }

  /* HERO-24 */
  .hero-24-txt h2 { font-size: 3rem; padding: 0 6%; }
  .hero-24-txt p.p-lg { margin-top: 20px; }
  .hero-24-txt .users { margin-top: 20px; }

  #hero-24-form { padding: 30px 25px; margin: 60px 8% -60px; }
  #hero-24-form h5 { margin-bottom: 12px; }
  #hero-24-form p { padding: 0 3%; margin-bottom: 20px; }
  #hero-24-form .btn { height: 54px; font-size: 1.0625rem; padding: 16px; }

  /* HERO-25 */
  .hero-25-txt h2 { font-size: 2.85rem; padding: 0 4%; }
  .hero-25-txt p.p-lg { padding: 0; margin-top: 18px; margin-bottom: 25px; }

  /* HERO-26 */
  .hero-26-txt h2 { font-size: 2.75rem; }
  .hero-26-txt p.p-lg { padding: 0; margin-top: 15px; margin-bottom: 25px; }
  .hero-26-img { margin: 45px -10% 0; }

  /* HERO-27 */
  .hero-27-txt h2 { font-size: 2.75rem; margin-bottom: 20px; }
  .hero-27-txt p { padding: 0 4%; }

  #hero-27 .img-link { bottom: 22px; left: 22px; width: 65px; height: 65px; }
  #hero-27 .img-link a span { top: 12px; }

  /* HERO REQUEST FORM */
  .request-form .form-control { height: 54px; font-size: 1.0625rem; margin-bottom: 15px; }
  
  /*------------------------------------------*/
  /*  CONTENT
  /*------------------------------------------*/

  /* FEATURES */
  #features-3.py-100.shape--bg:after { width: 100%; height: 70%; top: 30%; left: 0; }
  #features-3.pt-100.shape--bg:after { width: 100%; height: 66%; top: 31.5%; left: 0; }
  #features-4.py-100.shape--bg:after { width: 100%; height: 70%; top: 30%; }
  #features-4.pt-100.shape--bg:after { width: 100%; height: 68%; top: 30%; }

  .fbox-3, .fbox-4, .fbox-5 { padding: 40px 50px; } 
  .fbox-6 { text-align: center; padding: 45px 40px; }

  .fbox-9, .ico-title .fbox-9.fb-1, .ico-title .fbox-9.fb-2, .ico-title .fbox-9.fb-3 { padding: 40px; }
  .fbox-9.fb-4, .fbox-9.fb-5, .fbox-9.fb-6, .ico-title .fbox-9.fb-4, 
  .ico-title .fbox-9.fb-5, .ico-title .fbox-9.fb-6 { padding: 40px 40px 0; }
  .fbox-10, .fbox-10.fb-5, .fbox-10.fb-6 { padding: 40px; } 
  .ico-title .fbox-10.fb-5, .ico-title .fbox-10.fb-6 { padding: 40px 40px 30px; } 
  .fbox-10.fb-7, .fbox-10.fb-8, .ico-title .fbox-10.fb-7, .ico-title .fbox-10.fb-8 { padding: 40px 40px 30px; } 

  .fbox-11.fb-1, .fbox-11.fb-2 { margin-bottom: 30px; } 
  .fbox-12.fb-1, .fbox-12.fb-2, .fbox-12.fb-3 { margin-bottom: 24px; }

  .fbox-img.h-175 img, .fbox-img.h-180 img { max-height: 220px; } 

  .fbox-7 .fbox-img { margin-bottom: 25px; }
  .fbox-8 .fbox-img.h-175, .fbox-8 .fbox-img.h-180 { padding-top: 35px; margin: 0 10% 45px; } 

  .fbox-9.fb-1 .fbox-9-img, .fbox-9.fb-2 .fbox-9-img, .fbox-9.fb-3 .fbox-9-img { margin: 25px 0 0; }
  .fbox-9.fb-4 .fbox-9-img, .fbox-9.fb-5 .fbox-9-img, .fbox-9.fb-6 .fbox-9-img { margin: 25px 0 -40px; }
  .fbox-9.fb-7 .fbox-9-img, .fbox-9.fb-8 .fbox-9-img, .fbox-9.fb-9 .fbox-9-img { margin: 0 0 25px; }

  .fbox-10.fb-1 .fbox-10-img, .fbox-10.fb-2 .fbox-10-img, 
  .fbox-10.fb-3 .fbox-10-img, .fbox-10.fb-4 .fbox-10-img { margin: 0 5% 30px; }
  .fbox-10.fb-7 .fbox-10-img, .fbox-10.fb-8 .fbox-10-img { margin: 30px 5% 0; }
  .fbox-10.fb-5 .fbox-10-img, .fbox-10.fb-6 .fbox-10-img { margin: 0 0 35px; }
  .ico-title .fbox-10.fb-5 .fbox-10-img, .ico-title .fbox-10.fb-6 .fbox-10-img { margin: 30px 0 0; }

  .fbox-ico, .fbox-ico.ico-rounded, .fbox-5 .fbox-ico { margin-bottom: 20px; }
  .fbox-ico[class*="img-"], .fbox-ico.ico-rounded-md, .fbox-ico.ico-rounded-lg { margin-bottom: 20px; }

  .fbox-wrapper .ico-50 [class*="flaticon-"]:before, .fbox-wrapper .ico-50 [class*="flaticon-"]:after,
  .fbox-wrapper .ico-55 [class*="flaticon-"]:before, .fbox-wrapper .ico-55 [class*="flaticon-"]:after,
  .fbox-wrapper .ico-60 [class*="flaticon-"]:before, .fbox-wrapper .ico-60 [class*="flaticon-"]:after { font-size: 4rem; }
  .fbox-ico-wrap .ico-40 [class*="flaticon-"]:before, .fbox-ico-wrap .ico-40 [class*="flaticon-"]:after { font-size: 2.85rem; }

  .t-icon.ico-25 [class*="flaticon-"]:before, .t-icon.ico-25 [class*="flaticon-"]:after { font-size: 1.8125rem; }
  .t-icon.ico-30 [class*="flaticon-"]:before, .t-icon.ico-30 [class*="flaticon-"]:after { font-size: 2rem; }

  .fbox-3 h6, .fbox-4 h6 { margin-top: 18px; margin-bottom: 12px; }
  .fbox-2 h5, .fbox-wrapper .row-cols-md-2 h6, 
  .fbox-wrapper .row-cols-md-3 h6, .fbox-6 h6, .fbox-9 h6, .fbox-10 h5, .fbox-11 h6 { margin-bottom: 12px; }
  .fbox-10.fb-5 h5, .fbox-10.fb-6 h5 { margin-bottom: 14px; }
  .ico-title .fbox-10.fb-5 h5, .ico-title .fbox-10.fb-6 h5 {  margin-bottom: 12px; } 

  .fbox-7 p { padding: 0 2%; }

  /* CONTENT */
  .ct-05 .section-title, .ct-06 .section-title { padding: 0 3%; }
  .bc-03 .section-title { padding: 0; }
  .bc-03 .section-title p { padding: 0 5%; }

  .ct-06 .section-overlay { margin-bottom: 50px; }
  .bc-01 .section-overlay { padding: 70px 35px; }
  .bc-02 .section-overlay, .bc-02 .ico-title .section-overlay { padding: 55px 35px; }
  .bc-02.pt-30 .section-overlay, .bc-02.pb-30 .section-overlay { padding: 40px 35px; }
  .bc-02.pt-30.bc-02-top { padding-top: 20px; margin-top: 50px; }
  .bc-03 .section-overlay { padding: 70px 30px 0; }
 
  .txt-box, .bc-01 .txt-box { margin-bottom: 15px; }
  .bc-04-box { padding: 30px 35px; }

  .two-txt .txt-block.left-column { margin-bottom: 35px; }
  .two-txt .cbox-3, .two-txt .cbox-3 .ico-wrap { text-align: left!important; }

  .txt-block h3, .txt-block h2 { margin-bottom: 20px; }
  .txt-block h5 { margin-bottom: 15px; }
  h5.h5-title { margin: 15px 0; }

  .bc-04-box h2 { font-size: 2.85rem; margin-bottom: 14px; }
  .bc-04-box h2 small, .bc-04-box h2 span { font-size: 2.45rem; }
  .cbox-5 p.p-sm { font-size: 1rem; }

  .txt-block .btn { margin-top: 24px; }
  .txt-block-tra-link { margin-top: 18px; }

  .cbox-1-ico { width: 2rem; height: 2rem; line-height: 1.65rem; margin-top: 4px; }

  .cbox-3 .cbox-3-ico { margin-bottom: 10px; }
  .cbox-3-ico span[class*="flaticon-"]:before, .cbox-3-ico span[class*="flaticon-"]:after { font-size: 3.25rem; }

  .cbox-title span { margin-right: 22px; }
  .cbox-title span[class*="flaticon-"]:before, .cbox-title span[class*="flaticon-"]:after { font-size: 2.5rem; }
  .cbox-title.ico-30 h5 { line-height: 2.5rem!important; }

  .cbox-1-txt { margin-bottom: 20px; }
  .cbox-3-txt { margin-bottom: 30px; }
  .cbox-1-txt h5, .cbox-3-txt h5 { margin-bottom: 10px; }

  .cbox-4 { width: 400px; min-width: 400px; padding: 12px 16px; }
  .cbox-4.mt-20 { margin-top: 8px; }
  .cbox-4.mb-30 { margin-bottom: 25px; }
  .cbox-4-ico span { position: relative; top: 3px; }
  .cbox-4-txt { padding-left: 11px; }

  .cbox-5-wrapper { margin-top: 0; }
  .box-title { margin-bottom: 12px; }
  .cbox-5.cb-2 { margin-top: 15px; }
  .box-title span { top: 10px; left: 3px; right: 0; }
  .box-title h6 { position: relative; top: 2px; left: 8px; }

  .txt-block .accordion-1 .accordion-item { padding: 22px 0 27px; }
  .txt-block .accordion-1 .accordion-item .accordion-thumb:after,
  .txt-block .accordion-1 .accordion-item.is-active .accordion-thumb:after { font-size: 1rem; top: 0.5px; right: 5px; }

  .txt-block .accordion-1 .accordion-thumb h5 { font-size: 1.65rem; padding-left: 0; }
  .txt-block .accordion-1 .accordion-item.is-active .accordion-thumb h5 { margin-bottom: 18px; }
  .txt-block .accordion-1 .accordion-panel { padding: 0; }

  /* Advantages */
  .advantages, .text-center .advantages { margin-top: 15px; }

  /* Compatibility */
  .compatibility { margin-top: 10px; }

  /* Tools List / Payments Icons */
  .tools-list p, .payment-methods p { margin-bottom: 15px; }
  .payment-icons img { max-height: 32px; }

  .tools-list.ico-35 [class*="flaticon-"]:before, .tools-list.ico-35 [class*="flaticon-"]:after { 
    font-size: 2.125rem; line-height: 2.5rem!important; margin-right: 5px; 
  }

  /* Content Images */
  .img-block-hidden, .ct-06 .img-block { margin-bottom: -50px; }
  .img-block-txt, .img-block-btn { margin-top: 40px; }

  .ct-01 .img-block.right-column, .ct-02 .img-block.right-column, .ct-03 .img-block.right-column,
  .ct-01 .img-block.left-column, .ct-02 .img-block.left-column, .ct-03 .img-block.left-column { margin: 0 0 40px; }

  /* STATISTIC */
  .statistic-2-wrapper .txt-block { padding: 0 10%; }
  .statistic-ico { margin-bottom: 12px; }

  h3.statistic-number { font-size: 2.7rem; letter-spacing: -1px; }
  h2.statistic-number { font-size: 2.85rem; letter-spacing: -1px; }
  .statistic-2-wrapper h2, #sb-3-1 h2.statistic-number, #sb-3-2 h2.statistic-number { margin-bottom: 12px; }
  .statistic-4-wrapper h5 { margin: 20px 0 12px; }
  .statistic-5-wrapper h3 { font-size: 2.25rem; line-height: 1; }

  .statistic-5-wrapper p { margin-top: 12px; }
  
  .statistic-8-wrapper img { max-height: 48px; margin-top: 16px; }
  .statistic-section .action-btn { margin-top: 45px; }

  #portfolio-1 .more-btn { margin-top: 20px; }

  /* PROJECT DETAILS */
  .project-title { padding: 0; margin-bottom: 35px; }
  .project-title p { margin-top: 15px; }
  .project-data { padding-bottom: 25px; margin: 35px 0 30px; }
  .project-data p { margin-bottom: 10px; }

  .project-txt h5, .project-txt h6 { margin: 30px 0 15px; }

  .project-inner-img { margin: 35px 0; }
  .project-inner-img.d-flex { margin-left: -11.25px; margin-right: -11.25px; }
  .project-inner-img .top-img { margin-bottom: 30px; }

  #project-details .more-projects { margin-top: 45px; }
  #project-details .more-projects span { top: 1px; left: 5px; }
  #project-details .more-projects.ico-25 [class*="flaticon-"]:before, 
  #project-details .more-projects.ico-25 [class*="flaticon-"]:after { font-size: 1.2125rem; } 

  /* TEAM */
  .team-member-data p { font-size: 1.075rem; }

  /* CAREERS */
  .position-data p { font-size: 1.125rem; }

  .position-workplace { text-align: left; }
  .position-workplace h6 { font-size: 1.125rem; }

  .posting-apply a.btn { font-size: 0.9rem; padding: 0.7rem 1.2rem; margin-top: -8px; }

  /* JOB POSITION */
  #job-position .inner-page-title { padding-bottom: 45px; }
  #job-position .inner-page-title p { margin-top: 12px; padding: 0; }
  #job-position .inner-page-title .btn { padding: 0.7rem 1.1rem; margin-top: 5px; }

  .position-info .btn { margin-top: 20px!important; }

  /* PRICING */
  .pricing-1-table, .text-center .pricing-1-table { padding: 35px 35px 40px; }

  .pricing-table-header h5 { font-size: 1.6875rem!important; }
  .pt-btn { font-size: 1.0625rem; padding: 0.925rem 1.3rem; }
  .p-table p.btn-txt { font-size: 1rem; margin: 12px 0 0 0; }

  /* Price */
  .price span { font-size: 2.875rem; letter-spacing: -1.5px; } 
  .price sup { font-size: 2.15rem; top: -4px; }
  .price sup.validity { font-size: 1.75rem; }

  .text-center .price p { padding: 0; }

  /* Pricing Features */
  .pricing-features.mt-25 { margin-top: 18px; }
  .pricing-features li { padding: 12px 8px; }

  /* Pricing Notice */
  .pricing-notice.mb-40 { margin-bottom: 30px; }
  .col-lg-9 .pricing-notice p, .col-lg-10 .pricing-notice p { padding: 0; }

  /* Pricing Toggle Button */
  .toggle-btn-md .toggler-txt, .toggle-btn-lg .toggler-txt { font-size: 1.15rem; }

  /* Pricing Compare */
  .table-responsive.mb-50 { margin-bottom: 35px; }
  .table>:not(caption)>*>* { padding-top: 10px; padding-bottom: 10px; }
  .comp-table .table-responsive thead th { font-size: 0.975rem; }
  .comp-table .table-responsive tbody th { font-size: 0.925rem; }
  .comp-table .table-responsive tbody td { font-size: 0.925rem; top: 0; }

  .comp-table .table-responsive tbody td span { top: 1px; }

  .table-responsive .ico-15 [class*="flaticon-"]:before, 
  .table-responsive .ico-15 [class*="flaticon-"]:after { font-size: 0.725rem; }

  .table-responsive .ico-20 [class*="flaticon-"]:before, 
  .table-responsive .ico-20 [class*="flaticon-"]:after { font-size: 0.875rem; }

  #pbox-1, #pbox-2, #pbox-3 { margin-bottom: 30px; }
  .pricing-section .txt-box-last, .pricing-section .pm-last { margin-bottom: 40px; }
  .comp-table-payment h6 { margin-bottom: 15px; }

  /* RATING */
  .rating-title h5 { font-size: 1.1875rem; }
  .rbox-2-txt img { max-height: 32px; }
  p.rbox-2-txt { font-size: 1.125rem; line-height: 30px; }
  .rbox-1 .star-rating { font-size: 1rem; line-height: 1; }

  .rbox-1 .star-rating span { top: -1.5px; }
  .rbox-1 .star-rating.ico-15 [class*="flaticon-"]:before, 
  .rbox-1 .star-rating.ico-15 [class*="flaticon-"]:after { font-size: 1rem; } 
  .rbox-2 .ico-20 [class*="flaticon-"]:before, .rbox-2 .ico-20 [class*="flaticon-"]:after { font-size: 1.2rem; } 

  /* TESTIMONIALS */
  .reviews-3-wrapper { padding: 70px 6%; }
  .reviews-4-wrapper { padding: 70px 4%; }

  #reviews-3 .txt-block { margin-bottom: 40px; }

  .review-1, .review-5 { padding: 25px 25px 30px; }
  .review-2 { padding: 0; }
  .review-3 { text-align: center; padding: 30px 20px; }

  .review-5-logo { margin-left: 0; margin-bottom: 35px; }
  .review-5-logo img { max-height: 44px; }

  .review-1 .review-ico, .review-5 .review-ico { top: 10px; right: 20px; }

  .review-1 .review-rating, .review-5 .review-rating { margin-bottom: 8px; }
  .review-rating.ico-15 [class*="flaticon-"]:before, 
  .review-rating.ico-15 [class*="flaticon-"]:after,
  .review-1 .review-rating.ico-15 [class*="flaticon-"]:before, 
  .review-5 .review-rating.ico-15 [class*="flaticon-"]:before, 
  .review-1 .review-rating.ico-15 [class*="flaticon-"]:after, 
  .review-5 .review-rating.ico-15 [class*="flaticon-"]:after { font-size: 1.1rem; }

  .review-1 .review-author h6, .review-5 .review-author h6 { margin-bottom: 12px; }
  .review-2-txt h5 { padding: 0 10%; margin: 10px 0; }
  .review-3-txt h6.h6-md, .review-3-txt h6.h6-lg { margin-top: 5px; margin-bottom: 10px; }
  #reviews-3 .txt-block h2 { margin-bottom: 14px; }
  #reviews-3 .txt-block p { padding: 0 5%; margin-top: 0; }
  #reviews-3 .txt-block .btn { margin-top: 22px; }

  .review-3 .review-avatar { margin: 20px auto 14px; float: none; }

  .review-1 .review-author, .review-5 .review-author { margin-top: 15px; }
  .review-2 .review-author { margin-top: 20px; }
  .review-3 .review-author { text-align: center; padding: 0; margin-top: 0; }

  .review-author h6 { font-size: 1.175rem; margin-bottom: 12px; }
  .review-author p, .reviews-4-txt .review-author p { font-size: 1.0625rem; }
  .reviews-section p.p-sm { font-size: 1rem; }

  .review-5-avatar { right: 25px; bottom: 30px; }
  .review-5-avatar img { width: 43px; height: 43px; }

  .reviews-4-img { width: 130px; height: 130px; margin: 0 auto 10px; }
  .reviews-4-txt .review-author, .review-5 .review-author.mt-30 { margin-top: 20px; }

  /* BRANDS */
  #brand-2-1, #brand-2-2, #brand-2-3, #brand-3-1, #brand-3-2, 
  #brand-3-3, #brand-3-4, #brand-3-5, #brand-3-6 { margin-bottom: 35px; }
  #brand-3-10 { display: none; }

  .brands-title h6 { font-size: 1rem; margin-bottom: 35px; }
  .brands-title h5 { font-size: 1.4rem; margin-bottom: 35px; }
  .brands-title p { padding: 0; margin-bottom: 30px; }

  #brands-4 .txt-block { margin-bottom: 45px; }

  .brands-carousel-5 .brand-logo, .brands-carousel-6 .brand-logo { padding: 0 15px; } 
  #brands-2 .brand-logo, #brands-3 .brand-logo { padding: 0 7px; } 

  .brands-section .more-btn { margin-top: 35px; }

  /* INTEGRATIONS */
  #integrations-4.pt-100.pb-70 { padding-bottom: 54px; }
  .int-4-wrapper .col { padding: 0 8px; }

  .filter-btns { margin-bottom: 30px; }
  .filter-btns button { margin-bottom: 13px; }

  .int_tool-1 { padding: 18px 20px; }
  .int_tool-2 { padding: 20px 25px; }
  .int_tool-3 { padding: 24px; margin-bottom: 24px; }
  .int_tool-4 { padding: 22px 15px; margin-bottom: 16px; }

  .int_tool-1 .tool_logo img { width: 50px; height: 50px; }
  .int_tool-2 .tool_logo img { width: 44px; height: 44px; }
  .int_tool-3 img, .int_tool-4 img { width: 52px; height: 52px; }

  .tool_txt h6 { font-size: 1.3125rem; line-height: 1; margin-top: 1px; }
  .tool_logo h6 { font-size: 1.3125rem; line-height: 44px; top: 3px; margin-left: 10px; }
   .int_tool-4 h6 { font-size: 1rem; margin-top: 14px; }
  .tool_txt p { font-size: 1.0625rem; margin-top: 3px; }
  .tool_description p { font-size: 1rem; margin-top: 13px; }

  /* BANNER */
  .banner-2-wrapper .banner-overlay { padding: 60px 50px 65px; }
  #banner-3 .banner-overlay.pt-80.pb-90 { padding: 55px 0 65px; } 
  .banner-4-wrapper { padding: 50px 10%; }

  .banner-4-txt { margin-bottom: 12px; }

  .banner-2-txt h2, .banner-3-txt h2 { margin-bottom: 12px; }
  .banner-2-txt h2 { font-size: 1.75rem!important; padding: 0 5%; margin-bottom: 12px; }
  .banner-2-txt.l-txt h2 { padding: 0; }
  .banner-3-txt h2.h2-xl, .banner-3-txt h2.h2-title { margin-bottom: 20px; }

  .banner-section p { margin-bottom: 20px; }
  #banner-2.banner-section p { padding: 0; }

  /* FAQs */
  #faqs-3 .section-title { margin-bottom: 30px; }

  #faqs-2 .question h6, #faqs-2 .question h5 { font-size: 1.25rem; margin-bottom: 12px; }
  .accordion-thumb h5 { font-size: 1.25rem; }
  .faqs-3-question h5 { margin-bottom: 12px; }

  #faqs-3.faqs-section .accordion-thumb { padding: 20px 0 25px; }

  #faqs-3.faqs-section .accordion-item .accordion-thumb:after, 
  #faqs-3.faqs-section .accordion-item.is-active .accordion-thumb:after { font-size: 1rem; top: 28px; right: 2px; }

  #faqs-3 .accordion-panel { padding: 30px 0 15px 0; } 
  #faqs-3 .accordion-panel-item.mb-35 { margin-bottom: 25px; }

  .more-questions { margin-top: 10px; }
  .more-questions h6 { font-size: 1.1875rem; }
  #faqs-3 .more-questions { margin: 30px auto 0; }
  .more-questions-txt { padding: 18px 35px; }
  .more-questions-txt p { font-size: 1.125rem; }

  /* HELP CENTER */
  .help-category { padding: 35px 25px; margin-bottom: 30px; }
  .help-category h6 { margin: 10px 0; }

  /* HELP CATEGORY */
  .help-category-title { padding: 50px 30px 30px; margin-bottom: 50px; }
  .help-category-title p { margin-top: 12px; }

  #help-category h4 { margin-bottom: 35px; }
  .help-category-item { padding-bottom: 30px; margin-bottom: 30px; }

  .help-category-item p { margin: 14px 0; }
  .help-category-data span { font-size: 1rem; }
  .read-time { margin-left: 10px; }

  /* HELP ARTICLE */
  .breadcrumb-wrapper { margin-bottom: 28px; }
  .breadcrumb { margin-bottom: 0; }

  .help-article-title h3 { padding: 0 3%; margin-bottom: 18px; }
  .help-article-title img { margin: 30px 0; }

  .table-of-contents { padding: 32px 0 36px; margin: 30px 0 25px; }
  .table-of-contents h6 { margin-bottom: 15px; }

  #help-article .txt-block { margin: 30px 0; }
  #help-article .txt-block h6 { font-size: 1.375rem; margin: 22px 0 12px; }

  #help-article .txt-block img { margin: 15px 0 20px; }

  .help-vote-form { margin-top: 40px; }
  .help-vote-form h6 { margin-bottom: 20px; }

  /* NEWSLETTER */
  #newsletter-1 .newsletter-overlay { padding: 50px 0 40px; }  
  #newsletter-2 .newsletter-overlay { padding: 60px 0 50px; } 

  .newsletter-txt h4, .newsletter-txt h3 { font-size: 2rem; padding: 0 5%; margin-bottom: 25px; }

  #newsletter-1 .newsletter-form { padding: 0 6%; }
  #newsletter-2 .newsletter-form { padding: 0 10%; margin-top: 30px; }

  .newsletter-section .form-control, .newsletter-section .btn { height: 54px; font-size: 1.0625rem; }
  #newsletter-2.newsletter-section .btn { font-size: 1.0625rem; }

  /* SINGLE BLOG POST */
  .single-post-txt .blockquote p { font-size: 1.1875rem; margin-bottom: 30px; }
  .single-post-txt .blockquote-footer { font-size: 1.0625rem; }

  .post-data { margin-top: 60px; padding: 60px 0; }

  .subscribe-banner { padding: 40px 25px; }

  #post-comments img { width: 50px; height: 50px; }
  .comment-meta { margin-bottom: 5px; }
  .comment-meta h6 { font-size: 1.225rem; margin-bottom: 5px; }

  .comment-form { margin-top: 40px; }
  .comment-form .btn { font-size: 1.0625rem; height: 52px; }

  /* CONTACTS */
  .contacts-info { text-align: center; }
  .contacts-info h2 br { display: none; }
  .contacts-info p { margin: 16px 0 0; padding: 0; }
  .contacts-info h6 { margin: 22px 0 16px; }
  .contacts-info-socials li { padding-right: 12px; }

  .contact-boxes.pb-70 { text-align: center; padding-bottom: 40px; }
  .contact-box p { padding-right: 0; margin-top: 12px; }
  .contact-box span { top: 0.5px; left: 2px; }

  .contact-form .form-control, 
  .contact-form .form-select { height: 54px; font-size: 1.0625rem; padding: 0 15px; margin-bottom: 18px; }
  .contact-form textarea { min-height: 200px; }
  .contact-form textarea.form-control { padding: 15px; }

  .contact-form .form-btn { margin-top: 5px; }
  .contact-form .form-btn .btn { width: 100%; font-size: 1.0625rem; padding: 0.95rem 1.4rem; }
  .contact-form-msg { text-align: center; margin-top: 15px; }

  /* FOOTER */
  #footer-1 .footer-form { margin-top: 40px; }
  #footer-7 .footer-connect, #footer-8 .footer-connect { margin-bottom: 30px; }
  #footer-9 .footer-contacts { margin-bottom: 40px; }

  #footer-10 .foo-links { display: block; padding-top: 0; }
  #footer-10 .foo-links li { padding: 0 14px; }

  img.footer-logo { max-height: 34px; margin-bottom: 22px; }
  #footer-6 img.footer-logo { margin-bottom: 5px; }

  .footer h6 { font-size: 1.25rem; margin-bottom: 20px; }   /* 20px */
  .footer .footer-links h6.h6-lg { font-size: 1.25rem; margin-bottom: 18px; }

  .footer-mail-link { margin: 0 0 0 6px; }
  .footer-mail-link a { font-size: 1.09375rem; }  /* 17.5px */
  .footer-mail-link span { top: 4px; right: 4px; }

  .footer-mail-link.ico-20 [class*="flaticon-"]:before, 
  .footer-mail-link.ico-20 [class*="flaticon-"]:after { font-size: 1.275rem; }

  .footer-info-socials li { padding-right: 13px; }
  .footer-info-socials.ico-20 [class*="flaticon-"]:before, 
  .footer-info-socials.ico-20 [class*="flaticon-"]:after,
  .footer-info-socials.ico-25 [class*="flaticon-"]:before, 
  .footer-info-socials.ico-25 [class*="flaticon-"]:after { font-size: 1.3975rem; }

  .footer-socials.ico-25 [class*="flaticon-"]:before, 
  .footer-socials.ico-25 [class*="flaticon-"]:after { font-size: 1.435rem; }

  #footer-1 .footer-form { margin-bottom: 0; }
  .footer-form .form-control { font-size: 1.125rem; height: 40px; }
  .footer-form .btn { height: 40px; }

  .footer-form .ico-15 [class*="flaticon-"]:before, 
  .footer-form .ico-15 [class*="flaticon-"]:after { font-size: 1.0625rem; line-height: 26px!important; }

  #footer-1 .bottom-footer { margin-top: 22px; }
  .footer-copyright p { font-size: 1rem; }

  .footer-list-divider { top: -1px; }
  .bottom-secondary-link p span { top: 1.5px; padding: 0 2px; }
  .footer-info-copyright p span { top: 2px; padding: 0 2px; }

  .footer-info-copyright.ico-15 [class*="flaticon-"]:before, 
  .footer-info-copyright.ico-15 [class*="flaticon-"]:after { font-size: 0.815rem; }

  .bottom-foo-socials li { margin-right: 7px; }
  .bottom-foo-socials li span { top: 2.5px; right: 3px; }

  .bottom-foo-socials.ico-10 [class*="flaticon-"]:before, 
  .bottom-foo-socials.ico-10 [class*="flaticon-"]:after { font-size: 1.125rem; }

  .bottom-footer-socials.ico-20 [class*="flaticon-"]:before, 
  .bottom-footer-socials.ico-20 [class*="flaticon-"]:after { font-size: 1.375rem; }

  .footer-stores-badge { margin-bottom: 25px; }
  .footer-stores-badge .store { margin-right: 10px; }
  .footer .badge-img-xs .store img { max-height: 2.75rem; } 

  /* INNER PAGE WRAPPER */
  .inner-page-title { margin-bottom: 50px; }
  .inner-page-title h2 { margin-bottom: 0; }
  .inner-page-title p { margin-top: 10px; }

  /* SIGN IN / SIGN UP */
  .login-page-logo { margin-bottom: 40px; }
  .login-page-logo img { max-height: 35px; }

  .login-2 .register-page-form, .signup-2 .register-page-form { padding: 40px 30px; margin: 0 10%; }
  .login-3 .register-page-wrapper, .signup-3 .register-page-wrapper { border-radius: 10px; margin: 0 10%; }
  .login-3 .register-page-form, .signup-3 .register-page-form { padding: 40px 30px; }
  .reset-page-wrapper form { margin: 0 8%; padding: 40px 20px 5px; }

  .register-form-title { margin-bottom: 22px; }
  .login-2 .register-form-title, .signup-2 .register-form-title, .reset-form-title { margin-bottom: 18px; }

  .register-form-title h5, .register-form-title h4 { margin-bottom: 12px; }

  .register-page-form p.input-header, .register-page-form .btn-google { font-size: 1rem; }
  .reset-password-link p { font-size: 1.0625rem; margin-top: 20px; }
  .login-3 .reset-password-link p { margin-top: 3px; margin-bottom: 24px; }
  .form-data span { font-size: 0.95rem; }
  .reset-password-form .form-data p { font-size: 1rem; margin-bottom: 10px; }
  .register-page-form p.create-account { font-size: 1rem; margin-top: 20px; }
  .reset-form-title p.p-sm { font-size: 1rem; margin-top: 16px; }

  .separator-line { font-size: 1rem;  margin: 20px 0; }

  .reset-page-wrapper .form-control, .register-page-form .form-control { height: 54px; font-size: 1.0625rem; margin-bottom: 18px;}
  #login .register-page-form .btn.submit { height: 54px; font-size: 1.0625rem; margin-top: 0; }
  #signup .register-page-form .btn.submit { height: 54px; font-size: 1.0625rem; margin-top: 20px; }
  .reset-page-wrapper .btn.submit { height: 54px; font-size: 1.0625rem; margin-bottom: 16px; }
  .btn-google img { width: 20px; height: 20px; top: -2px; right: 5px; }

  /* PAGE 404 */
  .page-404-txt h2 { font-size: 2.6rem; margin-bottom: 15px; }
  .page-404-txt h6 { font-size: 1.175rem; padding: 0 8%; margin-bottom: 25px; }
  .page-404-img { padding: 0 13%; margin-bottom: 35px; }

  /* COOKIES */
  #cookies { bottom: 15px; left: 11%; width: 78%; padding: 17px 22px; }
  #cookies p { font-size: 1rem; }
  #cookies-ok { font-size: 0.95rem; padding: 7px 18px; }
  #cookies-info { margin-left: 15px; }
  #cookies-close { right: 14px; top: 6px; }
  #cookies-close svg { width: 12px; height: 12px; }

  /* MODAL */
  #modal-1 .modal-dialog { width: 350px; }
  #modal-2 .modal-dialog.modal-xl { width: 360px; }

  #modal-1 .modal-body-img { padding-top: 25px; }

  #modal-1 .modal-body-content { padding: 10px 25px 10px; }
  #modal-2 .modal-body-content { padding: 30px 15px 18px; }

  #modal-1 .modal-body-content h5 { font-size: 1.5rem; }
  #modal-2 .request-form-title p { margin-top: 12px; margin-bottom: 18px; }

  .modal-body .newsletter-form { margin-top: 18px; }
  .modal .newsletter-form .form-control,
  #modal-2 .request-form .form-control { height: 54px; font-size: 1.0625rem; margin-bottom: 16px; }
  .modal .newsletter-form .btn, #modal-2 .request-form .btn { height: 54px; font-size: 1.0625rem; }


}




/* ------------------------------------------------------------------------ */
/* ------------------------------------------------------------------------ */
/* ------------------------------------------------------------------------ */

@media (min-width: 414px) and (max-width: 479.98px) {

  /*------------------------------------------*/
  /*  BASE
  /*------------------------------------------*/

  #stlChanger { display: none; }
  .division { padding-left: 9px; padding-right: 9px; }
  .block--shadow { -webkit-box-shadow: 0 0; -moz-box-shadow: 0 0; box-shadow: 0 0; }
  .bg--fixed { width: auto; }
  .bg--scroll { width: auto; background-attachment: scroll!important; }

  /* Background Shape */
  .shape--01:after { height: 55%; }
  .shape--02:after { height: 55%; top: 45%; }
  .bg--shape:after { width: 1300px; height: 1300px; top: 12%; left: -10%; }
  .bg--shape-top:after { width: 450px; height: 450px; top: -5%; right: 12%; background-color: rgba(119, 67, 142, .15); }

  /* Headers */
  h6, h6.h6-md, h6.h6-lg, h6.h6-xl { font-size: 1.25rem; }            /* 20px; */
  h5, h5.h5-md, h5.h5-lg { font-size: 1.375rem; }                     /* 22px; */
  h5.h5-xl, h4, h4.h4-md, h4.h4-lg, h4.h4-xl { font-size: 1.5rem; }   /* 24px; */
  h3, h3.h3-md, h3.h3-lg, h3.h3-xl, h2, h2.h2-md, h2.h2-lg { font-size: 1.625rem; }  /* 26px; */
  h2.h2-xl, h2.h2-title { font-size: 1.875rem; }                       /* 30px; */          

  h6, h5, h4, h3, h2, h1 { line-height: 1.4; }    

  /* Paragraphs */
  p.p-sm { font-size: 0.9375rem; } 
  p { font-size: 1rem; }  
  p.p-md, p.p-lg, p.p-xl { font-size: 1.0625rem; }

  ol.digit-list p { margin-bottom: 5px; }

  /* Button */
  .btn.btn-sm, .btn, .btn.btn-md { font-size: 0.9375rem; padding: 0.8rem 1.45rem; }
  .btns-group .btn.ico-30.ico-left, .btns-group .btn.ico-30.ico-right { padding: 0.68165rem 0.5rem 0; }

  /* Buttons Group */
  .btns-group .btn:first-child { margin: 0 8px 15px; }
  .btns-group .btn:last-child { margin-left: 8px; margin-right: 8px; }

  /* Button Icon */
  .btn.ico-20 [class*="flaticon-"]:before, 
  .btn.ico-20 [class*="flaticon-"]:after { font-size: 1.225rem; line-height: 0.45rem!important;top: 4px; }

  .btn.ico-30 [class*="flaticon-"]:before, .btn.ico-30 [class*="flaticon-"]:after { font-size: 2.05rem; }

  .btn.ico-30.ico-left span.ico-30-txt { font-size: 1.15rem; top: -10px; margin-right: 0; }
  .btn.ico-30.ico-right span.ico-30-txt { font-size: 1.15rem; top: -10px; margin-left: 0; }
  .btn.ico-left span { margin-right: 8px; }
  .btn.ico-right span { margin-left: 8px; }

  /* Button Text */
  p.btn-txt, .text-center p.btns-group-txt { margin-top: 13px; margin-bottom: 0; }
  .btn-rating { margin: 15px 0 0 0; }
  .btn-rating p.p-sm { font-size: 0.9375rem; }
  .star-rating small, .star-rating span.rating-txt { font-size: 1rem; }

  .star-rating.ico-20 [class*="flaticon-"]:before, 
  .star-rating.ico-20 [class*="flaticon-"]:after { position: relative; font-size: 1.125rem; top: 4px; }

  /* Transparent Link */
  a.tra-link { font-size: 1.03125rem; }
  .tra-link.ico-20 [class*="flaticon-"]:before, 
  .tra-link.ico-20 [class*="flaticon-"]:after { font-size: 0.785rem; line-height: 0.7rem!important; top: 1px; left: 2px; }

  /* Store Badge */
  .store { margin-right: 6px; }
  .store.store-btn-2 { margin-right: 0; }
  .store-btn-3, .store-btn-4 { margin-top: 15px; }

  /* Watch Video Link */
  .watch-video { margin-top: 20px; }
  .watch-video-link { margin-right: 10px; }
  p.video-txt-lg { font-size: 1.125rem; }
  p.video-txt-sm { font-size: 1.0625rem; }

  /* Video Popup Icon */
  .video-btn-xl { width: 5.625rem; height: 5.625rem; margin-top: -2.8125rem; margin-left: -2.8125rem; }
  .video-btn-lg { width: 5rem; height: 5rem; margin-top: -2.5rem; margin-left: -2.5rem; }
  .video-btn-md { width: 5rem; height: 5rem; margin-top: -2.5rem; margin-left: -2.5rem; }

  .video-btn.video-btn-xl [class^="flaticon-"]:before, .video-btn.video-btn-xl [class^="flaticon-"]:after {
    font-size: 4.5rem; line-height: 5.625rem!important; margin-left: 8px;
  }

  .video-btn.video-btn-lg [class^="flaticon-"]:before, .video-btn.video-btn-lg [class^="flaticon-"]:after,
  .video-btn.video-btn-md [class^="flaticon-"]:before, .video-btn.video-btn-md [class^="flaticon-"]:after {
    font-size: 4.35rem; line-height: 5rem!important; margin-left: 8px;
  }

  /* Vector Icon Background */ 
  .ico-50 .shape-ico svg { width: 120px; height: 120px; top: -35px; left: calc(50% - 60px); }
  .ico-55 .shape-ico svg { width: 125px; height: 125px; top: -30px; left: calc(50% - 65px); }
  .ico-60 .shape-ico svg { width: 130px; height: 130px; top: -35px; left: calc(50% - 60px); }

  /* Vector Rounded Icons */
  .ico-rounded, .ico-rounded-md, .ico-rounded-lg { width: 94px; height: 94px; }

  .ico-rounded [class*="flaticon-"]:before, 
  .ico-rounded [class*="flaticon-"]:after,
  .ico-rounded-md [class*="flaticon-"]:before, 
  .ico-rounded-md [class*="flaticon-"]:after,
  .ico-rounded-lg [class*="flaticon-"]:before, 
  .ico-rounded-lg [class*="flaticon-"]:after { font-size: 3rem; line-height: 94px!important; }

  /* PNG Icons */
  .img-80 img { width: 75px; height: 75px; }
  .img-75 img { width: 70px; height: 70px; }
  .img-70 img, .img-65 img, .img-60 img { width: 65px; height: 65px; }

  /* Section ID */
  .section-id { font-size: 0.9rem; margin-bottom: 22px; }

  /* Section Title */
  .section-title.mb-70 { margin-bottom: 35px; }
  .section-title h2 { padding: 0 3px; }
  .section-title p { margin-top: 15px; }
  .ws-wrapper .section-title p, #features-10 .section-title p { padding: 0 2%; }
  .section-title .btns-group { padding: 0 8%; margin-top: 10px; }
  .section-title .btns-group .btn { width: 100%; margin: 15px 0 0; }

  /* Users */
  .txt-block .users { margin-top: 16px; }
  .users img { max-height: 48px; }
  .txt-block .users img { max-height: 44px; }
  .users p { display: block; font-size: 0.975rem; margin-top: 15px; top: 0; left: 0; }
  .txt-block .users p { display: inline-block; font-size: 0.925rem; top: 10px; left: 4px; }

  /*------------------------------------------*/
  /*   NAVIGATION MENU    
  /*------------------------------------------*/

  /* Header Logo */
  .wsmobileheader .smllogo { display: block; margin-top: 15px; padding-left: 16px; }
  .smllogo img { width: auto; max-width: inherit; max-height: 50px; }

  .wsmenu > .wsmenu-list { width: 325px; margin-right: -325px; }
  .wsactive .wsmobileheader { margin-right: 325px; }
  .overlapblackbg { width: calc(100% - 325px); }

  .wsanimated-arrow { padding: 14px 30px 14px 0px; margin: 25px 16px 0 0; }

  /* Nav Link Info */
  span.sm-info { width: 22px; height: 22px; font-size: 0.8rem; line-height: 20px; top: -1px; margin-left: 8px; }

  .wsmenu > .wsmenu-list > li > a { padding: 5px 32px 4px 20px; margin: 0; }
  .wsmenu > .wsmenu-list > li > .wsmenu-click > i { height: 8px; width: 8px; margin: 24px 18px 0px 0px; }

  .wsmenu > .wsmenu-list > li > ul.sub-menu, .wsmenu > .wsmenu-list > li > .wsmegamenu.w-75,
  .wsmenu > .wsmenu-list > li > .wsmegamenu.halfmenu { top: 0; }

  .wsmenu > .wsmenu-list > li > ul.sub-menu, .wsmenu > .wsmenu-list > li > .wsmegamenu.halfmenu, 
  .wsmenu > .wsmenu-list > li > .wsmegamenu.w-75 { padding: 6px 10px; }

  .wsmenu > .wsmenu-list > li > ul.sub-menu > li > a, 
  .wsmenu > .wsmenu-list > li > ul.sub-menu > li > a:hover, 
  .wsmenu > .wsmenu-list > li > .wsmegamenu .link-list li a,
  .wsmenu > .wsmenu-list > li > .wsmegamenu .link-list li a:hover,
  .wsmenu > .wsmenu-list > li > ul.sub-menu > li > ul.sub-menu > li > a,
  .wsmenu > .wsmenu-list > li > ul.sub-menu > li > ul.sub-menu > li > a:hover,
  .wsmenu > .wsmenu-list > li > .wsmegamenu.w-75 ul.link-list > li > a, 
  .wsmenu > .wsmenu-list > li > .wsmegamenu.halfmenu ul.link-list > li > a,
  .wsmenu > .wsmenu-list > li > .wsmegamenu.w-75 ul.link-list > li > a:hover, 
  .wsmenu > .wsmenu-list > li > .wsmegamenu.halfmenu ul.link-list > li > a:hover { padding: 13px 10px; }

  /* Header Store Badges */
  .store.header-store img { max-height: 45px; }

  /* Header Social Links */
  .header-socials.ico-20 [class*="flaticon-"]:before, 
  .header-socials.ico-25 [class*="flaticon-"]:after { line-height: 52px!important; } 

  /*------------------------------------------*/
  /*   HERO   
  /*------------------------------------------*/

  .hero-section.blur--purple:after { height: 90%; top: -34%; left: 10%; }

  .slideshow, #hero-20 .slideshow, .slideshow .slides, #hero-20 .slideshow .slides { height: 450px; }

  .img-link { bottom: 20px; right: 20px; width: 45px; height: 45px; }
  .img-link a span { top: 4.5px; }

  .hero-section .btns-group { display: block; }
  .hero-section .btns-group .btn { display: block; padding: 1rem 1.5rem; margin: 0 10%; }
  .hero-section .btns-group .btn:first-child { margin-bottom: 15px; }

  /* Hero Advantages */
  .hero-section .text-center .advantages { margin-top: 18px; }
  .hero-section .advantages li { display: block!important; margin-top: 4px; }
  .hero-section .advantages li p { display: block; float: none; }
  .hero-section li.advantages-links-divider { display: none!important; }

  /* HERO QUICK FORM */
  .hero-section .quick-form .form-control, .hero-section .quick-form .btn { height: 54px; font-size: 1.1rem; }
  .hero-section .quick-form.form-half .form-control { height: 54px; font-size: 1.1rem; padding: 0 14px; }
  .hero-section .quick-form.form-half .btn { height: 54px; font-size: 1.1rem; }

  .hero-section .text-center .quick-form-msg { margin: 20px 0 0 0; }

  /* HERO DIGITS */
  #hd-1-1, #hd-1-2 { width: 100%; padding: 0; } 
  #hd-1-1 { margin-bottom: 20px; } 
  .hero-digits h2.statistic-number { font-size: 2.85rem; }
  .hero-digits h2.statistic-number small { font-size: 2.15rem; margin-left: 3px; }

  /* HERO-1 */
  .hero-1-txt h2 { font-size: 1.925rem; padding: 0 2%; }
  .hero-1-txt p.p-lg, .hero-1-txt p.p-xl { padding: 0; margin: 16px 0 22px; }
  .hero-1-txt .btn.btn-md { display: block; padding: 1rem 1.5rem; margin: 0 10%; }
  .hero-1-txt .star-rating small { display: block; }
  .hero-1-img { margin: 35px 0 0; }

  /* HERO-2 */
  #hero-2 { padding-top: 70px; }
  #hero-2:after { height: 7%; }
  .hero-2-txt h2 { font-size: 2.375rem; padding: 0 2%; }
  .hero-2-txt p.p-lg { padding: 0 4%; margin-top: 15px; margin-bottom: 25px; }

  /* HERO-3 */
  #hero-3 { padding-top: 70px; padding-bottom: 70px; }
  .hero-3-txt h2 { font-size: 2.375rem; }
  .hero-3-txt p.p-md { margin-top: 12px; }
  .hero-3-txt .users { margin-top: 22px; }
  .hero-3-img { margin-top: 35px; }

  /* HERO-4 */
  #hero-4 { margin-bottom: 65px; }
  .hero-4-txt h2 { font-size: 2.125rem; padding: 0 3%; }
  .hero-4-txt p.p-lg { margin: 16px 0 22px; padding: 0 6%; }
  .hero-4-img { margin: 35px 0 -40px; }

  /* HERO-5 */
  #hero-5:after { height: 96%; left: 0; }
  .hero-5-txt h2 { font-size: 2.25rem; }
  .hero-5-txt p.p-md { margin: 15px 0 25px; padding: 0 2%; }
  .hero-5-img { margin: 40px 0 0; }

  /* HERO-6 */
  #hero-6 { padding-top: 70px; padding-bottom: 75px; }
  .hero-6-txt h2 { font-size: 2.15rem; }
  .hero-6-txt p.p-lg { margin-top: 15px; padding: 0 5%; }
  #hero-6 .quick-form { margin: 20px 3% 0; }
  .hero-6-img { margin-top: 35px; }

  /* HERO-7 */
  #hero-7 { padding-top: 60px; background-position: top center; }
  .hero-7-txt h2 { font-size: 2.125rem; }
  .hero-7-txt p.p-lg, .hero-7-txt p.p-xl { margin: 14px 0 20px; padding: 0 3%; }
  #hero-7 .users { margin-top: 25px; }
  #hero-7 .users img { max-height: 46px; }
  #hero-7 .users p { font-size: 1rem; top: 0; left: 0; margin-top: 12px; }
  .hero-7-img { margin: 30px 2% 0; }

  /* HERO-8 */
  .hero-8-txt h2 { font-size: 2.375rem; padding: 0 2%; }
  .hero-8-txt p.p-lg { padding: 0 3%; margin-top: 15px; margin-bottom: 25px; }
  .hero-8-txt p.btn-txt { margin-top: 20px; }
  #hero-8 .quick-form { margin: 25px 6% 0; }

  /* HERO-9 */
  #hero-9 { padding-top: 70px; padding-bottom: 80px; }
  #hero-9 .wave-shape-bottom svg { height: 45px; }
  .hero-9-txt h2 { font-size: 2.15rem; }
  .hero-9-txt p.p-lg { margin-top: 15px; }
  #hero-9 .quick-form { margin: 20px 3% 0; }
  .hero-9-img { margin-top: 35px; }

  /* HERO-10 */
  #hero-10 { overflow: visible; margin-bottom: 50px; }
  #hero-10 .container { max-width: 100%; }
  .hero-10-txt { margin-bottom: 45px; }
  .hero-10-txt h2 { font-size: 2.375rem; padding: 0 6%; }
  .hero-10-txt p.p-lg { padding: 0 4%; margin-top: 15px; margin-bottom: 25px; }

  .hero-10-wrapper { overflow: visible; margin: 0 -15px; border-radius: 0 0!important; }
  #hero-10 .hero-overlay { position: relative; padding: 60px 25px 0; }
  .hero-10-img { margin-bottom: -50px; }
  .hero-10-img .video-btn { top: calc(50% - 2px); }

  /* HERO-11 */
  .hero-11-txt h2 { font-size: 2.125rem; }
  .hero-11-txt p.p-md { margin: 14px 0 25px; padding: 0; }
  .hero-11-img { margin: 35px 0 0; }

  /* HERO-12 */
  #hero-12 { padding-top: 0; }
  #hero-12 .container { max-width: 100%; }
  .hero-12-wrapper { padding: 70px 25px; margin: 0 -15px; border-radius: 0 0!important; }
  .hero-12-txt h2 { font-size: 2.125rem; padding: 0 5%; }
  .hero-12-txt p.p-lg { margin: 15px 0 25px; }
  .hero-12-img { margin-top: 35px; }

  /* HERO-13 */
  .hero-13-txt h2 { font-size: 2.25rem; padding: 0 2%; }
  .hero-13-txt p.p-xl { padding: 0 4%; margin-top: 15px; }
  #hero-13 .quick-form { margin: 25px 6% 0; }
  .hero-13-txt p.btn-txt { margin-top: 20px; }

  /* HERO-14 */
  #hero-14 { padding-top: 70px; }
  #hero-14:after { height: 7%; }
  .hero-14-txt { padding: 0 3%; }
  .hero-14-txt .section-id { padding: 11px 25px; font-size: 0.9rem; margin-bottom: 30px; }
  .hero-14-txt h2 { font-size: 2.05rem; }
  .hero-14-txt p.p-md { margin: 16px 0 28px; }
  .hero-14-img { margin-top: 35px; }

  /* HERO-15 */
  .hero-15-txt h2 { font-size: 2.375rem; padding: 0 5%; }
  .hero-15-txt p.p-lg { padding: 0 3%; margin-top: 15px; margin-bottom: 25px; }

  /* HERO-16 */
  #hero-16 { padding-top: 70px; padding-bottom: 75px; }
  .hero-16-txt h2 { font-size: 2.15rem; }
  .hero-16-txt p.p-lg { margin-top: 15px; padding: 0 2%; }
  #hero-16 .quick-form { margin: 20px 3% 0; }
  #hero-16 .users { margin-top: 30px; }
  .hero-16-img { margin-top: 35px; }

  /* HERO-17 */
  #hero-17:after { height: 7%; }
  .hero-17-txt h2 { font-size: 2rem; padding: 0 2%; }
  .hero-17-txt p.p-xl { padding: 0; margin-top: 15px; }
  .hero-17-txt .btns-group { margin-top: 22px; }
  .hero-17-img { margin-top: 40px; }

  /* HERO-18 */
  #hero-18 { padding-top: 75px; }
  .hero-18-txt { margin-bottom: 45px; }
  .hero-18-txt .section-id { margin-bottom: 25px; }
  .hero-18-txt h2 { font-size: 2rem; }
  .hero-18-txt p.p-xl { padding: 0 3%; margin-top: 12px; }
  .hero-18-txt .btns-group { padding: 0 3%; margin-top: 22px; }
  .hero-18-txt .btns-group .btn { width: 100%; margin: 0; }
  .hero-18-txt .btns-group .btn:first-child { margin-bottom: 15px; }

  .h17-8 { display: none }
  .h17-8 { margin-bottom: 24px; }

  .hero-bg-shape { width: 220%; height: 100%; left: -32%; right: -32%; opacity: .3; }

  /* HERO-19 */
  .hero-19-txt h2 { font-size: 2.25rem; padding: 0 2%; }
  .hero-19-txt p.p-xl { padding: 0 4%; margin-top: 15px; }
  #hero-19 .quick-form { margin: 25px 6% 0; }
  .hero-19-txt p.btn-txt { margin-top: 20px; }
  .hero-19-img { margin-top: 35px; }

  /* HERO-20 */
  #hero-20 .caption { padding: 0 4%; }
  #hero-20 .caption h2 { font-size: 2.0625rem; padding: 0; }
  #hero-20 .caption p.p-xl { font-size: 1.0625rem; padding: 0; margin-top: 12px; }
  #hero-20 .caption .btn { margin-top: 24px; }

  /* HERO-21 */
  #hero-21:after { height: 10%; }
  .hero-21-shape { width: 110%; top: 4%; left: -5%; opacity: .8; }
  .hero-21-txt h2 { font-size: 2rem; padding: 0 2%; }
  .hero-21-txt p.p-xl { padding: 0; margin-top: 15px; }
  .hero-21-txt .btns-group { margin-top: 22px; }
  .hero-21-img { margin-top: 40px; }

  /* HERO-22 */
  #hero-22 .caption h2 { font-size: 2.0625rem; padding: 0; }
  #hero-22 .caption p.p-xl { font-size: 1.0625rem; padding: 0; margin-top: 12px; }
  #hero-22 .caption .btn { margin-top: 24px; }

  /* HERO-23 */
  #hero-23 { padding-top: 70px; }
  .hero-23-txt h2 { font-size: 2.3rem; padding: 0 5%; }
  .hero-23-txt p.p-lg { padding: 0 2%; margin-top: 15px; margin-bottom: 25px; }

  /* HERO-24 */
  #hero-24 { margin-bottom: 50px; }
  .hero-24-txt h2 { font-size: 2.375rem; padding: 0 6%; }
  .hero-24-txt p.p-lg { padding: 0 2%; margin-top: 12px; }
  .hero-24-txt .users { margin-top: 25px; }

  #hero-24-form { padding: 30px 22px; margin: 50px 3% -50px; }
  #hero-24-form h5 { font-size: 1.5rem; margin-bottom: 12px; }
  #hero-24-form p { margin-bottom: 16px; }
  #hero-24-form p.p-sm { padding: 0!important; }
  #hero-24-form .btn { height: 54px; font-size: 1.0625rem; padding: 16px; }

  /* HERO-25 */
  #hero-25 { padding-top: 70px; }
  .hero-25-txt h2 { font-size: 2.225rem; padding: 0 2%; }
  .hero-25-txt p.p-lg { padding: 0; margin-top: 15px; margin-bottom: 25px; }

  /* HERO-26 */
  .hero-26-txt h2 { font-size: 2.0625rem; }
  .hero-26-txt p.p-lg { padding: 0 2%; margin-top: 15px; margin-bottom: 25px; }
  .hero-26-img { margin: 45px -18% 0; }

  /* HERO-27 */
  .hero-27-txt h2 { font-size: 2rem; margin-bottom: 22px; }
  .hero-27-txt p { margin-top: 22px; margin-bottom: 30px; }

  #hero-27 .img-link { bottom: 12px; left: 12px; width: 55px; height: 55px; }
  #hero-27 .img-link a span { top: 7px; }

  /* HERO REQUEST FORM */
  .request-form .form-control { height: 54px; font-size: 1.0625rem; margin-bottom: 15px; }
  
  /*------------------------------------------*/
  /*  CONTENT
  /*------------------------------------------*/

  /* ABOUT */
  .about-1-img { margin: 0 -32%; }

  /* FEATURES */
  #features-3.py-100.shape--bg:after, #features-4.py-100.shape--bg:after { height: 68%; top: 32%; }
  #features-3.pt-100.shape--bg:after { height: 64%; top: 33.35%; }
  #features-4.pt-100.shape--bg:after { height: 64%; top: 32.5%; left: 0; }

  #features-3 .txt-block, #features-4 .txt-block { margin-bottom: 30px; }

  .fb-1, .fb-2, .rows-2 .fb-1, .rows-2 .fb-2, .rows-2 .fb-3, .rows-2 .fb-4, .rows-2 .fb-5,.row-cols-md-2 .fb-3, 
  .rows-3 .fb-1, .rows-3 .fb-2, .rows-3 .fb-3, .rows-3 .fb-4, .rows-3 .fb-5, .rows-4 .fb-1, .rows-4 .fb-2, .rows-4 .fb-3, 
  .rows-4 .fb-4, .rows-4 .fb-5, .rows-4 .fb-6,#fb-3-1, #fb-3-2, #fb-3-3, #fb-4-1, #fb-4-2, #fb-4-3, .fbox-10.fb-1, 
  .fbox-9.fb-1, .fbox-9.fb-2, .fbox-9.fb-4, .fbox-9.fb-5, .fbox-9.fb-7, .fbox-9.fb-8, .fbox-10.fb-3, .fbox-10.fb-5, 
  .fbox-10.fb-7 { margin-bottom: 35px; }

  .rows-4 .fbox-2.fb-1, .rows-4 .fbox-2.fb-2, .rows-4 .fbox-2.fb-3, .rows-4 .fbox-2.fb-4, 
  .rows-4 .fbox-2.fb-5, .rows-4 .fbox-2.fb-6 { margin-bottom: 35px; }

  .fbox-11.fb-1, .fbox-11.fb-2 { margin-bottom: 25px; }

  .rows-4 .fb-8 { margin-top: 35px; }
  #features-12 .txt-block { margin-bottom: 30px; }
  .fbox-6.fb-4 { margin-bottom: 25px; }
  .fbox-12.fb-1, .fbox-12.fb-2, .fbox-12.fb-3 { margin-bottom: 22px; }

  .fbox-3, .fbox-4, .fbox-6 { 
    padding: 35px;
    -webkit-box-shadow: 0 4px 12px 0 rgba(0, 0, 0, .08); 
    -moz-box-shadow: 0 4px 12px 0 rgba(0, 0, 0, .08); 
    box-shadow: 0 4px 12px 0 rgba(0, 0, 0, .08); 
  }
  .fbox-5 { padding: 45px 35px; } 
  .fbox-6 { text-align: center; padding: 35px; }

  .fbox-9 { padding: 35px 25px; }
  .ico-title .fbox-9.fb-1, .ico-title .fbox-9.fb-2, .ico-title .fbox-9.fb-3 { padding: 30px 25px; }
  .fbox-9.fb-4, .fbox-9.fb-5, .fbox-9.fb-6, .ico-title .fbox-9.fb-4, 
  .ico-title .fbox-9.fb-5, .ico-title .fbox-9.fb-6 { padding: 30px 25px 0; }

  .fbox-10, .fbox-10.fb-5, .fbox-10.fb-6, .fbox-10.fb-7, .fbox-10.fb-8 { padding: 35px 25px 30px; } 
  .ico-title .fbox-10.fb-5, .ico-title .fbox-10.fb-6,
  .ico-title .fbox-10.fb-7, .ico-title .fbox-10.fb-8 { padding: 30px 28px 25px; } 

  .fbox-img.h-175 img, .fbox-img.h-180 img { max-height: 190px; } 

  .fbox-7 .fbox-img { margin-bottom: 25px; }
  .fbox-8 .fbox-img.h-175, .fbox-8 .fbox-img.h-180 { padding-top: 30px; margin: 0 20px 45px; } 
  
  .fbox-9.fb-1 .fbox-9-img, .fbox-9.fb-2 .fbox-9-img, .fbox-9.fb-3 .fbox-9-img { margin: 25px 0 0; }
  .fbox-9.fb-4 .fbox-9-img, .fbox-9.fb-5 .fbox-9-img, .fbox-9.fb-6 .fbox-9-img { margin: 25px 0 -18px; }
  .fbox-9.fb-7 .fbox-9-img, .fbox-9.fb-8 .fbox-9-img, .fbox-9.fb-9 .fbox-9-img { margin: 0 0 25px; }

  .fbox-10.fb-1 .fbox-10-img, .fbox-10.fb-2 .fbox-10-img, 
  .fbox-10.fb-3 .fbox-10-img, .fbox-10.fb-4 .fbox-10-img { margin: 0 0 20px; }
  .fbox-10.fb-7 .fbox-10-img, .fbox-10.fb-8 .fbox-10-img { margin: 20px 0 0; }
  .fbox-10.fb-5 .fbox-10-img, .fbox-10.fb-6 .fbox-10-img { margin: 0 0 25px; }
  .ico-title .fbox-10.fb-5 .fbox-10-img, .ico-title .fbox-10.fb-6 .fbox-10-img { margin: 25px 0 0; }

  .fbox-ico, .fbox-ico.ico-rounded { margin-bottom: 15px; }
  .fbox-5 .fbox-ico, .fbox-6 .fbox-ico { margin-bottom: 20px; }
  .fbox-ico[class*="img-"], .fbox-ico.ico-rounded-md, .fbox-ico.ico-rounded-lg { margin-bottom: 16px; }
  .t-icon { margin-bottom: 2px; }
  .t-icon.ico-25 [class*="flaticon-"]:before, .t-icon.ico-25 [class*="flaticon-"]:after { font-size: 1.625rem; }

  .fbox-wrapper .ico-50 [class*="flaticon-"]:before, .fbox-wrapper .ico-50 [class*="flaticon-"]:after,
  .fbox-wrapper .ico-55 [class*="flaticon-"]:before, .fbox-wrapper .ico-55 [class*="flaticon-"]:after,
  .fbox-wrapper .ico-60 [class*="flaticon-"]:before, .fbox-wrapper .ico-60 [class*="flaticon-"]:after { font-size: 3.7rem; }
  .fbox-ico-wrap .ico-40 [class*="flaticon-"]:before, .fbox-ico-wrap .ico-40 [class*="flaticon-"]:after { font-size: 3.15rem; }

  .fbox-3 h6, .fbox-4 h6 { margin-top: 18px; margin-bottom: 10px; }
  .fbox-2 h5, .fbox-wrapper .row-cols-md-2 h6, 
  .fbox-wrapper .row-cols-md-3 h6, .fbox-10 h5, .fbox-6 h6, .fbox-9 h6, .fbox-12 h5 { margin-bottom: 12px; }
  .fbox-10.fb-5 h5, .fbox-10.fb-6 h5 { margin-bottom: 14px; }
  .fbox-11 h6 { margin-bottom: 12px; }
  .ico-title .fbox-10.fb-5 h5, .ico-title .fbox-10.fb-6 h5 { margin-bottom: 14px; } 

  .fbox-1 p { padding: 0 5%; }
  .fbox-7 p, .fbox-8 p { padding: 0 5%; }

  .fbox-5 .txt-block-tra-link { margin-top: 12px; }

  /* CONTENT */
  .bc-02.pt-30.ws-wrapper, .bc-02.pb-30.ws-wrapper { padding-top: 35px; }
  .bc-02.pt-30.bc-02-top { padding-top: 20px; margin-top: 50px; }
  .bc-02 .container { max-width: 100%; }
  .bc-02.pt-30 .container, .bc-02.pb-30 .container { max-width: auto; }
  .bc-02-wrapper { margin: 0 -15px; border-radius: 0 0!important; }
  .bc-02.pt-30 .bc-02-wrapper, .bc-02.pb-30 .bc-02-wrapper { margin: 0; border-radius: 12px!important; }

  .ct-06 .section-title p { font-size: 1.03125rem; }
  .bc-03 .section-title p { font-size: 1.03125rem; padding: 0; margin-top: 12px; }

  .ct-05.pt-100, .ct-06 .section-overlay { margin-bottom: 40px; }
  .ct-05.pt-100.ct-05-img { margin-bottom: 0; }
  .bc-01 .section-overlay, .bc-02 .section-overlay { padding: 70px 30px; }
  .bc-02 .ico-title .section-overlay { padding: 70px 30px; }
  .bc-02.pt-30 .section-overlay, .bc-02.pb-30 .section-overlay { padding: 30px 20px; }
  .bc-03 .section-overlay { padding: 70px 28px 0; }
  
  .ct-02-top { margin-bottom: 40px; }
  .txt-box, .bc-01 .txt-box { margin-bottom: 15px; }
  .bc-04-box { 
    padding: 30px 28px; 
    -webkit-box-shadow: 0 4px 12px 0 rgba(0, 0, 0, .08); 
    -moz-box-shadow: 0 4px 12px 0 rgba(0, 0, 0, .08); 
    box-shadow: 0 4px 12px 0 rgba(0, 0, 0, .08); 
  }

   .cbox-6 { text-align: center; }

  .two-txt .txt-block.left-column { margin-bottom: 35px; }
  .two-txt .cbox-3, .two-txt .cbox-3 .ico-wrap { text-align: left!important; }

  .txt-block h3, .txt-block h2 { margin-bottom: 16px; }
  .txt-block h5 { margin-bottom: 14px; }
  .ct-03 .txt-block h2 { padding-right: 5px; }
  h5.h5-title { margin: 14px 0; }

  .bc-04-box h2 { font-size: 2.85rem; margin-bottom: 10px; }
  .bc-04-box h2 small, .bc-04-box h2 span { font-size: 2.15rem; }
  .cbox-5 p.p-sm { font-size: 1rem; }

  .txt-block .btn { margin-top: 22px; }
  .txt-block-tra-link { margin-top: 16px; }

  .cbox-1 .ico-wrap { margin-right: 1rem; }
  .cbox-1-ico { width: 1.9rem; height: 1.9rem; font-size: 1.0625rem; line-height: 1.5rem; margin-top: 5px; }

  .cbox-3 .cbox-3-ico { margin-bottom: 10px; }
  .cbox-3-ico span[class*="flaticon-"]:before, .cbox-3-ico span[class*="flaticon-"]:after { font-size: 3.25rem; }

  .cbox-title { margin-bottom: 10px; }
  .cbox-title span {display: block; float: none; line-height: 1!important; margin: 0 0 16px 0; }
  .cbox-title span[class*="flaticon-"]:before, .cbox-title span[class*="flaticon-"]:after { font-size: 3.25rem; }

  .cbox-1-txt { margin-bottom: 20px; }
  .cbox-3-txt { margin-bottom: 30px; }
  .cbox-6-txt { margin-bottom: 30px; }
  .cbox-1-txt h5, .cbox-3-txt h5 { margin-bottom: 10px; }

  .cbox-4 { width: 340px; min-width: 340px; padding: 12px 16px; }
  .cbox-4.mt-20 { margin-top: 8px; }
  .cbox-4.mb-30 { margin-bottom: 20px; }
  .cbox-4-ico span { position: relative; top: 5px; }
  .cbox-4-txt { padding-left: 11px; }

  .cbox-5-wrapper { margin-top: 0; }
  .box-title { margin-bottom: 12px; }
  .cbox-5.cb-2 { margin-top: 15px; }
  .box-title span { top: 10px; left: 3px; right: 0; }
  .box-title h6 { position: relative; top: 2px; left: 8px; }

  .txt-block .accordion-1 .accordion-item { padding: 22px 0 27px; }
  .txt-block .accordion-1 .accordion-item .accordion-thumb:after,
  .txt-block .accordion-1 .accordion-item.is-active .accordion-thumb:after { font-size: 1rem; top: 0.5px; right: 5px; }

  .txt-block .accordion-1 .accordion-thumb h5 { font-size: 1.5rem; padding-left: 0; }
  .txt-block .accordion-1 .accordion-item.is-active .accordion-thumb h5 { margin-bottom: 18px; }
  .txt-block .accordion-1 .accordion-panel { padding: 0; }

  /* Advantages */
  .advantages, .text-center .advantages { margin-top: 15px; }
  .img-block-btn .advantages li { display: block!important; margin-top: 4px; }
  .img-block-btn .advantages li p { display: block; float: none; }
  .img-block-btn li.advantages-links-divider { display: none!important; }

  /* Compatibility */
  .compatibility { margin-top: 5px; }

  /* Tools List / Payments Icons */
  .tools-list p, .payment-methods p { margin-bottom: 12px; }
  .tools-list span { padding-right: 1px; }
  .payment-icons img { max-height: 30px; }

  .tools-list.ico-35 [class*="flaticon-"]:before, 
  .tools-list.ico-35 [class*="flaticon-"]:after { font-size: 2.125rem; line-height: 2.125rem!important; margin-right: 4px; }

  /* Content Images */
  .content-section .container.hidd { overflow: visible!important; }
  .img-block-hidden, .ct-06 .img-block { margin-bottom: -40px; }
  .img-block-txt { margin-top: 35px; }
  .img-block-btn { padding: 0 8%; margin-top: 35px; }
  .img-block-btn .btn { width: 100%; }

  .bc-3-img .video-btn { top: calc(50% - 25px); }

  .ct-01 .img-block.right-column, .ct-02 .img-block.right-column, .ct-03 .img-block.right-column,
  .ct-01 .img-block.left-column, .ct-02 .img-block.left-column, .ct-03 .img-block.left-column { margin: 0 0 35px; }

  .bc-01 .img-block, .bc-2-img.right-column, .bc-2-img.left-column { margin-bottom: 35px; }
  .bc-3-img { margin-bottom: -60px; }
  
  .img-content.py-100 { padding: 40px 0; }

  /* STATISTIC */
  .statistic-4-wrapper, .statistic-8-wrapper { padding: 0 8%; }
  .statistic-7-wrapper { padding: 65px 40px; }
  .statistic-7-wrapper .col-lg-4 { margin-bottom: 30px; }
  .txt-block-stat.mt-30 { margin-top: 20px; }

  #statistic-6 .statistic-block-txt:before { width: 75px; margin: 18px auto 16px; }

  #sb-1-1, #sb-1-2, #sb-2-1, #sb-5-1, #sb-5-2, 
  #sb-5-3, #sb-6-1, #sb-6-2, #sb-7-1, #sb-7-2, #sb-9-1, #sb-9-2 { margin-bottom: 35px; }
  #sb-3-2 { padding-left: 4%; }
  .statistic-4-wrapper .statistic-block { padding: 0; }
  #sb-4-1, #sb-4-2, #sb-8-1, #sb-8-2 { padding: 0 5% 35px; margin-bottom: 35px; }

  h3.statistic-number { font-size: 2.65rem; line-height: 1; letter-spacing: -1px; }
  h2.statistic-number { font-size: 2.85rem; line-height: 1; letter-spacing: -1px; }
  .statistic-2-wrapper h2,  #sb-3-1 h2.statistic-number, #sb-3-2 h2.statistic-number { margin-bottom: 12px; }
  .statistic-4-wrapper h5 { margin-top: 20px; margin-bottom: 12px; }
  .statistic-5-wrapper h3 { font-size: 2.25rem; line-height: 1; }
  #statistic-6 h2.statistic-number { font-size: 3.55rem; letter-spacing: -2px; }
  #statistic-6 h2.statistic-number small { font-size: 2.1875rem; margin-left: 4px; }
  .statistic-9-wrapper h3, .statistic-9-wrapper h2 { margin: 20px 0 12px; }

  .statistic-5-wrapper p { margin-top: 12px; }
  .statistic-8-wrapper p { margin-top: 15px; }  

  #sb-2-1 br { display: none; }
  #sb-2-2 { clear: both; }
  #sb-2-2 .txt-block-rating { display: inline-block; }
  #sb-2-2 p { display: inline-block; margin-left: 5px; }

  .statistic-8-wrapper img { max-height: 42px; margin-top: 16px; }
  .statistic-section .action-btn { margin-top: 40px; }

  /* PROJECTS */
  #portfolio-1.pb-70 { padding-bottom: 30px; }

  #portfolio-1 .filter-btns.mb-70 { margin-bottom: 25px; }
  #portfolio-1 .filter-item { margin-bottom: 30px; }

  .project-category { left: 20px; }
  .project-category p { padding: 7px 16px; margin-bottom: 0; }

  .project-data-txt { bottom: 34px; }
  .project-data-txt h5 { padding-right: 15%; }

  .project-source { width: 38px; height: 38px; bottom: 20px; }
  .project-source a span { top: 3.5px; }

  .filter-item:hover .project-data-txt { left: 25px; }
  .filter-item:hover .project-source { right: 20px; }

  #portfolio-1 .more-btn { margin-top: 15px; }

  /* PROJECT DETAILS */
  .project-title { padding: 0; margin-bottom: 30px; }
  .project-title p { margin-top: 15px; }
  .project-data { padding-bottom: 18px; margin: 25px 0 25px; }
  .project-data p { margin-bottom: 7px; }

  .project-txt h5 { margin: 25px 0 14px; }
  .project-txt h6 { font-size: 1.215rem; margin: 14px 0; }

  .project-inner-img { margin: 30px 0; }
  .project-inner-img.d-flex { margin-left: -12px; margin-right: -12px; }
  .project-inner-img .top-img { margin-bottom: 30px; }

  #project-details .more-projects { margin-top: 35px; }
  #project-details .more-projects span { top: 2px; left: 2px; }
  #project-details .more-projects.ico-25 [class*="flaticon-"]:before, 
  #project-details .more-projects.ico-25 [class*="flaticon-"]:after { font-size: 1.125rem; } 

  /* TEAM */
  .team-member-data h6 { font-size: 1.4375rem; }
  .team-member-data p { font-size: 1.125rem; }

  /* CAREERS */
  .position-data { margin-bottom: 4px; }
  .position-data p { font-size: 1.125rem; }

  .career-item .col-sm-8 { display: inline-block; width: 60%; float: left; }
  .position-workplace { text-align: left; }
  .position-workplace h6 { font-size: 1.125rem; }

  .career-item .col-sm-4 { display: inline-block; width: 40%; float: none; }

  /* JOB POSITION */
  #job-position .inner-page-title { padding-bottom: 45px; }
  #job-position .inner-page-title p { margin: 12px 0 10px; padding: 0; }
  #job-position .inner-page-title .btn { font-size: 0.9375rem; padding: 0.7rem 1.25rem; margin-top: 5px; }

  .position-info .btn { margin-top: 16px!important; }

  /* PRICING */
  .p-table { 
    -webkit-box-shadow: 0 4px 12px 0 rgba(0, 0, 0, .08); 
    -moz-box-shadow: 0 4px 12px 0 rgba(0, 0, 0, .08); 
    box-shadow: 0 4px 12px 0 rgba(0, 0, 0, .08); 
  }

  .pricing-1-table, .text-center .pricing-1-table { padding: 30px 35px; }

  .pricing-table-header h5 { font-size: 1.5625rem!important; }

  .pricing-discount { top: 0; right: -2px; padding: 6px 11px; }
  .pricing-1-table .pricing-discount { top: -4px; right: 0; padding: 7px 13px; }
  .text-center .pricing-1-table .pricing-discount { top: -32px; right: -5px; }
  .pricing-discount h6 { font-size: 1.05rem; }
  .pricing-1-table .pricing-discount h6 { font-size: 0.9375rem; }

  .pt-btn { font-size: 1.0625rem; padding: 0.925rem 1.3rem; }
  .p-table p.btn-txt { font-size: 1rem; margin: 12px 0 0 0; }

  .price span { font-size: 2.75rem; } 
  .price sup { font-size: 2rem; top: -5px; }
  .price sup.validity { font-size: 1.65rem; }
 
  .text-center .price p { padding: 0; }

  /* Pricing Features */
  .pricing-features.mt-25 { margin-top: 20px; }
  .pricing-features li { padding: 11px 8px; }

  /* Pricing Notice */
  .pricing-notice.mb-40 { margin-bottom: 30px; }
  .col-lg-9 .pricing-notice p, .col-lg-10 .pricing-notice p { padding: 0; }

  /* Pricing Toggle Button */
  .toggle-btn-md .toggler-txt, .toggle-btn-lg .toggler-txt { font-size: 1.15rem; }

  /* Pricing Compare */
  .table-responsive.mb-50 { margin-bottom: 30px; }
  .table>:not(caption)>*>* { padding: 10px 0 9px; }
  .comp-table .table-responsive thead th { font-size: 0.9375rem; }
  .comp-table .table-responsive tbody th { font-size: 0.915rem; line-height: 1.25; }
  .comp-table .table-responsive tbody td { font-size: 0.9rem; top: 0; line-height: 1.25; }

  .comp-table .table-responsive tbody td span { top: 3px; }

  .table-responsive .ico-15 [class*="flaticon-"]:before, 
  .table-responsive .ico-15 [class*="flaticon-"]:after { font-size: 0.675rem; }

  .table-responsive .ico-20 [class*="flaticon-"]:before, 
  .table-responsive .ico-20 [class*="flaticon-"]:after { font-size: 0.835rem; }

  .pbox { padding: 0; }
  #pbox-1, #pbox-2, #pbox-3 { margin-bottom: 30px; }
  .pricing-section .txt-box-last, .pricing-section .pm-last { margin-bottom: 40px; }
  .comp-table-payment h6 { margin-bottom: 15px; }

  /* RATING */
  #rb-2-1, #rb-2-2 { margin-bottom: 25px; }

  .rating-title { padding: 0 10%; }
  .rating-title h5 { font-size: 1.125rem; }
  .rbox-1-img { margin-bottom: 18px; }
  .rbox-1-img img { max-height: 32px; }
  .rbox-2-txt img { max-height: 30px; }
  p.rbox-2-txt { font-size: 1.1rem; line-height: 32px; }

  .rbox-1 .star-rating { padding: 5px 16px; font-size: 1rem; line-height: 1; }

  .rbox-1 .star-rating span { top: -2px; }
  .rbox-1 .star-rating.ico-15 [class*="flaticon-"]:before, 
  .rbox-1 .star-rating.ico-15 [class*="flaticon-"]:after { font-size: 1rem; } 
  .rbox-2 .ico-20 [class*="flaticon-"]:before, .rbox-2 .ico-20 [class*="flaticon-"]:after { font-size: 1.35rem; } 

  /* TESTIMONIALS */
  .reviews-3-wrapper { padding: 70px 8%; }
  .reviews-4-wrapper { padding: 70px 6%; }

  #reviews-3 .txt-block { margin-bottom: 40px; }

  .review-1, .review-5 { 
    padding: 20px 20px 25px;
    -webkit-box-shadow: 0 4px 12px 0 rgba(0, 0, 0, .08); 
    -moz-box-shadow: 0 4px 12px 0 rgba(0, 0, 0, .08); 
    box-shadow: 0 4px 12px 0 rgba(0, 0, 0, .08); 
  }
  .review-2 { padding: 0 5px; }
  .review-3 { 
    text-align: center; 
    padding: 30px 20px; 
    -webkit-box-shadow: 0 4px 12px 0 rgba(0, 0, 0, .08); 
    -moz-box-shadow: 0 4px 12px 0 rgba(0, 0, 0, .08); 
    box-shadow: 0 4px 12px 0 rgba(0, 0, 0, .08); 
  }

  .review-5-logo { margin-left: 0; margin-bottom: 35px; }
  .review-5-logo img { max-height: 42px; }
  
  .review-1 .review-ico, .review-5 .review-ico { top: 10px; right: 20px; }

  .review-1 .review-rating, .review-5 .review-rating { margin-bottom: 8px; }
  .review-rating.ico-15 [class*="flaticon-"]:before, 
  .review-rating.ico-15 [class*="flaticon-"]:after,
  .review-1 .review-rating.ico-15 [class*="flaticon-"]:before, 
  .review-5 .review-rating.ico-15 [class*="flaticon-"]:before, 
  .review-1 .review-rating.ico-15 [class*="flaticon-"]:after, 
  .review-5 .review-rating.ico-15 [class*="flaticon-"]:after { font-size: 1.1rem; }

  .review-2-txt h5 { padding: 0 10%; margin: 10px 0; }
  .review-3-txt h6.h6-md, .review-3-txt h6.h6-lg { margin: 5px 0 10px; }
  #reviews-3 .txt-block h2 { margin-bottom: 12px; padding: 0 10%; }
  #reviews-3 .txt-block p { margin-top: 0; padding: 0 4%; }
  #reviews-3 .txt-block .btn { margin-top: 18px; }

  .review-3 .review-avatar { margin: 18px auto 14px; float: none; }

  .review-1 .review-author, .review-5 .review-author { margin-top: 15px; }
  .review-2 .review-author { margin-top: 20px; }
  .review-3 .review-author { text-align: center; padding: 0; margin-top: 0; }

  .review-author h6 { font-size: 1.175rem; margin-bottom: 12px; }
  .review-1 .review-author h6, .review-5 .review-author h6 { font-size: 1.135rem; margin-bottom: 12px; }
  .review-author p, .reviews-4-txt .review-author p { font-size: 1.0625rem; }
  .reviews-section p.p-sm { font-size: 1rem; }

  .review-5-avatar { right: 20px; bottom: 25px; }
  .review-5-avatar img { width: 44px; height: 44px; }

  .reviews-4-img { width: 130px; height: 130px; margin: 0 auto 10px; }
  .reviews-4-txt .review-author, .review-5 .review-author.mt-30 { margin-top: 20px; }

  /* BRANDS */
  .brands-2-wrapper, .brands-3-wrapper { padding: 0 12px; }
  .brands-2-wrapper .col, .brands-3-wrapper .col { padding: 0; }

  .brands-title h6 { font-size: 1rem; line-height: 1.55; margin-bottom: 25px; }
  .brands-title h5 { font-size: 1.325rem; margin-bottom: 35px; }
  .brands-title p { padding: 0 8%; margin-bottom: 25px; }

  #brands-4 .txt-block { margin-bottom: 40px; }

  #brand-2-1, #brand-2-2, #brand-2-3, #brand-3-1, 
  #brand-3-2, #brand-3-3, #brand-3-4, #brand-3-5, #brand-3-6 { margin-bottom: 35px; }
  #brand-3-10 { display: none; }
  #brand-4-1, #brand-4-2, #brand-4-3, #brand-4-4, #brand-4-5, #brand-4-6 { margin-bottom: 26px; }

  .brands-carousel-5 .brand-logo, .brands-carousel-6 .brand-logo, #brands-2 .brand-logo, #brands-3 .brand-logo {padding: 0 10px; } 
  #brands-4 .brand-logo { padding: 0 5px; }

  .brands-section .more-btn { margin-top: 30px; }

  /* INTEGRATIONS */
  #integrations-4.pt-100.pb-70 { padding-bottom: 54px; }

  .int-4-wrapper .col { padding: 0 8px; }

  .filter-btns button { font-size: 1.1rem; }

  .int_tool-1 { padding: 18px 22px 16px; margin-bottom: 20px; }
  .int_tool-2 { padding: 18px 22px; }
  .int_tool-3 { padding: 24px; margin-bottom: 20px; }
  .int_tool-4 { padding: 18px 12px; margin-bottom: 16px; }
  .filter-item, .int-2-wrapper .int_tool-2 { margin-bottom: 20px; }

  .int_tool-1 .tool_logo { margin-right: 20px; }
  .int_tool-1 .tool_logo img { width: 48px; height: 48px; }
  .int_tool-3 img, .int_tool-4 img { width: 50px; height: 50px; }

  .tool_txt h6 { font-size: 1.25rem; line-height: 1; margin-top: 3px; }
  .int_tool-4 h6 { font-size: 0.95rem; margin-top: 14px; }
  .tool_txt p { font-size: 1.0625rem; margin-top: 3px; }

  /* BANNER */
  #banner-1 .container { max-width: 100%; } 

  .banner-1-wrapper { padding: 65px 30px 70px; margin: 0 -15px; border-radius: 0 0!important; }
  .banner-2-wrapper .banner-overlay { padding: 60px 35px 70px; }
  #banner-3 .banner-overlay.pt-80.pb-90 { padding: 55px 0 65px; } 
  .banner-4-wrapper { padding: 45px 5%; }

  .banner-4-txt { margin-bottom: 12px; }

  .banner-1-txt h4 { font-size: 1.625rem; }  /* 26px; */
  .banner-2-txt h2, .banner-3-txt h2 { margin-bottom: 10px; }
  .banner-3-txt h2.h2-xl, .banner-3-txt h2.h2-title { margin-bottom: 22px; }

  .banner-section p { margin-bottom: 18px; }
  #banner-1.banner-section p { padding: 0 5%; margin: 15px 0 20px; }
  #banner-2.banner-section p { padding: 0; }
  #banner-2.banner-section .l-txt p { padding: 0 4%; }
  #banner-3.banner-section p.p-xl { padding: 0 8%; }

  .banner-1-txt .btn { display: block; margin: 14px 8% 0; }
  .banner-2-txt .btns-group .btn { display: block; margin-left: 8%; margin-right: 8%; }

  /* DOWNLOAD */
  span.version-data { display: block; font-size: 1.85rem; margin-right: 0; margin-bottom: 12px; }
  span.release-date { display: block; font-size: 1.25rem; }
  span.release-date span { margin-left: 10px; }

  /* FAQs */
  #faqs-1 .section-title { margin-bottom: 25px; } 
  #faqs-2 .section-title { margin-bottom: 35px; } 
  #faqs-3 .section-title { margin-bottom: 30px; } 

  #faqs-1.faqs-section .accordion-thumb h5 { line-height: 1.5; }
  #faqs-2 .question h6, #faqs-2 .question h5 { font-size: 1.25rem; margin-bottom: 12px; }
  .accordion-thumb h5 { font-size: 1.25rem; line-height: 1.5; padding-right: 8%; }
  .faqs-3-question h5 { font-size: 1.25rem; margin-bottom: 12px; }

  #faqs-1 .accordion-thumb { padding: 14px 0 18px; }
  #faqs-3.faqs-section .accordion-thumb { padding: 20px 0 25px; }

  .accordion-panel { padding: 0 0 10px 0; }
  #faqs-3 .accordion-panel { padding: 30px 0 15px 0; } 
  #faqs-3 .accordion-panel-item.mb-35 { margin-bottom: 25px; }

  #faqs-1.faqs-section .accordion-item .accordion-thumb:after, 
  #faqs-1.faqs-section .accordion-item.is-active .accordion-thumb:after { font-size: 0.925rem; top: 18px; right: 2px; }

  #faqs-3.faqs-section .accordion-item .accordion-thumb:after, 
  #faqs-3.faqs-section .accordion-item.is-active .accordion-thumb:after { font-size: 0.9rem; top: 25px; right: 2px; }

  .more-questions { margin-top: 10px; }
  #faqs-1 .more-questions { margin-top: 25px; }
  #faqs-3 .more-questions { margin: 30px auto 0; }

  .more-questions h6 { font-size: 1.225rem; }
  .more-questions span { display: block; margin-bottom: 3px; }

  .more-questions-txt { padding: 18px 36px; }
  .more-questions-txt p { font-size: 1.125rem; }
  .more-questions .more-questions-txt a { display: inline-block; }

  /* HELP CENTER */
  .help-category { padding: 35px 30px; margin-bottom: 25px; }
  .help-category h6 { margin: 10px 0; }

  /* HELP CATEGORY */
  .help-category-title { padding: 45px 18px 34px; margin-bottom: 40px; }
  #help-category .breadcrumb-wrapper { margin-bottom: 30px; }
  .help-category-title p { margin-top: 10px; }

  #help-category h4 { margin-bottom: 35px; }
  .help-category-item { padding-bottom: 30px; margin-bottom: 30px; }

  .help-category-item p { margin: 16px 0; }
  .help-category-data span { font-size: 1rem; }

  .read-time, .updated-date { display: block; }
  .read-time { margin-top: 3px; margin-left: 0; }

  /* HELP ARTICLE */
  .breadcrumb-wrapper { display: inline-block!important; margin-left: 65px; margin-bottom: 20px; }
  .breadcrumb { margin-bottom: 0; }

  .help-article-title h3 { padding: 0 3%; margin-bottom: 14px; }
  .help-article-title img { margin: 30px 0; }

  .table-of-contents { padding: 30px 0 35px; margin: 30px 0 25px; }
  .table-of-contents h6 { margin-bottom: 15px; }

  #help-article .txt-block { margin: 30px 0; }
  #help-article .txt-block h6 { font-size: 1.125rem; margin: 22px 0 12px; }

  #help-article .txt-block img { margin: 15px 0 20px; }

  .help-vote-form { margin-top: 40px; }
  .help-vote-form h6 { margin-bottom: 20px; }
  .help-vote-form .btns-group .btn:first-child { margin: 0 0 14px; }
  .help-vote-form .btns-group .btn:last-child {  margin: 0; }
  .help-vote-form .btn { width: 100%; }

  /* BLOG */
  .posts-category { margin-top: 0; margin-bottom: 30px; }
  .posts-category span { top: 1.25px; }
  .posts-category.ico-20 [class*="flaticon-"]:before, 
  .posts-category.ico-20 [class*="flaticon-"]:after { font-size: 1.0625rem; } 

  .square-post .blog-post-txt { padding: 15px 30px 35px; }

  /* SINGLE BLOG POST */
  .single-post-title { margin-bottom: 30px; }
  .single-post-title h3, .single-post-title h2 { padding: 0 10px; }
  .single-post-title p.p-lg { margin-top: 12px; }

  #single-post .blog-post-meta { margin-bottom: 12px; }

  #single-post .blog-post-img.pb-60 { padding-bottom: 30px; }

  .single-post-txt .blockquote p { font-size: 1.15rem; margin-bottom: 35px; }
  .single-post-txt .blockquote-footer { font-size: 1rem; line-height: 1.5; }

  .post-inner-img { margin: 25px 0; }

  .post-data { text-align: center; margin-top: 60px; padding: 60px 0; }

  .post-author-avatar { float: none; margin-right: 0; margin-bottom: 10px; }
  .post-author-avatar img { width: 58px; height: 58px; }

  .post-author-txt { top: 0; }
  .post-author-txt p { font-size: 1.0625rem; margin-top: 10px; }

  .post-share-list.text-end { text-align: center!important; margin-top: 12px; }
  .post-share-list p { font-size: 1.0625rem; margin-bottom: 10px; }

  #post-comments img { width: 48px; height: 48px; }
  .comment-body { margin-left: 20px; }
  .comment-meta { margin-bottom: 7px; }
  .comment-meta h6 { font-size: 1.275rem; margin-bottom: 6px; }
  .comment-date, .btn-reply a { font-size: 1rem; } 
  .btn-reply.ico-20 [class*="flaticon-"]:before, .btn-reply.ico-20 [class*="flaticon-"]:after { font-size: 1rem; }

  .comment-form { margin-top: 35px; }
  .comment-form p { margin-bottom: 10px; }
  .comment-form .btn { font-size: 1.0625rem; height: 52px; }

  .comment-form .form-control { margin-bottom: 25px; }
  .comment-form textarea { min-height: 170px; }

  /* NEWSLETTER */
  #newsletter-2 .container { max-width: 100%; } 

  #newsletter-1 .newsletter-overlay { padding: 50px 0 40px; }  
  #newsletter-2 .newsletter-overlay { padding: 65px 35px 55px; margin: 0 -15px; border-radius: 0 0!important; }

  .newsletter-txt h4, .newsletter-txt h3 { font-size: 1.75rem; padding: 0; margin-bottom: 25px; }
  #newsletter-2 .newsletter-txt p { padding: 0 4%; }

  #newsletter-1 .newsletter-form { padding: 0 5%; }
  #newsletter-2 .newsletter-form { padding: 0 2%; margin-top: 30px; }

  .newsletter-section .form-control, .newsletter-section .btn { height: 54px; font-size: 1.0625rem; }
  #newsletter-2.newsletter-section .btn { font-size: 1.0625rem; }

  /* CONTACTS */
  .contacts-info { text-align: center; }
  .contacts-info p { margin: 14px 0 0; padding: 0 3%; }
  .contacts-info h6 { margin: 22px 0 14px; }
  .contacts-info-socials li { padding-right: 12px; }

  .contact-boxes.pb-70 { text-align: center; padding-bottom: 40px; }
  .contact-box p { padding: 0 5%; margin-top: 12px; }
  .contact-box span { top: 0.5px; left: 2px; }

  .contact-form .form-control, 
  .contact-form .form-select { height: 54px; font-size: 1.0625rem; padding: 0 15px; margin-bottom: 18px; }
  .contact-form textarea { min-height: 170px; }
  .contact-form textarea.form-control { padding: 15px; }

  .contact-form .form-btn { margin-top: 5px; }
  .contact-form .form-btn .btn { width: 100%; font-size: 1.0625rem; padding: 0.95rem 1.4rem; }
  .contact-form-msg { text-align: center; margin-top: 15px; }

  /* FOOTER */
  .footer { padding-bottom: 30px; }
  #footer-1 .footer-contacts { margin-top: -5px; }
  .footer-info, #footer-4 .footer-info, #footer-4 .footer-contacts { margin-bottom: 35px; }
  .footer-links, #footer-7 .footer-connect, #footer-8 .footer-connect { margin-bottom: 25px; }
  #footer-9 .footer-contacts { margin-top: -5px; margin-bottom: 35px; }
  .footer-form { margin-bottom: 10px; }
  #footer-11 .footer-form { margin-top: 35px; }

  .fl-1, .fl-2, .fl-3, .fl-4, #footer-1 .fl-5 { border-bottom: 1px solid #ddd; margin-bottom: 16px; padding-bottom: 17px; }
  #footer-1 .fl-3 { margin-bottom: 17px; }

  #footer-10 .foo-links { display: block; padding-top: 0; }
  #footer-10 .foo-links li { padding: 0 10px; }

  img.footer-logo { max-height: 33px; margin-bottom: 22px; }
  #footer-6 img.footer-logo { margin-bottom: 5px; }

  .footer h6 { font-size: 1.1875rem; margin-bottom: 20px; }   /* 19px */
  .footer .footer-links h6.h6-lg { font-size: 1.275rem; margin-bottom: 18px; }

  .footer-mail-link { margin: 0 0 0 6px; }
  .footer-mail-link a { font-size: 1.09375rem; }  /* 17.5px */
  .footer-mail-link span { top: 4.5px; right: 5px; }

  .footer-mail-link.ico-20 [class*="flaticon-"]:before, 
  .footer-mail-link.ico-20 [class*="flaticon-"]:after { font-size: 1.275rem; }

  .footer-info-socials li { padding-right: 13px; }
  .footer-info-socials.ico-20 [class*="flaticon-"]:before, 
  .footer-info-socials.ico-20 [class*="flaticon-"]:after,
  .footer-info-socials.ico-25 [class*="flaticon-"]:before, 
  .footer-info-socials.ico-25 [class*="flaticon-"]:after { font-size: 1.3975rem; }

  .footer-socials a { margin-right: 13px; }
  .footer-socials.ico-25 [class*="flaticon-"]:before, 
  .footer-socials.ico-25 [class*="flaticon-"]:after { font-size: 1.435rem; }
  
  #footer-1 .footer-form { margin-bottom: 0; }
  .footer-form .form-control { font-size: 1.1rem; height: 38px; }
  .footer-form .btn { height: 40px; }
  .footer-form .ico-15 [class*="flaticon-"]:before, 
  .footer-form .ico-15 [class*="flaticon-"]:after { font-size: 1rem; line-height: 28px!important; }

  #footer-1 .bottom-footer { margin-top: 20px; }
  .footer-copyright p, .bottom-footer-list p.p-sm, .bottom-secondary-link p.p-sm { font-size: 1rem; }

  .footer-list-divider { top: -1px; }
  .bottom-secondary-link p span { top: 1.5px; padding: 0 2px; }
  .footer-info-copyright p span { top: 2px; padding: 0 2px; }

  .footer-info-copyright.ico-15 [class*="flaticon-"]:before, 
  .footer-info-copyright.ico-15 [class*="flaticon-"]:after { font-size: 0.815rem; }

  .bottom-footer-socials.ico-20 [class*="flaticon-"]:before, 
  .bottom-footer-socials.ico-20 [class*="flaticon-"]:after { font-size: 1.375rem; }

  .footer-stores-badge { margin-bottom: 20px; }
  .footer-stores-badge .store { margin-right: 10px; }
  .footer .badge-img-xs .store img { max-height: 2.75rem; }

  .bottom-foo-socials { margin-left: 6px; }
  .bottom-foo-socials li { margin-right: 6px; }
  .bottom-foo-socials li span { top: 2.5px; right: 3px; }

  .bottom-foo-socials.ico-10 [class*="flaticon-"]:before, 
  .bottom-foo-socials.ico-10 [class*="flaticon-"]:after { font-size: 1.125rem; }

  .bottom-footer p.p-sm { margin-bottom: 0; }

  /* INNER PAGE WRAPPER */
  .inner-page-title { margin-bottom: 50px; }
  .inner-page-title h2 { margin-bottom: 0; }
  .inner-page-title p { padding: 0 5%; line-height: 1.6666; margin-top: 10px; }

  /* PAGE PAGINATION */
  .page-link { font-size: 0.95rem; padding: 2px 10px; }
  .pagination.ico-20 [class*="flaticon-"]:before, 
  .pagination.ico-20 [class*="flaticon-"]:after { font-size: 0.75rem; } 

  /* TERMS, PRIVACY, COOKIES */
  .txt-block.legal-info h5 { margin: 35px 0 15px; }
  .txt-block.legal-info h6 { margin: 15px 0; }

  /* SIGN IN / SIGN UP */
  .login-page-logo { margin-bottom: 40px; }
  .login-page-logo img { max-height: 35px; }

  .login-1 .register-page-form, .signup-1 .register-page-form,
  .login-2 .register-page-form, .signup-2 .register-page-form { 
    margin: 0 3%; 
    padding: 30px 18px 35px; 
    border-radius: 8px; 
    -webkit-border-radius: 8px; 
    -moz-border-radius: 8px; 
    -o-border-radius: 8px; 
    -webkit-box-shadow: 0 4px 12px 0 var(--tra-black);
    -moz-box-shadow: 0 4px 12px 0 var(--tra-black);
    box-shadow: 0 4px 12px 0 var(--tra-black);
  }

  .login-2 .register-page-form, .signup-2 .register-page-form { margin: 0 2%; }

  .login-3 .register-page-wrapper, .signup-3 .register-page-wrapper { border-radius: 8px; margin: 0 3%; }
  .login-3 .register-page-form, .signup-3 .register-page-form { padding: 30px 18px; }

  .reset-page-wrapper form { margin: 0 3%; padding: 35px 12px 5px; }

  .register-form-title { margin-bottom: 20px; }
  .login-2 .register-form-title, .signup-2 .register-form-title , .reset-form-title{ margin-bottom: 16px; }

  .register-form-title h5, .register-form-title h4 { margin-bottom: 10px; }

  .register-page-form p.input-header, .register-page-form .btn-google { font-size: 1rem; }
  .reset-password-link p { font-size: 1rem; margin-top: 16px; }
  .login-3 .reset-password-link p { margin-top: 3px; margin-bottom: 24px; }
  .form-data span { font-size: 0.9rem; }
  .reset-password-form .form-data p { font-size: 1rem; margin-bottom: 10px; }
  .register-page-form p.create-account { font-size: 1rem; margin-top: 20px; }
  .reset-form-title p.p-sm { font-size: 1rem; padding: 0 2%; margin-top: 16px; }

  .separator-line { font-size: 1rem;  margin: 15px 0; }

  .reset-page-wrapper .form-control, .register-page-form .form-control { height: 54px; font-size: 1.0625rem; margin-bottom: 16px; }
  #login .register-page-form .btn.submit { height: 54px; font-size: 1.0625rem; margin-top: 0; }
  #signup .register-page-form .btn.submit { height: 54px; font-size: 1.0625rem; margin-top: 18px; }
  .reset-page-wrapper .btn.submit { height: 54px; font-size: 1.0625rem; margin-bottom: 16px; }
  .btn-google img { width: 18px; height: 18px; top: -2px; right: 5px; }

  /* PAGE 404 */
  #page-404 { padding-top: 100px; }
  .page-404-txt h2 { font-size: 2.2rem; margin-bottom: 14px; }
  .page-404-txt h6 { font-size: 1.135rem; padding: 0 5%; margin-bottom: 25px; }
  .page-404-img { padding: 0 8%; margin-bottom: 30px; }
  .page-404-footer p span { display: block; }

  /* COOKIES */
  #cookies { bottom: 15px; left: 5%; width: 90%; padding: 22px 18px 18px; }
  #cookies p { font-size: 1rem; }
  #cookies-ok { font-size: 0.95rem; padding: 7px 18px; }
  #cookies-info { margin-left: 15px; }
  #cookies-close { right: 14px; top: 6px; }
  #cookies-close svg { width: 12px; height: 12px; }

  /* MODAL */
  #modal-1 .modal-dialog { width: 320px; margin: auto auto; }
  #modal-2 .modal-dialog.modal-xl { width: 350px; margin: auto auto; }

  #modal-1 .modal-body-img { padding-top: 25px; }

  #modal-1 .modal-body-content { padding: 5px 22px 10px; }
  #modal-2 .modal-body-content { padding: 25px 12px 18px; }

  .modal .btn-close { right: 16px!important; top: 16px!important; }

  #modal-2 .request-form-title p { margin-top: 12px; margin-bottom: 18px; }

  .modal-body .newsletter-form { margin-top: 18px; }
  .modal .newsletter-form .form-control,
  #modal-2 .request-form .form-control { height: 54px; font-size: 1.0625rem; margin-bottom: 16px; }
  .modal .newsletter-form .btn, #modal-2 .request-form .btn { height: 54px; font-size: 1.0625rem; }


}




/* ------------------------------------------------------------------------ */
/* ------------------------------------------------------------------------ */
/* ------------------------------------------------------------------------ */

@media (min-width: 390px) and (max-width: 413.98px) {

  /*------------------------------------------*/
  /*  BASE
  /*------------------------------------------*/

  #stlChanger { display: none; }
  .division { padding-left: 8px; padding-right: 8px; }
  .block--shadow { -webkit-box-shadow: 0 0; -moz-box-shadow: 0 0; box-shadow: 0 0; }
  .bg--fixed { width: auto; }
  .bg--scroll { width: auto; background-attachment: scroll!important; }

  /* Background Shape */
  .shape--01:after { height: 55%; }
  .shape--02:after { height: 55%; top: 45%; }
  .bg--shape:after { width: 1300px; height: 1300px; top: 12%; left: -10%; }
  .bg--shape-top:after { width: 450px; height: 450px; top: -5%; right: 12%; background-color: rgba(119, 67, 142, .15); }

  /* Headers */
  h6, h6.h6-md, h6.h6-lg, h6.h6-xl { font-size: 1.25rem; }            /* 20px; */
  h5, h5.h5-md, h5.h5-lg { font-size: 1.375rem; }                     /* 22px; */
  h5.h5-xl, h4, h4.h4-md, h4.h4-lg, h4.h4-xl { font-size: 1.5rem; }   /* 24px; */
  h3, h3.h3-md, h3.h3-lg, h3.h3-xl, h2, h2.h2-md, h2.h2-lg { font-size: 1.625rem; }  /* 26px; */
  h2.h2-xl, h2.h2-title { font-size: 1.875rem; }                       /* 30px; */            

  h6, h5, h4, h3, h2, h1 { line-height: 1.4; }

  /* Paragraphs */
  p.p-sm { font-size: 0.9375rem; } 
  p { font-size: 1rem; }  
  p.p-md, p.p-lg, p.p-xl { font-size: 1.0625rem; }

  ol.digit-list p { margin-bottom: 5px; }

  /* Button */
  .btn.btn-sm, .btn, .btn.btn-md { font-size: 0.9375rem; padding: 0.8rem 1.45rem; }
  .btns-group .btn.ico-30.ico-left, .btns-group .btn.ico-30.ico-right { padding: 0.61915rem 0.5rem 0; }

  /* Buttons Group */
  .btns-group .btn:first-child { margin: 0 8px 15px; }
  .btns-group .btn:last-child { margin-left: 8px; margin-right: 8px; }

  /* Button Icon */
  .btn.ico-20 [class*="flaticon-"]:before, 
  .btn.ico-20 [class*="flaticon-"]:after { font-size: 1.225rem; line-height: 0.45rem!important;top: 4px; }

  .btn.ico-30 [class*="flaticon-"]:before, .btn.ico-30 [class*="flaticon-"]:after { font-size: 2.05rem; }

  .btn.ico-30.ico-left span.ico-30-txt { font-size: 1.15rem; top: -10px; margin-right: 0; }
  .btn.ico-30.ico-right span.ico-30-txt { font-size: 1.15rem; top: -10px; margin-left: 0; }
  .btn.ico-left span { margin-right: 7px; }
  .btn.ico-right span { margin-left: 7px; }

  /* Button Text */
  p.btn-txt, .text-center p.btns-group-txt { margin-top: 13px; margin-bottom: 0; }
  .btn-rating { margin: 15px 0 0 0; }
  .btn-rating p.p-sm { font-size: 0.9375rem; }
  .star-rating small, .star-rating span.rating-txt { font-size: 1rem; }

  .star-rating.ico-20 [class*="flaticon-"]:before, 
  .star-rating.ico-20 [class*="flaticon-"]:after { position: relative; font-size: 1.125rem; top: 2px; }

  /* Transparent Link */
  a.tra-link { font-size: 1.03125rem; }
  .tra-link.ico-20 [class*="flaticon-"]:before, 
  .tra-link.ico-20 [class*="flaticon-"]:after { font-size: 0.785rem; line-height: 0.7rem!important; top: 1px; left: 2px; }

  /* Store Badge */
  .store { margin-right: 6px; }
  .store.store-btn-2 { margin-right: 0; }
  .store-btn-3, .store-btn-4 { margin-top: 15px; }

  /* Watch Video Link */
  .watch-video { margin-top: 20px; }
  .watch-video-link { margin-right: 10px; }
  p.video-txt-lg { font-size: 1.125rem; }
  p.video-txt-sm { font-size: 1.0625rem; }

  /* Video Popup Icon */
  .video-btn-xl { width: 5.625rem; height: 5.625rem; margin-top: -2.8125rem; margin-left: -2.8125rem; }
  .video-btn-lg { width: 5rem; height: 5rem; margin-top: -2.5rem; margin-left: -2.5rem; }
  .video-btn-md { width: 5rem; height: 5rem; margin-top: -2.5rem; margin-left: -2.5rem; }

  .video-btn.video-btn-xl [class^="flaticon-"]:before, .video-btn.video-btn-xl [class^="flaticon-"]:after {
    font-size: 4.5rem; line-height: 5.625rem!important; margin-left: 8px;
  }

  .video-btn.video-btn-lg [class^="flaticon-"]:before, .video-btn.video-btn-lg [class^="flaticon-"]:after,
  .video-btn.video-btn-md [class^="flaticon-"]:before, .video-btn.video-btn-md [class^="flaticon-"]:after {
    font-size: 4.35rem; line-height: 5rem!important; margin-left: 8px;
  }

  /* Vector Icon Background */ 
  .ico-50 .shape-ico svg { width: 120px; height: 120px; top: -35px; left: calc(50% - 60px); }
  .fbox-11 .ico-50 .shape-ico svg { top: -35px; left: calc(50% - 65px); }
  .ico-55 .shape-ico svg { width: 125px; height: 125px; top: -30px; left: calc(50% - 65px); }
  .ico-60 .shape-ico svg { width: 130px; height: 130px; top: -35px; left: calc(50% - 60px); }

  /* Vector Rounded Icons */
  .ico-rounded, .ico-rounded-md, .ico-rounded-lg { width: 94px; height: 94px; }

  .ico-rounded [class*="flaticon-"]:before, 
  .ico-rounded [class*="flaticon-"]:after,
  .ico-rounded-md [class*="flaticon-"]:before, 
  .ico-rounded-md [class*="flaticon-"]:after,
  .ico-rounded-lg [class*="flaticon-"]:before, 
  .ico-rounded-lg [class*="flaticon-"]:after { font-size: 3rem; line-height: 94px!important; }

  /* PNG Icons */
  .img-80 img { width: 70px; height: 70px; }
  .img-75 img { width: 65px; height: 65px; }
  .img-70 img, .img-65 img, .img-60 img { width: 60px; height: 60px; }

  /* Section ID */
  .section-id { font-size: 0.9rem; margin-bottom: 22px; }

  /* Section Title */
  .section-title.mb-70 {margin-bottom: 35px; }
  .section-title p { margin-top: 15px; }
  .section-title .btns-group { padding: 0 8%; margin-top: 10px; }
  .section-title .btns-group .btn { width: 100%; margin: 15px 0 0; }

  /* Users */
  .txt-block .users { margin-top: 16px; }
  .users img { max-height: 48px; }
  .txt-block .users img { max-height: 42px; }
  .users p { font-size: 0.975rem; margin-top: 15px; top: 0; left: 0; }
  .txt-block .users p { font-size: 0.915rem; top: 10px; left: 4px; }

  /*------------------------------------------*/
  /*   NAVIGATION MENU    
  /*------------------------------------------*/

  /* Header Logo */
  .wsmobileheader .smllogo { display: block; margin-top: 15px; padding-left: 16px; }
  .smllogo img { width: auto; max-width: inherit; max-height: 50px; }

  .wsmenu > .wsmenu-list { width: 325px; margin-right: -325px; }
  .wsactive .wsmobileheader { margin-right: 325px; }
  .overlapblackbg { width: calc(100% - 325px); }

  .wsanimated-arrow { padding: 14px 30px 14px 0px; margin: 25px 16px 0 0; }

  /* Nav Link Info */
  span.sm-info { width: 22px; height: 22px; font-size: 0.8rem; line-height: 20px; top: -1px; margin-left: 8px; }

  .wsmenu > .wsmenu-list > li > a { padding: 5px 32px 4px 20px; margin: 0; }
  .wsmenu > .wsmenu-list > li > .wsmenu-click > i { height: 8px; width: 8px; margin: 24px 18px 0px 0px; }

  .wsmenu > .wsmenu-list > li > ul.sub-menu, .wsmenu > .wsmenu-list > li > .wsmegamenu.w-75,
  .wsmenu > .wsmenu-list > li > .wsmegamenu.halfmenu { top: 0; }

  .wsmenu > .wsmenu-list > li > ul.sub-menu, .wsmenu > .wsmenu-list > li > .wsmegamenu.halfmenu, 
  .wsmenu > .wsmenu-list > li > .wsmegamenu.w-75 { padding: 6px 10px; }

  .wsmenu > .wsmenu-list > li > ul.sub-menu > li > a, 
  .wsmenu > .wsmenu-list > li > ul.sub-menu > li > a:hover, 
  .wsmenu > .wsmenu-list > li > .wsmegamenu .link-list li a,
  .wsmenu > .wsmenu-list > li > .wsmegamenu .link-list li a:hover,
  .wsmenu > .wsmenu-list > li > ul.sub-menu > li > ul.sub-menu > li > a,
  .wsmenu > .wsmenu-list > li > ul.sub-menu > li > ul.sub-menu > li > a:hover,
  .wsmenu > .wsmenu-list > li > .wsmegamenu.w-75 ul.link-list > li > a, 
  .wsmenu > .wsmenu-list > li > .wsmegamenu.halfmenu ul.link-list > li > a,
  .wsmenu > .wsmenu-list > li > .wsmegamenu.w-75 ul.link-list > li > a:hover, 
  .wsmenu > .wsmenu-list > li > .wsmegamenu.halfmenu ul.link-list > li > a:hover { padding: 13px 10px; }

  /* Header Store Badges */
  .store.header-store img { max-height: 45px; }

  /* Header Social Links */
  .header-socials.ico-20 [class*="flaticon-"]:before, 
  .header-socials.ico-25 [class*="flaticon-"]:after { line-height: 52px!important; } 

  /*------------------------------------------*/
  /*   HERO   
  /*------------------------------------------*/

  .hero-section.blur--purple:after { height: 90%; top: -34%; left: 10%; }

  .slideshow, #hero-20 .slideshow, .slideshow .slides, #hero-20 .slideshow .slides { height: 450px; }

  .img-link { bottom: 20px; right: 20px; width: 45px; height: 45px; }
  .img-link a span { top: 4.5px; }

  .hero-section .btns-group { display: block; }
  .hero-section .btns-group .btn { display: block; padding: 1rem 1.5rem; margin: 0 10%; }
  .hero-section .btns-group .btn:first-child { margin-bottom: 15px; }

  /* Hero Advantages */
  .hero-section .text-center .advantages { margin-top: 18px; }
  .hero-section .advantages li { display: block!important; margin-top: 4px; }
  .hero-section .advantages li p { display: block; float: none; }
  .hero-section li.advantages-links-divider { display: none!important; }

  /* HERO QUICK FORM */
  .hero-section .quick-form .form-control, .hero-section .quick-form .btn { height: 52px; font-size: 1.0625rem; }
  .hero-section .quick-form.form-half .form-control { height: 52px; font-size: 1.0625rem; padding: 0 14px; }
  .hero-section .quick-form.form-half .btn { height: 52px; font-size: 1.0625rem; }

  .hero-section .text-center .quick-form-msg { margin: 20px 0 0 0; }

  /* HERO DIGITS */
  #hd-1-1, #hd-1-2 { width: 100%; padding: 0; } 
  #hd-1-1 { margin-bottom: 20px; } 
  .hero-digits h2.statistic-number { font-size: 2.85rem; }
  .hero-digits h2.statistic-number small { font-size: 2.15rem; margin-left: 3px; }

  /* HERO-1 */
  .hero-1-txt h2 { font-size: 1.925rem; }
  .hero-1-txt p.p-lg, .hero-1-txt p.p-xl { margin: 18px 0 22px; }
  .hero-1-txt .btn.btn-md { display: block; padding: 1rem 1.5rem; margin: 0 8%; }
  .hero-1-txt .star-rating small { display: block; }
  .hero-1-img { margin: 35px 0 0; }

  /* HERO-2 */
  #hero-2 { padding-top: 70px; }
  #hero-2:after { height: 7%; }
  .hero-2-txt h2 { font-size: 2.3rem; padding: 0 2%; }
  .hero-2-txt p.p-lg { padding: 0 2%; margin-top: 15px; margin-bottom: 25px; }
  .hero-2-img { margin-top: 35px; }

  /* HERO-3 */
  #hero-3 { padding-top: 70px; padding-bottom: 70px; }
  .hero-3-txt h2 { font-size: 2.3rem; }
  .hero-3-txt p.p-md { margin-top: 12px; }
  .hero-3-txt .users { margin-top: 22px; }
  .hero-3-img { margin-top: 35px; }

  /* HERO-4 */
  #hero-4 { margin-bottom: 65px; }
  .hero-4-txt h2 { font-size: 2rem; padding: 0 2%; }
  .hero-4-txt p.p-lg { margin: 14px 0 22px; padding: 0 5%; }
  .hero-4-img { margin: 35px 0 -40px; }

  /* HERO-5 */
  #hero-5:after { height: 96%; left: 0; }
  .hero-5-txt h2 { font-size: 2.25rem; }
  .hero-5-txt p.p-md { margin: 15px 0 25px; padding: 0 2%; }
  .hero-5-img { margin: 40px 0 0; }

  /* HERO-6 */
  #hero-6 { padding-top: 70px; padding-bottom: 75px; }
  .hero-6-txt h2 { font-size: 2.15rem; }
  .hero-6-txt p.p-lg { margin-top: 15px; }
  #hero-6 .quick-form { margin: 20px 3% 0; }
  .hero-6-img { margin-top: 35px; }

  /* HERO-7 */
  #hero-7 { padding-top: 60px;  background-position: top center; }
  .hero-7-txt h2 { font-size: 2rem; }
  .hero-7-txt p.p-lg, .hero-7-txt p.p-xl { margin: 14px 0 20px; padding: 0 3%; }
  #hero-7 .users { margin-top: 25px; }
  #hero-7 .users img { max-height: 46px; }
  #hero-7 .users p { font-size: 1rem; top: 0; left: 0; margin-top: 12px; }
  .hero-7-img { margin: 30px 2% 0; }

  /* HERO-8 */
  .hero-8-txt h2 { font-size: 2.3rem; padding: 0 2%; }
  .hero-8-txt p.p-lg { padding: 0; margin-top: 15px; margin-bottom: 25px; }
  .hero-8-txt p.btn-txt { margin-top: 20px; }
  #hero-8 .quick-form { margin: 25px 6% 0; }

  /* HERO-9 */
  #hero-9 { padding-top: 70px; padding-bottom: 70px; }
  #hero-9 .wave-shape-bottom svg { height: 45px; }
  .hero-9-txt h2 { font-size: 2rem; }
  .hero-9-txt p.p-lg { margin-top: 15px; padding: 0 5%; }
  #hero-9 .quick-form { margin: 20px 6% 0; }
  .hero-9-img { margin-top: 35px; }

  /* HERO-10 */
  #hero-10 { margin-bottom: 40px; }
  #hero-10 .container { max-width: 100%; }
  .hero-10-txt { margin-bottom: 45px; }
  .hero-10-txt h2 { font-size: 2.3rem; padding: 0 5%; }
  .hero-10-txt p.p-lg { padding: 0 2%; margin-top: 15px; margin-bottom: 25px; }

  .hero-10-wrapper { overflow: visible; margin: 0 -15px; border-radius: 0 0!important; }
  #hero-10 .hero-overlay { position: relative; padding: 65px 25px 0; }
  .hero-10-img { margin-bottom: -40px; }
  .hero-10-img .video-btn { top: calc(50% - 2px); }

  /* HERO-11 */
  .hero-11-txt h2 { font-size: 2rem; }
  .hero-11-txt p.p-md { margin: 14px 0 25px; padding: 0; }
  .hero-11-img { margin: 35px 0 0; }

  /* HERO-12 */
  #hero-12 { padding-top: 0; }
  #hero-12 .container { max-width: 100%; }
  .hero-12-wrapper { padding: 70px 25px; margin: 0 -15px; border-radius: 0 0!important; }
  .hero-12-txt h2 { font-size: 1.925rem; padding: 0 6%; }
  .hero-12-txt p.p-lg { margin: 15px 0 25px; }
  .hero-12-img { margin-top: 35px; }

  /* HERO-13 */
  .hero-13-txt h2 { font-size: 2.1875rem; padding: 0 2%; }
  .hero-13-txt p.p-xl { padding: 0 3%; margin-top: 15px; }
  #hero-13 .quick-form { margin: 25px 6% 0; }
  .hero-13-txt p.btn-txt { margin-top: 20px; }

  /* HERO-14 */
  #hero-14 { padding-top: 70px; }
  #hero-14:after { height: 7%; }
  .hero-14-txt .section-id { padding: 11px 25px; font-size: 0.9rem; margin-bottom: 30px; }
  .hero-14-txt h2 { font-size: 2rem; }
  .hero-14-txt p.p-md { margin: 16px 0 28px; }
  .hero-14-img { margin-top: 35px; }

  /* HERO-15 */
  .hero-15-txt h2 { font-size: 2.3rem; }
  .hero-15-txt p.p-lg { padding: 0; margin-top: 15px; margin-bottom: 25px; }

  /* HERO-16 */
  #hero-16 { padding-top: 70px; padding-bottom: 75px; }
  .hero-16-txt h2 { font-size: 2rem; }
  .hero-16-txt p.p-lg { margin-top: 15px; }
  #hero-16 .quick-form { margin: 20px 3% 0; }
  #hero-16 .users { margin-top: 30px; }
  .hero-16-img { margin-top: 35px; }

  /* HERO-17 */
  #hero-17:after { height: 7%; }
  .hero-17-txt h2 { font-size: 2rem; padding: 0 2%; }
  .hero-17-txt p.p-xl { padding: 0 4%; margin-top: 15px; }
  .hero-17-txt .btns-group { margin-top: 22px; }
  .hero-17-img { margin-top: 40px; }

  /* HERO-18 */
  #hero-18 { padding-top: 75px; }
  .hero-18-txt { margin-bottom: 45px; }
  .hero-18-txt .section-id { margin-bottom: 25px; }
  .hero-18-txt h2 { font-size: 1.925rem; }
  .hero-18-txt p.p-xl { padding: 0; margin-top: 12px; }
  .hero-18-txt .btns-group { padding: 0 3%; margin-top: 22px; }
  .hero-18-txt .btns-group .btn { width: 100%; margin: 0; }
  .hero-18-txt .btns-group .btn:first-child { margin-bottom: 15px; }

  .h18-1 { display: none }
  .h18-2 { margin-bottom: 24px; }

  .hero-bg-shape { width: 220%; height: 100%; left: -32%; right: -32%; opacity: .3; }

  /* HERO-19 */
  .hero-19-txt h2 { font-size: 2.1875rem; padding: 0 2%; }
  .hero-19-txt p.p-xl { padding: 0 3%; margin-top: 15px; }
  #hero-19 .quick-form { margin: 25px 6% 0; }
  .hero-19-txt p.btn-txt { margin-top: 20px; }
  .hero-19-img { margin-top: 35px; }

  /* HERO-20 */
  #hero-20 .caption { padding: 0 3%; }
  #hero-20 .caption h2 { font-size: 2rem; padding: 0; }
  #hero-20 .caption p.p-xl { font-size: 1.0625rem; padding: 0; margin-top: 12px; }
  #hero-20 .caption .btn { margin-top: 24px; }

  /* HERO-21 */
  #hero-21:after { height: 10%; }
  .hero-21-shape { width: 110%; top: 4%; left: -5%; opacity: .8; }
  .hero-21-txt h2 { font-size: 2rem; padding: 0 2%; }
  .hero-21-txt p.p-xl { padding: 0 4%; margin-top: 15px; }
  .hero-21-txt .btns-group { margin-top: 22px; }
  .hero-21-img { margin-top: 40px; }

  /* HERO-22 */
  #hero-22 .caption h2 { font-size: 2rem; padding: 0; }
  #hero-22 .caption p.p-xl { font-size: 1.0625rem; padding: 0; margin-top: 12px; }
  #hero-22 .caption .btn { margin-top: 24px; }

  /* HERO-23 */
  #hero-23 { padding-top: 70px; }
  .hero-23-txt h2 { font-size: 2.25rem; padding: 0 2%; }
  .hero-23-txt p.p-lg { padding: 0 2%; margin-top: 15px; margin-bottom: 25px; }
  .hero-23-img { margin-top: 35px; }

  /* HERO-24 */
  #hero-24 { margin-bottom: 50px; }
  .hero-24-txt h2 { font-size: 2.3rem; padding: 0 5%; }
  .hero-24-txt p.p-lg { margin-top: 12px; }
  .hero-24-txt .users { margin-top: 25px; }

  #hero-24-form { padding: 30px 22px; margin: 50px 2% -50px; }
  #hero-24-form h5 { font-size: 1.5rem; margin-bottom: 12px; }
  #hero-24-form p { margin-bottom: 16px; }
  #hero-24-form p.p-sm { padding: 0!important; }
  #hero-24-form .btn { height: 52px; font-size: 1.0625rem; padding: 16px; }

  /* HERO-25 */
  #hero-25 { padding-top: 70px; }
  .hero-25-txt h2 { font-size: 2.15rem; }
  .hero-25-txt p.p-lg { padding: 0 2%; margin-top: 15px; margin-bottom: 25px; }
  .hero-25-img { margin-top: 35px; }

  /* HERO-26 */
  .hero-26-txt h2 { font-size: 2rem; }
  .hero-26-txt p.p-lg { padding: 0 2%; margin-top: 15px; margin-bottom: 25px; }
  .hero-26-img { margin: 45px -20% 0; }

  /* HERO-27 */
  .hero-27-txt h2 { font-size: 1.85rem; margin-bottom: 22px; }
  .hero-27-txt p { margin-top: 22px; margin-bottom: 30px; }

  #hero-27 .img-link { bottom: 12px; left: 12px; width: 50px; height: 50px; }
  #hero-27 .img-link a span { top: 5px; }

  /* HERO REQUEST FORM */
  .request-form .form-control { height: 52px; font-size: 1.0625rem; margin-bottom: 15px; }
  
  /*------------------------------------------*/
  /*  CONTENT
  /*------------------------------------------*/

  /* ABOUT */
  #a3-1 { margin-bottom: 20px; }

  .about-1-img { margin: 0 -32%; }

  /* FEATURES */
  #features-3.py-100.shape--bg:after, #features-4.py-100.shape--bg:after { height: 67%; top: 33%; }
  #features-3.pt-100.shape--bg:after { height: 64%; top: 34.2%; }
  #features-4.pt-100.shape--bg:after { height: 65%; top: 33%; left: 0; }

  #features-3 .txt-block, #features-4 .txt-block { margin-bottom: 30px; }

  .fb-1, .fb-2, .rows-2 .fb-1, .rows-2 .fb-2, .rows-2 .fb-3, .rows-2 .fb-4, .rows-2 .fb-5,.row-cols-md-2 .fb-3, 
  .rows-3 .fb-1, .rows-3 .fb-2, .rows-3 .fb-3, .rows-3 .fb-4, .rows-3 .fb-5, .rows-4 .fb-1, .rows-4 .fb-2, .rows-4 .fb-3, 
  .rows-4 .fb-4, .rows-4 .fb-5, .rows-4 .fb-6,#fb-3-1, #fb-3-2, #fb-3-3, #fb-4-1, #fb-4-2, #fb-4-3, .fbox-10.fb-1, 
  .fbox-9.fb-1, .fbox-9.fb-2, .fbox-9.fb-4, .fbox-9.fb-5, .fbox-9.fb-7, .fbox-9.fb-8, .fbox-10.fb-3, .fbox-10.fb-5, 
  .fbox-10.fb-7, .fbox-11.fb-1, .fbox-11.fb-2 { margin-bottom: 35px; }

  .rows-4 .fbox-2.fb-1, .rows-4 .fbox-2.fb-2, .rows-4 .fbox-2.fb-3, .rows-4 .fbox-2.fb-4, 
  .rows-4 .fbox-2.fb-5, .rows-4 .fbox-2.fb-6 { margin-bottom: 35px; }

  .fbox-11.fb-1, .fbox-11.fb-2 { margin-bottom: 25px; }

  .rows-4 .fb-8 { margin-top: 35px; }
  .fbox-6.fb-4, #features-12 .txt-block { margin-bottom: 25px; }
  .fbox-12.fb-1, .fbox-12.fb-2, .fbox-12.fb-3 { margin-bottom: 22px; }

  .fbox-3, .fbox-4, .fbox-6 { 
    padding: 35px 30px;
    -webkit-box-shadow: 0 4px 12px 0 rgba(0, 0, 0, .08); 
    -moz-box-shadow: 0 4px 12px 0 rgba(0, 0, 0, .08); 
    box-shadow: 0 4px 12px 0 rgba(0, 0, 0, .08); 
  }

  .fbox-5 { padding: 35px 30px; } 
  .fbox-6 { text-align: center; padding: 30px 25px; }

  .fbox-9 { padding: 35px 30px; }
  .ico-title .fbox-9.fb-1, .ico-title .fbox-9.fb-2, .ico-title .fbox-9.fb-3 { padding: 30px; }
  .fbox-9.fb-4, .fbox-9.fb-5, .fbox-9.fb-6, .ico-title .fbox-9.fb-4, 
  .ico-title .fbox-9.fb-5, .ico-title .fbox-9.fb-6 { padding: 30px 30px 0; }

  .fbox-10, .fbox-10.fb-5, .fbox-10.fb-6, .fbox-10.fb-7, .fbox-10.fb-8 { padding: 35px 25px 30px; } 
  .ico-title .fbox-10.fb-5, .ico-title .fbox-10.fb-6,
  .ico-title .fbox-10.fb-7, .ico-title .fbox-10.fb-8 { padding: 30px 25px 25px; } 

  .fbox-img.h-175 img, .fbox-img.h-180 img { max-height: 170px; } 

  .fbox-7 .fbox-img { margin-bottom: 25px; }
  .fbox-8 .fbox-img.h-175, .fbox-8 .fbox-img.h-180 { padding-top: 30px; margin: 0 18px 45px; } 

  .fbox-9.fb-1 .fbox-9-img, .fbox-9.fb-2 .fbox-9-img, .fbox-9.fb-3 .fbox-9-img { margin: 25px 0 0; }
  .fbox-9.fb-4 .fbox-9-img, .fbox-9.fb-5 .fbox-9-img, .fbox-9.fb-6 .fbox-9-img { margin: 25px 0 -18px; }
  .fbox-9.fb-7 .fbox-9-img, .fbox-9.fb-8 .fbox-9-img, .fbox-9.fb-9 .fbox-9-img { margin: 0 0 25px; }

  .fbox-10.fb-1 .fbox-10-img, .fbox-10.fb-2 .fbox-10-img, 
  .fbox-10.fb-3 .fbox-10-img, .fbox-10.fb-4 .fbox-10-img { margin: 0 0 20px; }
  .fbox-10.fb-7 .fbox-10-img, .fbox-10.fb-8 .fbox-10-img { margin: 20px 0 0; }
  .fbox-10.fb-5 .fbox-10-img, .fbox-10.fb-6 .fbox-10-img { margin: 0 0 20px; }
  .ico-title .fbox-10.fb-5 .fbox-10-img, .ico-title .fbox-10.fb-6 .fbox-10-img { margin: 25px 0 0; }

  .fbox-ico, .fbox-ico.ico-rounded { margin-bottom: 15px; }
  .fbox-5 .fbox-ico, .fbox-6 .fbox-ico { margin-bottom: 20px; }
  .fbox-ico[class*="img-"], .fbox-ico.ico-rounded-md, .fbox-ico.ico-rounded-lg { margin-bottom: 16px; }
  .t-icon { margin-bottom: 2px; }
  .t-icon.ico-25 [class*="flaticon-"]:before, .t-icon.ico-25 [class*="flaticon-"]:after { font-size: 1.625rem; }
  .fbox-11-ico { margin-bottom: 14px; }

  .fbox-wrapper .ico-50 [class*="flaticon-"]:before, .fbox-wrapper .ico-50 [class*="flaticon-"]:after,
  .fbox-wrapper .ico-55 [class*="flaticon-"]:before, .fbox-wrapper .ico-55 [class*="flaticon-"]:after,
  .fbox-wrapper .ico-60 [class*="flaticon-"]:before, .fbox-wrapper .ico-60 [class*="flaticon-"]:after { font-size: 3.7rem; }
  .fbox-ico-wrap .ico-40 [class*="flaticon-"]:before, .fbox-ico-wrap .ico-40 [class*="flaticon-"]:after { font-size: 3.15rem; }

  .fbox-3 h6, .fbox-4 h6 { margin-top: 18px; margin-bottom: 10px; }
  .fbox-2 h5, .fbox-wrapper .row-cols-md-2 h6, 
  .fbox-wrapper .row-cols-md-3 h6, .fbox-10 h5, .fbox-6 h6, .fbox-9 h6, .fbox-12 h5 { margin-bottom: 12px; }
  .fbox-10.fb-5 h5, .fbox-10.fb-6 h5, .ico-title .fbox-10.fb-5 h5, .ico-title .fbox-10.fb-6 h5 { margin-bottom: 14px; }
  .fbox-11 h6 { margin-bottom: 12px; }

  .fbox-1 p { padding: 0 2%; }
  .fbox-7 p, .fbox-8 p { padding: 0 4%; }

  .fbox-5 .txt-block-tra-link { margin-top: 14px; }

  /* CONTENT */
  .bc-02.pt-30.ws-wrapper, .bc-02.pb-30.ws-wrapper { padding-top: 35px; }
  .bc-02.pt-30.bc-02-top { padding-top: 20px; margin-top: 50px; }
  .bc-02 .container { max-width: 100%; }
  .bc-02.pt-30 .container, .bc-02.pb-30 .container { max-width: auto; }
  .bc-02-wrapper { margin: 0 -15px; border-radius: 0 0!important; }
  .bc-02.pt-30 .bc-02-wrapper, .bc-02.pb-30 .bc-02-wrapper { margin: 0; border-radius: 12px!important; }

  .bc-03 .section-title p { font-size: 1.03125rem; padding: 0; margin-top: 12px; }

  .ct-05.pt-100, .ct-06 .section-overlay { margin-bottom: 40px; }
  .ct-05.pt-100.ct-05-img { margin-bottom: 0; }
  .bc-01 .section-overlay, .bc-02 .section-overlay { padding: 70px 22px; }
  .bc-02 .ico-title .section-overlay { padding: 70px 22px; }
  .bc-02.pt-30 .section-overlay, .bc-02.pb-30 .section-overlay { padding: 30px 28px; }
  .bc-03 .section-overlay { padding: 70px 22px 0; }
  
  .ct-02-top { margin-bottom: 40px; }
  .txt-box, .bc-01 .txt-box { margin-bottom: 15px; }
  .bc-04-box { 
    padding: 30px 26px; 
    -webkit-box-shadow: 0 4px 12px 0 rgba(0, 0, 0, .08); 
    -moz-box-shadow: 0 4px 12px 0 rgba(0, 0, 0, .08); 
    box-shadow: 0 4px 12px 0 rgba(0, 0, 0, .08); 
  }

  .cbox-6 { text-align: center; }

  .two-txt .txt-block.left-column { margin-bottom: 35px; }
  .two-txt .cbox-3, .two-txt .cbox-3 .ico-wrap { text-align: left!important; }

  .txt-block h3, .txt-block h2 { margin-bottom: 16px; }
  .txt-block h5 { margin-bottom: 14px; }
  h5.h5-title { margin: 14px 0; }

  .bc-02 .txt-block h5 { padding-right: 5px; }
  .bc-04-box h2 { font-size: 2.75rem; margin-bottom: 12px; }
  .bc-04-box h2 small, .bc-04-box h2 span { font-size: 2.15rem; }
  .cbox-5 p.p-sm { font-size: 1rem; }

  .txt-block .btn { margin-top: 20px; }
  .txt-block-tra-link { margin-top: 16px; }

  .cbox-1 .ico-wrap { margin-right: 1rem; }
  .cbox-1-ico { width: 1.85rem; height: 1.85rem; font-size: 1rem; line-height: 1.45rem; margin-top: 5px; }

  .cbox-3 .cbox-3-ico { margin-bottom: 10px; }
  .cbox-3-ico span[class*="flaticon-"]:before, .cbox-3-ico span[class*="flaticon-"]:after { font-size: 3.25rem; }

  .cbox-title { margin-bottom: 10px; }
  .cbox-title span {display: block; float: none; line-height: 1!important; margin: 0 0 16px 0; }
  .cbox-title span[class*="flaticon-"]:before, .cbox-title span[class*="flaticon-"]:after { font-size: 3.25rem; }

  .cbox-1-txt { margin-bottom: 20px; }
  .cbox-3-txt { margin-bottom: 30px; }
  .cbox-6-txt { margin-bottom: 30px; }
  .cbox-1-txt h5, .cbox-3-txt h5 { margin-bottom: 10px; }

  .cbox-4 { width: 340px; min-width: 340px; padding: 12px 16px; }
  .cbox-4.mt-20 { margin-top: 8px; }
  .cbox-4.mb-30 { margin-bottom: 20px; }
  .cbox-4-ico span { position: relative; top: 5px; }
  .cbox-4-txt { padding-left: 11px; }

  .cbox-5-wrapper { margin-top: 0; }
  .box-title { margin-bottom: 12px; }
  .cbox-5.cb-2 { margin-top: 15px; }
  .box-title span { top: 10px; left: 3px; right: 0; }
  .box-title h6 { position: relative; top: 2px; left: 8px; }

  .txt-block .accordion-1 .accordion-item { padding: 22px 0 27px; }
  .txt-block .accordion-1 .accordion-item .accordion-thumb:after,
  .txt-block .accordion-1 .accordion-item.is-active .accordion-thumb:after { font-size: 0.935rem; top: 0; right: 5px; }

  .txt-block .accordion-1 .accordion-thumb h5 { font-size: 1.35rem; padding-left: 0; }
  .txt-block .accordion-1 .accordion-item.is-active .accordion-thumb h5 { margin-bottom: 18px; }
  .txt-block .accordion-1 .accordion-panel { padding: 0; }

  /* Advantages */
  .advantages, .text-center .advantages { margin-top: 15px; }
  .img-block-btn .advantages li { display: block!important; margin-top: 4px; }
  .advantages li p { font-size: 0.95rem; }
  .img-block-btn .advantages li p { display: block; float: none; }
  .img-block-btn li.advantages-links-divider { display: none!important; }

  /* Compatibility */
  .compatibility { margin-top: 10px; }

  /* Tools List / Payments Icons */
  .tools-list p, .payment-methods p { margin-bottom: 12px; }
  .tools-list span { padding-right: 1px; }
  .payment-icons img { max-height: 30px; }

  .tools-list.ico-35 [class*="flaticon-"]:before, 
  .tools-list.ico-35 [class*="flaticon-"]:after { font-size: 2.125rem; line-height: 2.125rem!important; margin-right: 4px; }

  /* Content Images */
  .content-section .container.hidd { overflow: visible!important; }
  .img-block-hidden, .ct-06 .img-block { margin-bottom: -40px; }
  .img-block-txt { margin-top: 35px; }
  .img-block-btn { padding: 0 8%; margin-top: 35px; }
  .img-block-btn .btn { width: 100%; }

  .bc-3-img .video-btn { top: calc(50% - 25px); }

  .ct-01 .img-block.right-column, .ct-02 .img-block.right-column, .ct-03 .img-block.right-column,
  .ct-01 .img-block.left-column, .ct-02 .img-block.left-column, .ct-03 .img-block.left-column { margin: 0 0 35px; }

  .bc-01 .img-block { margin-bottom: 35px; }
  .bc-2-img.right-column, .bc-2-img.left-column { margin-bottom: 35px; }
  .bc-3-img { margin-bottom: -60px; }
  
  .img-content.py-100 { padding: 35px 0; }

  /* STATISTIC */
  .statistic-4-wrapper { padding: 0 8%; }
  .statistic-7-wrapper { padding: 65px 40px; }
  .statistic-8-wrapper { padding: 0 5%; }
  .statistic-7-wrapper .col-lg-4 { margin-bottom: 30px; }
  .txt-block-stat.mt-30 { margin-top: 20px; }
  
  #statistic-6 .statistic-block-txt:before { width: 75px; margin: 18px auto 16px; }

  #sb-1-1, #sb-1-2, #sb-2-1, #sb-5-1, #sb-5-2, 
  #sb-5-3, #sb-6-1, #sb-6-2, #sb-7-1, #sb-7-2, #sb-9-1, #sb-9-2 { margin-bottom: 35px; }

  #sb-3-2 { padding-left: 4%; }
  .statistic-4-wrapper .statistic-block { padding: 0; }
  #sb-4-1, #sb-4-2, #sb-8-1, #sb-8-2 { padding: 0 5% 35px; margin-bottom: 35px; }

  h3.statistic-number { font-size: 2.45rem; line-height: 1; letter-spacing: -1px; }
  h2.statistic-number { font-size: 2.75rem; line-height: 1; letter-spacing: -1px; }
  .statistic-2-wrapper h2,  #sb-3-1 h2.statistic-number, #sb-3-2 h2.statistic-number { margin-bottom: 12px; }
  .statistic-4-wrapper h5 { margin-top: 20px; margin-bottom: 12px; }
  .statistic-5-wrapper h3 {font-size: 2.25rem; line-height: 1; }
  #statistic-6 h2.statistic-number { font-size: 3.55rem; letter-spacing: -2px; }
  #statistic-6 h2.statistic-number small { font-size: 2.1875rem; margin-left: 4px; }
  .statistic-9-wrapper h3, .statistic-9-wrapper h2 { margin: 20px 0 12px; }

  .statistic-5-wrapper p { margin-top: 12px; }
  .statistic-8-wrapper p { margin-top: 15px; }

  #sb-2-1 br { display: none; }
  #sb-2-2 { clear: both; }
  #sb-2-2 .txt-block-rating { display: inline-block; }
  #sb-2-2 p { display: inline-block; margin-left: 5px; }

  .statistic-8-wrapper img { max-height: 42px; margin-top: 16px; }
  .statistic-section .action-btn { margin-top: 40px; }

  /* PROJECTS */
  #portfolio-1.pb-70 { padding-bottom: 30px; }

  #portfolio-1 .filter-btns.mb-70 { margin-bottom: 25px; }
  #portfolio-1 .filter-item { margin-bottom: 30px; }

  .project-category { left: 20px; }
  .project-category p { padding: 7px 16px; margin-bottom: 0; }

  .project-data-txt { bottom: 34px; }
  .project-data-txt h5 { padding-right: 15%; }

  .project-source { width: 35px; height: 35px; bottom: 20px; }
  .project-source a span { top: 3px; }

  .filter-item:hover .project-data-txt { left: 25px; }
  .filter-item:hover .project-source { right: 20px; }

  #portfolio-1 .more-btn { margin-top: 15px; }

  /* PROJECT DETAILS */
  .project-title { padding: 0; margin-bottom: 30px; }
  .project-title p { margin-top: 15px; }
  .project-data { padding-bottom: 18px; margin: 25px 0 25px; }
  .project-data p { margin-bottom: 7px; }

  .project-txt h5 { margin: 25px 0 14px; }
  .project-txt h6 { font-size: 1.215rem; margin: 14px 0; }

  .project-inner-img { margin: 30px 0; }
  .project-inner-img.d-flex { margin-left: -12px; margin-right: -12px; }
  .project-inner-img .top-img { margin-bottom: 30px; }

  #project-details .more-projects { margin-top: 35px; }
  #project-details .more-projects span { top: 2px; left: 2px; }
  #project-details .more-projects.ico-25 [class*="flaticon-"]:before, 
  #project-details .more-projects.ico-25 [class*="flaticon-"]:after { font-size: 1.125rem; } 

  /* TEAM */
  .team-member-data h6 { font-size: 1.375rem; }
  .team-member-data p { font-size: 1.0625rem; }

  /* CAREERS */
  .position-data { margin-bottom: 4px; }
  .position-data p { font-size: 1.125rem; }

  .career-item .col-sm-8 { display: inline-block; width: 60%; float: left; }
  .position-workplace {text-align: left; }
  .position-workplace h6 { font-size: 1.125rem; }

  .career-item .col-sm-4 { display: inline-block; width: 40%; float: none; }

  /* JOB POSITION */
  #job-position .inner-page-title { padding-bottom: 45px; }
  #job-position .inner-page-title p { margin: 12px 0 10px; padding: 0; }
  #job-position .inner-page-title .btn { font-size: 0.9375rem; padding: 0.7rem 1.1rem; margin-top: 5px; }

  .position-info .btn { margin-top: 16px!important; }

  /* PRICING */
  .p-table { 
    -webkit-box-shadow: 0 4px 12px 0 rgba(0, 0, 0, .08); 
    -moz-box-shadow: 0 4px 12px 0 rgba(0, 0, 0, .08); 
    box-shadow: 0 4px 12px 0 rgba(0, 0, 0, .08); 
  }

  .pricing-1-table, .text-center .pricing-1-table { padding: 30px 30px 30px; }

  .pricing-table-header h5 { font-size: 1.5625rem!important; }

  .pricing-discount { top: 0; right: -2px; padding: 6px 11px; }
  .pricing-1-table .pricing-discount { top: -4px; right: 0; padding: 7px 13px; }
  .text-center .pricing-1-table .pricing-discount { top: -32px; right: -5px; }
  .pricing-discount h6 { font-size: 1.05rem; }
  .pricing-1-table .pricing-discount h6 { font-size: 0.9375rem; }

  .pt-btn { font-size: 1.0625rem; padding: 0.925rem 1.3rem; }
  .p-table p.btn-txt { font-size: 1rem; margin: 12px 0 0 0; }

  .price span { font-size: 2.75rem; } 
  .price sup { font-size: 2rem; top: -5px; }
  .price sup.validity { font-size: 1.65rem; }

  .text-center .price p { padding: 0; }

  /* Pricing Features */
  .pricing-features.mt-25 { margin-top: 20px; }
  .pricing-features li, .pricing-4-table .pricing-features li { padding: 10px 8px; }

  /* Pricing Notice */
  .pricing-notice.mb-40 { margin-bottom: 30px; }
  .col-lg-9 .pricing-notice p, .col-lg-10 .pricing-notice p { padding: 0 ; }

  /* Pricing Toggle Button */
  .toggle-btn-md .toggler-txt, .toggle-btn-lg .toggler-txt { font-size: 1.15rem; }

  /* Pricing Compare */
  .table-responsive.mb-50 { margin-bottom: 30px; }
  .table>:not(caption)>*>* { padding: 11px 0 9px; }
  .comp-table .table-responsive thead th { font-size: 0.9375rem; }
  .comp-table .table-responsive tbody th { font-size: 0.915rem; line-height: 1.25; }
  .comp-table .table-responsive tbody td { font-size: 0.9rem; top: 0; line-height: 1.25; }

  .comp-table .table-responsive tbody td span { top: 3px; }

  .table-responsive .ico-15 [class*="flaticon-"]:before, 
  .table-responsive .ico-15 [class*="flaticon-"]:after { font-size: 0.675rem; }

  .table-responsive .ico-20 [class*="flaticon-"]:before, 
  .table-responsive .ico-20 [class*="flaticon-"]:after { font-size: 0.835rem; }

  .pbox { padding: 0; }
  #pbox-1, #pbox-2, #pbox-3 { margin-bottom: 30px; }
  .pricing-section .txt-box-last, .pricing-section .pm-last { margin-bottom: 40px; }
  .comp-table-payment h6 { margin-bottom: 15px; }

  /* RATING */
  #rb-2-1, #rb-2-2 { margin-bottom: 25px; }

  .rating-title { padding: 0 10%; }
  .rating-title h5 { font-size: 1.125rem; }
  .rbox-1-img { margin-bottom: 18px; }
  .rbox-1-img img { max-height: 32px; }
  .rbox-2-txt img { max-height: 28px; }
  p.rbox-2-txt { font-size: 1.1rem; line-height: 28px; }

  .rbox-1 .star-rating { padding: 5px 16px; font-size: 1rem; line-height: 1; }

  .rbox-1 .star-rating span { top: -2px; }
  .rbox-1 .star-rating.ico-15 [class*="flaticon-"]:before, 
  .rbox-1 .star-rating.ico-15 [class*="flaticon-"]:after { font-size: 1rem; } 
  .rbox-2 .ico-20 [class*="flaticon-"]:before, .rbox-2 .ico-20 [class*="flaticon-"]:after { font-size: 1.35rem; } 

  /* TESTIMONIALS */
  .reviews-3-wrapper, .reviews-4-wrapper { padding: 70px 8%; }

  #reviews-3 .txt-block { margin-bottom: 40px; }

  .review-1, .review-5 { 
    padding: 20px 20px 25px;
    -webkit-box-shadow: 0 4px 12px 0 rgba(0, 0, 0, .08); 
    -moz-box-shadow: 0 4px 12px 0 rgba(0, 0, 0, .08); 
    box-shadow: 0 4px 12px 0 rgba(0, 0, 0, .08); 
  }
  .review-2 { padding: 0; }
  .review-3 { 
    text-align: center; 
    padding: 30px 20px; 
    -webkit-box-shadow: 0 4px 12px 0 rgba(0, 0, 0, .08); 
    -moz-box-shadow: 0 4px 12px 0 rgba(0, 0, 0, .08); 
    box-shadow: 0 4px 12px 0 rgba(0, 0, 0, .08); 
  }

  .review-5-logo { margin-left: 0; margin-bottom: 35px; }
  .review-5-logo img { max-height: 42px; }
  
  .review-1 .review-ico, .review-5 .review-ico { top: 10px; right: 20px; }
  .review-1 .review-rating, .review-5 .review-rating { margin-bottom: 8px; }

  .review-rating.ico-15 [class*="flaticon-"]:before, 
  .review-rating.ico-15 [class*="flaticon-"]:after,
  .review-1 .review-rating.ico-15 [class*="flaticon-"]:before, 
  .review-5 .review-rating.ico-15 [class*="flaticon-"]:before, 
  .review-1 .review-rating.ico-15 [class*="flaticon-"]:after, 
  .review-5 .review-rating.ico-15 [class*="flaticon-"]:after { font-size: 1.1rem; }

  .review-2-txt h5 { padding: 0 10%; margin: 10px 0; }
  .review-3-txt h6.h6-md, .review-3-txt h6.h6-lg { margin: 5px 0 10px; }
  #reviews-3 .txt-block h2 { margin-bottom: 12px; padding: 0 7%; }
  #reviews-3 .txt-block p { margin-top: 0; padding: 0 4%; }
  #reviews-3 .txt-block .btn { margin-top: 18px; }

  .review-3 .review-avatar { margin: 16px auto 14px; float: none; }

  .review-1 .review-author, .review-5 .review-author { margin-top: 15px; }
  .review-2 .review-author { margin-top: 20px; }
  .review-3 .review-author { text-align: center; padding: 0; margin-top: 0; }

  .review-author h6 { font-size: 1.175rem; margin-bottom: 12px; }
  .review-1 .review-author h6, .review-5 .review-author h6 { font-size: 1.135rem; margin-bottom: 12px; }
  .review-author p, .reviews-4-txt .review-author p { font-size: 1.0625rem; }
  .reviews-section p.p-sm { font-size: 1rem; }

  .review-5-avatar { right: 20px; bottom: 25px; }
  .review-5-avatar img { width: 44px; height: 44px; }

  .reviews-4-img { width: 130px; height: 130px; margin: 0 auto 10px; }
  .reviews-4-txt .review-author, .review-5 .review-author.mt-30 { margin-top: 20px; }

  /* BRANDS */
  .brands-2-wrapper, .brands-3-wrapper { padding: 0 12px; }
  .brands-2-wrapper .col, .brands-3-wrapper .col { padding: 0; }

  .brands-title h6 { font-size: 1rem; line-height: 1.55; padding: 0; margin-bottom: 25px; }
  .brands-title h5 { font-size: 1.325rem; margin-bottom: 30px; }
  .brands-title p { padding: 0 5%; margin-bottom: 25px; }

  #brands-4 .txt-block { margin-bottom: 30px; }

  #brand-2-1, #brand-2-2, #brand-2-3, #brand-3-1, 
  #brand-3-2, #brand-3-3, #brand-3-4, #brand-3-5, #brand-3-6 { margin-bottom: 35px; }
  #brand-3-10 { display: none; }
  #brand-4-1, #brand-4-2, #brand-4-3, #brand-4-4, #brand-4-5, #brand-4-6 { margin-bottom: 26px; }

  .brands-carousel-5 .brand-logo, .brands-carousel-6 .brand-logo, #brands-2 .brand-logo, #brands-3 .brand-logo {padding: 0 10px; } 
  #brands-4 .brand-logo { padding: 0 4px; } 

  .brands-section .more-btn { margin-top: 30px; }

  /* INTEGRATIONS */
  #integrations-4.pt-100.pb-70 { padding-bottom: 54px; }

  .int-4-wrapper .col { padding: 0 8px; }

  .filter-btns button { font-size: 1.1rem; }

  .int_tool-1 { padding: 18px 22px 16px; margin-bottom: 20px; }
  .int_tool-2 { padding: 18px 26px; }
  .int_tool-3 { padding: 20px; margin-bottom: 20px; }
  .int_tool-4 { padding: 18px 12px; margin-bottom: 16px; }
  .filter-item, .int-2-wrapper .int_tool-2 { margin-bottom: 20px; }

  .int_tool-1 .tool_logo { margin-right: 20px; }
  .int_tool-1 .tool_logo img, .int_tool-3 img, .int_tool-4 img { width: 48px; height: 48px; }

  .tool_txt h6 { font-size: 1.225rem; line-height: 1; margin-top: 3px; }
  .tool_logo h6 { font-size: 1.225rem; }
  .int_tool-4 h6 { font-size: 0.95rem; margin-top: 14px; }
  .tool_txt p { font-size: 1.0625rem; margin-top: 3px; }

  /* BANNER */
  #banner-1 .container { max-width: 100%; } 

  .banner-1-wrapper { padding: 65px 20px 70px; margin: 0 -15px; border-radius: 0 0!important; }
  .banner-2-wrapper .banner-overlay { padding: 60px 30px 70px; }
  #banner-3 .banner-overlay.pt-80.pb-90 { paddingp: 55px 0 65px; } 
  .banner-4-wrapper { padding: 45px 11%; }

  .banner-4-txt { margin-bottom: 12px; }

  .banner-1-txt h4 { font-size: 1.625rem!important; }  
  .banner-2-txt h2, .banner-3-txt h2 { margin-bottom: 10px; }
  .banner-3-txt h2.h2-xl, .banner-3-txt h2.h2-title { margin-bottom: 22px; }

  .banner-section p { margin-bottom: 20px; }
  #banner-1.banner-section p { padding: 0 5%; margin: 15px 0 20px; }
  #banner-2.banner-section p { padding: 0; }
  #banner-2.banner-section .l-txt p { padding: 0 2%; }
  #banner-3.banner-section p.p-xl { padding: 0 8%; }

  .banner-1-txt .btn { display: block; margin: 14px 8% 0; }
  .banner-2-txt .btns-group .btn { display: block; margin-left: 8%; margin-right: 8%; }

  /* DOWNLOAD */
  span.version-data { display: block; font-size: 1.85rem; margin-right: 0; margin-bottom: 12px; }
  span.release-date { display: block; font-size: 1.25rem; }
  span.release-date span { margin-left: 10px; }

  /* FAQs */
  #faqs-1 .section-title { margin-bottom: 25px; } 
  #faqs-2 .section-title { margin-bottom: 35px; } 
  #faqs-3 .section-title { margin-bottom: 30px; }

  #faqs-1.faqs-section .accordion-thumb h5 { line-height: 1.5; }
  #faqs-2 .question h6, #faqs-2 .question h5 { font-size: 1.25rem; margin-bottom: 12px; }
  .accordion-thumb h5 { font-size: 1.25rem; line-height: 1.5; padding-right: 8%; }
  .faqs-3-question h5 { font-size: 1.25rem; margin-bottom: 12px; }

  #faqs-1  .accordion-thumb { padding: 14px 0 18px; }
  #faqs-3.faqs-section .accordion-thumb { padding: 20px 0 25px; }

  .accordion-panel { padding: 0 0 10px 0; }
  #faqs-3 .accordion-panel { padding: 30px 0 15px 0; } 
  #faqs-3 .accordion-panel-item.mb-35 { margin-bottom: 25px; }

  #faqs-1.faqs-section .accordion-item .accordion-thumb:after, 
  #faqs-1.faqs-section .accordion-item.is-active .accordion-thumb:after { font-size: 0.925rem; top: 17px; right: 2px; }

  #faqs-3.faqs-section .accordion-item .accordion-thumb:after, 
  #faqs-3.faqs-section .accordion-item.is-active .accordion-thumb:after { font-size: 0.9rem; top: 25px; right: 2px; }

  .more-questions { margin-top: 5px; }
  #faqs-1 .more-questions { margin-top: 25px; }
  #faqs-3 .more-questions { margin: 25px auto 0; }

  .more-questions h6 { font-size: 1.225rem; }
  .more-questions span { display: block; margin-bottom: 3px; }

  .more-questions-txt { padding: 18px 30px; }
  .more-questions-txt p { font-size: 1.125rem; }
  .more-questions .more-questions-txt a { display: inline-block; }

  /* HELP CENTER */
  .help-category { padding: 35px 30px; margin-bottom: 25px; }
  .help-category h6 { margin: 10px 0; }

  /* HELP CATEGORY */
  .help-category-title { padding: 45px 18px 34px; margin-bottom: 40px; }
  #help-category .breadcrumb-wrapper { margin-bottom: 25px; }
  .help-category-title p { margin-top: 10px; }

  #help-category h4 { margin-bottom: 35px; }
  .help-category-item { padding-bottom: 30px; margin-bottom: 30px; }

  .help-category-item p { margin: 16px 0; }
  .help-category-data span { font-size: 1rem; }

  .read-time, .updated-date { display: block; }
  .read-time { margin-top: 3px; margin-left: 0; }

  /* HELP ARTICLE */
  .breadcrumb-wrapper { display: inline-block!important; margin-left: 55px; margin-bottom: 22px; }
  .breadcrumb { margin-bottom: 0; }

  .help-article-title h3 { margin-bottom: 14px; }
  .help-article-title img { margin: 30px 0; }

  .table-of-contents { padding: 25px 0 32px; margin: 30px 0 25px; }
  .table-of-contents h6 { margin-bottom: 15px; }

  #help-article .txt-block { margin: 30px 0; }
  #help-article .txt-block h6 { font-size: 1.125rem; margin: 22px 0 12px; }

  #help-article .txt-block img { margin: 15px 0 20px; }

  .help-vote-form { margin-top: 40px; }
  .help-vote-form h6 { margin-bottom: 20px; }
  .help-vote-form .btns-group .btn:first-child { margin: 0 0 14px; }
  .help-vote-form .btns-group .btn:last-child { margin: 0; }
  .help-vote-form .btn { width: 100%; }

  /* BLOG */
  .posts-category { margin-top: 0; margin-bottom: 30px; }
  .posts-category span { top: 1.25px; }
  .posts-category.ico-20 [class*="flaticon-"]:before, 
  .posts-category.ico-20 [class*="flaticon-"]:after { font-size: 1.0625rem; } 

  .square-post .blog-post-txt { padding: 15px 25px 35px; }

  /* SINGLE BLOG POST */
  .single-post-title { margin-bottom: 30px; }
  .single-post-title p.p-lg { margin-top: 12px; }

  #single-post .blog-post-meta { margin-bottom: 12px; }

  #single-post .blog-post-img.pb-60 { padding-bottom: 30px; }

  .single-post-txt .blockquote p { font-size: 1.125rem; margin-bottom: 30px; }
  .single-post-txt .blockquote-footer { font-size: 1rem; line-height: 1.5; }

  .post-inner-img { margin: 25px 0; }

  .post-data { text-align: center; margin-top: 60px; padding: 60px 0; }
  .post-author-avatar { float: none; margin-right: 0; margin-bottom: 10px; }
  .post-author-avatar img { width: 58px; height: 58px; }

  .post-author-txt { top: 0; }
  .post-author-txt p { font-size: 1.0625rem; margin-top: 10px; }

  .post-share-list.text-end { text-align: center!important; margin-top: 12px; }
  .post-share-list p { font-size: 1.0625rem; margin-bottom: 10px; }

  .subscribe-banner { padding: 35px 25px; }

  #post-comments img { width: 45px; height: 45px; }
  .comment-body { margin-left: 16px; }
  .comment-meta { margin-bottom: 7px; }
  .comment-meta h6 { font-size: 1.275rem; margin-bottom: 6px; }
  .comment-date, .btn-reply a { font-size: 1rem; } 
  .btn-reply.ico-20 [class*="flaticon-"]:before, .btn-reply.ico-20 [class*="flaticon-"]:after { font-size: 1rem; }

  .comment-form { margin-top: 35px; }
  .comment-form p { margin-bottom: 10px; }

  .comment-form .form-control { margin-bottom: 25px; }
  .comment-form textarea { min-height: 170px; }
  .comment-form .btn { font-size: 1.0625rem; height: 52px; }

  /* NEWSLETTER */
  #newsletter-2 .container { max-width: 100%; } 

  #newsletter-1 .newsletter-overlay { padding: 50px 0 40px; } 
  #newsletter-2 .newsletter-overlay { padding: 65px 30px 55px; margin: 0 -15px; border-radius: 0 0!important; } 

  .newsletter-txt h4, .newsletter-txt h3 { font-size: 1.7rem; padding: 0; margin-bottom: 25px; }
  #newsletter-2 .newsletter-txt p { padding: 0 2%; }

  #newsletter-1 .newsletter-form { padding: 0 5%; }
  #newsletter-2 .newsletter-form { padding: 0 2%; margin-top: 25px; }

  .newsletter-section .form-control, .newsletter-section .btn { height: 54px; font-size: 1.0625rem; }
  #newsletter-2.newsletter-section .btn { font-size: 1.0625rem; }

  /* CONTACTS */
  .contacts-info { text-align: center; }
  .contacts-info p { margin: 14px 0 0; padding: 0; }
  .contacts-info h6 { margin: 20px 0 15px; }
  .contacts-info-socials li { padding-right: 12px; }

  .contact-boxes.pb-70 { text-align: center; padding-bottom: 40px; }
  .contact-box p { padding: 0 3%; margin-top: 12px; }
  .contact-box span { top: 0.5px; left: 2px; }

  .contact-form .form-control, 
  .contact-form .form-select { height: 52px; font-size: 1.0625rem; padding: 0 15px; margin-bottom: 15px; }
  .contact-form textarea { min-height: 170px; }
  .contact-form textarea.form-control { padding: 15px; }

  .contact-form .form-btn { margin-top: 5px; }
  .contact-form .form-btn .btn { width: 100%; font-size: 1.0625rem; padding: 0.95rem 1.4rem; }
  .contact-form-msg { text-align: center; margin-top: 15px; }

  /* FOOTER */
  .footer { padding-bottom: 30px; }

  #footer-1 .footer-contacts { margin-top: -5px; }
  .footer-info, #footer-4 .footer-info, #footer-4 .footer-contacts { margin-bottom: 35px; }
  #footer-9 .footer-contacts { margin-top: -5px; margin-bottom: 30px; }
  .footer-links, #footer-7 .footer-connect, #footer-8 .footer-connect { margin-bottom: 25px; }
  .footer-form { margin-bottom: 10px; }
  #footer-1 .footer-form { margin-top: 35px; }

  .fl-1, .fl-2, .fl-3, .fl-4, #footer-1 .fl-5 { border-bottom: 1px solid #ddd; margin-bottom: 15px; padding-bottom: 16px; }
  #footer-1 .fl-3 { margin-bottom: 16px; }

  #footer-10 .foo-links { display: block; padding-top: 0; }
  #footer-10 .foo-links li { padding: 0 10px; }

  img.footer-logo { max-height: 33px; margin-bottom: 22px; }
  #footer-6 img.footer-logo { margin-bottom: 5px; }

  .footer h6 { font-size: 1.1875rem; margin-bottom: 20px; }   /* 19px */
  .footer .footer-links h6.h6-lg { font-size: 1.275rem; margin-bottom: 18px; }

  .footer-mail-link { margin: 0 0 0 6px; }
  .footer-mail-link a { font-size: 1.09375rem; }  /* 17.5px */
  .footer-mail-link span { top: 4.5px; right: 5px; }

  .footer-mail-link.ico-20 [class*="flaticon-"]:before, 
  .footer-mail-link.ico-20 [class*="flaticon-"]:after { font-size: 1.275rem; }

  .footer-stores-badge { margin-bottom: 20px; }
  .footer-stores-badge .store { margin-right: 10px; }
  .footer .badge-img-xs .store img { max-height: 2.75rem; }

  .footer-info-socials li { padding-right: 13px; }
  .footer-info-socials.ico-20 [class*="flaticon-"]:before, 
  .footer-info-socials.ico-20 [class*="flaticon-"]:after,
  .footer-info-socials.ico-25 [class*="flaticon-"]:before, 
  .footer-info-socials.ico-25 [class*="flaticon-"]:after { font-size: 1.3975rem; }

  .footer-socials a { margin-right: 13px; }
  .footer-socials.ico-25 [class*="flaticon-"]:before, 
  .footer-socials.ico-25 [class*="flaticon-"]:after { font-size: 1.435rem; }

  #footer-1 .footer-form { margin-bottom: 0; }
  .footer-form .form-control { font-size: 1.1rem; height: 38px; }
  .footer-form .btn { height: 40px; }
  .footer-form .ico-15 [class*="flaticon-"]:before, 
  .footer-form .ico-15 [class*="flaticon-"]:after { font-size: 1rem; line-height: 28px!important; }

  #footer-1 .bottom-footer { margin-top: 20px; }
  .footer-copyright p, .bottom-footer-list p.p-sm, .bottom-secondary-link p.p-sm { font-size: 1rem; }

  .footer-list-divider { top: -1px; }
  .bottom-secondary-link p span { top: 1.5px; padding: 0 2px; }
  .footer-info-copyright p span { top: 2px; padding: 0 2px; }

  .bottom-foo-socials { margin-left: 6px; }
  .bottom-foo-socials li { margin-right: 6px; }
  .bottom-foo-socials li span { top: 2.5px; right: 3px; }

  .bottom-foo-socials.ico-10 [class*="flaticon-"]:before, 
  .bottom-foo-socials.ico-10 [class*="flaticon-"]:after { font-size: 1.125rem; }

  .footer-info-copyright.ico-15 [class*="flaticon-"]:before, 
  .footer-info-copyright.ico-15 [class*="flaticon-"]:after { font-size: 0.815rem; }

  .bottom-footer-socials.ico-20 [class*="flaticon-"]:before, 
  .bottom-footer-socials.ico-20 [class*="flaticon-"]:after { font-size: 1.375rem; }

  .bottom-footer p.p-sm { margin-bottom: 0; }

  /* INNER PAGE WRAPPER */
  .inner-page-title { margin-bottom: 50px; }
  .inner-page-title h2 { margin-bottom: 0; }
  .inner-page-title p { padding: 0 5%; line-height: 1.6666; margin-top: 10px; }

  /* PAGE 404 */
  #page-404 { padding-top: 100px; }
  .page-404-txt h2 { font-size: 2.1rem; margin-bottom: 14px; }
  .page-404-txt h6 { font-size: 1.125rem; padding: 0 5%; margin-bottom: 25px; }
  .page-404-img { padding: 0 8%; margin-bottom: 30px; }
  .page-404-footer p span { display: block; }

  /* PAGE PAGINATION */
  .page-link { font-size: 0.95rem; padding: 2px 10px; }
  .pagination.ico-20 [class*="flaticon-"]:before, 
  .pagination.ico-20 [class*="flaticon-"]:after { font-size: 0.75rem; } 

  /* TERMS, PRIVACY, COOKIES */
  .txt-block.legal-info h5 { margin-top: 35px; margin-bottom: 15px; }
  .txt-block.legal-info h6 { margin-top: 15px; margin-bottom: 15px; }

  /* SIGN IN / SIGN UP */
  .login-page-logo { margin-bottom: 40px; }
  .login-page-logo img { max-height: 35px; }

  .login-1 .register-page-form, .signup-1 .register-page-form,
  .login-2 .register-page-form, .signup-2 .register-page-form { 
    margin: 0 2%; 
    padding: 30px 15px 35px; 
    border-radius: 8px; 
    -webkit-border-radius: 8px; 
    -moz-border-radius: 8px; 
    -o-border-radius: 8px; 
    -webkit-box-shadow: 0 4px 12px 0 var(--tra-black);
    -moz-box-shadow: 0 4px 12px 0 var(--tra-black);
    box-shadow: 0 4px 12px 0 var(--tra-black);
  }

  .login-2 .register-page-form, .signup-2 .register-page-form { margin: 0 1%; }

  .login-3 .register-page-wrapper, .signup-3 .register-page-wrapper { border-radius: 8px; margin: 0 1%; }
  .login-3 .register-page-form, .signup-3 .register-page-form { padding: 30px 18px; }

  .reset-page-wrapper form { margin: 0 2%; padding: 35px 12px 5px; }

  .register-form-title { margin-bottom: 20px; }
  .login-2 .register-form-title, .signup-2 .register-form-title, .reset-form-title { margin-bottom: 16px; }

  .register-form-title h5, .register-form-title h4 { margin-bottom: 10px; }

  .register-page-form p.input-header, .register-page-form .btn-google { font-size: 1rem; }
  .reset-password-link p { font-size: 1rem; margin-top: 16px; }
  .login-3 .reset-password-link p { margin-top: 3px; margin-bottom: 24px; }
  .form-data span { font-size: 0.875rem; }
  .reset-password-form .form-data p { font-size: 1rem; margin-bottom: 10px; }
  .register-page-form p.create-account { font-size: 1rem; margin-top: 20px; }
  .reset-form-title p.p-sm { font-size: 1rem; margin-top: 18px; }

  .separator-line { font-size: 1rem;  margin: 15px 0; }

  .reset-page-wrapper .form-control, .register-page-form .form-control { height: 52px; font-size: 1.0625rem; margin-bottom: 16px;}
  #login .register-page-form .btn.submit { height: 52px; font-size: 1.0625rem; margin-top: 0; }
  #signup .register-page-form .btn.submit { height: 52px; font-size: 1.0625rem; margin-top: 18px; }
  .reset-page-wrapper .btn.submit { height: 52px; font-size: 1.0625rem; margin-bottom: 16px; }
  .btn-google img { width: 18px; height: 18px; top: -2px; right: 5px; }

  /* COOKIES */
  #cookies { bottom: 15px; left: 5%; width: 90%; padding: 22px 18px 18px; }
  #cookies p { font-size: 1rem; }
  #cookies-ok { font-size: 0.95rem; padding: 7px 18px; }
  #cookies-info { margin-left: 15px; }
  #cookies-close { right: 14px; top: 5px; }
  #cookies-close svg { width: 12px; height: 12px; }

  /* MODAL */
  #modal-1 .modal-dialog { width: 320px; margin: auto auto; }
  #modal-2 .modal-dialog.modal-xl { width: 340px; margin: auto auto; }

  #modal-1 .modal-body-img { padding-top: 25px; }

  #modal-1 .modal-body-content { padding: 5px 22px 10px; }
  #modal-2 .modal-body-content { padding: 25px 12px 18px; }

  .modal .btn-close { right: 16px!important; top: 16px!important; }

  #modal-2 .request-form-title p { margin-top: 12px; margin-bottom: 18px; }

  .modal-body .newsletter-form { margin-top: 15px; }
  .modal .newsletter-form .form-control,
  #modal-2 .request-form .form-control { height: 52px; font-size: 1.0625rem; margin-bottom: 16px; }
  .modal .newsletter-form .btn, #modal-2 .request-form .btn { height: 52px; font-size: 1.0625rem; }


}




/* ------------------------------------------------------------------------ */
/* ------------------------------------------------------------------------ */
/* ------------------------------------------------------------------------ */

@media (min-width: 321px) and (max-width: 389.98px) {

  html { font-size: 93.75%; } 

  /*------------------------------------------*/
  /*  BASE
  /*------------------------------------------*/

  #stlChanger { display: none; }
  .ts-0 { padding-top: 80px; }
  .division { padding-left: 5px; padding-right: 5px; }
  .block--shadow { -webkit-box-shadow: 0 0; -moz-box-shadow: 0 0; box-shadow: 0 0; }
  .bg--fixed { width: auto; }
  .bg--scroll { width: auto; background-attachment: scroll!important; }

  /* Background Shape */
  .shape--01:after { height: 55%; }
  .shape--02:after { height: 55%; top: 45%; }
  .bg--shape:after { width: 1300px; height: 1300px; top: 12%; left: -10%; }
  .bg--shape-top:after { width: 450px; height: 450px; top: -5%; right: 12%; background-color: rgba(119, 67, 142, .15); }

  /* Headers */
  h6, h6.h6-md, h6.h6-lg, h6.h6-xl { font-size: 1.33334rem; }  /* 20px */
  h5, h5.h5-md, h5.h5-lg, h5.h5-xl, h4, h4.h4-md, h4.h4-lg, h4.h4-xl { font-size: 1.46667rem; }  /* 22px */
  h3, h3.h3-md, h3.h3-lg, h3.h3-xl, h2, h2.h2-md, h2.h2-lg  { font-size: 1.6rem; }               /* 24px */ 
  h2.h2-xl, h2.h2-title { font-size: 1.86667rem; }  /* 28px */ 

  h6, h5, h4, h3, h2, h1 { line-height: 1.4; }

  /* Paragraphs */
  p.p-sm { font-size: 0.93333rem; }
  p { font-size: 1rem; }
  p.p-md, p.p-lg, p.p-xl { font-size: 1.0625rem; }

  ol.digit-list p { margin-bottom: 5px; }

  /* Button */
  .btn.btn-sm, .btn, .btn.btn-md { font-size: 0.93333rem; padding: 0.8rem 1.45rem; }
  .btns-group .btn.ico-30.ico-left, .btns-group .btn.ico-30.ico-right { padding: 0.61095rem 0.5rem 0; }

  /* Buttons Group */
  .btns-group .btn:first-child { margin: 0 5px 15px; }

  /* Button Icon */
  .btn.ico-20 [class*="flaticon-"]:before, 
  .btn.ico-20 [class*="flaticon-"]:after { font-size: 1.225rem; line-height: 0.5rem!important;top: 4px; }

  .btn.ico-30 [class*="flaticon-"]:before, .btn.ico-30 [class*="flaticon-"]:after { font-size: 2rem; }

  .btn.ico-30.ico-left span.ico-30-txt { font-size: 1.1rem; top: -10px; margin-right: 0; }
  .btn.ico-30.ico-right span.ico-30-txt { font-size: 1.1rem; top: -10px; margin-left: 0; }
  .btn.ico-left span { margin-right: 8px; }
  .btn.ico-right span { margin-left: 8px; }

  /* Button Text */
  p.btn-txt { margin: 10px 0 0 0; }
  p.p-sm.btns-group-txt { font-size: 0.95192rem; }
  .text-center p.btns-group-txt { margin-top: 18px; }
  .btn-rating { margin: 15px 0 0 0; }
  .btn-rating p.p-sm { font-size: 0.9375rem; }
  .star-rating small, .star-rating span.rating-txt { font-size: 1rem; }

  .star-rating.ico-20 [class*="flaticon-"]:before, 
  .star-rating.ico-20 [class*="flaticon-"]:after { position: relative; font-size: 1.125rem; top: 2px; }

  /* Transparent Link */
  a.tra-link { font-size: 1.034rem; }
  .tra-link.ico-20 [class*="flaticon-"]:before, 
  .tra-link.ico-20 [class*="flaticon-"]:after { font-size: 0.785rem; line-height: 0.7rem!important; top: 1px; left: 2px; }

  /* Store Badge */
  .store { margin-right: 6px; }
  .store.store-btn-2 { margin-right: 0; }
  .store-btn-3, .store-btn-4 { margin-top: 15px; }

  /* Watch Video Link */
  .watch-video { margin-top: 18px; }
  .watch-video-link { margin-right: 10px; }
  p.video-txt-lg { font-size: 1.0625rem; }
  p.video-txt-sm { font-size: 1rem; }

  /* Video Popup Icon */
  .video-btn-xl { width: 5.625rem; height: 5.625rem; margin-top: -2.8125rem; margin-left: -2.8125rem; }
  .video-btn-lg { width: 5rem; height: 5rem; margin-top: -2.5rem; margin-left: -2.5rem; }
  .video-btn-md { width: 5rem; height: 5rem; margin-top: -2.5rem; margin-left: -2.5rem; }

  .video-btn.video-btn-xl [class^="flaticon-"]:before, .video-btn.video-btn-xl [class^="flaticon-"]:after {
    font-size: 4.5rem; line-height: 5.625rem!important; margin-left: 8px;
  }

  .video-btn.video-btn-lg [class^="flaticon-"]:before, .video-btn.video-btn-lg [class^="flaticon-"]:after,
  .video-btn.video-btn-md [class^="flaticon-"]:before, .video-btn.video-btn-md [class^="flaticon-"]:after {
    font-size: 4.35rem; line-height: 5rem!important; margin-left: 8px;
  }

  /* Vector Icon Background */ 
  .ico-50 .shape-ico svg { width: 120px; height: 120px; top: -35px; left: calc(50% - 60px); }
  .ico-60 .shape-ico svg { width: 125px; height: 125px; top: -30px; left: calc(50% - 65px); }
  .ico-55 .shape-ico svg { width: 120px; height: 120px; top: -30px; left: calc(50% - 60px); }

  /* Vector Rounded Icons */
  .ico-rounded, .ico-rounded-md, .ico-rounded-lg { width: 94px; height: 94px; }

  .ico-rounded [class*="flaticon-"]:before, 
  .ico-rounded [class*="flaticon-"]:after,
  .ico-rounded-md [class*="flaticon-"]:before, 
  .ico-rounded-md [class*="flaticon-"]:after,
  .ico-rounded-lg [class*="flaticon-"]:before, 
  .ico-rounded-lg [class*="flaticon-"]:after { font-size: 3rem; line-height: 94px!important; }

  /* PNG Icons */
  .img-80 img { width: 70px; height: 70px; }
  .img-75 img { width: 65px; height: 65px; }
  .img-70 img, .img-65 img, .img-60 img { width: 60px; height: 60px; }

  /* Section ID */
  .section-id { font-size: 0.9rem; margin-bottom: 20px; }

  /* Section Title */
  .section-title.mb-70 {margin-bottom: 35px; }
  .section-title p { margin-top: 15px; }
  .section-title .btns-group { padding: 0 8%; margin-top: 8px; }
  .section-title .btns-group .btn { width: 100%; margin: 12px 0 0; }

  /* Users */
  .txt-block .users { margin-top: 14px; }
  .users img { max-height: 44px; }
  .txt-block .users img { max-height: 40px; }
  .users p { font-size: 0.975rem; margin-top: 15px; top: 0; left: 0; }
  .txt-block .users p { font-size: 0.9rem; top: 10px; left: 4px; }

  /*------------------------------------------*/
  /*   NAVIGATION MENU    
  /*------------------------------------------*/

  /* Header Logo */
  .wsmobileheader .smllogo { display: block; margin-top: 15px; padding-left: 16px; }
  .smllogo img { width: auto; max-width: inherit; max-height: 50px; }

  .wsmenu > .wsmenu-list { width: 290px; margin-right: -290px; }
  .wsactive .wsmobileheader { margin-right: 290px; }
  .overlapblackbg { width: calc(100% - 290px); }

  .wsanimated-arrow { padding: 14px 30px 14px 0px; margin: 25px 16px 0 0; }

  /* Nav Link Info */
  span.sm-info { width: 22px; height: 22px; font-size: 0.8rem; line-height: 20px; top: -1px; margin-left: 8px; }

  .wsmenu > .wsmenu-list > li > a { padding: 5px 32px 4px 20px; margin: 0; }
  .wsmenu > .wsmenu-list > li > .wsmenu-click > i { height: 8px; width: 8px; margin: 24px 18px 0px 0px; }

  .wsmenu > .wsmenu-list > li > ul.sub-menu, .wsmenu > .wsmenu-list > li > .wsmegamenu.w-75,
  .wsmenu > .wsmenu-list > li > .wsmegamenu.halfmenu { top: 0; }

  .wsmenu > .wsmenu-list > li > ul.sub-menu, .wsmenu > .wsmenu-list > li > .wsmegamenu.halfmenu, 
  .wsmenu > .wsmenu-list > li > .wsmegamenu.w-75 { padding: 6px 10px; }

  .wsmenu > .wsmenu-list > li > ul.sub-menu > li > a, 
  .wsmenu > .wsmenu-list > li > ul.sub-menu > li > a:hover, 
  .wsmenu > .wsmenu-list > li > .wsmegamenu .link-list li a,
  .wsmenu > .wsmenu-list > li > .wsmegamenu .link-list li a:hover,
  .wsmenu > .wsmenu-list > li > ul.sub-menu > li > ul.sub-menu > li > a,
  .wsmenu > .wsmenu-list > li > ul.sub-menu > li > ul.sub-menu > li > a:hover,
  .wsmenu > .wsmenu-list > li > .wsmegamenu.w-75 ul.link-list > li > a, 
  .wsmenu > .wsmenu-list > li > .wsmegamenu.halfmenu ul.link-list > li > a,
  .wsmenu > .wsmenu-list > li > .wsmegamenu.w-75 ul.link-list > li > a:hover, 
  .wsmenu > .wsmenu-list > li > .wsmegamenu.halfmenu ul.link-list > li > a:hover { padding: 13px 10px; }

  /* Header Store Badges */
  .store.header-store img { max-height: 44px; }

  /* Header Social Links */
  .header-socials.ico-20 [class*="flaticon-"]:before, 
  .header-socials.ico-25 [class*="flaticon-"]:after { line-height: 52px!important; } 

  .wsmenu > .wsmenu-list > li > .wsmegamenu ul li.title { margin-top: 12px; } 

  /*------------------------------------------*/
  /*   HERO   
  /*------------------------------------------*/

  .hero-section.blur--purple:after { height: 90%; top: -34%; left: 10%; }

  .slideshow, #hero-20 .slideshow, .slideshow .slides, #hero-20 .slideshow .slides { height: 440px; }

  .img-link { bottom: 20px; right: 20px; width: 45px; height: 45px; }
  .img-link a span { top: 4.5px; }

  .hero-section .btns-group { display: block; }
  .hero-section .btns-group .btn { display: block; padding: 1rem 1.5rem; margin: 0 10%; }
  .hero-section .btns-group .btn:first-child { margin-bottom: 15px; }

  /* Hero Advantages */
  .hero-section .text-center .advantages { margin-top: 18px; }
  .hero-section .advantages li { display: block!important; margin-top: 3px; }
  .hero-section .advantages li p { display: block; float: none; }
  .hero-section li.advantages-links-divider { display: none!important; }

  /* HERO QUICK FORM */
  .hero-section .quick-form .form-control, .hero-section .quick-form .btn { height: 52px; font-size: 1.0625rem; }
  .hero-section .quick-form.form-half .form-control { height: 52px; font-size: 1.0625rem; padding: 0 14px; }
  .hero-section .quick-form.form-half .btn { height: 52px; font-size: 1.0625rem; }

  .hero-section .text-center .quick-form-msg { margin: 20px 0 -10px 0; }

  /* HERO DIGITS */
  #hd-1-1, #hd-1-2 { width: 100%; padding: 0; } 
  #hd-1-1 { margin-bottom: 20px; } 
  .hero-digits h2.statistic-number { font-size: 2.75rem; }
  .hero-digits h2.statistic-number small { font-size: 2rem; margin-left: 3px; }

  /* HERO-1 */
  .hero-1-txt h2 { font-size: 1.925rem; }
  .hero-1-txt p.p-lg, .hero-1-txt p.p-xl { margin: 14px 0 20px; }
  .hero-1-txt .btn.btn-md { display: block; padding: 1.05rem 1.5rem; margin: 0 8%; }
  .hero-1-txt .star-rating small { display: block; }
  .hero-1-img { margin: 35px 0 0; }

  /* HERO-2 */
  #hero-2 { padding-top: 70px; }
  #hero-2:after { height: 7%; }
  .hero-2-txt h2 { font-size: 2.25rem; padding: 0 2%; }
  .hero-2-txt p.p-lg { padding: 0; margin-top: 12px; margin-bottom: 22px; }
  .hero-2-img { margin-top: 35px; }

  /* HERO-3 */
  #hero-3 { padding-top: 70px; padding-bottom: 70px; }
  .hero-3-txt h2 { font-size: 2.25rem; }
  .hero-3-txt p.p-md { margin-top: 12px; }
  .hero-3-txt .users { margin-top: 22px; }
  .hero-3-img { margin-top: 35px; }

  /* HERO-4 */
   #hero-4 { margin-bottom: 65px; }
  .hero-4-txt h2 { font-size: 1.925rem; padding: 0 2%; }
  .hero-4-txt p.p-lg { margin: 14px 0 22px; padding: 0 4%; }
  .hero-4-img { margin: 35px 0 -40px; }

  /* HERO-5 */
  #hero-5:after { height: 96%; left: 0; }
  .hero-5-txt h2 { font-size: 2.125rem; }
  .hero-5-txt p.p-md { margin: 14px 0 22px; padding: 0 2%; }
  .hero-5-img { margin: 35px 0 0; }

  /* HERO-6 */
  #hero-6 { padding-top: 70px; padding-bottom: 70px; }
  .hero-6-txt h2 { font-size: 2.1rem; }
  .hero-6-txt p.p-lg { margin-top: 15px; }
  #hero-6 .quick-form { margin: 20px 3% 0; }
  .hero-6-img { margin-top: 35px; }

  /* HERO-7 */
  #hero-7 { padding-top: 60px;  background-position: top center; }
  .hero-7-txt h2 { font-size: 2rem; }
  .hero-7-txt p.p-lg, .hero-7-txt p.p-xl { margin: 14px 0 20px; padding: 0 3%; }
  #hero-7 .users { margin-top: 25px; }
  #hero-7 .users img { max-height: 45px; }
  #hero-7 .users p { font-size: 1rem; top: 0; left: 0; margin-top: 12px; }
  .hero-7-img { margin: 30px 2% 0; }

  /* HERO-8 */
  .hero-8-txt h2 { font-size: 2.25rem; padding: 0 2%; }
  .hero-8-txt p.p-lg { padding: 0; margin-top: 12px; margin-bottom: 22px; }
  .hero-8-txt p.btn-txt { margin-top: 25px; }
  #hero-8 .quick-form { margin: 22px 4% 0; }
  .hero-8-img { margin-top: 35px; }

  /* HERO-9 */
  #hero-9 { padding-top: 70px; padding-bottom: 70px; }
  #hero-9 .wave-shape-bottom svg { height: 45px; }
  .hero-9-txt h2 { font-size: 2rem; }
  .hero-9-txt p.p-lg { margin-top: 15px; padding: 0 3%; }
  #hero-9 .quick-form { margin: 22px 5% 0; }
  .hero-9-img { margin-top: 35px; }

  /* HERO-10 */
  #hero-10 { margin-bottom: 40px; }
  #hero-10 .container { max-width: 100%; }
  .hero-10-txt { margin-bottom: 45px; }
  .hero-10-txt h2 { font-size: 2.25rem; padding: 0 5%; }
  .hero-10-txt p.p-lg { padding: 0; margin-top: 12px; margin-bottom: 22px; }

  .hero-10-wrapper { overflow: visible; margin: 0 -15px; border-radius: 0 0!important; }
  #hero-10 .hero-overlay { position: relative; padding: 60px 25px 0; }
  .hero-10-img { margin-bottom: -40px; }
  .hero-10-img .video-btn { top: calc(50% - 2px); }

  /* HERO-11 */
  .hero-11-txt h2 { font-size: 1.925rem; }
  .hero-11-txt p.p-md { margin: 14px 0 25px; padding: 0; }
  .hero-11-img { margin: 35px 0 0; }

  /* HERO-12 */
  #hero-12 { padding-top: 0; }
  #hero-12 .container { max-width: 100%; }
  .hero-12-wrapper { padding: 70px 25px; margin: 0 -15px; border-radius: 0 0!important; }
  .hero-12-txt h2 { font-size: 1.925rem; padding: 0 5%; }
  .hero-12-txt p.p-lg { margin: 12px 0 22px; }
  .hero-12-img { margin-top: 35px; }

  /* HERO-13 */
  .hero-13-txt h2 { font-size: 2.1rem; padding: 0 2%; }
  .hero-13-txt p.p-xl { padding: 0 3%; margin-top: 15px; }
  #hero-13 .quick-form { margin: 25px 4% 0; }
  .hero-13-txt p.btn-txt { margin-top: 30px; }

  /* HERO-14 */
  #hero-14 { padding-top: 70px; }
  #hero-14:after { height: 7%; }
  .hero-14-txt .section-id { padding: 11px 25px; font-size: 0.9rem; margin-bottom: 30px; }
  .hero-14-txt h2 { font-size: 2rem; }
  .hero-14-txt p.p-md { margin: 15px 0 25px; }
  .hero-14-img { margin-top: 30px; }

  /* HERO-15 */
  .hero-15-txt h2 { font-size: 2.25rem; }
  .hero-15-txt p.p-lg { padding: 0; margin-top: 15px; margin-bottom: 25px; }

  /* HERO-16 */
  #hero-16 { padding-top: 70px; padding-bottom: 70px; }
  .hero-16-txt h2 { font-size: 2rem; }
  .hero-16-txt p.p-lg { margin-top: 15px; }
  #hero-16 .quick-form { margin: 20px 3% 0; }
  #hero-16 .users { margin-top: 25px; }
  .hero-16-img { margin-top: 35px; }

  /* HERO-17 */
  #hero-17:after { height: 7%; }
  .hero-17-txt h2 { font-size: 1.925rem; padding: 0 2%; }
  .hero-17-txt p.p-xl { padding: 0 4%; margin-top: 14px; }
  .hero-17-txt .btns-group { margin-top: 22px; }
  .hero-17-img { margin-top: 40px; }

  /* HERO-18 */
  #hero-18 { padding-top: 75px; }
  .hero-18-txt { margin-bottom: 45px; }
  .hero-18-txt .section-id { margin-bottom: 25px; }
  .hero-18-txt h2 { font-size: 1.925rem; }
  .hero-18-txt p.p-xl { padding: 0; margin-top: 12px; }
  .hero-18-txt .btns-group { padding: 0 3%; margin-top: 20px; }
  .hero-18-txt .btns-group .btn { width: 100%; margin: 0; }
  .hero-18-txt .btns-group .btn:first-child { margin-bottom: 15px; }

  .h18-1 { display: none }
  .h18-2 { margin-bottom: 24px; }

  .hero-bg-shape { width: 220%; height: 100%; left: -32%; right: -32%; opacity: .3; }

  /* HERO-19 */
  .hero-19-txt h2 { font-size: 2.1rem; padding: 0 2%; }
  .hero-19-txt p.p-xl { padding: 0 3%; margin-top: 15px; }
  #hero-19 .quick-form { margin: 25px 4% 0; }
  .hero-19-txt p.btn-txt { margin-top: 30px; }
  .hero-19-img { margin-top: 35px; }

  /* HERO-20 */
  #hero-20 .caption {padding: 0 3%; }
  #hero-20 .caption h2 { font-size: 1.86667rem; padding: 0 3%; }
  #hero-20 .caption p.p-xl { font-size: 1.0625rem; padding: 0; margin-top: 12px; }
  #hero-20 .caption .btn { margin-top: 22px; }

  /* HERO-21 */
  #hero-21:after { height: 10%; }
  .hero-21-shape { width: 110%; top: 4.5%; left: -5%; opacity: .8; }
  .hero-21-txt h2 { font-size: 1.925rem; padding: 0 2%; }
  .hero-21-txt p.p-xl { padding: 0 4%; margin-top: 14px; }
  .hero-21-txt .btns-group { margin-top: 22px; }
  .hero-21-img { margin-top: 40px; }

  /* HERO-22 */
  #hero-22 .caption h2 { font-size: 1.86667rem; padding: 0; }
  #hero-22 .caption p.p-xl { font-size: 1.0625rem; padding: 0; margin-top: 12px; }
  #hero-22 .caption .btn { margin-top: 22px; }

  /* HERO-23 */
  #hero-23 { padding-top: 70px; }
  .hero-23-txt h2 { font-size: 2.1875rem; padding: 0 2%; }
  .hero-23-txt p.p-lg { padding: 0; margin-top: 12px; margin-bottom: 22px; }
  .hero-23-img { margin-top: 35px; }

  /* HERO-24 */
  #hero-24 { margin-bottom: 50px; }
  .hero-24-txt h2 { font-size: 2.25rem; padding: 0 5%; }
  .hero-24-txt p.p-lg { margin-top: 12px; }
  .hero-24-txt .users { margin-top: 25px; }

  #hero-24-form { padding: 25px 20px; margin: 50px 1% -50px; }
  #hero-24-form h5 { font-size: 1.5rem; margin-bottom: 12px; }
  #hero-24-form p { margin-bottom: 16px; }
  #hero-24-form p.p-sm { padding: 0!important; }
  #hero-24-form .btn { height: 52px; font-size: 1.125rem; padding: 16px; }

  /* HERO-25 */
  #hero-25 { padding-top: 70px; }
  .hero-25-txt h2 { font-size: 2.125rem; }
  .hero-25-txt p.p-lg { padding: 0; margin-top: 14px; margin-bottom: 22px; }
  .hero-25-img { margin-top: 35px; }

  /* HERO-26 */
  .hero-26-txt h2 { font-size: 1.86667rem; }
  .hero-26-txt p.p-lg { padding: 0; margin-top: 12px; margin-bottom: 22px; }
  .hero-26-img { margin: 40px -20% 0; }

  /* HERO-27 */
  .hero-27-txt h2 { font-size: 1.9rem; margin-bottom: 20px; }
  .hero-27-txt p { margin-top: 22px; margin-bottom: 30px; }

  #hero-27 .img-link { bottom: 7px; left: 9px; width: 50px; height: 50px; }
  #hero-27 .img-link a span { top: 6px; }

  /* HERO REQUEST FORM */
  .request-form .form-control { height: 52px; font-size: 1.0625rem; margin-bottom: 15px; }
  
  /*------------------------------------------*/
  /*  CONTENT
  /*------------------------------------------*/

  /* ABOUT */
  #a3-1 { margin-bottom: 18px; }

  .about-1-img { margin: 0 -32%; }

  /* FEATURES */
  #features-3.py-100.shape--bg:after, #features-4.py-100.shape--bg:after { height: 68%; top: 32%; }
  #features-3.pt-100.shape--bg:after { height: 64%; top: 34%; }
  #features-4.pt-100.shape--bg:after { height: 65%; top: 32.5%; left: 0; }

  #features-3 .txt-block, #features-4 .txt-block { margin-bottom: 30px; }

  .fb-1, .fb-2, .rows-2 .fb-1, .rows-2 .fb-2, .rows-2 .fb-3, .rows-2 .fb-4, .rows-2 .fb-5,.row-cols-md-2 .fb-3, 
  .rows-3 .fb-1, .rows-3 .fb-2, .rows-3 .fb-3, .rows-3 .fb-4, .rows-3 .fb-5, .rows-4 .fb-1, .rows-4 .fb-2, .rows-4 .fb-3, 
  .rows-4 .fb-4, .rows-4 .fb-5, .rows-4 .fb-6,#fb-3-1, #fb-3-2, #fb-3-3, #fb-4-1, #fb-4-2, #fb-4-3, .fbox-9.fb-1, 
  .fbox-9.fb-2, .fbox-9.fb-4, .fbox-9.fb-5, .fbox-9.fb-7, .fbox-9.fb-8.fbox-10.fb-1, .fbox-10.fb-3, .fbox-10.fb-5, 
  .fbox-10.fb-7 { margin-bottom: 35px; }

  .fbox-11.fb-1, .fbox-11.fb-2 { margin-bottom: 25px; }

  .rows-4 .fbox-2.fb-1, .rows-4 .fbox-2.fb-2, .rows-4 .fbox-2.fb-3, .rows-4 .fbox-2.fb-4, 
  .rows-4 .fbox-2.fb-5, .rows-4 .fbox-2.fb-6 { margin-bottom: 35px; }

  .rows-4 .fb-8 { margin-top: 35px; }
  #features-12 .txt-block, .fbox-6.fb-4 { margin-bottom: 25px; }
  .fbox-12.fb-1, .fbox-12.fb-2, .fbox-12.fb-3 { margin-bottom: 20px; }

  .fbox-3, .fbox-4, .fbox-6 { 
    padding: 35px 25px;
    -webkit-box-shadow: 0 4px 12px 0 rgba(0, 0, 0, .08); 
    -moz-box-shadow: 0 4px 12px 0 rgba(0, 0, 0, .08); 
    box-shadow: 0 4px 12px 0 rgba(0, 0, 0, .08); 
  }
  .fbox-5 { padding: 35px 25px; } 
  .fbox-6 { text-align: center; padding: 30px 22px; }

  .fbox-9 { padding: 35px 20px; }
  .ico-title .fbox-9.fb-1, .ico-title .fbox-9.fb-2, .ico-title .fbox-9.fb-3 { padding: 30px 20px; }
  .fbox-9.fb-4, .fbox-9.fb-5, .fbox-9.fb-6, .ico-title .fbox-9.fb-4, 
  .ico-title .fbox-9.fb-5, .ico-title .fbox-9.fb-6 { padding: 30px 20px 0; }

  .fbox-10, .fbox-10.fb-5, .fbox-10.fb-6, .fbox-10.fb-7, .fbox-10.fb-8 { padding: 35px 20px 30px; } 
  .ico-title .fbox-10.fb-5, .ico-title .fbox-10.fb-6,
  .ico-title .fbox-10.fb-7, .ico-title .fbox-10.fb-8 { padding: 30px 20px 25px; } 

  .fbox-img.h-175 img, .fbox-img.h-180 img { max-height: 160px; } 

  .fbox-7 .fbox-img { margin-bottom: 20px; }
  .fbox-8 .fbox-img.h-175, .fbox-8 .fbox-img.h-180 { padding-top: 30px; margin: 0 18px 45px; } 

  .fbox-9.fb-1 .fbox-9-img, .fbox-9.fb-2 .fbox-9-img, .fbox-9.fb-3 .fbox-9-img { margin: 25px 0 0; }
  .fbox-9.fb-4 .fbox-9-img, .fbox-9.fb-5 .fbox-9-img, .fbox-9.fb-6 .fbox-9-img { margin: 25px 0 -18px; }
  .fbox-9.fb-7 .fbox-9-img, .fbox-9.fb-8 .fbox-9-img, .fbox-9.fb-9 .fbox-9-img { margin: 0 0 25px; }

  .fbox-10.fb-1 .fbox-10-img, .fbox-10.fb-2 .fbox-10-img, 
  .fbox-10.fb-3 .fbox-10-img, .fbox-10.fb-4 .fbox-10-img { margin: 0 8% 20px; }
  .fbox-10.fb-7 .fbox-10-img, .fbox-10.fb-8 .fbox-10-img { margin: 20px 8% 0; }
  .fbox-10.fb-5 .fbox-10-img, .fbox-10.fb-6 .fbox-10-img { margin: 0 0 25px; }
  .ico-title .fbox-10.fb-5 .fbox-10-img, .ico-title .fbox-10.fb-6 .fbox-10-img { margin: 25px 0 0; }

  .fbox-ico, .fbox-ico.ico-rounded { margin-bottom: 15px; }
  .fbox-5 .fbox-ico, .fbox-6 .fbox-ico { margin-bottom: 20px; }
  .fbox-ico[class*="img-"], .fbox-ico.ico-rounded-md, .fbox-ico.ico-rounded-lg { margin-bottom: 16px; }
  .t-icon { margin-bottom: 2px; }
  .t-icon.ico-25 [class*="flaticon-"]:before, .t-icon.ico-25 [class*="flaticon-"]:after { font-size: 1.75rem; }
  .fbox-11-ico { margin-bottom: 14px; }

  .fbox-wrapper .ico-50 [class*="flaticon-"]:before, .fbox-wrapper .ico-50 [class*="flaticon-"]:after,
  .fbox-wrapper .ico-55 [class*="flaticon-"]:before, .fbox-wrapper .ico-55 [class*="flaticon-"]:after,
  .fbox-wrapper .ico-60 [class*="flaticon-"]:before, .fbox-wrapper .ico-60 [class*="flaticon-"]:after { font-size: 3.65rem; }
  .fbox-ico-wrap .ico-40 [class*="flaticon-"]:before, .fbox-ico-wrap .ico-40 [class*="flaticon-"]:after { font-size: 3.1rem; }

  .fbox-3 h6, .fbox-4 h6 { margin-top: 18px; margin-bottom: 10px; }
  .fbox-2 h5, .fbox-wrapper .row-cols-md-2 h6, 
  .fbox-wrapper .row-cols-md-3 h6, .fbox-10 h5, .fbox-6 h6, .fbox-9 h6, .fbox-12 h5 { margin-bottom: 12px; }
  .fbox-10.fb-5 h5, .fbox-10.fb-6 h5, .ico-title .fbox-10.fb-5 h5, .ico-title .fbox-10.fb-6 h5 { margin-bottom: 14px; }
  .fbox-11 h6 { margin-bottom: 12px; }

  .fbox-1 p { padding: 0 2%; }
  .fbox-7 p, .fbox-8 p { padding: 0 3%; }

  .fbox-5 .txt-block-tra-link { margin-top: 14px; }

  /* CONTENT */
  .bc-02.pt-30.ws-wrapper, .bc-02.pb-30.ws-wrapper { padding-top: 35px; }
  .bc-02.pt-30.bc-02-top { padding-top: 20px; margin-top: 50px; }
  .bc-02 .container { max-width: 100%; }
  .bc-02.pt-30 .container, .bc-02.pb-30 .container { max-width: auto; }
  .bc-02-wrapper { margin: 0 -15px; border-radius: 0 0!important; }
  .bc-02.pt-30 .bc-02-wrapper, .bc-02.pb-30 .bc-02-wrapper { margin: 0; border-radius: 12px!important; }

  .ct-05 .section-title, .ct-06 .section-title, .bc-03-wrapper .section-title { margin-bottom: 30px; }
  .bc-03 .section-title p { font-size: 1rem; margin-top: 12px; }

  .ct-05.pt-100, .ct-06 .section-overlay { margin-bottom: 40px; }
  .ct-05.pt-100.ct-05-img { margin-bottom: 0; }
  .bc-01 .section-overlay, .bc-02 .section-overlay, .bc-02 .ico-title .section-overlay { padding: 70px 20px; }
  .bc-02.pt-30 .section-overlay, .bc-02.pb-30 .section-overlay { padding: 30px 25px; }
  .bc-03 .section-overlay { padding: 70px 20px 0; }

  .cbox-6 { text-align: center; }

  .txt-box, .bc-1-wrapper .txt-box { margin-bottom: 15px; }
  .bc-04-box { 
    padding: 30px 24px; 
    -webkit-box-shadow: 0 4px 12px 0 rgba(0, 0, 0, .08); 
    -moz-box-shadow: 0 4px 12px 0 rgba(0, 0, 0, .08); 
    box-shadow: 0 4px 12px 0 rgba(0, 0, 0, .08);
  }

  .two-txt .txt-block.left-column { margin-bottom: 35px; }
  .two-txt .cbox-3, .two-txt .cbox-3 .ico-wrap { text-align: left!important; }

  .txt-block h3, .txt-block h2 { margin-bottom: 16px; }
  .txt-block h5 { margin-bottom: 14px; }
  h5.h5-title { margin: 14px 0; }

  .bc-04-box h2 { font-size: 2.75rem; margin-bottom: 12px; }
  .bc-04-box h2 small, .bc-04-box h2 span { font-size: 2.15rem; }
  .cbox-5 p.p-sm { font-size: 1rem; }

  .txt-block .btn { margin-top: 20px; }
  .txt-block .btns-group .btn.btn--transparent, .txt-block-tra-link { margin-top: 16px; }

  .cbox-1 .ico-wrap { margin-right: 0.9rem; }
  .cbox-1-ico { width: 1.55rem; height: 1.55rem; font-size: 0.95rem; line-height: 1.2rem; margin-top: 5px; }

  .cbox-3 .cbox-3-ico { margin-bottom: 10px; }
  .cbox-3-ico span[class*="flaticon-"]:before, .cbox-3-ico span[class*="flaticon-"]:after { font-size: 3.25rem; }

  .cbox-title { margin-bottom: 12px; }
  .cbox-title span {display: block; float: none; line-height: 1!important; margin: 0 0 14px 0; }
  .cbox-title span[class*="flaticon-"]:before, .cbox-title span[class*="flaticon-"]:after { font-size: 3rem; }

  .cbox-1-txt { margin-bottom: 20px; }
  .cbox-3-txt { margin-bottom: 30px; }
  .cbox-6-txt { margin-bottom: 30px; }
  .cbox-1-txt h5, .cbox-3-txt h5 { margin-bottom: 10px; }

  .cbox-4 { width: 320px; min-width: 320px; padding: 12px 16px; }
  .cbox-4.mt-20 { margin-top: 8px; }
  .cbox-4.mb-30 { margin-bottom: 20px; }
  .cbox-4-ico span  { position: relative; top: 3px; }
  .cbox-4-txt { padding-left: 11px; }

  .cbox-5-wrapper { margin-top: 0; }
  .box-title { margin-bottom: 12px; }
  .cbox-5.cb-2 { margin-top: 15px; }
  .box-title span { top: 10px; left: 3px; right: 0; }
  .box-title h6 { position: relative; top: 2px; left: 8px; }

  .txt-block .accordion-1 .accordion-item { padding: 18px 0 23px; }
  .txt-block .accordion-1 .accordion-item .accordion-thumb:after,
  .txt-block .accordion-1 .accordion-item.is-active .accordion-thumb:after { font-size: 0.9rem; top: 0px; right: 5px; }

  .txt-block .accordion-1 .accordion-thumb h5 { padding-left: 0; }
  .txt-block .accordion-1 .accordion-item.is-active .accordion-thumb h5 { margin-bottom: 18px; }
  .txt-block .accordion-1 .accordion-panel { padding: 0; }

  /* Advantages */
  .advantages, .text-center .advantages { margin-top: 15px; }
  .advantages li { display: block!important; margin-top: 4px; }
  .advantages li p { display: block; float: none; }
  li.advantages-links-divider { display: none!important; }

  /* Compatibility */
  .compatibility { margin-top: 10px; }

  /* Tools List / Payments Icons */
  .tools-list p, .payment-methods p { margin-bottom: 12px; }
  .tools-list span { padding-right: 1px; }
  .payment-icons img { max-height: 30px; }

  .tools-list.ico-35 [class*="flaticon-"]:before, .tools-list.ico-35 [class*="flaticon-"]:after { 
    font-size: 2.125rem;line-height: 2.125rem!important; margin-right: 4px;
  }

  /* Content Images */
  .content-section .container.hidd { overflow: visible!important; }
  .img-block-hidden, .ct-06 .img-block { margin-bottom: -40px; }
  .img-block-txt { margin-top: 30px; }
  .img-block-btn { padding: 0 8%; margin-top: 30px; }
  .img-block-btn .btn { width: 100%; }

  .bc-3-img .video-btn { top: calc(50% - 25px); }

  .ct-01 .img-block.right-column, .ct-02 .img-block.right-column, .ct-03 .img-block.right-column,
  .ct-01 .img-block.left-column, .ct-02 .img-block.left-column, .ct-03 .img-block.left-column { margin: 0 0 35px; }

  .bc-01 .img-block { margin-bottom: 35px; }
  .bc-2-img.right-column, .bc-2-img.left-column { margin-bottom: 30px; }
  .bc-3-img { margin-bottom: -60px; }

  .img-content.py-100 { padding: 30px 0; }

  /* STATISTIC */
  .statistic-4-wrapper, .statistic-8-wrapper { padding: 0 6%; }
  .statistic-7-wrapper { padding: 65px 35px; }
  .statistic-7-wrapper .col-lg-4 { margin-bottom: 30px; }
  .txt-block-stat.mt-30 { margin-top: 20px; }

  #statistic-6 .statistic-block-txt:before { width: 75px; margin: 16px auto 16px; }

  #sb-1-1, #sb-1-2, #sb-2-1, #sb-5-1, #sb-5-2, #sb-5-3, #sb-6-1, #sb-6-2, #sb-7-1, #sb-7-2, #sb-9-1, #sb-9-2 {margin-bottom: 35px;}
  #sb-3-2 { padding-left: 4%; }
  #sb-4-1, #sb-4-2, #sb-8-1, #sb-8-2 { padding-bottom: 35px; margin-bottom: 35px; }

  h3.statistic-number { font-size: 2.6rem; line-height: 1; letter-spacing: -1px; }
  h2.statistic-number { font-size: 2.75rem; line-height: 1; letter-spacing: -1px; }
  .statistic-2-wrapper h2,  #sb-3-1 h2.statistic-number, #sb-3-2 h2.statistic-number { margin-bottom: 12px; }
  .statistic-4-wrapper h5 { margin: 20px 0 12px; }
  .statistic-5-wrapper h3 {font-size: 2.25rem; line-height: 1; }
  #statistic-6 h2.statistic-number { font-size: 3.55rem; letter-spacing: -2px; }
  #statistic-6 h2.statistic-number small { font-size: 2.1875rem; margin-left: 4px; }
  .statistic-9-wrapper h3, .statistic-9-wrapper h2 { margin: 20px 0 12px; }

  .statistic-5-wrapper p { margin-top: 12px; }
  .statistic-8-wrapper p { margin-top: 15px; }
  
  #sb-2-1 br { display: none; }
  #sb-2-2 { clear: both; }
  #sb-2-2 .txt-block-rating { display: inline-block; }
  #sb-2-2 p { display: inline-block; margin-left: 5px; }

  .statistic-8-wrapper img { max-height: 42px; margin-top: 16px; }
  .statistic-section .action-btn { margin-top: 40px; }

  /* PROJECTS */
  #portfolio-1.pb-70 { padding-bottom: 35px; }

  #portfolio-1 .filter-btns.mb-70 { margin-bottom: 25px; }
  #portfolio-1 .filter-item { margin-bottom: 25px; }

  .project-category { left: 20px; }
  .project-category p { padding: 7px 16px; margin-bottom: 0; }

  .project-data-txt { bottom: 34px; }
  .project-data-txt h5 { font-size: 1.35rem; padding-right: 14%; }

  .project-source { width: 35px; height: 35px; bottom: 20px; }
  .project-source a span { top: 3px; }

  .filter-item:hover .project-data-txt { left: 25px; }
  .filter-item:hover .project-source { right: 20px; }

  #portfolio-1 .more-btn { margin-top: 10px; }

  /* PROJECT DETAILS */
  .project-title { padding: 0; margin-bottom: 30px; }
  .project-title p { margin-top: 15px; }
  .project-data { padding-bottom: 18px; margin: 25px 0; }
  .project-data p { margin-bottom: 7px; }

  .project-txt h5 { margin: 25px 0 14px; }
  .project-txt h6 { font-size: 1.215rem; margin: 14px 0; }

  .project-inner-img { margin: 30px 0; }
  .project-inner-img.d-flex { margin-left: -11.25px; margin-right: -11.25px; }
  .project-inner-img .top-img { margin-bottom: 25px; }

  #project-details .more-projects { margin-top: 35px; }
  #project-details .more-projects span { top: 2px; left: 2px; }
  #project-details .more-projects.ico-25 [class*="flaticon-"]:before, 
  #project-details .more-projects.ico-25 [class*="flaticon-"]:after { font-size: 1.125rem; } 

  /* TEAM */
  .team-member-photo { margin-bottom: 20px; }
  .team-member-data h6 { font-size: 1.4666rem; }
  .team-member-data p { font-size: 1.1rem; }

  /* CAREERS */
  .position-data { margin-bottom: 4px; }
  .position-data p { font-size: 1.125rem; }

  .career-item .col-sm-8 { display: inline-block; width: 60%; float: left; }
  .position-workplace {text-align: left; }
  .position-workplace h6 { font-size: 1.125rem; }

  .career-item .col-sm-4 { display: inline-block; width: 40%; float: none; }

  /* JOB POSITION */
  #job-position .inner-page-title p { margin: 12px 0 10px; padding: 0; }
  #job-position .inner-page-title .btn { font-size: 0.93333rem; padding: 0.7rem 1.1rem; margin-top: 5px; }

  .position-info .btn { margin-top: 16px!important; }

  /* PRICING */
  .p-table { 
    -webkit-box-shadow: 0 4px 12px 0 rgba(0, 0, 0, .08); 
    -moz-box-shadow: 0 4px 12px 0 rgba(0, 0, 0, .08); 
    box-shadow: 0 4px 12px 0 rgba(0, 0, 0, .08); 
  }

  .pricing-1-table, .text-center .pricing-1-table { padding: 30px 30px 25px; }

  .pricing-table-header h5 { font-size: 1.47435rem!important; }

  .pricing-discount { top: 0; right: -2px; padding: 6px 11px; }
  .pricing-1-table .pricing-discount { top: -4px; right: 0; padding: 7px 13px; }
  .text-center .pricing-1-table .pricing-discount { top: -32px; right: -5px; }
  .pricing-discount h6 { font-size: 1.05rem; }
  .pricing-1-table .pricing-discount h6 { font-size: 0.9375rem; }

  .pt-btn { font-size: 1.0625rem; padding: 0.925rem 1.3rem; }
  .pricing-1-table .pt-btn { margin-top: 15px; }
  .p-table p.btn-txt { font-size: 1rem; margin: 11px 0 0 0; }

  /* Price */
  .price span { font-size: 2.564102rem; letter-spacing: -1.5px; } 
  .price sup { font-size: 1.9230769rem; top: -4px; }
  .price sup.validity { font-size: 1.65rem; }

  .text-center .price p { padding: 0; }

  /* Pricing Features */
  .pricing-features.mt-25 { margin-top: 15px; }
  .pricing-features li { padding: 11px 8px; }

  /* Pricing Notice */
  .pricing-notice.mb-40 { margin-bottom: 30px; }
  .col-lg-9 .pricing-notice p, .col-lg-10 .pricing-notice p { padding: 0; }

  /* Pricing Toggle Button */
  .toggle-btn-md .toggler-txt, .toggle-btn-lg .toggler-txt { font-size: 1.15rem; }

  /* Pricing Compare */
  .table-responsive.mb-50 { margin-bottom: 30px; }

  .comp-table-rouded { padding: 10px 10px 0; }
  .table>:not(caption)>*>* { padding: 9px 0; }
  .comp-table .table-responsive thead th { font-size: 0.9rem; }
  .comp-table .table-responsive tbody th { font-size: 0.815rem; line-height: 1.25; }
  .comp-table .table-responsive tbody td { font-size: 0.85rem; top: 0; line-height: 1.25; }

  .comp-table .table-responsive tbody td span { top: 3px; }

  .table-responsive .ico-15 [class*="flaticon-"]:before, 
  .table-responsive .ico-15 [class*="flaticon-"]:after { font-size: 0.675rem; }

  .table-responsive .ico-20 [class*="flaticon-"]:before, 
  .table-responsive .ico-20 [class*="flaticon-"]:after { font-size: 0.835rem; }

  .pbox { padding: 0; }
  #pbox-1, #pbox-2, #pbox-3 { margin-bottom: 30px; }
  .pricing-section .txt-box-las, .pricing-section .pm-lastt { margin-bottom: 40px; }
  .comp-table-payment h6 { margin-bottom: 15px; }

  /* RATING */
  #rb-2-1, #rb-2-2 { margin-bottom: 25px; }

  .rating-title { padding: 0 10%; }
  .rating-title h5 { font-size: 1.125rem; }
  .rbox-1-img { margin-bottom: 14px; }
  .rbox-1-img img { max-height: 32px; }
  .rbox-2-txt img { max-height: 28px; }
  p.rbox-2-txt { font-size: 1.1rem; line-height: 28px; }

  .rbox-1 .star-rating { padding: 5px 16px; font-size: 1rem; line-height: 1; }

  .rbox-1 .star-rating span { top: -2px; }
  .rbox-1 .star-rating.ico-15 [class*="flaticon-"]:before, 
  .rbox-1 .star-rating.ico-15 [class*="flaticon-"]:after { font-size: 1rem; }  
  .rbox-2 .ico-20 [class*="flaticon-"]:before, .rbox-2 .ico-20 [class*="flaticon-"]:after { font-size: 1.35rem; } 

  /* TESTIMONIALS */
  .reviews-3-wrapper, .reviews-4-wrapper { padding: 70px 8%; }

  #reviews-3 .txt-block { padding: 0 3%; margin-bottom: 40px; }

  .review-1, .review-5 { 
    padding: 20px 20px 25px;
    -webkit-box-shadow: 0 4px 12px 0 rgba(0, 0, 0, .08); 
    -moz-box-shadow: 0 4px 12px 0 rgba(0, 0, 0, .08); 
    box-shadow: 0 4px 12px 0 rgba(0, 0, 0, .08); 
  }
  .review-2 { padding: 0; }
  .review-3 { 
    text-align: center; 
    padding: 30px 18px; 
    -webkit-box-shadow: 0 4px 12px 0 rgba(0, 0, 0, .08); 
    -moz-box-shadow: 0 4px 12px 0 rgba(0, 0, 0, .08); 
    box-shadow: 0 4px 12px 0 rgba(0, 0, 0, .08); 
  }

  .review-5-logo { margin-left: 0; margin-bottom: 35px; }
  .review-5-logo img { max-height: 42px; }
  
  .review-1 .review-ico, .review-5 .review-ico { top: 10px; right: 20px; }

  .review-1 .review-rating, .review-5 .review-rating { margin-bottom: 8px; }
  .review-rating.ico-15 [class*="flaticon-"]:before, 
  .review-rating.ico-15 [class*="flaticon-"]:after,
  .review-1 .review-rating.ico-15 [class*="flaticon-"]:before,
  .review-5 .review-rating.ico-15 [class*="flaticon-"]:before, 
  .review-1 .review-rating.ico-15 [class*="flaticon-"]:after,
  .review-5 .review-rating.ico-15 [class*="flaticon-"]:after { font-size: 1.1rem; }

  .review-2-txt h5 { padding: 0 10%; margin: 10px 0; }
  .review-3-txt h6.h6-md, .review-3-txt h6.h6-lg { margin: 5px 0 10px; }
  #reviews-3 .txt-block h2 { margin-bottom: 12px; padding: 0 5%; }
  #reviews-3 .txt-block p { margin-top: 0; }
  #reviews-3 .txt-block .btn { margin-top: 18px; }

  .review-3 .review-avatar { margin: 16px auto 14px; float: none; }

  .review-1 .review-author, .review-5 .review-author { margin-top: 15px; }
  .review-2 .review-author { margin-top: 18px; }
  .review-3 .review-author { text-align: center; padding: 0; margin-top: 0; }

  .review-author h6 { font-size: 1.175rem; margin-bottom: 12px; }
  .review-1 .review-author h6, .review-5 .review-author h6 { font-size: 1.135rem; margin-bottom: 10px; }
  .review-author p, .reviews-4-txt .review-author p { font-size: 1.0625rem; }
  .reviews-section p.p-sm { font-size: 1rem; }

  .review-5-avatar { right: 20px; bottom: 25px; }
  .review-5-avatar img { width: 44px; height: 44px; }

  .reviews-4-img { width: 130px; height: 130px; margin: 0 auto 10px; }
  .reviews-4-txt .review-author, .review-5 .review-author.mt-30 { margin-top: 20px; }

  /* BRANDS */
  .brands-2-wrapper, .brands-3-wrapper { padding: 0 11.7px; }
  .brands-2-wrapper .col, .brands-3-wrapper .col { padding: 0; }

  .brands-title h6 { font-size: 1rem; line-height: 1.55; margin-bottom: 25px; }
  .brands-title h5 { font-size: 1.325rem; margin-bottom: 30px; }
  .brands-title p { padding: 0 5%; margin-bottom: 25px; }

  #brands-4 .txt-block { margin-bottom: 30px; }

  #brand-2-1, #brand-2-2, #brand-2-3, #brand-3-1, 
  #brand-3-2, #brand-3-3, #brand-3-4, #brand-3-5, #brand-3-6 { margin-bottom: 35px; }
  #brand-3-10 { display: none; }
  #brand-4-1, #brand-4-2, #brand-4-3, #brand-4-4, #brand-4-5, #brand-4-6 { margin-bottom: 26px; }

  .brands-carousel-5 .brand-logo, .brands-carousel-6 .brand-logo, #brands-2 .brand-logo, #brands-3 .brand-logo { padding: 0 8px; }
  #brands-4 .brand-logo { padding: 0 4px; }  

  .brands-section .more-btn { margin-top: 25px; }

  /* INTEGRATIONS */
  #integrations-4.pt-100.pb-70 { padding-bottom: 60px; }
  .int-4-wrapper .col { padding: 0 5px; }

  .filter-btns button { font-size: 1.125rem; }

  .int_tool-1 { padding: 18px 22px 16px; margin-bottom: 20px; }
  .int_tool-2 { padding: 16px 26px 18px; }
  .int_tool-3 { padding: 18px; margin-bottom: 20px; }
  .int_tool-4 { padding: 18px 14px; margin-bottom: 10px; }
  .filter-item, .int-2-wrapper .int_tool-2 { margin-bottom: 20px; }

  .int_tool-1 .tool_logo { margin-right: 20px; }
  .int_tool-1 .tool_logo img, .int_tool-3 img, .int_tool-4 img { width: 48px; height: 48px; }

  .tool_txt h6 { font-size: 1.3125rem; line-height: 1; margin-top: 3px; }
  .tool_logo h6 { font-size: 1.3125rem; }
  .int_tool-4 h6 { font-size: 0.95rem; margin-top: 14px; }
  .tool_txt p { font-size: 1.0625rem; margin-top: 3px; }

  .integrations-section .more-btn.mt-30 { margin-top: 18px; }

  /* BANNER */
  #banner-1 .container { max-width: 100%; } 

  .banner-1-wrapper { padding: 65px 18px 70px; margin: 0 -15px; border-radius: 0 0!important; }
  .banner-2-wrapper .banner-overlay { padding: 55px 20px 65px; }
  .banner-4-wrapper { padding: 50px 10%; }

  .banner-4-txt { margin-bottom: 12px; }

  .banner-1-txt h4 { font-size: 1.73334rem; }  /* 26px; */
  .banner-2-txt h2, .banner-3-txt h2 { margin-bottom: 12px; }
  .banner-2-txt.l-txt h2 { padding: 0 5%; margin-bottom: 14px; }
  .banner-3-txt h2.h2-xl, .banner-3-txt h2.h2-title { margin-bottom: 22px; }

  .banner-section p { margin-bottom: 20px; }
  #banner-1.banner-section p { padding: 0 3%; margin: 14px 0 20px; }
  #banner-2.banner-section p { padding: 0; }
  #banner-2.banner-section .l-txt p { padding: 0 4%; margin-bottom: 18px; }
  #banner-3.banner-section p.p-xl { padding: 0 8%; }

  .banner-1-txt .btn { display: block; margin: 14px 8% 0; }
  .banner-2-txt .btns-group .btn { display: block; margin-left: 7%; margin-right: 7%; }

  /* DOWNLOAD */
  .release-data { padding-bottom: 25px; margin-bottom: 25px; } 
  span.version-data { display: block; font-size: 1.75rem; margin-right: 0; margin-bottom: 12px; }
  span.release-date { display: block; font-size: 1.175rem; }
  span.release-date span { margin-left: 10px; }

  /* FAQs */
  #faqs-1 .section-title { margin-bottom: 25px; } 
  #faqs-2 .section-title { margin-bottom: 35px; } 
  #faqs-3 .section-title { margin-bottom: 30px; }

  #faqs-1.faqs-section .accordion-thumb h5 { line-height: 1.5; }
  #faqs-2 .question h6, #faqs-2 .question h5 { font-size: 1.2rem; margin-bottom: 12px; }
  .accordion-thumb h5 { font-size: 1.25rem; line-height: 1.5; padding-right: 8%; }
  .faqs-3-question h5 { font-size: 1.25rem; margin-bottom: 12px; }

  #faqs-1 .accordion-thumb { padding: 13px 0 17px; }
  #faqs-3.faqs-section .accordion-thumb { padding: 20px 0 25px; }

  .accordion-panel { padding: 0 0 10px 0; }
  #faqs-3 .accordion-panel { padding: 30px 0 15px 0; } 
  #faqs-3 .accordion-panel-item.mb-35 { margin-bottom: 25px; }

  #faqs-1.faqs-section .accordion-item .accordion-thumb:after, 
  #faqs-1.faqs-section .accordion-item.is-active .accordion-thumb:after { font-size: 0.875rem; top: 18px; right: 2px; }

  #faqs-3.faqs-section .accordion-item .accordion-thumb:after, 
  #faqs-3.faqs-section .accordion-item.is-active .accordion-thumb:after { font-size: 0.9rem; top: 26px; right: 2px; }

  .more-questions { margin-top: 5px; }
  #faqs-1 .more-questions { margin-top: 25px; }
  #faqs-3 .more-questions { margin: 25px auto 0; }

  .more-questions h6 { font-size: 1.2rem; }
  .more-questions span { display: block; margin-bottom: 2px; }

  .more-questions-txt { padding: 16px 24px; }
  .more-questions-txt p { font-size: 1.1rem; }
  .more-questions .more-questions-txt a { display: inline-block; }

  /* HELP CENTER */
  .help-category { padding: 35px 30px; margin-bottom: 25px; }
  .help-category h6 { margin: 10px 0; }

  /* HELP CATEGORY */
  .help-category-title { padding: 40px 18px 30px; margin-bottom: 40px; }
  #help-category .breadcrumb-wrapper { margin-bottom: 25px; }
  .help-category-title p { margin-top: 10px; }

  #help-category h4 { margin-bottom: 35px; }
  .help-category-item { padding-bottom: 30px; margin-bottom: 30px; }

  .help-category-item p { margin: 16px 0; }
  .help-category-data span { font-size: 1rem; }

  .read-time, .updated-date { display: block; }
  .read-time { margin-top: 3px; margin-left: 0; }

  /* HELP ARTICLE */
  .breadcrumb-wrapper { display: inline-block!important; margin-left: 50px; margin-bottom: 22px; }
  .breadcrumb { margin-bottom: 0; }

  .help-article-title h3 { margin-bottom: 14px; }
  .help-article-title img { margin: 30px 0; }

  .table-of-contents { padding: 25px 0 32px; margin: 30px 0 25px; }
  .table-of-contents h6 { margin-bottom: 15px; }

  #help-article .txt-block { margin: 30px 0; }
  #help-article .txt-block h6 { font-size: 1.215rem; margin: 22px 0; }

  #help-article .txt-block img { margin: 15px 0 20px; }

  .help-vote-form { margin-top: 40px; }
  .help-vote-form h6 { margin-bottom: 20px; }
  .help-vote-form .btns-group .btn:first-child { margin: 0 0 12px; }
  .help-vote-form .btn { width: 100%; }

  /* BLOG */
  .posts-category { margin: 0 0 30px; }
  .posts-category span { top: 1.25px; }
  .posts-category.ico-20 [class*="flaticon-"]:before, 
  .posts-category.ico-20 [class*="flaticon-"]:after { font-size: 1.0625rem; } 

  .square-post .blog-post-txt { padding: 15px 20px 35px; }

  /* SINGLE BLOG POST */
  .single-post-title { margin-bottom: 30px; }
  .single-post-title p.p-lg { margin-top: 12px; }

  #single-post .blog-post-meta { margin-bottom: 12px; }

  #single-post .blog-post-img.pb-60 { padding-bottom: 30px; }

  .single-post-txt .blockquote p { font-size: 1.125rem; margin-bottom: 30px; }
  .single-post-txt .blockquote-footer { font-size: 1.0625rem; line-height: 1.5; }

  .post-inner-img { margin: 25px 0; }

  .post-data { text-align: center; margin-top: 60px; padding: 60px 0; }
  .post-author-avatar { float: none; margin-right: 0; margin-bottom: 10px; }
  .post-author-avatar img { width: 58px; height: 58px; }

  .post-author-txt { top: 0; }
  .post-author-txt p { font-size: 1.0625rem; margin-top: 10px; }

  .post-share-list.text-end { text-align: center!important; margin-top: 12px; }
  .post-share-list p { font-size: 1.0625rem; margin-bottom: 10px; }

  .subscribe-banner { padding: 30px 20px; }

  #post-comments img { width: 40px; height: 40px; }
  .comment-body { margin-left: 12px; }
  .comment-meta { margin-bottom: 7px; }
  .comment-meta h6 { font-size: 1.15rem; margin-bottom: 5px; }
  .comment-date, .btn-reply a { font-size: 0.95rem; } 
  .btn-reply.ico-20 [class*="flaticon-"]:before, .btn-reply.ico-20 [class*="flaticon-"]:after { font-size: 0.95rem; }

  .comment-form { margin-top: 35px; }
  .comment-form p { margin-bottom: 10px; }

  .comment-form .form-control { margin-bottom: 25px; }
  .comment-form textarea { min-height: 150px; }
  .comment-form .btn { font-size: 1.0625rem; height: 52px; }

  /* NEWSLETTER */
  #newsletter-2 .container { max-width: 100%; } 

  #newsletter-1 .newsletter-overlay { padding: 50px 0 40px; }  
  #newsletter-2 .newsletter-overlay { padding: 65px 25px 55px; margin: 0 -15px; border-radius: 0 0!important; }

  .newsletter-txt h4, .newsletter-txt h3 { font-size: 1.6666rem; padding: 0; margin-bottom: 25px; }
  #newsletter-2 .newsletter-txt p { padding: 0; }

  #newsletter-1 .newsletter-form { padding: 0 3%; }
  #newsletter-2 .newsletter-form { padding: 0 3%; margin-top: 25px; }

  .newsletter-section .form-control, .newsletter-section .btn { height: 52px; font-size: 1.0625rem; }
  #newsletter-2.newsletter-section .btn { font-size: 1.0625rem; }

  /* CONTACTS */
  .contacts-info { text-align: center; }
  .contacts-info p { margin: 14px 0 0; padding: 0; }
  .contacts-info h6 { margin: 20px 0 15px; }
  .contacts-info-socials li { padding-right: 12px; }

  .contact-boxes.pb-70 { text-align: center; padding-bottom: 40px; }
  .contact-box p { padding: 0 3%; margin-top: 12px; }
  .contact-box span { top: 0.5px; left: 2px; }

  .contact-form .form-control, 
  .contact-form .form-select { height: 52px; font-size: 1.0625rem; padding: 0 15px; margin-bottom: 15px; }
  .contact-form textarea { min-height: 160px; }
  .contact-form textarea.form-control { padding: 15px; }

  .contact-form .form-btn { margin-top: 5px; }
  .contact-form .form-btn .btn { width: 100%; font-size: 1.0625rem; padding: 0.95rem 1.4rem; }
  .contact-form-msg { text-align: center; margin-top: 15px; }

  /* FOOTER */
  .footer { padding-bottom: 30px; }

  .footer-info, #footer-4 .footer-info, #footer-4 .footer-contacts { margin-bottom: 35px; }
  #footer-1 .footer-contacts { margin-top: -5px; }
  #footer-9 .footer-contacts { margin-top: -5px; margin-bottom: 30px; }
  .footer-links, #footer-7 .footer-connect, #footer-8 .footer-connect { margin-bottom: 25px; }
  #footer-10 .footer-links { margin-bottom: 10px; }
  .footer-form { margin-bottom: 10px; }
  #footer-1 .footer-form { margin-top: 35px; }
  
  .fl-1, .fl-2, .fl-3, .fl-4, #footer-1 .fl-5 { border-bottom: 1px solid #ddd; margin-bottom: 15px; padding-bottom: 16px; }
  #footer-1 .fl-3 { margin-bottom: 16px; }

  #footer-10 .foo-links { display: block; padding-top: 0; }
  #footer-10 .foo-links li { padding: 0 12px; }

  img.footer-logo { max-height: 33px; margin-bottom: 22px; }
  #footer-6 img.footer-logo { margin-bottom: 0; }

  .footer h6 { font-size: 1.2rem; margin-bottom: 20px; } /* 18px */
  .footer .footer-links h6.h6-lg { font-size: 1.275rem; margin-bottom: 18px; }

  .footer-mail-link { margin: 0 0 0 8px; }
  .footer-mail-link a { font-size: 1.1rem; } /* 16.5px */
  .footer-mail-link span { top: 4.5px; right: 5px; }

  .footer-mail-link.ico-20 [class*="flaticon-"]:before, 
  .footer-mail-link.ico-20 [class*="flaticon-"]:after { font-size: 1.275rem; }

  .footer-stores-badge { margin-bottom: 20px; }
  .footer-stores-badge .store { margin-right: 10px; }
  .footer .badge-img-xs .store img { max-height: 2.75rem; }

  .footer-info-socials li { padding-right: 13px; }
  .footer-info-socials.ico-20 [class*="flaticon-"]:before, 
  .footer-info-socials.ico-20 [class*="flaticon-"]:after,
  .footer-info-socials.ico-25 [class*="flaticon-"]:before, 
  .footer-info-socials.ico-25 [class*="flaticon-"]:after { font-size: 1.45rem; }

  .footer-socials a { margin-right: 13px; }
  .footer-socials.ico-25 [class*="flaticon-"]:before, 
  .footer-socials.ico-25 [class*="flaticon-"]:after { font-size: 1.5rem; }

  #footer-1 .footer-form { margin-bottom: 0; }
  .footer-form .form-control { font-size: 1.0625rem; }
  .footer-form .form-control, .footer-form .btn { height: 36px; }
  .footer-form .ico-15 [class*="flaticon-"]:before, 
  .footer-form .ico-15 [class*="flaticon-"]:after { font-size: 1.0625rem; line-height: 26px!important; }

  #footer-1 .bottom-footer { margin-top: 20px; }
  .footer-copyright p, .bottom-footer-list p.p-sm, .bottom-secondary-link p.p-sm { font-size: 1rem; }

  .footer-list-divider { top: 0; }
  .bottom-secondary-link p span { top: 1.5px; padding: 0 2px; }
  .footer-info-copyright p span { top: 2px; padding: 0 2px; }

  .bottom-foo-socials { margin-left: 6px; }
  .bottom-foo-socials li { margin-right: 6px; }
  .bottom-foo-socials li span { top: 2.5px; right: 3px; }

  .bottom-foo-socials.ico-10 [class*="flaticon-"]:before, 
  .bottom-foo-socials.ico-10 [class*="flaticon-"]:after { font-size: 1.125rem; }

  .footer-info-copyright.ico-15 [class*="flaticon-"]:before, 
  .footer-info-copyright.ico-15 [class*="flaticon-"]:after { font-size: 0.815rem; }

  .bottom-footer-socials.ico-20 [class*="flaticon-"]:before, 
  .bottom-footer-socials.ico-20 [class*="flaticon-"]:after { font-size: 1.375rem; }

  /* INNER PAGE WRAPPER */
  .inner-page-title { margin-bottom: 50px; }
  .inner-page-title h2 { margin-bottom: 0; }
  .inner-page-title p { line-height: 1.6666; padding: 0 3%; margin-top: 12px; }

  /* PAGE PAGINATION */
  .page-link { font-size: 0.95rem; padding: 2px 10px; }
  .pagination.ico-20 [class*="flaticon-"]:before, .pagination.ico-20 [class*="flaticon-"]:after { font-size: 0.75rem; } 

  /* TERMS, PRIVACY, COOKIES */
  .txt-block.legal-info h5 { margin: 35px 0 15px; }
  .txt-block.legal-info h6 { margin: 15px 0; }

  /* PAGE PAGINATION */
  .page-link { font-size: 0.95rem; padding: 2px 10px; }
  .pagination.ico-20 [class*="flaticon-"]:before, .pagination.ico-20 [class*="flaticon-"]:after { font-size: 0.75rem; } 

  /* SIGN IN / SIGN UP */
  .login-page-logo { margin-bottom: 40px; }
  .login-page-logo img { max-height: 35px; }

  .login-1 .register-page-form, .signup-1 .register-page-form,
  .login-2 .register-page-form, .signup-2 .register-page-form { 
    margin: 0 2%; 
    padding: 30px 15px 35px; 
    border-radius: 8px; 
    -webkit-border-radius: 8px; 
    -moz-border-radius: 8px; 
    -o-border-radius: 8px; 
    -webkit-box-shadow: 0 4px 12px 0 var(--tra-black);
    -moz-box-shadow: 0 4px 12px 0 var(--tra-black);
    box-shadow: 0 4px 12px 0 var(--tra-black);
  }

  .login-3 .register-page-wrapper, .signup-3 .register-page-wrapper { border-radius: 8px; margin: 0 2%;  }
  .login-3 .register-page-form, .signup-3 .register-page-form { padding: 30px 15px; }

  .reset-page-wrapper form { margin: 0 1%; padding: 35px 12px 5px; }

  .register-form-title { margin-bottom: 20px; }
  .login-2 .register-form-title, .signup-2 .register-form-title, .reset-form-title { margin-bottom: 16px; }

  .register-form-title h5, .register-form-title h4 { margin-bottom: 10px; }

  .register-page-form p.input-header, .register-page-form .btn-google { font-size: 1rem; }
  .reset-password-link p { font-size: 1rem; margin-top: 16px; }
  .login-3 .reset-password-link p { margin-top: 3px; margin-bottom: 24px; }
  .form-data span { font-size: 0.85rem; }
  .reset-password-form .form-data p { font-size: 1rem; margin-bottom: 10px; }
  .register-page-form p.create-account { font-size: 1rem; margin-top: 20px; }
  .reset-form-title p.p-sm { font-size: 1rem; margin-top: 16px; }

  .separator-line { font-size: 1rem;  margin: 15px 0; }

  .reset-page-wrapper .form-control, .register-page-form .form-control { height: 52px; font-size: 1.0625rem; margin-bottom: 16px; }
  #login .register-page-form .btn.submit { height: 52px; font-size: 1.0625rem; margin-top: 0; }
  #signup .register-page-form .btn.submit { height: 52px; font-size: 1.0625rem; margin-top: 18px; }
  .reset-page-wrapper .btn.submit { height: 52px; font-size: 1.0625rem; margin-bottom: 16px; }
  .btn-google img { width: 18px; height: 18px; top: -2px; right: 5px; }

  /* PAGE 404 */
  #page-404 { padding-top: 100px; }
  .page-404-txt h2 { font-size: 2rem; margin-bottom: 14px; }
  .page-404-txt h6 { font-size: 1.125rem; padding: 0 5%; margin-bottom: 26px; }
  .page-404-img { padding: 0 10%; margin-bottom: 30px; }
  .page-404-footer p span { display: block; }

  /* COOKIES */
  #cookies { bottom: 12px; left: 6%; width: 90%; padding: 20px 17px 17px; }
  #cookies p { font-size: 1rem; }
  #cookies-ok { font-size: 0.95rem; padding: 7px 18px; }
  #cookies-info { margin-left: 15px; }
  #cookies-close { right: 14px; top: 5px; }
  #cookies-close svg { width: 12px; height: 12px; }

  /* MODAL */
  #modal-1 .modal-dialog { width: 310px; margin: auto auto; }
  #modal-2 .modal-dialog.modal-xl { width: 320px; margin: auto auto; }

  #modal-1 .modal-body-img { padding-top: 25px; }

  #modal-1 .modal-body-content { padding: 10px 20px 5px; }
  #modal-2 .modal-body-content { padding: 25px 10px 18px; }

  .modal .btn-close { right: 15px!important; top: 15px!important; }

  #modal-2 .request-form-title p { margin-top: 12px; margin-bottom: 18px; }

  .modal-body .newsletter-form { margin-top: 15px; }
  .modal .newsletter-form .form-control,
  #modal-2 .request-form .form-control { height: 52px; font-size: 1.0625rem; margin-bottom: 16px; }
  .modal .newsletter-form .btn, #modal-2 .request-form .btn { height: 52px; font-size: 1.0625rem; }


}




/* ------------------------------------------------------------------------ */
/* ------------------------------------------------------------------------ */
/* ------------------------------------------------------------------------ */

@media (max-width: 320.98px) { 

  /*------------------------------------------*/
  /*  BASE
  /*------------------------------------------*/

  html { font-size: 87.5%; } 

  .block--shadow { -webkit-box-shadow: 0 0; -moz-box-shadow: 0 0; box-shadow: 0 0; }
  .bg--shape:after { width: 1100px; height: 1100px; top: 12%; left: -10%; }
  .bg--shape-top:after { width: 450px; height: 450px; top: -5%; right: 12%; background-color: rgba(119, 67, 142, .15); }

  .py-40 { padding: 30px 0; }
  .py-50 { padding: 35px 0; }
  .py-60 { padding: 40px 0; }
  .py-70 { padding: 40px 0; }
  .py-80 { padding: 40px 0; }
  .py-90 { padding: 50px 0; }
  .py-100 { padding: 60px 0; }

  .pt-40 { padding-top: 30px; }
  .pt-50 { padding-top: 35px; }
  .pt-60 { padding-top: 40px; }
  .pt-70 { padding-top: 40px; }
  .pt-80 { padding-top: 40px; }
  .pt-90 { padding-top: 50px; }
  .pt-100 { padding-top: 60px; }

  .pb-40 { padding-bottom: 30px; }
  .pb-50 { padding-bottom: 35px; }
  .pb-60 { padding-bottom: 40px; }
  .pb-70 { padding-bottom: 40px; }
  .pb-80 { padding-bottom: 40px; }
  .pb-90 { padding-bottom: 50px; }
  .pb-100 { padding-bottom: 60px; }

  .mt-30 { margin-top: 24px; }
  .mt-40 { margin-top: 30px; }
  .mt-45 { margin-top: 30px; }
  .mt-50 { margin-top: 35px; }
  .mt-55 { margin-top: 35px; }
  .mt-60 { margin-top: 40px; }
  .mt-65 { margin-top: 40px; }
  .mt-70 { margin-top: 40px; }
  .mt-75 { margin-top: 40px; }
  .mt-80 { margin-top: 40px; }

  .mb-30 { margin-bottom: 24px; }
  .mb-40 { margin-bottom: 30px; }
  .mb-45 { margin-bottom: 30px; }
  .mb-50 { margin-bottom: 35px; }
  .mb-55 { margin-bottom: 35px; }
  .mb-60 { margin-bottom: 40px; }
  .mb-65 { margin-bottom: 40px; }
  .mb-70 { margin-bottom: 40px; }
  .mb-75 { margin-bottom: 40px; }
  .mb-80 { margin-bottom: 40px; }

  #stlChanger { display: none; }
  .division { padding-left: 0; padding-right: 0; }
  .ts-0 { padding-top: 80px; }
  .bg--fixed { width: auto; }
  .bg--scroll { width: auto; background-attachment: scroll!important; }

  /* Background Shape */
  .shape--01:after { height: 55%; }
  .shape--02:after { height: 55%; top: 45%; }

  /* Headers */
  h6, h6.h6-md, h6.h6-lg, h6.h6-xl { font-size: 1.35714rem; }  /* 19px */
  h5, h5.h5-md, h5.h5-lg, h5.h5-xl, h4, h4.h4-md, h4.h4-lg, h4.h4-xl { font-size: 1.42857rem; }  /* 20px */
  h3, h3.h3-md, h3.h3-lg, h3.h3-xl, h2, h2.h2-md, h2.h2-lg { font-size: 1.57143rem; }            /* 22px */
  h2.h2-xl, h2.h2-title { font-size: 1.85714rem; }  /* 26px */ 

  h6, h5, h4, h3, h2, h1 { line-height: 1.4; }

  /* Paragraphs */
  p.p-sm { font-size: 0.95rem; }
  p { font-size: 1rem; }
  p.p-md, p.p-lg, p.p-xl { font-size: 1.0625rem; }

  ol.digit-list p { margin-bottom: 5px; }

  /* Button */
  .btn.btn-sm, .btn, .btn.btn-md { font-size: 0.9rem; padding: 0.8rem 1.45rem; }
  .btns-group .btn.ico-30.ico-left, .btns-group .btn.ico-30.ico-right { padding: 0.391175rem 0.5rem 0; }

  /* Buttons Group */
  .btns-group .btn:first-child { margin: 0 5px 15px; }

  /* Button Text */
  p.btn-txt { margin: 10px 0 0 0; }
  .text-center p.btns-group-txt { margin-top: 18px; }
  .btn-rating { margin: 15px 0 0 0; }
  .btn-rating p.p-sm { font-size: 0.9375rem; }
  .star-rating small, .star-rating span.rating-txt { font-size: 1rem; }

  .star-rating.ico-20 [class*="flaticon-"]:before, 
  .star-rating.ico-20 [class*="flaticon-"]:after { position: relative; font-size: 1.125rem; top: 2px; }

  /* Button Icon */
  .btn.ico-20 [class*="flaticon-"]:before, 
  .btn.ico-20 [class*="flaticon-"]:after { font-size: 1.15rem; line-height: 0.45rem!important;top: 3.5px; }
  .btn.ico-30 [class*="flaticon-"]:before, .btn.ico-30 [class*="flaticon-"]:after { font-size: 1.85rem; }

  .btn.ico-30.ico-left span.ico-30-txt { font-size: 1.125rem; top: -8px; margin-right: 0; }
  .btn.ico-30.ico-right span.ico-30-txt { font-size: 1.125rem; top: -8px; margin-left: 0; }
  .btn.ico-left span { margin-right: 8px; }
  .btn.ico-right span { margin-left: 8px; }

  /* Transparent Link */
  a.tra-link { font-size: 1.035rem; }
  .tra-link.ico-20 [class*="flaticon-"]:before, 
  .tra-link.ico-20 [class*="flaticon-"]:after { font-size: 0.785rem; line-height: 0.7rem!important; top: 1px; left: 2px; }

  /* Store Badges */
  .store { margin-right: 6px; }
  .store.store-btn-2 { margin-right: 0; }
  .store-btn-3, .store-btn-4 { margin-top: 12px; }

  /* Watch Video Link */
  .watch-video { margin-top: 18px; }
  .watch-video-link { margin-right: 8px; }
  p.video-txt-lg { font-size: 1.0625rem; }
  p.video-txt-sm { font-size: 1rem; }

  /* Video Popup Icon */
  .video-btn-xl { width: 5.625rem; height: 5.625rem; margin-top: -2.8125rem; margin-left: -2.8125rem; }
  .video-btn-lg { width: 5rem; height: 5rem; margin-top: -2.5rem; margin-left: -2.5rem; }
  .video-btn-md { width: 5rem; height: 5rem; margin-top: -2.5rem; margin-left: -2.5rem; }

  .video-btn.video-btn-xl [class^="flaticon-"]:before, .video-btn.video-btn-xl [class^="flaticon-"]:after {
    font-size: 4.5rem; line-height: 5.625rem!important; margin-left: 8px;
  }

  .video-btn.video-btn-lg [class^="flaticon-"]:before, .video-btn.video-btn-lg [class^="flaticon-"]:after,
  .video-btn.video-btn-md [class^="flaticon-"]:before, .video-btn.video-btn-md [class^="flaticon-"]:after {
    font-size: 4.35rem; line-height: 5rem!important; margin-left: 8px;
  }

  /* Vector Icon Background */ 
  .ico-50 .shape-ico svg { width: 100px; height: 100px; top: -25px; left: calc(50% - 55px); }
  .ico-55 .shape-ico svg { width: 105px; height: 105px; top: -25px; left: calc(50% - 55px); }
  .ico-60 .shape-ico svg { width: 110px; height: 110px; top: -25px; left: calc(50% - 55px); }

  /* Vector Rounded Icons */
  .ico-rounded, .ico-rounded-md, .ico-rounded-lg { width: 88px; height: 88px; }

  .ico-rounded [class*="flaticon-"]:before, 
  .ico-rounded [class*="flaticon-"]:after, 
  .ico-rounded-md [class*="flaticon-"]:before, 
  .ico-rounded-md [class*="flaticon-"]:after, 
  .ico-rounded-lg [class*="flaticon-"]:before, 
  .ico-rounded-lg [class*="flaticon-"]:after { font-size: 2.85rem; line-height: 88px!important; }

  /* PNG Icons */
  .img-80 img { width: 70px; height: 70px; }
  .img-75 img { width: 65px; height: 65px; }
  .img-70 img, .img-65 img, .img-60 img { width: 60px; height: 60px; }

  /* Section ID */
  .section-id { font-size: 0.9rem; margin-bottom: 20px; }

  /* Section Title */
  .section-title.mb-70 { margin-bottom: 30px; }
  .section-title h2 { padding: 0 7%; }
  .section-title p { margin-top: 12px; padding: 0 2%; }
  .section-title .btns-group { padding: 0 8%; margin-top: 8px; }
  .section-title .btns-group .btn { width: 100%; margin: 12px 0 0; }

  /* Users */
  .txt-block .users { margin-top: 14px; }
  .users img { max-height: 42px; }
  .txt-block .users img { max-height: 35px; }
  .users p { font-size: 0.975rem; margin-top: 15px; top: 0; left: 0; }
  .txt-block .users p { font-size: 0.9rem; top: 10px; left: 3px; }

  /*------------------------------------------*/
  /*   NAVIGATION MENU    
  /*------------------------------------------*/

  /* Header Logo */
  .wsmobileheader .smllogo { display: block; margin-top: 15px; padding-left: 15px; }
  .smllogo img { width: auto; max-width: inherit; max-height: 50px; }

  .wsmenu > .wsmenu-list { width: 250px; margin-right: -250px; }
  .wsactive .wsmobileheader { margin-right: 250px; }
  .overlapblackbg { width: calc(100% - 250px); }

  .wsanimated-arrow { padding: 14px 30px 14px 0px; margin: 25px 15px 0 0; }

  /* Nav Link Info */
  span.sm-info { width: 20px; height: 20px; font-size: 0.785rem; line-height: 18px; top: -1px; margin-left: 5px; }

  .wsmenu > .wsmenu-list > li > a { padding: 5px 32px 4px 20px; margin: 0; }
  .wsmenu > .wsmenu-list > li > .wsmenu-click > i { height: 8px; width: 8px; margin: 24px 18px 0px 0px; }

  .wsmenu > .wsmenu-list > li > ul.sub-menu, .wsmenu > .wsmenu-list > li > .wsmegamenu.w-75,
  .wsmenu > .wsmenu-list > li > .wsmegamenu.halfmenu { top: 0; }

  .wsmenu > .wsmenu-list > li > ul.sub-menu, .wsmenu > .wsmenu-list > li > .wsmegamenu.halfmenu, 
  .wsmenu > .wsmenu-list > li > .wsmegamenu.w-75 { padding: 6px 10px; }

  .wsmenu > .wsmenu-list > li > ul.sub-menu > li > a, 
  .wsmenu > .wsmenu-list > li > ul.sub-menu > li > a:hover, 
  .wsmenu > .wsmenu-list > li > .wsmegamenu .link-list li a,
  .wsmenu > .wsmenu-list > li > .wsmegamenu .link-list li a:hover,
  .wsmenu > .wsmenu-list > li > ul.sub-menu > li > ul.sub-menu > li > a,
  .wsmenu > .wsmenu-list > li > ul.sub-menu > li > ul.sub-menu > li > a:hover,
  .wsmenu > .wsmenu-list > li > .wsmegamenu.w-75 ul.link-list > li > a, 
  .wsmenu > .wsmenu-list > li > .wsmegamenu.halfmenu ul.link-list > li > a,
  .wsmenu > .wsmenu-list > li > .wsmegamenu.w-75 ul.link-list > li > a:hover, 
  .wsmenu > .wsmenu-list > li > .wsmegamenu.halfmenu ul.link-list > li > a:hover { padding: 13px 9px; }

  /* Header Store Badges */
  .store.header-store img { max-height: 42px; }

  /* Header Social Links */
  .header-socials.ico-20 [class*="flaticon-"]:before, 
  .header-socials.ico-25 [class*="flaticon-"]:after { line-height: 52px!important; } 

  .wsmenu > .wsmenu-list > li > .wsmegamenu ul li.title { margin-top: 12px; } 

  /*------------------------------------------*/
  /*   HERO   
  /*------------------------------------------*/

  .hero-section.blur--purple:after { height: 90%; top: -34%; left: 10%; }

  .slideshow, #hero-20 .slideshow, .slideshow .slides, #hero-20 .slideshow .slides { height: 420px; }

  .img-link { bottom: 20px; right: 20px; width: 42px; height: 42px; }
  .img-link a span { top: 4.5px; }

  .hero-section .btns-group { display: block; }
  .hero-section .btns-group .btn { display: block; padding: 1rem 1.5rem; margin: 0 10%; }
  .hero-section .btns-group .btn:first-child { margin-bottom: 12px; }

  /* Hero Advantages */
  .hero-section .text-center .advantages { margin-top: 15px; }
  .hero-section .advantages li { display: block!important; margin-top: 3px; }
  .hero-section .advantages li p { display: block; float: none; }
  .hero-section li.advantages-links-divider { display: none!important; }

  /* HERO QUICK FORM */
  .hero-section .quick-form .form-control, .hero-section .quick-form .btn { height: 48px; font-size: 1.1rem; }
  .hero-section .quick-form.form-half .form-control { height: 48px; font-size: 1.1rem; padding: 0 14px; }
  .hero-section .quick-form.form-half .btn { height: 48px; font-size: 1.1rem; }

  .hero-section .text-center .quick-form-msg { margin: 20px 0 -10px 0; }

  /* HERO DIGITS */
  #hd-1-1, #hd-1-2 { width: 100%; padding: 0; } 
  #hd-1-1 { margin-bottom: 20px; } 
  .hero-digits h2.statistic-number { font-size: 2.75rem; }
  .hero-digits h2.statistic-number small { font-size: 2rem; margin-left: 3px; }

  /* HERO-1 */
  #hero-1 { padding: 60px 0; }
  .hero-1-txt h2 { font-size: 1.85rem; }
  .hero-1-txt p.p-lg, .hero-1-txt p.p-xl { margin: 14px 0 20px; }
  .hero-1-txt .btn.btn-md { display: block; padding: 1rem 1.5rem; margin: 0 8%; }
  .hero-1-txt .star-rating small { display: block; }
  .hero-1-img { margin: 30px 0 0; }

  /* HERO-2 */
  #hero-2 { padding-top: 70px; }
  #hero-2:after { height: 7%; }
  .hero-2-txt h2 { font-size: 2.15rem; padding: 0 2%; }
  .hero-2-txt p.p-lg { padding: 0; margin-top: 12px; margin-bottom: 22px; }
  .hero-2-img { margin-top: 30px; }

  /* HERO-3 */
  #hero-3 { padding-top: 70px; padding-bottom: 70px; }
  .hero-3-txt h2 { font-size: 2.15rem; }
  .hero-3-txt p.p-md { margin-top: 12px; }
  .hero-3-txt .users { margin-top: 20px; }
  .hero-3-img { margin-top: 30px; }

   /* HERO-4 */
   #hero-4 { padding-top: 70px; margin-bottom: 60px; }
  .hero-4-txt h2 { font-size: 1.85rem; padding: 0 2%; }
  .hero-4-txt p.p-lg { margin: 12px 0 22px; padding: 0 5%; }
  .hero-4-img { margin: 30px 0 -35px; }

  /* HERO-5 */
  #hero-5 { padding-top: 70px; }
  #hero-5:after { width: 100%; height: 96%; left: 0; }
  .hero-5-txt h2 { font-size: 2.05rem; }
  .hero-5-txt p.p-md { margin: 14px 0 20px; }
  .hero-5-img { margin: 35px 0 0; }

  /* HERO-6 */
  #hero-6 { padding-top: 70px; padding-bottom: 70px; }
  .hero-6-txt h2 { font-size: 2rem; }
  .hero-6-txt p.p-lg { margin-top: 14px; }
  #hero-6 .quick-form { margin: 18px 2% 0; }
  .hero-6-img { margin-top: 30px; }

  /* HERO-7 */
  #hero-7 { padding-top: 60px;  background-position: top center; }
  .hero-7-txt h2 { font-size: 2rem; }
  .hero-7-txt p.p-lg, .hero-7-txt p.p-xl { margin: 14px 0 20px; padding: 0 3%; }
  #hero-7 .users { margin-top: 25px; }
  #hero-7 .users img { max-height: 43px; }
  #hero-7 .users p { font-size: 1rem; top: 0; left: 0; margin-top: 12px; }
  .hero-7-img { margin: 30px 2% 0; }

  /* HERO-8 */
  .hero-8-txt h2 { font-size: 2.15rem; padding: 0 2%; }
  .hero-8-txt p.p-lg { padding: 0; margin-top: 12px; margin-bottom: 22px; }
  .hero-8-txt p.btn-txt { margin-top: 25px; }
  #hero-8 .quick-form { margin: 22px 4% 0; }
  .hero-8-img { margin-top: 35px; }

  /* HERO-9 */
  #hero-9 { padding-top: 70px; padding-bottom: 70px; }
  #hero-9 .wave-shape-bottom svg { height: 45px; }
  .hero-9-txt h2 { font-size: 1.9rem; }
  .hero-9-txt p.p-lg { margin-top: 14px; padding: 0 3%; }
  #hero-9 .quick-form { margin: 22px 4% 0; }
  .hero-9-img { margin-top: 30px; }

  /* HERO-10 */
  #hero-10 { padding-top: 70px; margin-bottom: 35px; }
  #hero-10 .container { max-width: 100%; }
  .hero-10-txt { margin-bottom: 40px; }
  .hero-10-txt h2 { font-size: 2.15rem; padding: 0 5%; }
  .hero-10-txt p.p-lg { padding: 0; margin-top: 12px; margin-bottom: 22px; }

  .hero-10-wrapper { overflow: visible; margin: 0 -15px; border-radius: 0 0!important; }
  #hero-10 .hero-overlay { padding: 55px 20px 0; }
  .hero-10-img { margin-bottom: -35px; }
  .hero-10-img .video-btn { top: calc(50% - 2px); }

  /* HERO-11 */
  #hero-11 { padding: 60px 0; }
  .hero-11-txt h2 { font-size: 1.85rem; }
  .hero-11-txt p.p-md { margin: 14px 0 25px; padding: 0; }
  .hero-11-img { margin: 30px 0 0; }

  /* HERO-12 */
  #hero-12 { padding-top: 0; }
  #hero-12 .container { max-width: 100%; }
  .hero-12-wrapper { padding: 60px 20px; margin: 0 -15px; border-radius: 0 0!important; }
  .hero-12-txt h2 { font-size: 1.85rem; padding: 0 6%; }
  .hero-12-txt p.p-lg {margin: 12px 0 22px; }
  .hero-12-img { margin-top: 30px; }

  /* HERO-13 */
  #hero-13 { padding-top: 70px; }
  .hero-13-txt h2 { font-size: 2.05rem; padding: 0 2%; }
  .hero-13-txt p.p-xl { padding: 0 3%; margin-top: 15px; }
  #hero-13 .quick-form { margin: 25px 4% 0; }
  .hero-13-txt p.btn-txt { margin-top: 30px; }

  /* HERO-14 */
  #hero-14 { padding-top: 70px; }
  #hero-14:after { height: 7%; }
  .hero-14-txt .section-id { padding: 10px 25px; font-size: 0.9rem; margin-bottom: 25px; }
  .hero-14-txt h2 { font-size: 1.9rem; }
  .hero-14-txt p.p-md { margin: 15px 0 25px; }
  .hero-14-img { margin-top: 30px; }
  
  /* HERO-15 */
  #hero-15 .hero-overlay { padding-top: 70px; padding-bottom: 70px; }
  .hero-15-txt h2 { font-size: 2.15rem; }
  .hero-15-txt p.p-lg { padding: 0; margin-top: 12px; margin-bottom: 22px; }

  /* HERO-16 */
  #hero-16 { padding-top: 70px; padding-bottom: 60px; }
  .hero-16-txt h2 { font-size: 1.9rem; }
  .hero-16-txt p.p-lg { margin-top: 12px; padding: 0 2%; }
  #hero-16 .quick-form { margin: 18px 2% 0; }
  #hero-16 .users { margin-top: 25px; }
  .hero-16-img { margin-top: 30px; }

  /* HERO-17 */
  #hero-17 { padding-top: 70px; }
  #hero-17:after { height: 7%; }
  .hero-17-txt h2 { font-size: 1.9rem; padding: 0 2%; }
  .hero-17-txt p.p-xl { padding: 0 2%; margin-top: 12px; }
  .hero-17-txt .btns-group { margin-top: 18px; }
  .hero-17-img { margin-top: 40px; }

  /* HERO-18 */
  #hero-18 { padding-top: 70px; }
  .hero-18-txt { margin-bottom: 40px; }
  .hero-18-txt .section-id { margin-bottom: 25px; }
  .hero-18-txt h2 { font-size: 1.86667rem; }
  .hero-18-txt p.p-xl { padding: 0; margin-top: 12px; }
  .hero-18-txt .btns-group { padding: 0 3%; margin-top: 20px; }
  .hero-18-txt .btns-group .btn { width: 100%; margin: 0; }
  .hero-18-txt .btns-group .btn:first-child { margin-bottom: 12px; }

  .h18-1 { display: none }
  .h18-2 { margin-bottom: 24px; }

  .hero-bg-shape { width: 220%; height: 100%; left: -32%; right: -32%; opacity: .3; }

  /* HERO-19 */
  #hero-19 { padding-top: 70px; }
  .hero-19-txt h2 { font-size: 2.05rem; padding: 0 2%; }
  .hero-19-txt p.p-xl { padding: 0 3%; margin-top: 15px; }
  #hero-19 .quick-form { margin: 25px 4% 0; }
  .hero-19-txt p.btn-txt { margin-top: 30px; }
  .hero-19-img { margin-top: 35px; }

  /* HERO-20 */
  #hero-20 .caption { padding: 0 3%; }
  #hero-20 .caption h2 { font-size: 1.85714rem; padding: 0; }
  #hero-20 .caption p.p-xl { font-size: 1.0625rem; padding: 0; margin-top: 12px; }
  #hero-20 .caption .btn { margin-top: 22px; }

  /* HERO-21 */
  #hero-21 { padding-top: 70px; }
  #hero-21:after { height: 12%; }
  .hero-21-shape { width: 110%; top: 4.5%; left: -5%; opacity: .8; }

  .hero-21-txt h2 { font-size: 1.9rem; padding: 0 2%; }
  .hero-21-txt p.p-xl { padding: 0 2%; margin-top: 12px; }
  .hero-21-txt .btns-group { margin-top: 18px; }
  .hero-21-img { margin-top: 40px; }

  /* HERO-22 */
  #hero-22 .caption h2 { font-size: 1.85714rem; padding: 0; }
  #hero-22 .caption p.p-xl { font-size: 1.0625rem; padding: 0; margin-top: 12px; }
  #hero-22 .caption .btn { margin-top: 22px; }

  /* HERO-23 */
  #hero-23 { padding-top: 70px; }
  .hero-23-txt h2 { font-size: 2rem; padding: 0 3%; }
  .hero-23-txt p.p-lg { padding: 0 2%; margin-top: 12px; margin-bottom: 22px; }
  .hero-23-img { margin-top: 30px; }

  /* HERO-24 */
  #hero-24 { padding-top: 70px; margin-bottom: 40px; }
  .hero-24-txt h2 { font-size: 2.15rem; padding: 0 5%; }
  .hero-24-txt p.p-lg { margin-top: 15px; }
  .hero-24-txt .users { margin-top: 22px; }

  #hero-24-form { padding: 25px 15px; margin: 50px 1% -40px; }
  #hero-24-form h5 { font-size: 1.5rem; margin-bottom: 12px; }
  #hero-24-form p { margin-bottom: 16px; }
  #hero-24-form p.p-sm { padding: 0!important; }
  #hero-24-form .btn { height: 48px; font-size: 1.0625rem; padding: 16px; }

  /* HERO-25 */
  #hero-25 { padding-top: 70px; }
  .hero-25-txt h2 { font-size: 2rem; }
  .hero-25-txt p.p-lg { padding: 0 2%; margin-top: 14px; margin-bottom: 22px; }
  .hero-25-img { margin-top: 30px; }

  /* HERO-26 */
  #hero-26 { padding-top: 70px; }
  .hero-26-txt h2 { font-size: 1.85714rem; }
  .hero-26-txt p.p-lg { padding: 0; margin-top: 12px; margin-bottom: 22px; }
  .hero-26-img { margin: 35px -20% 0; }

  /* HERO-27 */
  .hero-27-txt h2 { font-size: 1.825rem; margin-bottom: 20px; }
  .hero-27-txt p { margin-top: 22px; margin-bottom: 30px; }

  #hero-27 .img-link { bottom: 9px; left: 9px; width: 45px; height: 45px; }
  #hero-27 .img-link a span { top: 4px; }

  /* HERO REQUEST FORM */
  .request-form .form-control { height: 48px; font-size: 1.0625rem; margin-bottom: 15px; }

  /*------------------------------------------*/
  /*  CONTENT
  /*------------------------------------------*/

  /* ABOUT */
  .about-1-title { margin-bottom: 25px; }
  .about-1-title p { margin-top: 14px; }

  #a3-1 { margin-bottom: 18px; }

  .about-1-img { margin: 0 -30%; }

  /* FEATURES */
  #features-3.py-100.shape--bg:after, #features-4.py-100.shape--bg:after { height: 69%; top: 31%; }
  #features-3.pt-100.shape--bg:after { height: 64%; top: 34%; }
  #features-4.pt-100.shape--bg:after { height: 65%; top: 32.5%; left: 0; }

  #features-3 .txt-block, #features-4 .txt-block { margin-bottom: 30px; }

  .fb-1, .fb-2, .rows-2 .fb-1, .rows-2 .fb-2, .rows-2 .fb-3, .rows-2 .fb-4, .rows-2 .fb-5,.row-cols-md-2 .fb-3, 
  .rows-3 .fb-1, .rows-3 .fb-2, .rows-3 .fb-3, .rows-3 .fb-4, .rows-3 .fb-5, .rows-4 .fb-1, .rows-4 .fb-2, .rows-4 .fb-3, 
  .rows-4 .fb-4, .rows-4 .fb-5, .rows-4 .fb-6,#fb-3-1, #fb-3-2, #fb-3-3, #fb-4-1, #fb-4-2, #fb-4-3, .fbox-10.fb-1,
  .fbox-9.fb-1, .fbox-9.fb-2, .fbox-9.fb-4, .fbox-9.fb-5, .fbox-9.fb-7, .fbox-9.fb-8, .fbox-10.fb-3, .fbox-10.fb-5, 
  .fbox-10.fb-7 { margin-bottom: 30px; }

  .fbox-11.fb-1, .fbox-11.fb-2 { margin-bottom: 22px; }

  .rows-4 .fbox-2.fb-1, .rows-4 .fbox-2.fb-2, .rows-4 .fbox-2.fb-3, .rows-4 .fbox-2.fb-4, 
  .rows-4 .fbox-2.fb-5, .rows-4 .fbox-2.fb-6 { margin-bottom: 30px; }

  .fbox-6.fb-1, .fbox-6.fb-2, .fbox-6.fb-3, .fbox-6.fb-4 { margin-bottom: 30px; }
  .fbox-12.fb-1, .fbox-12.fb-2, .fbox-12.fb-3 { margin-bottom: 20px; }

  .rows-4 .fb-8 { margin-top: 30px; }
  #features-12 .txt-block { margin-bottom: 25px; }

  .fbox-3, .fbox-4, .fbox-6 { 
    padding: 30px 23px;
    -webkit-box-shadow: 0 4px 12px 0 rgba(0, 0, 0, .08); 
    -moz-box-shadow: 0 4px 12px 0 rgba(0, 0, 0, .08); 
    box-shadow: 0 4px 12px 0 rgba(0, 0, 0, .08); 
  }
  .fbox-5 { padding: 30px 16px; } 
  .fbox-6 { text-align: center; padding: 30px 16px; }
  .fbox-9 { padding: 35px 18px; }
  .ico-title .fbox-9.fb-1, .ico-title .fbox-9.fb-2, .ico-title .fbox-9.fb-3 { padding: 30px 18px; }
  .fbox-9.fb-4, .fbox-9.fb-5, .fbox-9.fb-6, .ico-title .fbox-9.fb-4, 
  .ico-title .fbox-9.fb-5, .ico-title .fbox-9.fb-6 { padding: 30px 18px 0; }
  .fbox-10, .fbox-10.fb-5, .fbox-10.fb-6, .fbox-10.fb-7, .fbox-10.fb-8 { padding: 35px 18px 30px; } 
  .ico-title .fbox-10.fb-5, .ico-title .fbox-10.fb-6,
  .ico-title .fbox-10.fb-7, .ico-title .fbox-10.fb-8 { padding: 30px 18px 20px; } 

  .fbox-img.h-175 img, .fbox-img.h-180 img { max-height: 150px; } 

  .fbox-7 .fbox-img { margin-bottom: 20px; }
  .fbox-8 .fbox-img.h-175, .fbox-8 .fbox-img.h-180 { padding-top: 25px; margin: 0 18px 45px; } 

  .fbox-9.fb-1 .fbox-9-img, .fbox-9.fb-2 .fbox-9-img, .fbox-9.fb-3 .fbox-9-img { margin: 20px 0 0; }
  .fbox-9.fb-4 .fbox-9-img, .fbox-9.fb-5 .fbox-9-img, .fbox-9.fb-6 .fbox-9-img { margin: 20px 0 -18px; }
  .fbox-9.fb-7 .fbox-9-img, .fbox-9.fb-8 .fbox-9-img, .fbox-9.fb-9 .fbox-9-img { margin: 0 0 20px; }

  .fbox-10.fb-1 .fbox-10-img, .fbox-10.fb-2 .fbox-10-img, 
  .fbox-10.fb-3 .fbox-10-img, .fbox-10.fb-4 .fbox-10-img { margin: 0 0 20px; }
  .fbox-10.fb-7 .fbox-10-img, .fbox-10.fb-8 .fbox-10-img { margin: 20px 0 0; }
  .fbox-10.fb-5 .fbox-10-img, .fbox-10.fb-6 .fbox-10-img { margin: 0 0 25px; }
  .ico-title .fbox-10.fb-5 .fbox-10-img, .ico-title .fbox-10.fb-6 .fbox-10-img { margin: 20px 0 0; }

  .fbox-ico, .fbox-ico.ico-rounded { margin-bottom: 14px; }
  .fbox-5 .fbox-ico, .fbox-6 .fbox-ico { margin-bottom: 15px; }
  .fbox-ico[class*="img-"], .fbox-ico.ico-rounded-md, .fbox-ico.ico-rounded-lg { margin-bottom: 16px; }

  .fbox-wrapper .ico-50 [class*="flaticon-"]:before, .fbox-wrapper .ico-50 [class*="flaticon-"]:after,
  .fbox-wrapper .ico-55 [class*="flaticon-"]:before, .fbox-wrapper .ico-55 [class*="flaticon-"]:after,
  .fbox-wrapper .ico-60 [class*="flaticon-"]:before, .fbox-wrapper .ico-60 [class*="flaticon-"]:after { font-size: 3.6rem; }
  .fbox-ico-wrap .ico-40 [class*="flaticon-"]:before, .fbox-ico-wrap .ico-40 [class*="flaticon-"]:after { font-size: 3rem; }

  .t-icon { margin-bottom: 2px; }
  .t-icon.ico-25 [class*="flaticon-"]:before, .t-icon.ico-25 [class*="flaticon-"]:after { font-size: 1.625rem; }
  .fbox-11-ico { margin-bottom: 12px; }

  .fbox-3 h6, .fbox-4 h6 { margin-top: 18px; margin-bottom: 10px; }
  .fbox-2 h5, .fbox-wrapper .row-cols-md-2 h6, 
  .fbox-wrapper .row-cols-md-3 h6, .fbox-10 h5, .fbox-6 h6, .fbox-9 h6, .fbox-12 h5 { margin-bottom: 12px; }
  .fbox-10.fb-5 h5, .fbox-10.fb-6 h5, .ico-title .fbox-10.fb-5 h5, .ico-title .fbox-10.fb-6 h5 { margin-bottom: 14px; }
  .fbox-11 h6 { margin-bottom: 12px; }

  .fbox-1 p { padding: 0 2%; }
  .fbox-7 p, .fbox-8 p { padding: 0 2%; }

  .fbox-5 .txt-block-tra-link { margin-top: 12px; }

  /* CONTENT */
  .bc-02.pt-30.ws-wrapper, .bc-02.pb-30.ws-wrapper { padding-top: 30px; }
  .bc-02.pt-30.bc-02-top { padding-top: 20px; margin-top: 40px; }
  .bc-02 .container { max-width: 100%; }
  .bc-02.pt-30 .container, .bc-02.pb-30 .container { max-width: auto; }
  .bc-02-wrapper { margin: 0 -15px; border-radius: 0 0!important; }
  .bc-02.pt-30 .bc-02-wrapper, .bc-02.pb-30 .bc-02-wrapper { margin: 0; border-radius: 12px!important; }

  .ct-05 .section-title, .ct-06 .section-title { margin-bottom: 30px; }
  .bc-03-wrapper .section-title { margin-bottom: 25px; }
  .ct-06 .section-title p { font-size: 1rem; }
  .bc-03 .section-title p { font-size: 1rem; padding: 0 3%; margin-top: 12px; }
  
  .ct-05.pt-100, .ct-06 .section-overlay { margin-bottom: 30px; }
  .ct-05.pt-100.ct-05-img { margin-bottom: 0; }
  .bc-01 .section-overlay, .bc-02 .section-overlay { padding: 60px 20px; }
  .bc-02 .ico-title .section-overlay { padding: 60px 20px; }
  .bc-02.pt-30 .section-overlay, .bc-02.pb-30 .section-overlay { padding: 30px 18px; }
  .bc-03 .section-overlay { padding: 60px 20px 0; }

  .ct-02-top { margin-bottom: 40px; }
  .txt-box, .bc-01 .txt-box { margin-bottom: 15px; }
  .bc-04-box { 
    padding: 30px 16px; 
    -webkit-box-shadow: 0 4px 12px 0 rgba(0, 0, 0, .08); 
    -moz-box-shadow: 0 4px 12px 0 rgba(0, 0, 0, .08); 
    box-shadow: 0 4px 12px 0 rgba(0, 0, 0, .08); 
  }

  .cbox-6 { text-align: center; }

  .two-txt .txt-block.left-column { margin-bottom: 30px; }
  .two-txt .cbox-3, .two-txt .cbox-3 .ico-wrap { text-align: left!important; }

  .txt-block h3, .txt-block h2, .txt-block h5 { margin-bottom: 14px; }
  h5.h5-title { margin: 12px 0; }

  .bc-04-box h2 { font-size: 2.75rem; margin-bottom: 12px; }
  .bc-04-box h2 small, .bc-04-box h2 span { font-size: 2.15rem; }
  .cbox-5 p.p-sm { font-size: 1rem; }

  .txt-block .btn { margin-top: 20px; }
  .txt-block-tra-link { margin-top: 16px; }

  .cbox-1 .ico-wrap { margin-right: 0.7rem; }
  .cbox-1-ico { width: 1.4rem; height: 1.4rem; font-size: 0.85rem; line-height: 1.1rem; margin-top: 6px; }

  .cbox-3 .cbox-3-ico { margin-bottom: 10px; }
  .cbox-3-ico span[class*="flaticon-"]:before, .cbox-3-ico span[class*="flaticon-"]:after { font-size: 3.25rem; }

  .cbox-title { margin-bottom: 10px; }
  .cbox-title span {display: block; float: none; line-height: 1!important; margin: 0 0 12px 0; }
  .cbox-title span[class*="flaticon-"]:before, .cbox-title span[class*="flaticon-"]:after { font-size: 3rem; }

  .cbox-1-txt { margin-bottom: 20px; }
  .cbox-3-txt { margin-bottom: 30px; }
  .cbox-6-txt { margin-bottom: 25px; }
  .cbox-1-txt h5, .cbox-3-txt h5 { margin-bottom: 10px; }

  .cbox-4 { width: 100%; min-width: auto; padding: 12px 16px; }
  .cbox-4.mt-20 { margin-top: 8px; }
  .cbox-4.mb-30 { margin-bottom: 20px; }
  .cbox-4-ico span { position: relative; top: 3px; }
  .cbox-4-txt { padding-left: 11px; }

  .cbox-5-wrapper { margin-top: 0; }
  .box-title { margin-bottom: 12px; }
  .cbox-5.cb-2 { margin-top: 15px; }
  .box-title span { top: 10px; left: 3px; right: 0; }
  .box-title h6 { position: relative; top: 2px; left: 8px; }

  .txt-block .accordion-1 .accordion-item { padding: 18px 0 23px; }
  .txt-block .accordion-1 .accordion-item .accordion-thumb:after,
  .txt-block .accordion-1 .accordion-item.is-active .accordion-thumb:after { font-size: 1rem; top: 0px; right: 5px; }

  .txt-block .accordion-1 .accordion-thumb h5 { padding-left: 0; }
  .txt-block .accordion-1 .accordion-item.is-active .accordion-thumb h5 { margin-bottom: 18px; }
  .txt-block .accordion-1 .accordion-panel { padding: 0; }

  /* Advantages */
  .advantages, .text-center .advantages { margin-top: 15px; }
  .advantages li { display: block!important; margin-top: 4px; }
  .advantages li p { display: block; float: none; }
  li.advantages-links-divider { display: none!important; }

  /* Compatibility */
  .compatibility { margin-top: 10px; }

  /* Tools List / Payments Icons */
  .tools-list p, .payment-methods p { margin-bottom: 12px; }
  .tools-list span { padding-right: 1px; }
  .payment-icons img { max-height: 26px; }

  .tools-list.ico-35 [class*="flaticon-"]:before, .tools-list.ico-35 [class*="flaticon-"]:after { 
    font-size: 2rem; line-height: 2rem!important; margin-right: 3px; 
  }

  /* Content Images */
  .ct-07-images { margin: 30px 0; }
  .content-section .container.hidd { overflow: visible!important; }
  .img-block-hidden, .ct-06 .img-block { margin-bottom: -30px; }
  .img-block-txt { margin-top: 30px; }
  .img-block-btn { padding: 0 8%; margin-top: 30px; }
  .img-block-btn .btn { width: 100%; }

  .bc-3-img .video-btn { top: calc(50% - 25px); }

  .ct-01 .img-block.right-column, .ct-02 .img-block.right-column, .ct-03 .img-block.right-column,
  .ct-01 .img-block.left-column, .ct-02 .img-block.left-column, .ct-03 .img-block.left-column { margin: 0 0 30px; }
  .ct-04 .img-block img { margin-bottom: 35px; }

  .bc-01 .img-block, .bc-2-img.right-column, .bc-2-img.left-column { margin-bottom: 30px; }
  .bc-3-img { margin-bottom: -60px; }
  
  .img-content.py-100 { padding: 35px 0; }

  /* STATISTIC */
  .statistic-4-wrapper, .statistic-8-wrapper { padding: 0 5%; }
  .statistic-7-wrapper { padding: 60px 35px; }
  .statistic-7-wrapper .col-lg-4 { margin-bottom: 30px; }
  .txt-block-stat.mt-30 { margin-top: 20px; }

  #statistic-6 .statistic-block-txt { padding: 0 4%; }
  #statistic-6 .statistic-block-txt:before { width: 70px; margin: 12px auto 14px; }

  #sb-1-1, #sb-1-2, #sb-2-1, #sb-5-1, #sb-5-2, #sb-5-3, 
  #sb-6-1, #sb-6-2, #sb-7-1, #sb-7-2, #sb-9-1, #sb-9-2 { margin-bottom: 30px; }
  #sb-3-2 { padding-left: 4%; }
  #sb-4-1, #sb-4-2, #sb-8-1, #sb-8-2 { padding-bottom: 30px; margin-bottom: 30px; }

  h3.statistic-number { font-size: 2.6rem; line-height: 1; letter-spacing: -1px; margin-bottom: 12px; }
  h2.statistic-number { font-size: 2.75rem; line-height: 1; letter-spacing: -1px; margin-bottom: 12px; }
  .statistic-2-wrapper h2,  #sb-3-1 h2.statistic-number, #sb-3-2 h2.statistic-number { margin-bottom: 12px; }
  .statistic-4-wrapper h5 { margin: 20px 0 12px; }
  .statistic-5-wrapper h3 { font-size: 2.25rem; line-height: 1; }
  #statistic-6 h2.statistic-number { font-size: 3.65rem; letter-spacing: -2px; }
  #statistic-6 h2.statistic-number small { font-size: 2.25rem; margin-left: 4px; }
  .statistic-9-wrapper h3, .statistic-9-wrapper h2 { margin: 20px 0 12px; }

  .statistic-5-wrapper p { margin-top: 12px; }
  .statistic-8-wrapper p { margin-top: 15px; }
  
  #sb-2-1 br { display: none; }
  #sb-2-2 { clear: both; }
  #sb-2-2 .txt-block-rating { display: inline-block; }
  #sb-2-2 p { display: inline-block; margin-left: 5px; }

  .statistic-8-wrapper img { max-height: 42px; margin-top: 16px; }
  .statistic-section .action-btn { margin-top: 40px; }

  /* PROJECTS */
  #portfolio-1.pb-70 { padding-bottom: 35px; }

  #portfolio-1 .filter-btns.mb-70 { margin-bottom: 25px; }
  #portfolio-1 .filter-item { margin-bottom: 25px; }

  .project-category { left: 20px; }
  .project-category p { padding: 7px 16px; margin-bottom: 0; }

  .project-data-txt { bottom: 30px; }
  .project-data-txt h5 { font-size: 1.4rem; padding-right: 20%; }

  .project-source { width: 32px; height: 32px; bottom: 15px; }
  .project-source a span { top: 3px; }

  .filter-item:hover .project-category { top: 16px; }
  .filter-item:hover .project-data-txt { left: 20px; }
  .filter-item:hover .project-source { right: 16px; }

  #portfolio-1 .more-btn { margin-top: 10px; }

  /* PROJECT DETAILS */
  .project-title { padding: 0; margin-bottom: 30px; }
  .project-title p { margin-top: 15px; }
  .project-data { padding-bottom: 19px; margin: 25px 0; }
  .project-data p { margin-bottom: 6px; }

  .project-txt h5 { margin: 25px 0 14px; }
  .project-txt h6 { font-size: 1.215rem; margin: 14px 0; }

  .project-inner-img { margin: 30px 0; }
  .project-inner-img.d-flex { margin-left: -10.5px; margin-right: -10.5px; }
  .project-inner-img .top-img { margin-bottom: 25px; }

  #project-details .more-projects { margin-top: 35px; }
  #project-details .more-projects span { top: 2px; left: 2px; }
  #project-details .more-projects.ico-25 [class*="flaticon-"]:before, 
  #project-details .more-projects.ico-25 [class*="flaticon-"]:after { font-size: 1.125rem; } 

  /* TEAM */
  .team-member-photo { margin-bottom: 20px; }
  .team-member-data h6 { font-size: 1.5rem; }
  .team-member-data p { font-size: 1.1rem; }

  /* CAREERS */
  .position-data { margin-bottom: 4px; }
  .position-data p { font-size: 1.125rem; }

  .career-item .col-sm-8 { display: inline-block; width: 60%; float: left; }
  .position-workplace { text-align: left; }
  .position-workplace h6 { font-size: 1.125rem; }

  .career-item .col-sm-4 { display: inline-block; width: 40%; float: none; }

  /* JOB POSITION */
  #job-position .inner-page-title p { margin: 12px 0 10px; padding: 0; }
  #job-position .inner-page-title .btn { font-size: 0.9rem; padding: 0.7rem 1.25rem; margin-top: 5px; }

  .position-info .btn { margin-top: 15px!important; }

  /* PRICING */
  .p-table { 
    margin-bottom: 30px; 
    -webkit-box-shadow: 0 4px 12px 0 rgba(0, 0, 0, .08); 
    -moz-box-shadow: 0 4px 12px 0 rgba(0, 0, 0, .08); 
    box-shadow: 0 4px 12px 0 rgba(0, 0, 0, .08); 
  }

  .pricing-1-table, .text-center .pricing-1-table { padding: 30px 30px 25px; }

  .pricing-table-header h5 { font-size: 1.4285714rem!important; }

  .pricing-discount { top: 0; right: -2px; padding: 6px 11px; }
  .pricing-1-table .pricing-discount { top: -4px; right: 0; padding: 7px 13px; }
  .text-center .pricing-1-table .pricing-discount { top: -32px; right: -5px; }
  .pricing-discount h6 { font-size: 1rem; }
  .pricing-1-table .pricing-discount h6 { font-size: 0.9rem; }

  .pt-btn { font-size: 1.0625rem; padding: 0.935rem 1.3rem; }
  .pricing-1-table .pt-btn { margin-top: 14px; }
  .p-table p.btn-txt { font-size: 1rem; margin: 11px 0 0 0; }

  /* Price */
  .price span { font-size: 2.75rem; letter-spacing: -1.5px; } 
  .price sup { font-size: 2.3rem; top: -2px; }
  .price sup.validity { font-size: 1.65rem; }

  .text-center .price p { padding: 0; }

  /* Pricing Features */
  .pricing-features.mt-25 { margin-top: 16px; }
  .pricing-features li { padding: 10px 8px; }

  /* Pricing Notice */
  .pricing-notice.mb-40 { margin-bottom: 20px; }
  .col-lg-9 .pricing-notice p, .col-lg-10 .pricing-notice p { padding: 0; }

  /* Pricing Toggle Button */
  .toggle-btn-md .toggler-txt, .toggle-btn-lg .toggler-txt { font-size: 1.15rem; }

  /* Pricing Compare */
  .table-responsive.mb-50 { margin-bottom: 30px; }

  .comp-table-rouded { padding: 10px 10px 0; }
  .table>:not(caption)>*>* { padding: 9px 0; }
  .comp-table .table-responsive thead th { font-size: 0.9rem; }
  .comp-table .table-responsive tbody th { font-size: 0.815rem; line-height: 1.25; }
  .comp-table .table-responsive tbody td { font-size: 0.85rem; top: 0; line-height: 1.25; }

  .comp-table .table-responsive tbody td span { top: 3px; }

  .table-responsive .ico-15 [class*="flaticon-"]:before, 
  .table-responsive .ico-15 [class*="flaticon-"]:after { font-size: 0.675rem; }

  .table-responsive .ico-20 [class*="flaticon-"]:before, 
  .table-responsive .ico-20 [class*="flaticon-"]:after { font-size: 0.835rem; }

  #pbox-1, #pbox-2, #pbox-3 { margin-bottom: 30px; }
  .pricing-section .txt-box-last, .pricing-section .pm-last { margin-bottom: 40px; }
  .comp-table-payment h6 { margin-bottom: 14px; }

  /* RATING */
  #rb-2-1, #rb-2-2 { margin-bottom: 25px; }

  .rating-title { padding: 0 8%; }
  .rating-title h5 { font-size: 1.125rem; }
  .rbox-1-img { margin-bottom: 14px; }
  .rbox-1-img img { max-height: 32px; }
  .rbox-2-txt img { max-height: 28px; }
  p.rbox-2-txt { font-size: 1.0625rem; line-height: 28px; }

  .rbox-1 .star-rating { padding: 5px 16px; font-size: 1rem; line-height: 1; }

  .rbox-1 .star-rating span { top: -2px; }
  .rbox-1 .star-rating.ico-15 [class*="flaticon-"]:before, 
  .rbox-1 .star-rating.ico-15 [class*="flaticon-"]:after { font-size: 1rem; } 
  .rbox-2 .ico-20 [class*="flaticon-"]:before, .rbox-2 .ico-20 [class*="flaticon-"]:after { font-size: 1.35rem; } 

  /* TESTIMONIALS */
  .reviews-3-wrapper { padding: 60px 30px; }
  .reviews-4-wrapper { padding: 60px 5%; }

  #reviews-3 .txt-block { margin-bottom: 35px; }

  .review-1, .review-5 { 
    padding: 20px 20px 25px;
    -webkit-box-shadow: 0 4px 12px 0 rgba(0, 0, 0, .08); 
    -moz-box-shadow: 0 4px 12px 0 rgba(0, 0, 0, .08); 
    box-shadow: 0 4px 12px 0 rgba(0, 0, 0, .08); 
  }
  .review-2 { padding: 0; }
  .review-3 { 
    text-align: center; 
    padding: 25px 20px 30px; 
    -webkit-box-shadow: 0 4px 12px 0 rgba(0, 0, 0, .08); 
    -moz-box-shadow: 0 4px 12px 0 rgba(0, 0, 0, .08); 
    box-shadow: 0 4px 12px 0 rgba(0, 0, 0, .08); 
  }

  .review-5-logo { margin-left: 0; margin-bottom: 30px; }
  .review-5-logo img { max-height: 40px; }

  .review-1 .review-ico, .review-5 .review-ico { top: 10px; right: 20px; }

  .review-1 .review-rating, .review-5 .review-rating { margin-bottom: 8px; }
  .review-rating.ico-15 [class*="flaticon-"]:before, 
  .review-rating.ico-15 [class*="flaticon-"]:after,
  .review-1 .review-rating.ico-15 [class*="flaticon-"]:before,
  .review-5 .review-rating.ico-15 [class*="flaticon-"]:before,  
  .review-1 .review-rating.ico-15 [class*="flaticon-"]:after,
  .review-5 .review-rating.ico-15 [class*="flaticon-"]:after { font-size: 1.1rem; }

  .review-2-txt h5 { padding: 0 10%; margin: 10px 0; }
  .review-3-txt h6.h6-md, .review-3-txt h6.h6-lg { margin: 5px 0 10px; }
  #reviews-3 .txt-block h2 { margin-bottom: 12px; padding: 0 5%; }
  #reviews-3 .txt-block p { margin-top: 0; }
  #reviews-3 .txt-block .btn { margin-top: 18px; }

  .review-3 .review-avatar { margin: 15px auto 12px; float: none; }

  .review-1 .review-author, .review-5 .review-author { margin-top: 15px; }
  .review-2 .review-author { margin-top: 18px; }
  .review-3 .review-author { text-align: center; padding: 0; margin-top: 0; }

  .review-author h6 { font-size: 1.175rem; margin-bottom: 8px; }
  .review-1 .review-author h6, .review-5 .review-author h6 { font-size: 1.135rem; margin-bottom: 9px; }
  .review-author p, .reviews-4-txt .review-author p { font-size: 1.0625rem; }
  .reviews-section p.p-sm { font-size: 1rem; }

  .review-5-avatar { right: 20px; bottom: 25px; }
  .review-5-avatar img { width: 42px; height: 42px; }

  .reviews-4-img { width: 130px; height: 130px; margin: 0 auto 10px; }
  .reviews-4-txt .review-author, .review-5 .review-author.mt-30 { margin-top: 20px; }

  /* BRANDS */
  .brands-2-wrapper, .brands-3-wrapper { padding: 0 10.5px; }
  .brands-2-wrapper .col, .brands-3-wrapper .col { padding: 0; }

  .brands-title h6 { font-size: 1rem; line-height: 1.55; margin-bottom: 25px; }
  .brands-title h5 { font-size: 1.25rem; margin-bottom: 25px; }
  .brands-title p { padding: 0 5%; margin-bottom: 20px; }

  #brands-4 .txt-block { margin-bottom: 25px; }

  #brand-2-1, #brand-2-2, #brand-2-3, #brand-3-1, 
  #brand-3-2, #brand-3-3, #brand-3-4, #brand-3-5, #brand-3-6 { margin-bottom: 30px; }
  #brand-3-10 { display: none; }
  #brand-4-1, #brand-4-2, #brand-4-3, #brand-4-4, #brand-4-5, #brand-4-6 { margin-bottom: 26px; }

  .brands-carousel-5 .brand-logo, .brands-carousel-6 .brand-logo, #brands-2 .brand-logo, #brands-3 .brand-logo { padding: 0 8px; } 
  #brands-4 .brand-logo { padding: 0 2px; }

  .brands-section .more-btn { margin-top: 25px; }

  /* INTEGRATIONS */
  #integrations-4.pt-100.pb-70 { padding-bottom: 50px; }
  .int-4-wrapper .col { padding: 0 5px; }
  .filter-btns button { font-size: 1.15rem;  }

  .int_tool-1 { padding: 16px 22px 14px; margin-bottom: 20px; }
  .int_tool-2 { padding: 16px 26px 18px; }
  .int_tool-3 { padding: 16px; margin-bottom: 20px; }
  .int_tool-4 { padding: 16px 12px; margin-bottom: 10px; }
  .filter-item, .int-2-wrapper .int_tool-2 { margin-bottom: 20px; }

  .int_tool-1 .tool_logo { margin-right: 20px; }
  .int_tool-1 .tool_logo img { width: 46px; height: 46px; }
  .int_tool-3 img { width: 45px; height: 45px; }
  .int_tool-4 img { width: 45px; height: 45px; }

  .tool_txt h6 { font-size: 1.3125rem; line-height: 1; margin-top: 3px; }
  .tool_logo h6 { font-size: 1.3125rem; }
  .int_tool-4 h6 { font-size: 0.95rem; margin-top: 14px; }
  .tool_txt p { font-size: 1.0625rem; margin-top: 3px; }
  .tool_description p { margin-top: 8px; }

  .integrations-section .more-btn.mt-30 { margin-top: 15px; }

  /* BANNER */
  #banner-1 .container { max-width: 100%; } 
  .banner-1-wrapper { padding: 55px 18px 60px; margin: 0 -15px; border-radius: 0 0!important; }
  .banner-2-wrapper .banner-overlay { padding: 55px 15px 65px; }
  #banner-3 .banner-overlay.pt-80.pb-90 { padding: 50px 0 60px; } 
  .banner-4-wrapper { padding: 50px 10%; }

  .banner-4-txt { margin-bottom: 10px; }

  .banner-1-txt h4 { font-size: 1.714285rem; }  /* 24px; */
  .banner-2-txt h3, .banner-2-txt h2, .banner-3-txt h2 { margin-bottom: 10px; }
  .banner-2-txt.l-txt h2 { padding: 0 4%; }
  .banner-3-txt h2.h2-xl, .banner-3-txt h2.h2-title { margin-bottom: 22px; }

  .banner-section p { margin-bottom: 18px; }
  #banner-1.banner-section p { padding: 0 5%; margin: 15px 0 20px; }
  #banner-2.banner-section .l-txt p { padding: 0 3%; }
  #banner-3.banner-section p.p-xl { padding: 0 3%; margin-bottom: 20px; }

  .banner-1-txt .btn { display: block; margin: 14px 8% 0; }
  .banner-2-txt .btns-group .btn { display: block; margin-left: 5%; margin-right: 5%; }
  .banner-section p.btn-txt { padding: 0; margin-top: 13px; margin-bottom: 0!important; }

  /* DOWNLOAD */
  #download-1 { padding-bottom: 30px; }
  .version-release { margin-bottom: 30px; }
  .release-data h5 { margin-bottom: 30px!important; }

  .release-data { padding-bottom: 20px; margin-bottom: 20px; } 
  span.version-data { display: block; font-size: 1.8rem; margin-right: 0; margin-bottom: 12px; }
  span.release-date { display: block; font-size: 1.2rem; }
  span.release-date span { margin-left: 8px; }
  .release-download { margin-top: 20px; }

  /* FAQs */
  #faqs-1 .section-title, #faqs-3 .section-title { margin-bottom: 25px; } 
  #faqs-2 .section-title { margin-bottom: 35px; } 

  #faqs-2 .question { margin-bottom: 15px; }

  #faqs-1.faqs-section .accordion-thumb h5 { line-height: 1.5; }

  #faqs-2 .question h6, #faqs-2 .question h5 { font-size: 1.21428rem; margin-bottom: 14px; }
  .accordion-thumb h5 { font-size: 1.25rem; line-height: 1.5; padding-right: 8%; }
  .faqs-3-question h5 { font-size: 1.21428rem; margin-bottom: 12px; }

  #faqs-1 .accordion-thumb { padding: 13px 0 17px; }
  .accordion-thumb { padding: 14px 0 18px; }
  #faqs-3.faqs-section .accordion-thumb { padding: 16px 0 21px; }

  .accordion-panel { padding: 0 0 10px 0; }
  #faqs-3 .accordion-panel { padding: 25px 0 10px 0; } 
  #faqs-3 .accordion-panel-item.mb-35 { margin-bottom: 20px; }

  #faqs-1.faqs-section .accordion-item .accordion-thumb:after, 
  #faqs-1.faqs-section .accordion-item.is-active .accordion-thumb:after { font-size: 0.875rem; top: 16px; right: 2px; }

  #faqs-3.faqs-section .accordion-item .accordion-thumb:after, 
  #faqs-3.faqs-section .accordion-item.is-active .accordion-thumb:after { font-size: 0.875rem; top: 20px; right: 2px; }

  .more-questions { margin-top: 5px; }
  #faqs-1 .more-questions { margin-top: 25px; }
  #faqs-3 .more-questions { margin: 22px auto 0; }

  .more-questions h6 { font-size: 1.2rem; }
  .more-questions span { display: block; margin-bottom: 2px; }

  .more-questions-txt { padding: 16px 24px; }
  .more-questions-txt p { font-size: 1.1rem; }
  .more-questions .more-questions-txt a { display: inline-block; }

  /* HELP CENTER */
  .help-category { padding: 35px 25px; margin-bottom: 25px; }
  .help-category h6 { margin: 10px 0; }

  /* HELP CATEGORY */
  .help-category-title { padding: 40px 18px 30px; margin-bottom: 40px; }
  #help-category .breadcrumb-wrapper { margin-bottom: 20px; }
  .help-category-title p { margin-top: 10px; }

  #help-category h4 { margin-bottom: 35px; }
  .help-category-item { padding-bottom: 30px; margin-bottom: 30px; }

  .help-category-item p { margin: 14px 0 16px; }
  .help-category-data span { font-size: 1rem; }

  .read-time, .updated-date { display: block; }
  .read-time { margin-top: 3px; margin-left: 0; }

  /* HELP ARTICLE */
  .breadcrumb-wrapper { display: inline-block!important; margin-left: 45px; margin-bottom: 18px; }
  .breadcrumb { margin-bottom: 0; }

  .help-article-title h3 { margin-bottom: 12px; }
  .help-article-title img { margin: 30px 0; }

  .table-of-contents { padding: 25px 0 32px; margin: 30px 0 25px; }
  .table-of-contents h6 { margin-bottom: 15px; }

  #help-article .txt-block { margin: 30px 0; }
  #help-article .txt-block h6 { font-size: 1.215rem; margin: 22px 0 12px; }

  #help-article .txt-block img { margin: 15px 0 20px; }

  .help-vote-form { margin-top: 40px; }
  .help-vote-form h6 { margin-bottom: 20px; }
  .help-vote-form .btns-group .btn:first-child { margin: 0 0 12px; }
  .help-vote-form .btn { width: 100%; }

  /* BLOG */
  #blog-page .section-title p { margin-top: 14px; }

  .square-post .blog-post-txt { padding: 15px 20px 40px; }

  /* SINGLE BLOG POST */
  .single-post-title { margin-bottom: 30px; }
  .single-post-title p.p-lg { margin-top: 12px; }

  #single-post .blog-post-meta { margin-bottom: 12px; }

  #single-post .blog-post-img.pb-60 { padding-bottom: 30px; }

  .single-post-txt .blockquote p { font-size: 1.125rem; margin-bottom: 30px; }
  .single-post-txt .blockquote-footer { font-size: 1.0625rem; line-height: 1.5; }

  .post-inner-img { margin: 25px 0; }

  .post-data { text-align: center; margin-top: 50px; padding: 50px 0; }
  .post-author-avatar { float: none; margin-right: 0; margin-bottom: 10px; }
  .post-author-avatar img { width: 50px; height: 50px; }

  .post-author-txt { top: 0; }
  .post-author-txt p { font-size: 1.0625rem; margin-top: 10px; }

  .post-share-list.text-end { text-align: center!important; margin-top: 12px; }
  .post-share-list p { font-size: 1.0625rem; margin-bottom: 10px; }

  .subscribe-banner { padding: 30px 20px; }

  #post-comments img { width: 36px; height: 36px; }
  .comment-body { margin-left: 12px; }
  .comment-meta { margin-bottom: 7px; }
  .comment-meta h6 { font-size: 1.15rem; margin-bottom: 5px; }
  .comment-date, .btn-reply a { font-size: 0.95rem; } 
  .btn-reply.ico-20 [class*="flaticon-"]:before, .btn-reply.ico-20 [class*="flaticon-"]:after { font-size: 0.95rem; }

  .comment-form { margin-top: 35px; }
  .comment-form p { margin-bottom: 10px; }

  .comment-form .form-control { margin-bottom: 25px; }
  .comment-form textarea { min-height: 140px; }
  .comment-form .btn { font-size: 1.1rem; height: 48px; }

  /* NEWSLETTER */
  #newsletter-2 .container { max-width: 100%; } 

  #newsletter-1 .newsletter-overlay { padding: 50px 0 40px; }  
  #newsletter-2 .newsletter-overlay { padding: 55px 18px 45px; margin: 0 -15px; border-radius: 0 0!important; }

  .newsletter-txt h4, .newsletter-txt h3 { font-size: 1.642857rem; padding: 0; margin-bottom: 22px; }
  #newsletter-2 .newsletter-txt p { padding: 0; }

  #newsletter-1 .newsletter-form { padding: 0 3%; }
  #newsletter-2 .newsletter-form { padding: 0 3%; margin-top: 25px; }

  .newsletter-section .form-control, .newsletter-section { height: 48px; font-size: 1.0625rem; }
  #newsletter-2.newsletter-section .btn { font-size: 1.0625rem; }

  /* CONTACTS */
  .contacts-info { text-align: center; }
  .contacts-info p { margin: 14px 0 0; padding: 0; }
  .contacts-info h6 { margin: 20px 0 14px; }
  .contacts-info-socials li { padding-right: 12px; }

  .contact-boxes.pb-70 { text-align: center; padding-bottom: 30px; }
  .contact-box p { padding-right: 0; margin-top: 12px; }
  .contact-box span { top: 0.5px; left: 2px; }

  .contact-form .form-control, 
  .contact-form .form-select { height: 48px; font-size: 1.0625rem; padding: 0 15px; margin-bottom: 15px; }
  .contact-form textarea { min-height: 160px; }
  .contact-form textarea.form-control { padding: 15px; }

  .contact-form .form-btn { margin-top: 5px; }
  .contact-form .form-btn .btn { width: 100%; font-size: 1.0625rem; padding: 0.95rem 1.4rem; }
  .contact-form-msg { text-align: center; margin-top: 15px; }
  
  /* FOOTER */
  .footer { padding-bottom: 30px; }

  .footer-info, #footer-4 .footer-info, #footer-4 .footer-contacts { margin-bottom: 30px; }
  #footer-1 .footer-contacts { margin-top: -5px; }
  #footer-9 .footer-contacts { margin-top: -5px; margin-bottom: 30px; }
  .footer-links, #footer-7 .footer-connect, #footer-8 .footer-connect { margin-bottom: 20px; }
  #footer-10 .footer-links { margin-bottom: 10px; }
  .footer-form { margin-bottom: 10px; }
  #footer-1 .footer-form { margin-top: 35px; }
  
  .fl-1, .fl-2, .fl-3, .fl-4, #footer-1 .fl-5 { border-bottom: 1px solid #ddd; margin-bottom: 15px; padding-bottom: 16px; }
  #footer-1 .fl-3 { margin-bottom: 16px; }

  #footer-10 .foo-links { display: block; padding-top: 0; }
  #footer-10 .foo-links li { padding: 0 10px; }

  img.footer-logo { max-height: 32px; margin-bottom: 22px; }
  #footer-6 img.footer-logo { margin-bottom: 0; }

  .footer h6 { font-size: 1.21428rem; margin-bottom: 20px; }  /* 17px */
  .footer .footer-links h6.h6-lg { font-size: 1.275rem; margin-bottom: 18px; }

  .footer-mail-link { margin: 0 0 0 6px; } 
  .footer-mail-link a { font-size: 1.10714rem; }  /* 15.5px */
  .footer-mail-link span { top: 4.5px; right: 5px; }

  .footer-mail-link.ico-20 [class*="flaticon-"]:before, 
  .footer-mail-link.ico-20 [class*="flaticon-"]:after { font-size: 1.275rem; }

  .foo-links.ico-10 li span { top: 1px; right: 3px; }

  .footer-stores-badge { margin-top: 5px; margin-bottom: 30px; }
  .footer-stores-badge .store { display: block!important; text-align: left; margin-bottom: 15px; margin-right: 0; }
  .footer .badge-img-xs .store img { max-height: 2.85rem; }

  .footer-info-socials li { padding-right: 13px; }
  .footer-info-socials.ico-20 [class*="flaticon-"]:before, 
  .footer-info-socials.ico-20 [class*="flaticon-"]:after,
  .footer-info-socials.ico-25 [class*="flaticon-"]:before, 
  .footer-info-socials.ico-25 [class*="flaticon-"]:after { font-size: 1.45rem; }

  .footer-socials a { margin-right: 13px; }
  .footer-socials.ico-25 [class*="flaticon-"]:before, 
  .footer-socials.ico-25 [class*="flaticon-"]:after { font-size: 1.5rem; }

  #footer-1 .footer-form { margin-bottom: 0; }
  .footer-form .form-control { font-size: 1.0625rem; }
  .footer-form .form-control, .footer-form .btn { height: 34px; }
  .footer-form .ico-15 [class*="flaticon-"]:before, 
  .footer-form .ico-15 [class*="flaticon-"]:after { font-size: 1.0625rem; line-height: 22px!important; }

  #footer-1 .bottom-footer { margin-top: 20px; }
  .footer-copyright p, .bottom-footer-list p.p-sm, .bottom-secondary-link p.p-sm { font-size: 1rem; }

  .footer-list-divider { top: 0; }
  .bottom-secondary-link p span { top: 1.5px; padding: 0 2px; }
  .footer-info-copyright p span { top: 2px; padding: 0 2px; }

  .footer-info-copyright.ico-15 [class*="flaticon-"]:before, 
  .footer-info-copyright.ico-15 [class*="flaticon-"]:after { font-size: 0.815rem; }

  .bottom-foo-socials { margin-left: 6px; }
  .bottom-foo-socials li { margin-right: 6px; }
  .bottom-foo-socials li span { top: 2.5px; right: 3px; }

  .bottom-foo-socials.ico-10 [class*="flaticon-"]:before, 
  .bottom-foo-socials.ico-10 [class*="flaticon-"]:after { font-size: 1.125rem; }

  .bottom-footer-socials.ico-20 [class*="flaticon-"]:before, 
  .bottom-footer-socials.ico-20 [class*="flaticon-"]:after { font-size: 1.375rem; }

  /* INNER PAGE WRAPPER */
  .inner-page-hero, .page-hero-section-overlay { padding-top: 60px; }

  .inner-page-title { margin-bottom: 40px; }
  .inner-page-title h2 { margin-bottom: 0; }
  .inner-page-title p { line-height: 1.6666; padding: 0 3%; margin-top: 12px; }

  /* PAGE PAGINATION */
  .page-link { font-size: 0.95rem; padding: 2px 10px; }
  .pagination.ico-20 [class*="flaticon-"]:before, .pagination.ico-20 [class*="flaticon-"]:after { font-size: 0.75rem; } 

  /* SIGN IN / SIGN UP */
  .login-page-logo { margin-bottom: 35px; }
  .login-page-logo img { max-height: 30px; }

  .login-1 .register-page-form, .signup-1 .register-page-form,
  .login-2 .register-page-form, .signup-2 .register-page-form { 
    margin: 0; 
    padding: 25px 12px 30px; 
    border-radius: 8px; 
    -webkit-border-radius: 8px; 
    -moz-border-radius: 8px; 
    -o-border-radius: 8px; 
    -webkit-box-shadow: 0 4px 12px 0 var(--tra-black);
    -moz-box-shadow: 0 4px 12px 0 var(--tra-black);
    box-shadow: 0 4px 12px 0 var(--tra-black);
  }

  .login-3 .register-page-wrapper, .signup-3 .register-page-wrapper { border-radius: 8px; margin: 0; }
  .login-3 .register-page-form, .signup-3 .register-page-form { padding: 25px 12px; }

  .reset-page-wrapper form { margin: 0 1%; padding: 32px 10px 5px; }

  .register-form-title { margin-bottom: 20px; }
  .login-2 .register-form-title, .signup-2 .register-form-title, .reset-form-title { margin-bottom: 15px; }

  .register-form-title h5, .register-form-title h4 { margin-bottom: 10px; }

  .register-page-form p.input-header, .register-page-form .btn-google { font-size: 1rem; }
  .reset-password-link p { font-size: 1rem; margin-top: 16px; }
  .login-3 .reset-password-link p { margin-top: 0; margin-bottom: 20px; }
  .form-data span { font-size: 0.85rem; }
  .reset-password-form .form-data p { font-size: 1rem; margin-bottom: 10px; }
  .register-page-form p.create-account { font-size: 1rem; margin-top: 20px; }
  .reset-form-title p.p-sm { font-size: 1rem; margin-top: 16px; }

  .separator-line { font-size: 1rem;  margin: 15px 0; }

  .reset-page-wrapper .form-control, .register-page-form .form-control { height: 48px; font-size: 1.0625rem; margin-bottom: 16px; }
  #login .register-page-form .btn.submit { height: 48px; font-size: 1.0625rem; margin-top: 0; }
  #signup .register-page-form .btn.submit { height: 48px; font-size: 1.0625rem; margin-top: 18px; }
  .reset-page-wrapper .btn.submit { height: 48px; font-size: 1.0625rem; margin-bottom: 16px; }
  .btn-google img { width: 18px; height: 18px; top: -2px; right: 5px; }

  /* PAGE 404 */
  #page-404 { padding-top: 80px; }
  .page-404-txt { padding-bottom: 70px; }
  .page-404-txt h2 { font-size: 2rem; margin-bottom: 10px; }
  .page-404-txt h6 { font-size: 1.125rem; padding: 0 5%; margin-bottom: 22px; }
  .page-404-img { padding: 0 10%; margin-bottom: 25px; }
  .page-404-footer p span { display: block; }

  /* COOKIES */
  #cookies { bottom: 12px; left: 3%; width: 94%; padding: 20px 15px 15px; }
  #cookies p { font-size: 1em; }
  #cookies-ok { font-size: 0.95rem; padding: 7px 18px; }
  #cookies-info { margin-left: 15px; }
  #cookies-close { right: 14px; top: 6px; }
  #cookies-close svg { width: 12px; height: 12px; }

  /* MODAL */
  #modal-1 .modal-dialog, #modal-2 .modal-dialog.modal-xl { width: 290px; margin: auto auto; }

  #modal-1 .modal-body-img { padding-top: 20px; }

  #modal-1 .modal-body-content { padding: 10px 18px 5px; }
  #modal-2 .modal-body-content { padding: 25px 5px 15px; }

  .modal .btn-close { right: 15px!important; top: 15px!important; }

  #modal-2 .request-form-title p { margin-top: 12px; margin-bottom: 16px; }

  .modal-body .newsletter-form { margin-top: 15px; }
  .modal .newsletter-form .form-control, 
  #modal-2 .request-form .form-control { height: 48px; font-size: 1.0625rem; margin-bottom: 16px; }
  .modal .newsletter-form .btn, #modal-2 .request-form .btn { height: 48px; font-size: 1.0625rem; }


}